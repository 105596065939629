/* Ensure the main container takes the full viewport height and width */
.page-not-found-main-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; 
    box-sizing: border-box; 
}


.card-page-not-found {
    /* border-top: 10px solid #1F8BD4; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    max-width: 100%; 
    max-height: 100%;
    overflow-y: none; 
    /* background-color: #fff; */
}

.page-not-found-main-backgournd{
    background-image: url('../../../assets/svg/page-not-found-background.svg');
    background-position: center;
     background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
    width: 900px;
}
.not-found-content{
    display: flex;
    width: 90%;
    height: 100%;
    align-items: center;
    justify-content: end;


}
.check-mark{
    width:180px;
     height:180px;
}
.success-text{
    font-size: 7rem;
    font-weight: 900;
    color: var(--color-darkorange);
}
.not-found-text{
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--color-midnightblue);
}
@media (max-width:1050px) {
    
    .page-not-found-main-backgournd{
      
        height: 450px;
        width: 600px;
    }
    .check-mark{
        width:120px;
         height:120px;
    }
}
@media (max-width:650px) {
    
    .page-not-found-main-backgournd{
      
        height: 250px;
        width: 400px;
    }
    .check-mark{
        width:60px;
         height:60px;
    }
    .success-text{
        font-size: 1.8rem;
        font-weight: 700;
     
    }
    .not-found-text{
        font-size: 0.5rem;
        font-weight: 700;
        color: var(--color-midnightblue);
    }
}
@media (max-width:450px) {
    .page-not-found-main-backgournd{
      
        height: 200px;
        width: 300px;
    }  
    .success-text{
        font-size: 1.0rem;
        font-weight: 700;
     
    }
    .not-found-content{
       
       
        height: 100%;
       
    
    
    }
}