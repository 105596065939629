body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  
    font-family: 'Nunito', sans-serif;
  
}
#root {
  overflow: auto;
  height: 100vh; /* Viewport height */
  display: flex;
  flex-direction: column;
}

/* Ensure the container for your content can scroll */






ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  border: none;
  background: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* Predefine Style */
.py-120 {
  padding: 120px 0 !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.py-100 {
  padding: 100px 0 !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.py-80 {
  padding: 80px 0 !important;
}




/* Button */


.navbar .navbar-nav .nav-link {
  font-size: 18px;
  letter-spacing: -0.2px;
  line-height: initial;
  color: #02274a;
  padding: 15px 0;
  margin: 0 26px;
  position: relative;
}

.navbar .dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 0 15px;
  letter-spacing: 0px;
  text-transform: capitalize;
  line-height: 40px;
  margin-bottom: 5px;
  color: white;
  background: transparent;
  display: inline-block;
  position: relative;
  transition: all 0.35s;
  white-space: inherit;
}

.navbar .dropdown-menu .dropdown:hover>.dropdown-item,
.navbar .dropdown-menu .dropdown-item.active,
.navbar .dropdown-menu .dropdown-item:hover {
  color: #edcb88;
}

.navbar .dropdown-toggle::after {

  font-family: "Font Awesome 6 Free";
  border: none !important;
  font-size: 12px;
  font-weight: 900;
  position: absolute;
  line-height: 30px;
  color: black;
  top: calc(50% - 15px);
  text-align: center;
  transition: all 0.3s ease;
}

.navbar .dropdown-submenu:hover>.dropdown-toggle::after {
  transform: rotate(90deg);
}

.header-primary.sticky {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #080e26;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  width: 100%;
  z-index: 1000;
  transition: 0.3s ease-in;
  -webkit-animation: upsdown 1000ms;
  animation: upsdown 1000ms;
}

/*----- For Desktop -------*/
@media screen and (min-width: 199.5px) {
  .header-primary {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
    background-color: transparent;
  }

  .header-primary .navbar .navbar-nav .nav-link {
    color: white;
  }

  .header-primary .navbar .navbar-nav .nav-link::after {
    color: white;
  }

  .navbar .navbar-nav .nav-link {
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: initial;
    color: white;
    font-weight: 500;
    padding: 35px 0;
    margin: 0 20px;
    position: relative;
  }

  .nav-item:hover .nav-link::after {
    transform: rotate(-180deg);
    color: white;
  }

  .navbar .dropdown-menu {
    background-color: #04091e;
    right: 0;
    left: 0;
    padding: 10px;
    border: none;
    top: 100%;
    visibility: hidden;
    transform: scale(1, 0);
    opacity: 0;
    min-width: 200px;
    box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.12);
    transform-origin: 0 0;
    transition: all 0.3s ease-in-out;

    /* Ensure the dropdown menu is above other elements */
    z-index: 999; /* Adjust this value as needed */
}

.navbar .dropdown:hover > .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}


  .navbar .dropdown-menu .dropdown-menu {
    left: calc(100% + 10px);
    top: 0;
    right: auto;
    min-width: 240px;
    box-shadow: 0 20px 30px -10px rgb(0, 0, 0, 0.15);
    transform: scale(0, 1);
  }

  .navbar .dropdown:hover>.dropdown-menu {
    opacity: 1;
    visibility: visible;
    /* transform: scale(1); */
  }

  .navbar .navbar-nav .nav-item.dropdown:hover .nav-link.dropdown-toggle {
    transition: all 0.4s ease-in-out;
  }

  .navbar .navbar-nav .nav-item.dropdown:hover .nav-link.dropdown-toggle::after {
    transform: rotate(180deg);
    color: var(--interface-4);
  }
}

/*----- For Mobile ----*/


/* Navbar Toggler */

/* Hero One End */

/* Hero Two Start */
.aai-hero-two-content {
  padding: 150px 0 200px 0;
}

.aai-hero-two-title {
  font-size: 72px;
  font-weight: var(--font-bold);
  color: var(--white);
  line-height: 1.3;
  margin-bottom: 30px;
}

.aai-hero-two-desc {
  max-width: 680px;
  font-size: var(--text-xl);
  color: var(--interface-3);
  line-height: 30px;
}

.aai-hero-two-img {
  margin-top: -150px;
}

/* Hero Two End */

/* Breadcrumb Start */
.aai-breadcrumb {
  padding: 200px 0 120px 0;
}

.aai-breadcrumb-nav {
  gap: 4px;
}

.aai-page-title {
  font-size: 36px;
  color: var(--white);
  font-weight: var(--font-semibold);
  margin-bottom: 15px;
}

.aai-breadcrumb-link {
  font-size: var(--text-lg);
  color: var(--interface-3);
  line-height: 28px;
}

/* Breadcrumb End */

/* Step Start*/
.writing-steps-wrapper {
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
}

.writing-step {
  padding: 48px 60px;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
  position: relative;
  height: 100%;
}

.writing-step-icon {
  margin-bottom: 20px;
}

.writing-step-title {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: var(--font-bold);
  color: var(--white);
}

.writing-step-desc {
  font-size: 18px;
  color: var(--interface-2);
  line-height: 28px;
}

.writing-step-arrow {
  position: absolute;
  background-color: var(--interface-1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.12);
  display: grid;
  place-items: center;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
}

/* Step End */

/* Services Start */
.aai-list-items {
  display: grid;
  gap: 16px;
}

.aai-list-item {
  gap: 16px;
}

.aai-list-item-text {
  font-size: var(--text-lg);
  color: var(--white);
}

/* Services End */

/* Aai Featurek Start*/
.aai-feature-box {
  border-radius: 30px;
  padding: 40px;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.aai-feature-box-inner {
  position: relative;
  z-index: 20;
}

.aai-feature-box::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 1px;
  background: linear-gradient(137deg, #80fcf0 0%, #efce85 52.6%, #ac5dfa 100%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: 3;
}

.aai-feature-box:hover {
  background-color: rgba(18, 23, 43, 0.5);
}

.aai-feature-icon {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}

.aai-feature-title {
  font-size: 24px;
  font-weight: var(--font-bold);
  color: var(--white);
  line-height: 34px;
  letter-spacing: -0.8px;
  margin-bottom: 15px;
}

.aai-feature-desc {
  font-size: 18px;
  color: var(--interface-2);
  margin-bottom: 20px;
  font-weight: var(--font-normal);
}

.aai-feature-link {
  color: var(--white);
  transition: all 0.3s;
}

.aai-feature-box:hover .aai-feature-link {
  padding-left: 30px;
  color: var(--interface-4);
}

.aai-feature-box:hover .aai-feature-link svg {
  color: var(--interface-4);
}

/* Aai Featurek End */

/* Aai Awards */
.work-list {
  gap: 18px;
}

.work-list-item {
  gap: 16px;
}

.aai-awards-desc {
  font-size: var(--text-xl);
  color: var(--interface-3);
  line-height: 28px;
}

.aai-awards-stars {
  color: #f59e0b;
}

.aai-awards-names {
  margin-top: 40px;
  gap: 30px;
}

.aai-awards-ratings {
  color: var(--interface-3);
  font-size: var(--text-base);
  opacity: 0.6;
}

/* Aai Awards End */

/* Testimonials Start */
.testimoniaslSlider .swiper-wrapper,
.testimoniaslSliderTwo .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  position: relative;
}

.aai-testimonial-item {
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.05);
}

.aai-testimonial-item-top {
  border-bottom: 1px solid rgba(116, 118, 129, 0.5);
  padding: 24px 30px;
}

.aai-testimonial-quoate {
  background-color: #1e223f;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.aai-testimonial-desc {
  font-size: var(--text-lg);
  color: var(--white);
  line-height: 28px;
  padding-top: 24px;
}

.aai-testimonial-item-bottom {
  gap: 8px;
  padding: 16px 30px 20px 30px;
}

.aai-testimonial-item::after {
  content: "";
  width: 100%;
  height: 4px;
  background-image: linear-gradient(137deg, #80fcf0 0%, #ac5dfa 100%);
  opacity: 0.6000000238418579;
  position: absolute;
  bottom: 0px;
  left: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.aai-testimonial-author-img {
  border-radius: 50%;
}

.aai-testimonial-author-name {
  font-size: var(--text-lg);
  color: var(--white);
  font-weight: var(--font-semibold);
}

.aai-testimonial-author-title {
  color: var(--interface-3);
  font-size: 14px;
}

/* Testimonials End */

/* Price Table */

.aai-price-pack-name {
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  color: var(--white);
  margin-bottom: 10px;
}

.aai-price-pack-desc {
  font-size: var(--text-lg);
  line-height: 28px;
  color: var(--interface-3);
}

.aai-price-table {
  border-radius: 12px;
  border: 1px solid var(--white);
  padding: 32px 64px;
  transition: all 0.4s ease-in-out;
}

.aai-price-table.has-bg {
  background-color: #0e1327;
}

.aai-price-table:hover {
  background-color: #0e1327;
  border-color: var(--interface-4);
}

.aai-price-lists {
  display: grid;
  gap: 8px;
}

.aai-price-list-item {
  gap: 12px;
}

.aai-price-list-item-text {
  color: var(--interface-3);
}

.aai-price {
  font-size: 48px;
  font-weight: var(--font-bold);
  line-height: 56px;
  color: var(--white);
}

/* Price Table End */

/* Faq Start */
.gradient-text {
  background: linear-gradient(137deg, #80fcf0 0%, #efce85 52.6%, #ac5dfa 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.aai-accordions .accordion {
  background: transparent;
}

.aai-accordions .accordion .accordion-item {
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.aai-accordions .accordion-button {
  background-color: transparent;
  color: var(--white);
  font-size: var(--text-2xl);
  font-weight: var(--font-semibold);
  line-height: 34px;
  padding: 20px;
}

.accordion-button:not(.collapsed) {
  color: var(--interface-4);
}

.aai-accordions .accordion-button::after {
  background-image: none;
  content: "\f078";
  font-family: "Font Awesome 6 Free";
  font-size: 14px;
  font-weight: 900;
}

.aai-accordions .accordion-button:not(.collapsed)::after {
  color: var(--interface-4);
}

.aai-accordions .accordion-body {
  padding-top: 0;
}

.aai-accordions .accordion-body .aai-accordion-text {
  color: var(--interface-3);
  font-size: var(--text-lg);
  line-height: 32px;
}

/* Faq End */

/* Cta Start */
.aai-btns-group {
  gap: 30px;
}

.aai-cta-line {
  /* background-image: url(../img/cta/line.svg); */
  z-index: 1;
  background-repeat: no-repeat;
  background-position: bottom;
}

/* Cta Start End */

/* Footer  Start*/
.aai-footer-support {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 35px;
  border-radius: 12px;
}

.aai-support-info {
  gap: 14px;
}

.aai-support-text {
  font-size: var(--text-lg);
  color: var(--white);
}

.aai-support-number {
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  color: var(--white);
}

.aai-newsletter .form-control {
  border-radius: 50px;
  border: 1px solid rgba(116, 118, 129, 0.2);
  background: #14182f;
  padding: 20px 30px;
  color: var(--white);
}

.aai-newsletter .form-control::placeholder {
  color: var(--interface-2);
}

.aai-newsletter-btn {
  position: absolute;
  right: 0;
  padding: 16px 32px;
  border-radius: 30px;
  background-color: var(--white);
  font-size: var(--text-lg);
  color: var(--interface-1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  top: 8px;
  bottom: 8px;
  right: 8px;
  z-index: 10;
  overflow: hidden;
}

.aai-newsletter-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: var(--interface-4);
  transition: all 0.35s;
  border-radius: 30px;
  z-index: -1;
}

.aai-newsletter-btn:hover::after {
  width: 100%;
}

.aai-footer-logo {
  margin-bottom: 25px;
  display: block;
}

.aai-footer-desc {
  color: var(--white);
  font-size: var(--text-lg);
  margin-bottom: 30px;
}

.aai-social-links {
  gap: 10px;
}

.aai-social-links a {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  color: var(--interface-2);
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transition: all 0.35s;
  position: relative;
  overflow: hidden;
  z-index: 10;
}

.aai-social-links a::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  transition: all 0.35s;
  height: 100%;
  background: linear-gradient(137deg, #80fcf0 0%, #ac5dfa 100%);
  border-radius: 50%;
  z-index: -1;
}

.aai-social-links a:hover {
  color: var(--white);
}

.aai-social-links a:hover::after {
  width: 100%;
}

.aai-footer-nav-title {
  font-size: var(--text-2xl);
  color: var(--white);
  font-weight: var(--font-bold);
  line-height: 34px;
  margin-bottom: 20px;
}

.aai-footer-nav-list {
  display: grid;
  gap: 20px;
}

.aai-footer-nav-link {
  color: var(--white);
}

.aai-footer-nav-link:hover {
  color: #edcb88;
}

.aai-footer-copyright {
  padding: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.aai-copyright-text {
  font-size: var(--text-lg);
  color: var(--white);
  line-height: 28px;
}

/* Footer  End */

/*****************
Service Details Page Start
******************/

.aai-img-slider-item .aai-slider-img {
  border-radius: 12px;
}

.swiper-pagination.aai-swiper-pagination .swiper-pagination-bullet {
  width: 16px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;
  border-radius: 6px;
}

.swiper-pagination.aai-swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--white);
}

.aai-service-thumb {
  border-radius: 12px;
}

.aai-about-service-cta {
  padding: 35px 50px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  z-index: 10;
  position: relative;
}

.aai-about-service-cta::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 1px;
  background: linear-gradient(137deg, #80fcf0 0%, #ac5dfa 100%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.aai-about-service-title {
  font-size: var(--text-2xl);
  margin-bottom: 15px;
  font-weight: var(--font-bold);
  color: var(--white);
}

.aai-about-video {
  position: relative;
}

.aai-about-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-items: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
}

/*****************
Service Details End
******************/

/*****************
Contact Page Start
******************/

/* Contact Widget */
.aai-contact-widget {
  border-radius: 12px;
  position: relative;
  width: 465px;
}

.aai-contact-widget::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 1px;
  background: linear-gradient(137deg, #80fcf0 0%, #efce85 52.6%, #ac5dfa 100%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.aai-contact-widget-inner {
  padding: 20px 30px;
  gap: 30px;
  background: rgba(255, 255, 255, 0.05);
}

.aai-contact-widget-title {
  font-size: var(--text-2xl);
  margin-bottom: 15px;
  color: var(--white);
  font-weight: var(--font-bold);
}

.aai-contact-address {
  font-size: var(--text-xl);
  color: var(--white);
}

.aai-form-input {
  position: relative;
  margin-bottom: 30px;
}

.aai-form-input .form-control {
  border-radius: 28px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #747681;
  color: var(--white);
}

.aai-form-input .form-control:focus {
  border-color: var(--interface-4);
}

.aai-form-input .form-control.text-area {
  min-height: 240px;
  border-radius: 12px;
  padding: 16px 20px;
  resize: none;
}

.aai-form-input .form-control::placeholder {
  color: var(--interface-2);
}

.aai-form-input-icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.aai-form-input label {
  color: var(--white);
  font-size: var(--text-base);
  margin-left: 10px;
}

.aai-contact-support {
  font-size: var(--text-xl);
  color: var(--interface-2);
}

/* Contact Widget End */

/* Feature Table Start*/

.aai-table {
  background: transparent;
  width: 100%;
}

.aai-table> :not(caption)>*>* {
  padding: 16px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--white);
  font-size: 16px;
  font-weight: var(--font-normal);
}

.aai-thead tr th {
  font-size: 24px;
  font-weight: var(--font-bold);
  padding-right: 16px;
}

.aai-text-warning {
  color: #ffc876;
}

.aai-text-danger {
  color: #ff776f;
}

/* Feature Table End */

/***************
Contact Page End
****************/

/***************************
Signup and Signin Page Start
****************************/
.aai-signup-in-links {
  gap: 36px;
}

.aai-signup-in-links a:first-of-type {
  color: var(--white);
  font-size: var(--text-lg);
  line-height: 28px;
  border-bottom: 1px solid var(--white);
}

.aai-signup-in-links a:first-of-type:hover {
  color: var(--interface-4);
  border-color: var(--interface-4);
}

.aai-signup-in {
  min-height: 100vh;
  padding: 150px 0;
}

.aai-form-wrapper {
  padding: 40px 60px;
  position: relative;
  z-index: 10;
  background-color: var(--interface-1);
}

.aai-form-wrapper::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  border-radius: 12px;
  padding: 1px;
  background: linear-gradient(137deg, #80fcf0 0%, #efce85 52.6%, #ac5dfa 100%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.aai-form-header {
  grid-area: 10px;
}

.aai-form-title {
  font-size: 36px;
  font-weight: var(--font-semibold);
  line-height: 48px;
  color: var(--white);
}

.aai-form-desc {
  color: var(--interface-2);
  font-size: var(--text-lg);
}

.aai-auth-links {
  margin-top: 30px;
}

.aai-auth-link {
  padding: 16px 24px;
  gap: 8px;
  border-radius: 50px;
  color: var(--interface-2);
  border: 1px solid #3e3c47;
}

.aai-auth-link:hover {
  color: var(--white);
  border-color: var(--interface-4);
}

.aai-option-bar {
  padding: 24px 0;
  position: relative;
  gap: 12px;
}

.aai-auth-option {
  color: var(--interface-2);
  font-size: var(--text-xl);
  background-color: var(--interface-1);
}

.aai-auth-line {
  background-color: #3e3c47;
  height: 1px;
  width: 220px;
  max-width: 100%;
}

.aai-form-container .form-control {
  border-radius: 50px;
  padding: 16px 24px;
  border: 1px solid #3e3c47;
  background-color: var(--interface-1);
  color: var(--white);
}

.aai-form-container .form-control::placeholder {
  color: var(--interface-2);
}

.aai-form-container .form-control:focus {
  border-color: var(--interface-4);
}

.aai-form-eye {
  background: none;
  border: none;
  position: absolute;
  top: 16px;
  right: 16px;
}

.aai-form-check-label {
  color: var(--interface-2);
}

.aai-form-support-text {
  color: var(--interface-2);
}

.aai-form-support-link {
  color: var(--white);
}

.aai-form-support-link:hover {
  color: var(--interface-4);
}

/*************************
Signup and Signin Page End
***************************/

/**************************
Blog and Blog Detailss Start
***************************/
.aai-post-wrapper {
  margin-bottom: 30px;
}

.aai-post-thumb {
  border-radius: 12px 12px 0 0;
}

.aai-post-content {
  padding: 40px 30px;
  background-color: #0e1327;
  border-radius: 0 0 12px 12px;
}

.aai-post-meta a {
  color: var(--interface-3);
  font-size: 16px;
}

.aai-post-meta i {
  color: var(--interface-4);
}

.aai-post-title {
  font-size: 36px;
  line-height: 1.3;
  font-weight: var(--font-semibold);
  padding: 15px 0;
  margin: 0;
}

.aai-post-title:hover a {
  color: var(--interface-4);
}

.aai-post-title a {
  color: var(--white);
  transition: all 0.4s ease-in-out;
}

.aai-post-exerpt {
  font-size: var(--text-lg);
  color: var(--interface-3);
}

.aai-post-readmore {
  font-size: var(--text-lg);
  color: var(--white);
}

.aai-post-readmore:hover {
  color: var(--interface-4);
}

.aai-sidebar-widget {
  padding: 30px;
  position: relative;
}

.aai-sidebar-widget::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 1px;
  background: linear-gradient(137deg, #80fcf0 0%, #efce85 52.6%, #ac5dfa 100%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.aai-sidebar-search-from {
  position: relative;
}

.aai-sidebar-search-from .form-control {
  height: 50px;
  padding: 14px 25px;
  color: var(--interface-1);
}

.aai-sidebar-search-button {
  position: absolute;
  right: 25px;
  top: 14px;
  bottom: 14px;
}

.aai-sidebar-title {
  color: var(--white);
  font-weight: var(--font-semibold);
  padding-bottom: 15px;
  border-bottom: 1px solid var(--white);
  display: inline-block;
}

.aai-blog-lists {
  position: relative;
  z-index: 10;
}

.aai-blog-list-item a {
  font-size: var(--text-base);
  color: var(--white);
  line-height: 28px;
}

.aai-blog-list-item a:hover {
  color: var(--interface-4);
}

.aai-post-details-top {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.aai-blog-details-thumb {
  border-radius: 12px;
}

.aai-post-details-text {
  color: var(--interface-3);
  font-size: var(--text-lg);
}

.aai-post-blockquote {
  position: relative;
  margin: 41px 0;
  padding: 67px 50px 40px;
  background: #080e26;
  border-radius: 10px;
  -webkit-box-shadow: -4px 0 0 0 #edcb88;
  -moz-box-shadow: -4px 0 0 0 #edcb88;
  box-shadow: -4px 0 0 0 #edcb88;
}

.aai-post-blockquote::before {
  position: absolute;
  left: 50px;
  top: 68px;
  content: ",,";
  font-family: "Open Sans", sans-serif;
  font-size: 100px;
  line-height: 25px;
  color: #edcb88;
  letter-spacing: -5px;
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.aai-post-blockquote::after {
  position: absolute;
  right: 10px;
  bottom: 122px;
  content: ",,";
  font-family: "Open Sans", sans-serif;
  font-size: 200px;
  line-height: 25px;
  color: #1c2145;
  letter-spacing: -10px;
}

.aai-post-blockquote p {
  font-size: var(--text-xl);
  color: var(--interface-3);
}

.aai-post-social-share {
  color: var(--white);
  font-size: var(--text-lg);
  font-weight: var(--font-semibold);
}

.aai-post-social-links a {
  display: grid;
  width: 40px;
  height: 40px;
  place-items: center;

  color: #fff;
  border-radius: 50%;
  font-size: 14px;
  margin-right: 6px;
}

.aai-post-social-links a[class*="facebook"] {
  background-color: #1877f2;
}

.aai-post-social-links a[class*="twitter"] {
  background-color: #00acee;
}

.aai-post-social-links a[class*="pinterest"] {
  background-color: #c8232c;
}

.aai-post-social-links a[class*="linkedin"] {
  background-color: #446bb9;
}

.aai-post-comment-note {
  font-size: 24px;
  color: var(--white);
  font-weight: var(--font-semibold);
  margin-bottom: 15px;
}

.aai-post-email-note {
  color: var(--interface-3);
  font-size: var(--text-lg);
}

.aai-comment-input .aai-comment-form-label {
  color: var(--white);
  font-size: var(--text-lg);
  display: block;
  margin-bottom: 10px;
}

.aai-comment-input .form-control {
  background-color: var(--interface-1);
  border: 1px solid #747681;
  height: 50px;
  padding: 20px;
  color: var(--white);
}

.aai-comment-input .form-control:focus {
  border: 1px solid var(--interface-4);
}

.aai-comment-input textarea.form-control {
  height: 180px;
  resize: none;
}

/*************************
Blog and Blog Detailss End
***************************/

/* Responsive CSS */
@media (min-width: 1600px) {
  .aai-hero-one-img .aai-hero-img {
    right: 0;
  }
}

@media (max-width: 1199px) {
  .aai-hero-one-img .aai-hero-img {
    position: relative;
    left: 0;
    top: 50px;
  }
}

@media (max-width: 768px) {
  .writing-step {
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }

  .section-title {
    font-size: 36px;
    line-height: 1.3;
  }

  .section-desc {
    font-size: 16px;
  }

  .aai-contact-widget {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .aai-hero-one {
    padding: 150px 0 120px 0;
  }

  .aai-hero-one-title {
    font-size: 36px;
    line-height: 1.5;
  }

  .aai-hero-two-title {
    font-size: 36px;
    line-height: 1.5;
  }

  .aai-form-wrapper {
    padding: 20px;
  }

  .aai-accordions .accordion-button {
    font-size: var(--text-xl);
  }

  .writing-step {
    padding: 30px 20px;
  }

  .writing-step-title {
    font-size: var(--text-xl);
  }

  .aai-table-wrapper {
    overflow: scroll;
  }
}

.cursor {
  animation-name: blink;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  opacity: 1;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.vp-wrapper {
  width: 0;
  height: 0vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.8);
  transition: 300ms;
}

.vp-Close {
  position: fixed;
  top: 20px;
  right: 20px;
  fill: rgba(255, 255, 255, 0.5);
  font-size: 30px;
  cursor: pointer;
}

.vp-Close:hover {
  fill: rgba(255, 255, 255, 0.8);
}

/* .active {
  width: 100%;
  height: 100vh;
} */

/* accordion  */

/* .accordion-button {
  background-color: transparent !important;
  color: white !important;
} */

/* .accordion-button::after {
  background-image: none !important;
  color: white !important;
} */

.accordion {
  --bs-accordion-btn-icon-transform: rotate(-0deg) !important;
}

.accordion-button {
  box-shadow: none !important;
  font-weight: 700 !important;
}

.accordion-collapse:not(.accordion-collapse-show) {
  height: 0;
  overflow: hidden;
}

.accordion-collapse {
  transition: 500ms;
}

.aai-accordion-text {
  color: var(--interface-3) !important;
  font-size: 18px !important;
  font-weight: 500;
  line-height: 32px;
}

.accordion-button-show {
  color: var(--interface-4) !important;
  --bs-accordion-btn-icon-transform: rotate(-180deg) !important;
}

.accordion-button-show::after {
  color: var(--interface-4) !important;
}

.accordion-collapse-show {
  height: auto;
  overflow: hidden;
}

/* loader  */
.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: var(--interface-1);
  z-index: 99999;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}