.dashbord{
    background-color: red;
}
/* .sidebar{
    height: 100vh;
    background: rgb(243, 242, 245);
    width: 4%;
    
   
} */

.overflow-scroll {
  scrollbar-color: #00B4B7 #ffffff !important;
  /* overflow: scroll !important; */
}
.profile{
    width: 89%;
    cursor: pointer;

}
.upperhader{
    width: 89%;
    flex-direction: column;
    height: calc(var(--screen-height) - var(--app-margin-top,0px));
    background: rgb(243, 242, 245);
}
.header{
    box-sizing: border-box;
    position: sticky;
    z-index: 3;
    color: rgb(255, 255, 255);
    background: transparent;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    top: 0px;
    width: 100%;
    min-height: 60px;
    padding: 10px 10px 6px 0px;}

.profillayout
{
    width: 100%;
    background: rgb(255, 255, 255);
    /* height: calc(97vh - 57px); */
    overflow: auto;
    /* margin-right: -16px; */
    /* border-radius: 12px 12px 0px 0px; */
}
.sidebar-section{
  width: 250px;
  height: 100vh;
  position: fixed;
  gap: 0px;
  border: 1px solid #D7D7D7;
  opacity: 0px;
  border-radius: 0px 0px 20px 0px;
  box-shadow: 0px 4px 14.7px 0px #00000026;
  background-color: #FFFFFF;
  }
  .content-section{
    margin-left: 250px;
  }
  .topnavbar-main-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    background-color: white;
}
.text{
    font-style: Roboto;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    margin-top: 5px;
}

.sidebar {
  width: 14%;
  background-color: #f8f6f2; 
  transition: ease 0.1s;
  overflow-y: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-color: var(--card-color) var(--card-color);
  scrollbar-width: thin;
  background: var(--body-);
  position: fixed; 
  left: 0; /* Positioning it to the left */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  z-index: 9;
  height: 100vh;
  font-size: 1rem;
  -ms-flex-order: 1;
  order: 1;
  scrollbar-color: #00B4B7  #ffffff;
  flex-direction: column;
  display: flex;
}

.content {
  margin-left: 13%; /* Adjust margin to match sidebar width */
  width: 86%; /* Adjust width accordingly */
}

  
.sidebarfooter{
  margin-top: 4px;
}
 
  
 
  

 
  
  .footer {
    padding-top: 70px;
    background-color: #f8f6f2;
    width: 84%; 
    border-top: 1px solid #ccc; /* Adding a top border */
    position: fixed;
    /* padding: 1rem;  */
    bottom: 0;
    /* Ensure footer takes full width */
  }
  
  .footer-content {
    justify-content: space-between;
  }
  
  .footer-links {
    display: flex;
    justify-content: flex-end;
  }
  .content.contentsmallscreen{
    transition: margin-right 0.3s ease;
    
  }
  
@media (min-width: 1450px) and (max-width: 1599px) {
  .sidebar {
    width: 17%;
    background-color: #f8f6f2; 
    background-color: #f8f6f2; 
    transition: ease 0.1s;
    overflow-y: auto;
    
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-color: var(--card-color) var(--card-color);
    scrollbar-width: thin;
    background: var(--body-);
    position: fixed; 
    left: 0; /* Positioning it to the left */
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    z-index: 9;
    height: 100vh;
    font-size: 1rem;
    -ms-flex-order: 1;
    order: 1;
    scrollbar-color: #00B4B7  #ffffff;

  
  }
  .footer{
    width: 84%;
  }
  .content {
    margin-left: 17%; 
    width: 83%;
  }
}
@media (min-width: 1150px) and (max-width: 1450px) {
  .sidebar {
    width: 20%;
    background-color: #f8f6f2; 
    background-color: #f8f6f2; 
    transition: ease 0.1s;
    overflow-y: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-color: var(--card-color) var(--card-color);
    scrollbar-width: thin;
    background: var(--body-);
    position: fixed; 
    left: 0; /* Positioning it to the left */
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    z-index: 9;
    height: 100vh;
    font-size: 1rem;
    -ms-flex-order: 1;
    order: 1;
    scrollbar-color: #00B4B7  #ffffff;

  
  }
  
  .content {
    margin-left: 20%; 
    width: 80%;
  }
  .footer{
    width: 81%;
  }
}
@media (min-width: 1100px) and (max-width: 1150px) {
  .sidebar {
    width: 23%;
    background-color: #f8f6f2; 
    background-color: #f8f6f2; 
    transition: ease 0.1s;
    overflow-y: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-color: var(--card-color) var(--card-color);
    scrollbar-width: thin;
    background: var(--body-);
    position: fixed; 
    left: 0; /* Positioning it to the left */
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    z-index: 9;
    height: 100vh;
    font-size: 1rem;
    -ms-flex-order: 1;
    order: 1;
    scrollbar-color: #00B4B7  #ffffff;

  
  }
  .footer{
    width: 77%;
  }
  .content {
    margin-left: 23%; 
    width: 77%;
  }
}
@media  (max-width: 1099px) {
  .sidebar{
    display: none !important;
    width: 0%;
  }
  .footer{
    width: 100%;
  }
  .content {
    margin-left: 0px; 
    padding-left: 0px;
    width: 100%;
  }
}
@media (min-width: 1600px) and (max-width: 1799px) {
  .sidebar {
    width: 17%;
    background-color: #f8f6f2; 
    transition: ease 0.1s;
    overflow-y: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-color: var(--card-color) var(--card-color);
    scrollbar-width: thin;
    background: var(--body-);
    position: fixed; 
    left: 0; /* Positioning it to the left */
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    z-index: 9;
    height: 100vh;
    font-size: 1rem;
    -ms-flex-order: 1;
    order: 1;
    scrollbar-color: #00B4B7  #ffffff;

  
  }
  
  .content {
    margin-left: 17%; 
    width: 83%;
  }
}
@media (min-width: 1800px) and (max-width: 1899px) {
  .sidebar {
    width: 19%;
    background-color: #f8f6f2; 
    transition: ease 0.1s;
    overflow-y: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-color: var(--card-color) var(--card-color);
    scrollbar-width: thin;
    background: var(--body-);
    position: fixed; 
    left: 0; /* Positioning it to the left */
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    z-index: 9;
    height: 100vh;
    font-size: 1rem;
    -ms-flex-order: 1;
    order: 1;  
    scrollbar-color: #00B4B7  #ffffff;

  }
  .content {
    width: 81%;
    margin-left: 19%; 
  }
}
@media (min-width: 1900px) and (max-width: 2299px) {
  .sidebar {
    width: 16%;
    background-color: #f8f6f2; 
    transition: ease 0.1s;
    overflow-y: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-color: var(--card-color) var(--card-color);
    scrollbar-width: thin;
    background: var(--body-);
    position: fixed; 
    left: 0; /* Positioning it to the left */
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    z-index: 9;
    height: 100vh;
    font-size: 1rem;
    -ms-flex-order: 1;
    order: 1;  
    scrollbar-color: #00B4B7  #ffffff;

  }
  .content {
    width: 85%;
    margin-left: 15%; 
  }
}
/* @media (min-width: 1800px) and (max-width: 1999px) {
  .sidebar {
    width: 17%;
    background-color: #f8f6f2; 
  
  }
  
  .content {
    width: 82%;
  }
} */

