

:root {
  --font-hankenGrotesk: "Hanken Grotesk", sans-serif;
  --font-inter: "Inter", sans-serif;
  --white-color: #fff;
  --dark-white-color: #fff;
  --black-color: #000;
  --black-color2: #1e1f1f;
  --black-color3: #0b0c0c;
  --light-black-color: #242627;
  --title-color: #0b0c0c;
  --dark-title-color: #0b0c0c;
  --title-color-opc: 11, 12, 12;
  --text-color: #3f444b;
  --text-color-opc: 63, 68, 75;
  --primary-color1: #3067ff;
  --primary-color1-opc: 48, 103, 255;
  --primary-color2: #0b0c0c;
  --dark-primary-color2: #0b0c0c;
  --primary-color2-opc: 11, 12, 12;
  --border-color: #eee;
  --border-color2: #eee;
}

body.dark {
  --white-color: #fff;
  --dark-white-color: #0b0c0c;
  --black-color: #000;
  --black-color2: #1e1f1f;
  --title-color: #fff;
  --title-color-opc: 255, 255, 255;
  --text-color: #b5b5b5;
  --text-color-opc: 255, 255, 255;
  --primary-color1: #3067ff;
  --primary-color1-opc: 48, 103, 255;
  --primary-color2: #fff;
  --primary-color2-opc: 255, 255, 255;
  --border-color: #5e5a5a6e;
  --border-color2: #3d3a3a6e;
  background-color: #000;
}

body.dark.style-2 {
  background-color: #000;
}
.sidbar-item-link {
  text-decoration: none;
  list-style-type: none;
}
a {
  text-decoration: none !important;
  list-style-type: none !important;
}
.header-area-scroll{
  background-color: #0e1d46f7 !important;
}
html {
  font-size: 100%;
  scroll-behavior: smooth;
}
.header-area {
  transition: background-color 0.3s ease;
}

.header-black {
  background-color: black;
}

body {
  margin: 0;
  padding: 0;
  font-family: "'Nunito', sans-serif";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.home1-about-section .about-countdown-area ul .single-countdown .content .number span {
  font-size: 90px;
}

body.style-2 {
  background: #f7f6f1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-hankenGrotesk);
  font-weight: 600;
  line-height: 1.4;
  color: var(--title-color);
}

input {
  border: none;
  outline: none;
}

button {
  outline: none;
  border: none;
}

i.bx {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

p {
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pb-130 {
  padding-bottom: 130px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .pb-130 {
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .pb-130 {
    padding-bottom: 90px;
  }
}

.pt-120 {
  padding-top: 120px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .pt-120 {
    padding-top: 100px;
  }
}

@media (max-width: 991px) {
  .pt-120 {
    padding-top: 90px;
  }
}

@media (max-width: 767px) {
  .pt-120 {
    padding-top: 70px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .pb-120 {
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .pb-120 {
    padding-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .pb-120 {
    padding-bottom: 70px;
  }
}

.pt-100 {
  padding-top: 110px;
}

@media (max-width: 991px) {
  .pt-100 {
    padding-top: 80px;
  }
}

.pb-100 {
  padding-bottom: 110px;
}

@media (max-width: 991px) {
  .pb-100 {
    padding-bottom: 80px;
  }
}

.pt-90 {
  padding-top: 90px;
}

@media (max-width: 991px) {
  .pt-90 {
    padding-top: 80px;
  }
}

@media (max-width: 767px) {
  .pt-90 {
    padding-top: 70px;
  }
}

.pb-90 {
  padding-bottom: 90px;
}

@media (max-width: 991px) {
  .pb-90 {
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .pb-90 {
    padding-bottom: 70px;
  }
}

.pb-80 {
  padding-bottom: 80px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .pb-80 {
    padding-bottom: 60px;
  }
}

.pb-65 {
  padding-bottom: 65px;
}

.mt-120 {
  margin-top: 120px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mt-120 {
    margin-top: 100px;
  }
}

@media (max-width: 991px) {
  .mt-120 {
    margin-top: 90px;
  }
}

.mb-110 {
  margin-bottom: 110px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mb-110 {
    margin-bottom: 90px;
  }
}

@media (max-width: 991px) {
  .mb-110 {
    margin-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .mb-110 {
    margin-bottom: 60px;
  }
}

.mb-120 {
  margin-bottom: 120px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mb-120 {
    margin-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .mb-120 {
    margin-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .mb-120 {
    margin-bottom: 70px;
  }
}

.mb-130 {
  margin-bottom: 130px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mb-130 {
    margin-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .mb-130 {
    margin-bottom: 90px;
  }
}

.mb-100 {
  margin-bottom: 100px;
}

@media (max-width: 991px) {
  .mb-100 {
    margin-bottom: 80px;
  }
}

.mt-100 {
  margin-top: 100px;
}

@media (max-width: 991px) {
  .mt-100 {
    margin-top: 80px;
  }
}

.mb-90 {
  margin-bottom: 90px;
}

@media (max-width: 991px) {
  .mb-90 {
    margin-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .mb-90 {
    margin-bottom: 50px;
  }
}

.mb-80 {
  margin-bottom: 80px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mb-80 {
    margin-bottom: 70px;
  }
}

@media (max-width: 991px) {
  .mb-80 {
    margin-bottom: 70px;
  }
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-50 {
  padding-top: 50px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .pt-50 {
    padding-top: 40px;
  }
}

@media (max-width: 991px) {
  .pt-50 {
    padding-top: 30px;
  }
}

.pt-40 {
  padding-top: 40px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pe-80 {
  padding-right: 80px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-110 {
  padding-left: 110px;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .pl-110 {
    padding-left: 70px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .pl-110 {
    padding-left: 40px;
  }
}

@media (max-width: 1199px) {
  .pl-110 {
    padding-left: unset;
  }
}

.mb-60 {
  margin-bottom: 60px;
}

@media (max-width: 1199px) {
  .mb-60 {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .mb-60 {
    margin-bottom: 40px;
  }
}

.mb-70 {
  margin-bottom: 70px;
}

@media (max-width: 767px) {
  .mb-70 {
    margin-bottom: 40px;
  }
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

@media (max-width: 1199px) {
  .mb-40 {
    margin-bottom: 30px;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-50 {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .mb-50 {
    margin-bottom: 40px;
  }
}

.mt-60 {
  margin-top: 60px;
}

@media (max-width: 767px) {
  .mt-60 {
    margin-top: 40px;
  }
}

.mt-70 {
  margin-top: 70px;
}

@media (max-width: 991px) {
  .mt-70 {
    margin-top: 40px;
  }
}

.primary-btn1 {
  border-radius: 5px;
  background-color: var(--primary-color1);
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 16px;
  color: var(--white-color);
  letter-spacing: 0.48px;
  line-height: 1;
  padding: 18px 30px;
  position: relative;
  align-items: center;
  display: inline-flex;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}

.primary-btn1>span {
  transition: opacity 0.2s, transform 0.2s;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.primary-btn1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 102% 102%;
  border-radius: inherit;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  opacity: 0;
  z-index: -1;
}

.primary-btn1::after {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  opacity: 0;
  transform: translate(0, 100%);
  transition: opacity 0.2s, transform 0.2s;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  white-space: nowrap;
}

.primary-btn1:hover {
  color: var(--dark-white-color);
  background-color: var(--primary-color2);
}

.primary-btn1:hover::before {
  opacity: 1;
}

.primary-btn1:hover>span {
  transform: translateY(-150%);
  opacity: 0;
}

.primary-btn1:hover::after {
  transform: translate(0%, -50%);
  opacity: 1;
}

.primary-btn1.hover-white:hover {
  background-color: var(--dark-white-color);
  color: var(--title-color);
}

.primary-btn2 {
  border-radius: 5px;
  background-color: var(--primary-color2);
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 16px;
  color: var(--dark-white-color);
  letter-spacing: 0.48px;
  line-height: 1;
  padding: 16px 20px;
  position: relative;
  align-items: center;
  display: inline-flex;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}

@media (max-width: 576px) {
  .primary-btn2 {
    font-size: 15px;
  }
}

.primary-btn2>span {
  transition: opacity 0.2s, transform 0.2s;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.primary-btn2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 102% 102%;
  border-radius: inherit;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  opacity: 0;
  z-index: -1;
}

.primary-btn2::after {
  content: "Get started";
  position: absolute;
  top: 50%;
  opacity: 0;
  transform: translate(0, 100%);
  transition: opacity 0.2s, transform 0.2s;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  white-space: nowrap;
}

.primary-btn2:hover {
  color: var(--white-color);
  background-color: var(--primary-color1);
}

.primary-btn2:hover::before {
  opacity: 1;
}

.primary-btn2:hover>span {
  transform: translateY(-150%);
  opacity: 0;
}

.primary-btn2:hover::after {
  transform: translate(0%, -50%);
  opacity: 1;
}

.primary-btn3 {
  border-radius: 35px;
  background-color: transparent;
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 16px;
  color: var(--title-color);
  letter-spacing: 0.48px;
  line-height: 1;
  padding: 15px 34px;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
  border: 1px solid #0b0c0c;
  border-bottom: 3px solid #0b0c0c;
}

.primary-btn3 svg {
  fill: none;
  stroke: var(--primary-color2);
}

.primary-btn3::after {
  position: absolute;
  content: "";
  border-radius: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--primary-color2);
  transform-origin: right;
  transform: scalex(0);
  z-index: -1;
  transition: transform 0.7s;
}

.primary-btn3:hover {
  color: var(--dark-white-color);
}

.primary-btn3:hover::after {
  transform: scalex(1);
  transform-origin: left;
}

.primary-btn3:hover svg {
  stroke: var(--dark-white-color);
}

.primary-btn4 {
  border-radius: 50px;
  background-color: var(--primary-color1);
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 16px;
  color: var(--white-color);
  letter-spacing: 0.48px;
  line-height: 1;
  padding: 16px 28px;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}

.primary-btn4::after {
  position: absolute;
  content: "";
  border-radius: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--white-color);
  transform-origin: right;
  transform: scalex(0);
  z-index: -1;
  transition: transform 0.7s;
}

.primary-btn4:hover {
  color: var(--dark-title-color);
}

.primary-btn4:hover::after {
  transform: scalex(1);
  transform-origin: left;
}

.primary-btn4.black-hover::after {
  background-color: var(--primary-color2);
}

.primary-btn4.black-hover:hover {
  color: var(--dark-white-color);
}

.primary-btn4.black-hover:hover::after {
  transform: scalex(1);
  transform-origin: left;
}

.primary-btn5 {
  color: var(--dark-white-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 600;
  border-radius: 50px;
  background-color: transparent;
  line-height: 1;
  padding: 16px 30px;
  position: relative;
  z-index: 1;
  transition: 0.45s;
}

.primary-btn5::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(3deg);
  background-color: var(--primary-color1);
  width: 100%;
  height: 100%;
  border-radius: 50px;
  z-index: -1;
  transition: 0.45s;
}

.primary-btn5::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--title-color);
  width: 100%;
  height: 100%;
  border-radius: 50px;
  z-index: -1;
  transition: 0.45s;
}

.primary-btn5.two {
  color: var(--white-color);
}

.primary-btn5.two::before {
  background-color: var(--title-color);
}

.primary-btn5.two::after {
  background-color: var(--primary-color1);
}

.primary-btn5.two:hover {
  color: var(--dark-white-color);
}

.primary-btn5.two:hover::before {
  transform: translate(-50%, -50%) rotate(0deg);
}

.primary-btn5.two:hover::after {
  background-color: var(--title-color);
}

.primary-btn5:hover {
  color: var(--white-color);
}

.primary-btn5:hover::before {
  transform: translate(-50%, -50%) rotate(0deg);
}

.primary-btn5:hover::after {
  background-color: var(--primary-color1);
}

.load-btn {
  border-radius: 50px;
  background-color: var(--primary-color2);
  padding: 14px 35px;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}

.load-btn::after {
  position: absolute;
  content: "";
  border-radius: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--primary-color1);
  transform-origin: right;
  transform: scalex(0);
  z-index: -1;
  transition: transform 0.7s;
}

.load-btn span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  line-height: 1.4;
  font-weight: 500;
  text-decoration: none;
  outline: none;
  padding: 0 1.375em 0.0625em 0;
  cursor: pointer;
  background-color: transparent;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: var(--background-size, 100%) 0.0625em;
  transition: background-size 0.2s linear var(--background-delay, 0.15s);
  transform: translateZ(0);
  border: none;
  background-image: linear-gradient(0deg, #fff 0%, #fff 100%);
  color: var(--dark-white-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
}

.load-btn span::after {
  content: "\f123";
  font-family: bootstrap-icons;
  display: block;
  position: absolute;
  right: 0;
  left: initial;
  top: 0;
  opacity: 1;
  transition: opacity 0.3s 0.15s;
  color: var(--white-color);
}

.load-btn span svg {
  position: absolute;
  display: block;
  line-height: 1.8125em;
  width: 1.375em;
  height: 1.8125em;
  right: 0;
  left: initial;
  top: 50%;
  margin: -0.92em 0 0;
  fill: none;
  stroke: var(--white-color);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1px;
  stroke-dasharray: 7.95 30;
  stroke-dashoffset: var(--stroke-dashoffset, 46);
  transition: stroke-dashoffset var(--stroke-duration, 0.15s) var(--stroke-easing, linear) var(--stroke-delay, 0s);
}

.load-btn:hover span {
  --background-size: 0%;
  --stroke-dashoffset: 26;
  --stroke-duration: 0.3s;
  --stroke-easing: cubic-bezier(0.3, 1.5, 0.5, 1);
  --stroke-delay: 0.195s;
}

.load-btn:hover span::after {
  opacity: 0;
  transition-delay: 0s;
}

.load-btn:hover::after {
  transform: scalex(1);
  transform-origin: left;
}

.explore-btn {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  transition: 0.5s;
}

.explore-btn svg {
  fill: var(--text-color);
  transition: 0.5s;
}

.explore-btn:hover {
  color: var(--primary-color1);
}

.explore-btn:hover svg {
  transform: rotate(45deg);
  fill: var(--primary-color1);
}

.details-button {
  position: relative;
  display: inline-block;
  vertical-align: top;
  line-height: 1.4;
  font-weight: 500;
  text-decoration: none;
  outline: none;
  padding: 0 1.375em 0.0625em 0;
  cursor: pointer;
  background-color: transparent;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: var(--background-size, 100%) 0.0625em;
  transition: background-size 0.2s linear var(--background-delay, 0.15s);
  transform: translateZ(0);
  border: none;
  background-image: linear-gradient(0deg, #0b0c0c 0%, #0b0c0c 100%);
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 17px;
  font-weight: 500;
  white-space: nowrap;
}

.details-button::after {
  content: "\f123";
  font-family: bootstrap-icons;
  display: block;
  position: absolute;
  right: 0;
  left: initial;
  top: 0;
  opacity: 1;
  transition: opacity 0.3s 0.15s;
  color: var(--title-color);
}

.details-button svg {
  position: absolute;
  display: block;
  line-height: 1.8125em;
  width: 1.375em;
  height: 1.8125em;
  right: 0;
  left: initial;
  top: 50%;
  margin: -0.92em 0 0;
  fill: none;
  stroke: var(--title-color);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1px;
  stroke-dasharray: 7.95 30;
  stroke-dashoffset: var(--stroke-dashoffset, 46);
  transition: stroke-dashoffset var(--stroke-duration, 0.15s) var(--stroke-easing, linear) var(--stroke-delay, 0s);
}

.details-button:hover {
  --background-size: 0%;
  --stroke-dashoffset: 26;
  --stroke-duration: 0.3s;
  --stroke-easing: cubic-bezier(0.3, 1.5, 0.5, 1);
  --stroke-delay: 0.195s;
}

.details-button:hover::after {
  opacity: 0;
  transition-delay: 0s;
}

.slider-btn-group {
  display: flex;
  align-items: center;
  gap: 20px;
}

.slider-btn-group .slider-btn {
  height: 40px;
  width: 40px;
  background-color: var(--white-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  cursor: pointer;
  border: 1px solid rgba(217, 217, 217, 0.9019607843);
}

.slider-btn-group .slider-btn svg {
  fill: var(--primary-color1);
  transition: 0.4s;
}

.slider-btn-group .slider-btn:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.slider-btn-group .slider-btn:hover svg {
  fill: var(--white-color);
}

.slider-btn-group .slider-btn.swiper-button-disabled {
  opacity: 0.2;
}

.slider-btn-group2 {
  display: flex;
  align-items: center;
  gap: 40px;
}

.slider-btn-group2 .slider-btn {
  cursor: pointer;
}

.slider-btn-group2 .slider-btn svg {
  fill: var(--primary-color2);
  transition: 0.4s;
}

.slider-btn-group2 .slider-btn:hover svg {
  fill: var(--primary-color1);
}

.slider-btn-group2 .slider-btn.swiper-button-disabled {
  opacity: 0.2;
}

.circle-container {
  background: 0 0;
  position: fixed;
  right: 30px;
  bottom: 55px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px #3067ff;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  transition: all 200ms linear;
}

.circle-container.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.circle-container::after {
  position: absolute;
  font-family: bootstrap-icons;
  content: "\f145";
  text-align: center;
  line-height: 40px;
  font-size: 28px;
  color: var(--primary-color1);
  left: 0;
  top: 0;
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: all 200ms linear;
}

.circle-container:hover svg path {
  fill: var(--primary-color1);
  transition: 0.5s;
}

.circle-container:hover::after {
  color: var(--white-color);
}

.circle-container svg path {
  fill: none;
}

.circle-container svg.circle-progress path {
  stroke: var(--primary-color1);
  stroke-width: 5;
  box-sizing: border-box;
  transition: all 200ms linear;
}

.select {
  margin-bottom: 25px;
}

.nice-select {
  width: 100%;
  background: 0 0;
  border-radius: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 0 25px;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #f3f3f3;
}

.nice-select::after {
  height: 8px;
  width: 8px;
  border-bottom: 1px solid var(--text-color);
  border-right: 1px solid var(--text-color);
  right: 28px;
  margin-top: -5px;
}

.nice-select .current {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
}

.nice-select:hover {
  border-color: #f3f3f3;
  background: #f3f3f3;
}

.nice-select:focus {
  border-color: var(--primary-color1);
  background-color: var(--white-color);
}

.nice-select .list {
  border: none;
  border-radius: 5px;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
}

.nice-select .list::-webkit-scrollbar {
  width: 5px;
}

.nice-select .list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.nice-select .list::-webkit-scrollbar-thumb {
  background: var(--text-color);
}

.nice-select .list .option {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nice-select .list .option:hover {
  color: var(--title-color);
  background-color: var(--primary-color1);
  font-weight: 500;
}

.nice-select .list .option.selected {
  background-color: var(--primary-color1);
  color: var(--title-color);
}

.section-title>span {
  color: var(--primary-color1);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .section-title>span {
    margin-bottom: 10px;
  }
}

.section-title>span svg {
  fill: rgba(var(--text-color-opc), 0.3);
}

.section-title h2 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 50px;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .section-title h2 {
    font-size: 38px;
  }
}

@media (max-width: 576px) {
  .section-title h2 {
    font-size: 35px;
  }
}

.section-title p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  padding-top: 20px;
  margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-title p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .section-title p {
    padding-top: 15px;
  }
}

.section-title.white span svg {
  fill: rgba(255, 255, 255, 0.5);
}

.section-title.white h2 {
  color: var(--white-color);
}

.section-title.white p {
  color: rgba(255, 255, 255, 0.6);
}

.section-title2>span {
  color: var(--primary-color1);
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 8px 10px;
  border: 1px solid var(--border-color);
  background: rgba(233, 229, 255, 0.6);
  border-radius: 5px;
  margin-bottom: 5px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .section-title2>span {
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-title2>span {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .section-title2>span {
    margin-bottom: 10px;
  }
}

.section-title2>span svg {
  fill: rgba(var(--text-color-opc), 0.4);
}

.section-title2 h2 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 50px;
  font-weight: 800;
  line-height: 1.2;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-title2 h2 {
    font-size: 48px;
  }
}

@media (max-width: 991px) {
  .section-title2 h2 {
    font-size: 45px;
  }
}

@media (max-width: 767px) {
  .section-title2 h2 {
    font-size: 38px;
  }
}

.section-title2 p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
  padding-top: 15px;
}

@media (max-width: 991px) {
  .section-title2 p {
    padding-top: 10px;
  }
}

.section-title3>span {
  border-radius: 50px;
  border: 1px solid rgba(var(--text-color-opc), 0.2);
  padding: 13px 20px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.39px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.section-title3>span svg {
  fill: var(--primary-color1);
}

.section-title3 h2 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 50px;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-title3 h2 {
    font-size: 48px;
  }
}

@media (max-width: 991px) {
  .section-title3 h2 {
    font-size: 45px;
  }
}

@media (max-width: 767px) {
  .section-title3 h2 {
    font-size: 38px;
  }
}

.section-title3 p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section-title3 p {
    font-size: 17px;
  }
}

.section-title5 h2 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 50px;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 0;
}

@media (max-width: 1399px) {
  .section-title5 h2 {
    font-size: 42px;
  }
}

@media (max-width: 767px) {
  .section-title5 h2 {
    font-size: 38px;
  }
}

.section-title5 h2 span {
  display: block;
  font-weight: 400;
  color: var(--text-color);
}

.section-title5 p {
  color: var(--text-color);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0;
  padding-top: 20px;
}

.section-title5.two h2 {
  font-size: 60px;
}

.section-title5.two h2 span {
  color: var(--text-color);
  display: contents;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-title5.two h2 {
    font-size: 48px;
  }
}

@media (max-width: 991px) {
  .section-title5.two h2 {
    font-size: 45px;
  }
}

@media (max-width: 767px) {
  .section-title5.two h2 {
    font-size: 38px;
  }
}

.slider-btn-grp {
  display: flex;
  align-items: center;
  gap: 50px;
}

.slider-btn-grp .slider-btn {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid var(--title-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
}

.slider-btn-grp .slider-btn i {
  color: var(--title-color);
  line-height: 1;
  transition: 0.5s;
}

.slider-btn-grp .slider-btn:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.slider-btn-grp .slider-btn:hover i {
  color: var(--white-color);
}

.slider-btn-grp .slider-btn.swiper-button-disabled {
  opacity: 0.2;
}

.form-inner {
  line-height: 1;
}

.form-inner label {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
  line-height: 1;
  margin-bottom: 12px;
}

.form-inner input {
  width: 100%;
  background: var(--white-color);
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 400;
  padding: 10px 20px;
  height: 50px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
}

.form-inner input::-moz-placeholder {
  color: rgba(var(--text-color-opc), 0.4);
}

.form-inner input::placeholder {
  color: rgba(var(--text-color-opc), 0.4);
}

.form-inner input:focus {
  border-color: var(--primary-color1);
}

.form-inner textarea {
  width: 100%;
  border-radius: 5px;
  background: #fff;
  color: var(--title-color);
  border: 1px solid #eee;
  outline: none;
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 400;
  padding: 20px 25px;
  min-height: 150px;
}

.form-inner textarea:focus {
  border-color: var(--primary-color1);
}

.form-inner textarea::-moz-placeholder {
  color: rgba(var(--text-color-opc), 0.4);
}

.form-inner textarea::placeholder {
  color: rgba(var(--text-color-opc), 0.4);
}

.form-inner2 .form-check {
  min-height: unset;
  margin-bottom: 0;
}

.form-inner2 .form-check .form-check-input {
  cursor: pointer;
  height: 14px;
  width: 14px;
  margin-top: 8px;
}

.form-inner2 .form-check .form-check-input:focus {
  box-shadow: none;
}

.form-inner2 .form-check .form-check-input:checked {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.form-inner2 .form-check .form-check-input[type="checkbox"] {
  border-radius: unset;
}

.form-inner2 .form-check .form-check-label {
  cursor: pointer;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
}

@keyframes fade-down {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}

@keyframes zoom-In-effect {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

header.style-1 {
  position: absolute;
  width: 100%;
  z-index: 999;
  transition: all 0.8s ease-out 0s;
  background-color: transparent;
  border-bottom: 1px solid rgba(var(--title-color-opc), 0.1);
}

header.style-1.inner-page {
  position: relative;
  background-color: var(--dark-white-color);
}

header.style-1.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  background-color: var(--dark-white-color);
  z-index: 999;
}

@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 991px) {
  header.style-1 .company-logo {
    padding: 15px 0;
  }
}

header.style-1 .company-logo .logo-dark {
  display: block;
}

header.style-1 .company-logo .logo-light {
  display: none;
}

header.style-1 .nav-right {
  gap: 50px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-1 .nav-right {
    gap: 35px;
  }
}

@media (max-width: 1199px) {
  header.style-1 .nav-right {
    gap: 25px;
  }
}

@media (max-width: 991px) {
  header.style-1 .nav-right {
    gap: 0;
  }
}

header.style-1 .nav-right .right-sidebar-and-hotline-area {
  display: flex;
  align-items: center;
}

header.style-1 .nav-right .right-sidebar-and-hotline-area .hotline-area {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 5px 0 0 5px;
  border: 1px solid rgba(var(--title-color-opc), 0.05);
  background: rgba(var(--primary-color1-opc), 0.05);
  padding: 5px 25px 5px 15px;
  border-right: unset;
}

header.style-1 .nav-right .right-sidebar-and-hotline-area .hotline-area .icon {
  background-color: rgba(var(--title-color-opc), 0.1);
  border-radius: 5px;
  padding: 4px 5px;
  line-height: 1;
}

header.style-1 .nav-right .right-sidebar-and-hotline-area .hotline-area .icon svg {
  fill: var(--primary-color2);
}

header.style-1 .nav-right .right-sidebar-and-hotline-area .hotline-area .content {
  position: relative;
  line-height: 1;
}

header.style-1 .nav-right .right-sidebar-and-hotline-area .hotline-area .content span {
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 11px;
  line-height: 1;
  color: var(--text-color);
  margin-bottom: 5px;
  display: inline-block;
}

header.style-1 .nav-right .right-sidebar-and-hotline-area .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}

header.style-1 .nav-right .right-sidebar-and-hotline-area .hotline-area .content h6 a {
  font-family: var(--font-hankenGrotesk);
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.5s;
}

header.style-1 .nav-right .right-sidebar-and-hotline-area .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}

header.style-1 .nav-right .right-sidebar-and-hotline-area .sidebar-btn {
  background-color: var(--black-color2);
  padding: 16px 18px;
  line-height: 1;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

@media (max-width: 1199px) {
  header.style-1 .nav-right .right-sidebar-and-hotline-area .sidebar-btn {
    border-radius: 5px;
    padding: 13px 15px;
  }
}

@media (max-width: 576px) {
  header.style-1 .nav-right .right-sidebar-and-hotline-area .sidebar-btn {
    padding: 8px;
  }
}

header.style-1 .nav-right .right-sidebar-and-hotline-area .sidebar-btn svg {
  fill: var(--white-color);
}

header.style-1 .nav-right .right-sidebar-and-hotline-area .sidebar-btn svg.close {
  display: none;
}

header.style-1 .nav-right .right-sidebar-and-hotline-area .sidebar-btn.active svg.close {
  display: block;
}

header.style-1 .nav-right .right-sidebar-and-hotline-area .sidebar-btn.active svg.open {
  display: none;
}

header.style-1 .nav-right .sidebar-and-btn {
  display: flex;
  align-items: center;
  gap: 35px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  header.style-1 .nav-right .sidebar-and-btn {
    gap: 25px;
  }
}

header.style-1 .nav-right .sidebar-and-btn .sidebar-btn {
  cursor: pointer;
}

header.style-1 .nav-right .sidebar-and-btn .sidebar-btn svg {
  fill: var(--text-color);
}

header.style-1 .nav-right .sidebar-and-btn .primary-btn2 {
  border-radius: 10px;
}

header.style-1 .main-menu {
  display: inline-block;
  position: relative;
}

header.style-1 .main-menu>ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

header.style-1 .main-menu>ul>li {
  display: inline-block;
  position: relative;
  padding: 0 25px;
}

@media (max-width: 1399px) {
  header.style-1 .main-menu>ul>li {
    padding: 0 20px;
  }
}

@media (max-width: 991px) {
  header.style-1 .main-menu>ul>li {
    padding: 0 12px;
  }
}

header.style-1 .main-menu>ul>li:hover i {
  color: var(--black-color2);
  font-size: 20px;
}

@media (min-width: 992px) {
  header.style-1 .main-menu>ul>li:first-child {
    padding-left: 0;
  }

  header.style-1 .main-menu>ul>li:first-child a {
    padding-left: 0;
  }

  header.style-1 .main-menu>ul>li:last-child {
    padding-right: 0;
  }
}

header.style-1 .main-menu>ul>li>a {
  color: var(--text-color);
  display: block;
  letter-spacing: 0.4px;
  padding: 25px 0;
  position: relative;
  font-family: var(--font-inter);
  font-weight: 500;
  font-size: 15px;
  transition: all 0.5s ease-out 0s;
  position: relative;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-1 .main-menu>ul>li>a {
    padding: 22px 0;
  }
}

header.style-1 .main-menu>ul>li>a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 26px;
  left: 0;
  width: 100%;
  border-bottom: 1px solid var(--title-color);
  transform-origin: right center;
  transform: scale(0, 1);
  transition: transform 0.5s;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-1 .main-menu>ul>li>a::after {
    bottom: 22px;
  }
}

@media (max-width: 991px) {
  header.style-1 .main-menu>ul>li>a::after {
    display: none;
  }
}

header.style-1 .main-menu>ul>li i {
  font-size: 20px;
  text-align: center;
  color: var(--title-color);
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 35px;
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
}

@media (max-width: 991px) {
  header.style-1 .main-menu>ul>li i {
    opacity: 1;
  }
}

header.style-1 .main-menu>ul>li i.active {
  color: var(--title-color);
}

header.style-1 .main-menu>ul>li i.active::before {
  content: "\f2ea";
}

header.style-1 .main-menu>ul>li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  display: none;
  min-width: 220px;
  background: var(--dark-white-color);
  box-shadow: 0 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  transform-origin: top;
}

@media (max-width: 1199px) {
  header.style-1 .main-menu>ul>li ul.sub-menu {
    box-shadow: none;
  }
}

header.style-1 .main-menu>ul>li ul.sub-menu>li {
  padding: 0;
  display: block;
  position: relative;
}

header.style-1 .main-menu>ul>li ul.sub-menu>li i {
  position: absolute;
  top: 14px;
  right: 6px;
  display: block;
  color: var(--title-color);
  font-size: 14px;
}

header.style-1 .main-menu>ul>li ul.sub-menu>li .dropdown-icon {
  color: var(--title-color);
  opacity: 1;
  top: 14px;
  font-size: 14px;
  right: 10px;
}

@media (max-width: 991px) {
  header.style-1 .main-menu>ul>li ul.sub-menu>li .dropdown-icon {
    right: 0;
    top: 8px;
    font-size: 20px;
  }
}

header.style-1 .main-menu>ul>li ul.sub-menu>li>a {
  display: block;
  padding: 15px;
  color: var(--title-color);
  font-weight: 400;
  font-family: var(--font-hankenGrotesk);
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  transition: all 0.4s ease-out 0s;
  position: relative;
  border-bottom: 1px solid var(--border-color);
}

header.style-1 .main-menu>ul>li ul.sub-menu>li>a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  background: linear-gradient(90deg, #fff, var(--primary-color1), #fff);
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: 1;
}

@media (max-width: 991px) {
  header.style-1 .main-menu>ul>li ul.sub-menu>li>a {
    border-color: rgba(233, 228, 228, 0.5);
  }

  header.style-1 .main-menu>ul>li ul.sub-menu>li>a::before {
    background: linear-gradient(90deg, #ddd, #efb93f, #ddd);
  }
}

header.style-1 .main-menu>ul>li ul.sub-menu>li>a:hover {
  color: var(--primary-color1);
}

header.style-1 .main-menu>ul>li ul.sub-menu>li>a:hover::before {
  transform: scale(1);
}

header.style-1 .main-menu>ul>li ul.sub-menu>li>a.active {
  color: var(--primary-color1);
}

header.style-1 .main-menu>ul>li ul.sub-menu>li>a.active::before {
  transform: scale(1);
}

header.style-1 .main-menu>ul>li ul.sub-menu>li:last-child a {
  border-bottom: none;
}

header.style-1 .main-menu>ul>li ul.sub-menu>li:last-child a::before {
  display: none;
}

header.style-1 .main-menu>ul>li ul.sub-menu>li:last-child .sub-menu li a {
  border-bottom: 1px solid var(--border-color);
}

header.style-1 .main-menu>ul>li ul.sub-menu>li:last-child .sub-menu li a::before {
  display: block;
}

header.style-1 .main-menu>ul>li ul.sub-menu>li:last-child .sub-menu li:last-child a {
  border-bottom: none;
}

header.style-1 .main-menu>ul>li ul.sub-menu>li:last-child .sub-menu li:last-child a::before {
  display: none;
}

header.style-1 .main-menu>ul>li ul.sub-menu>li:hover>a {
  color: var(--primary-color1);
}

header.style-1 .main-menu>ul>li ul.sub-menu>li:hover .dropdown-icon {
  color: var(--primary-color1);
}

header.style-1 .main-menu>ul>li ul.sub-menu>li.active>a {
  color: var(--primary-color1);
}

header.style-1 .main-menu>ul>li ul.sub-menu>li.active .dropdown-icon {
  color: var(--primary-color1);
}

header.style-1 .main-menu>ul>li ul.sub-menu>li .sub-menu {
  left: 220px;
  position: absolute;
  max-width: 230px;
  min-width: 215px;
  background: var(--dark-white-color);
  box-shadow: 0 30px 80px rgba(8, 0, 42, 0.08);
  top: 0;
}

@media (max-width: 1199px) {
  header.style-1 .main-menu>ul>li ul.sub-menu>li .sub-menu {
    box-shadow: none;
  }
}

header.style-1 .main-menu>ul>li ul.sub-menu>li .sub-menu::before {
  display: none;
  visibility: hidden;
}

@media only screen and (max-width: 991px) {
  header.style-1 .main-menu>ul>li ul.sub-menu>li .sub-menu {
    position: unset;
    max-width: 230px;
    min-width: 215px;
    background: 0 0;
    top: 0;
  }
}

header.style-1 .main-menu>ul>li ul.sub-menu>li .sub-menu>li i {
  display: block;
}

@media (min-width: 992px) {
  header.style-1 .main-menu>ul>li ul.sub-menu>li:hover>.sub-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}

header.style-1 .main-menu>ul>li.menu-item-has-children {
  transition: all 0.55s ease-in-out;
}

header.style-1 .main-menu>ul>li.menu-item-has-children::after {
  content: "\f282";
  font-family: bootstrap-icons;
  font-weight: 500;
  position: absolute;
  top: 26px;
  right: 6px;
  font-size: 12px;
  color: var(--title-color);
  transition: all 0.55s ease-in-out;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-1 .main-menu>ul>li.menu-item-has-children::after {
    right: 0;
    top: 23px;
  }
}

@media (max-width: 1199px) {
  header.style-1 .main-menu>ul>li.menu-item-has-children::after {
    right: 0;
  }
}

@media (max-width: 991px) {
  header.style-1 .main-menu>ul>li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}

header.style-1 .main-menu>ul>li.menu-item-has-children:hover::after {
  content: "\f286";
}

@media (min-width: 992px) {
  header.style-1 .main-menu>ul>li:hover>ul.sub-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}

header.style-1 .main-menu>ul>li:hover>a {
  color: var(--title-color);
}

header.style-1 .main-menu>ul>li:hover>a::after {
  transform-origin: left center;
  transform: scale(1, 1);
}

header.style-1 .main-menu>ul>li:hover::after {
  color: var(--title-color);
}

header.style-1 .main-menu>ul>li:hover .dropdown-icon2 {
  color: var(--primary-color1);
}

header.style-1 .main-menu>ul>li.active>a {
  color: var(--primary-color1);
}

header.style-1 .main-menu>ul>li.active>a::before {
  content: "";
  position: absolute;
  bottom: 26px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--primary-color1);
}

@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-1 .main-menu>ul>li.active>a::before {
    bottom: 22px;
  }
}

@media (max-width: 991px) {
  header.style-1 .main-menu>ul>li.active>a::before {
    display: none;
  }
}

header.style-1 .main-menu>ul>li.active>a::after {
  border-bottom: 1px solid var(--primary-color1);
}

header.style-1 .main-menu>ul>li.active::after {
  color: var(--primary-color1);
}

header.style-1 .main-menu>ul>li.active .dropdown-icon2 {
  color: var(--primary-color1);
}

header.style-1 .main-menu>ul>li.active.position-inherit>a::after {
  color: var(--primary-color1);
}

header.style-1 .main-menu>ul li.menu-item-has-children>i {
  display: block;
}

header.style-2 {
  position: absolute;
  width: 99% !important;
  z-index: 999;
  transition: all 0.8s ease-out 0s;
  background: linear-gradient(180deg, #314976 0%, #000000 100%) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

header.style-2.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  background-color: blue !important;
  box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
  z-index: 999;
}

.scrolled{
  background-color: red;
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0px);
  }
}


@media (max-width: 991px) {
  header.style-2 .company-logo {
    padding: 15px 0;
  }
}

header.style-2 .company-logo .logo-dark {
  display: block;
}

header.style-2 .company-logo .logo-light {
  display: none;
}

header.style-2 .nav-right {
  gap: 50px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-2 .nav-right {
    gap: 35px;
  }
}

@media (max-width: 1199px) {
  header.style-2 .nav-right {
    gap: 25px;
  }
}

@media (max-width: 991px) {
  header.style-2 .nav-right {
    gap: 0;
  }
}

header.style-2 .nav-right .right-sidebar-and-hotline-area {
  display: flex;
  align-items: center;
}

header.style-2 .nav-right .right-sidebar-and-hotline-area .hotline-area {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 5px 0 0 5px;
  border: 1px solid rgba(var(--title-color-opc), 0.05);
  background: rgba(var(--primary-color1-opc), 0.05);
  padding: 5px 25px 5px 15px;
  border-right: unset;
}

header.style-2 .nav-right .right-sidebar-and-hotline-area .hotline-area .icon {
  background-color: rgba(var(--title-color-opc), 0.1);
  border-radius: 5px;
  padding: 4px 5px;
  line-height: 1;
}

header.style-2 .nav-right .right-sidebar-and-hotline-area .hotline-area .icon svg {
  fill: var(--primary-color2);
}

header.style-2 .nav-right .right-sidebar-and-hotline-area .hotline-area .content {
  position: relative;
  line-height: 1;
}

header.style-2 .nav-right .right-sidebar-and-hotline-area .hotline-area .content span {
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 11px;
  line-height: 1;
  color: var(--text-color);
  margin-bottom: 5px;
  display: inline-block;
}

header.style-2 .nav-right .right-sidebar-and-hotline-area .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}

header.style-2 .nav-right .right-sidebar-and-hotline-area .hotline-area .content h6 a {
  font-family: var(--font-hankenGrotesk);
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.5s;
}

header.style-2 .nav-right .right-sidebar-and-hotline-area .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}

header.style-2 .nav-right .right-sidebar-and-hotline-area .right-sidebar-button {
  background-color: var(--black-color2);
  padding: 16px 18px;
  line-height: 1;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

@media (max-width: 1199px) {
  header.style-2 .nav-right .right-sidebar-and-hotline-area .right-sidebar-button {
    border-radius: 5px;
    padding: 13px 15px;
  }
}

@media (max-width: 576px) {
  header.style-2 .nav-right .right-sidebar-and-hotline-area .right-sidebar-button {
    padding: 8px;
  }
}

header.style-2 .nav-right .right-sidebar-and-hotline-area .right-sidebar-button svg {
  fill: var(--white-color);
}

header.style-2 .nav-right .sidebar-and-btn {
  display: flex;
  align-items: center;
  gap: 35px;
}

@media (max-width: 1199px) {
  header.style-2 .nav-right .sidebar-and-btn {
    gap: 20px;
  }
}

header.style-2 .nav-right .sidebar-and-btn .sidebar-btn {
  cursor: pointer;
}

header.style-2 .nav-right .sidebar-and-btn .sidebar-btn svg {
  fill: var(--white-color);
}

header.style-2 .nav-right .sidebar-and-btn .primary-btn2 {
  background-color: var(--white-color);
  color: var(--dark-title-color);
}

header.style-2 .nav-right .sidebar-and-btn .primary-btn2:hover {
  color: var(--white-color);
  background-color: var(--primary-color1);
}

header.style-2 .main-menu {
  display: inline-block;
  position: relative;
}

header.style-2 .main-menu>ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

header.style-2 .main-menu>ul>li {
  display: inline-block;
  position: relative;
  padding: 0 25px;
}

@media (max-width: 1399px) {
  header.style-2 .main-menu>ul>li {
    padding: 0 20px;
  }
}

@media (max-width: 991px) {
  header.style-2 .main-menu>ul>li {
    padding: 0 12px;
  }
}

header.style-2 .main-menu>ul>li:hover i {
  color: var(--black-color2);
  font-size: 20px;
}

@media (min-width: 992px) {
  header.style-2 .main-menu>ul>li:first-child {
    padding-left: 0;
  }

  header.style-2 .main-menu>ul>li:first-child a {
    padding-left: 0;
  }
}

header.style-2 .main-menu>ul>li>a {
  color: var(--white-color);
  display: block;
  letter-spacing: 0.4px;
  padding: 25px 0;
  position: relative;
  font-family: var(--font-inter);
  font-weight: 500;
  font-size: 15px;
  transition: all 0.5s ease-out 0s;
  position: relative;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-2 .main-menu>ul>li>a {
    padding: 22px 0;
  }
}

header.style-2 .main-menu>ul>li>a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  border-bottom: 1px solid var(--primary-color1);
  transform-origin: right center;
  transform: scale(0, 1);
  transition: transform 0.5s;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-2 .main-menu>ul>li>a::after {
    bottom: 22px;
  }
}

@media (max-width: 991px) {
  header.style-2 .main-menu>ul>li>a::after {
    display: none;
  }
}

header.style-2 .main-menu>ul>li i {
  font-size: 20px;
  text-align: center;
  color: var(--title-color);
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 35px;
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
}

@media (max-width: 991px) {
  header.style-2 .main-menu>ul>li i {
    opacity: 1;
  }
}

header.style-2 .main-menu>ul>li i.active {
  color: var(--title-color);
}

header.style-2 .main-menu>ul>li i.active::before {
  content: "\f2ea";
}

header.style-2 .main-menu>ul>li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  display: none;
  min-width: 220px;
  background: var(--dark-white-color);
  box-shadow: 0 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  transform-origin: top;
}

@media (max-width: 1199px) {
  header.style-2 .main-menu>ul>li ul.sub-menu {
    box-shadow: none;
  }
}

header.style-2 .main-menu>ul>li ul.sub-menu>li {
  padding: 0;
  display: block;
  position: relative;
}

header.style-2 .main-menu>ul>li ul.sub-menu>li i {
  position: absolute;
  top: 14px;
  right: 6px;
  display: block;
  color: var(--title-color);
  font-size: 14px;
}

header.style-2 .main-menu>ul>li ul.sub-menu>li .dropdown-icon {
  color: var(--title-color);
  opacity: 1;
  top: 14px;
  font-size: 14px;
  right: 10px;
}

@media (max-width: 991px) {
  header.style-2 .main-menu>ul>li ul.sub-menu>li .dropdown-icon {
    right: 0;
    top: 8px;
    font-size: 20px;
  }
}

header.style-2 .main-menu>ul>li ul.sub-menu>li>a {
  display: block;
  padding: 15px;
  color: var(--title-color);
  font-weight: 400;
  font-family: var(--font-hankenGrotesk);
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  transition: all 0.4s ease-out 0s;
  position: relative;
  border-bottom: 1px solid var(--border-color);
}

header.style-2 .main-menu>ul>li ul.sub-menu>li>a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  background: linear-gradient(90deg, #fff, var(--primary-color1), #fff);
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: 1;
}

@media (max-width: 991px) {
  header.style-2 .main-menu>ul>li ul.sub-menu>li>a {
    border-color: rgba(233, 228, 228, 0.5);
  }

  header.style-2 .main-menu>ul>li ul.sub-menu>li>a::before {
    background: linear-gradient(90deg, #ddd, #efb93f, #ddd);
  }
}

header.style-2 .main-menu>ul>li ul.sub-menu>li>a:hover {
  color: var(--primary-color1);
}

header.style-2 .main-menu>ul>li ul.sub-menu>li>a:hover::before {
  transform: scale(1);
}

header.style-2 .main-menu>ul>li ul.sub-menu>li>a.active {
  color: var(--primary-color1);
}

header.style-2 .main-menu>ul>li ul.sub-menu>li>a.active::before {
  transform: scale(1);
}

header.style-2 .main-menu>ul>li ul.sub-menu>li:last-child a {
  border-bottom: none;
}

header.style-2 .main-menu>ul>li ul.sub-menu>li:last-child a::before {
  display: none;
}

header.style-2 .main-menu>ul>li ul.sub-menu>li:last-child .sub-menu li a {
  border-bottom: 1px solid var(--border-color);
}

header.style-2 .main-menu>ul>li ul.sub-menu>li:last-child .sub-menu li a::before {
  display: block;
}

header.style-2 .main-menu>ul>li ul.sub-menu>li:last-child .sub-menu li:last-child a {
  border-bottom: none;
}

header.style-2 .main-menu>ul>li ul.sub-menu>li:last-child .sub-menu li:last-child a::before {
  display: none;
}

header.style-2 .main-menu>ul>li ul.sub-menu>li:hover>a {
  color: var(--primary-color1);
}

header.style-2 .main-menu>ul>li ul.sub-menu>li:hover .dropdown-icon {
  color: var(--primary-color1);
}

header.style-2 .main-menu>ul>li ul.sub-menu>li.active>a {
  color: var(--primary-color1);
}

header.style-2 .main-menu>ul>li ul.sub-menu>li.active .dropdown-icon {
  color: var(--primary-color1);
}

header.style-2 .main-menu>ul>li ul.sub-menu>li .sub-menu {
  left: 190px;
  position: absolute;
  max-width: 10px;
  min-width: 215px;
  background: var(--dark-white-color);
  box-shadow: 0 30px 80px rgba(8, 0, 42, 0.08);
  top: 0;
}

@media (max-width: 1199px) {
  header.style-2 .main-menu>ul>li ul.sub-menu>li .sub-menu {
    box-shadow: none;
  }
}

header.style-2 .main-menu>ul>li ul.sub-menu>li .sub-menu::before {
  display: none;
  visibility: hidden;
}

@media only screen and (max-width: 991px) {
  header.style-2 .main-menu>ul>li ul.sub-menu>li .sub-menu {
    position: unset;
    max-width: 230px;
    min-width: 215px;
    background: 0 0;
    top: 0;
  }
}

header.style-2 .main-menu>ul>li ul.sub-menu>li .sub-menu>li i {
  display: block;
}

@media (min-width: 992px) {
  header.style-2 .main-menu>ul>li ul.sub-menu>li:hover>.sub-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}

header.style-2 .main-menu>ul>li ul.sub-menu>li:last-child {
  border-bottom: none;
}

header.style-2 .main-menu>ul>li.menu-item-has-children {
  transition: all 0.55s ease-in-out;
}

header.style-2 .main-menu > ul > li.menu-item-has-children::after {
  content: "\f107"; /* Example Unicode for a downward arrow in FontAwesome */
  font-family: 'Font Awesome 5 Free'; /* Specify the correct font family */
  font-weight: 900; /* For FontAwesome 5 Free */
  position: absolute;
  top: 26px;
  right: 6px;
  font-size: 12px;
  color: var(--white-color);
  transition: all 0.55s ease-in-out;
}


@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-2 .main-menu>ul>li.menu-item-has-children::after {
    right: 0;
    top: 23px;
  }
}

@media (max-width: 1199px) {
  header.style-2 .main-menu>ul>li.menu-item-has-children::after {
    right: 0;
  }
}

@media (max-width: 991px) {
  header.style-2 .main-menu>ul>li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}

header.style-2 .main-menu>ul>li.menu-item-has-children:hover::after {
  content: "\f286";
}

@media (min-width: 992px) {
  header.style-2 .main-menu>ul>li:hover>ul.sub-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}

header.style-2 .main-menu>ul>li:hover>a {
  color: var(--primary-color1);
}

header.style-2 .main-menu>ul>li:hover>a::after {
  transform-origin: left center;
  transform: scale(1, 1);
}

header.style-2 .main-menu>ul>li:hover::after {
  color: var(--primary-color1);
}

header.style-2 .main-menu>ul>li:hover .dropdown-icon2 {
  color: var(--primary-color1);
}

header.style-2 .main-menu>ul>li.active>a {
  color: var(--primary-color1);
}

header.style-2 .main-menu>ul>li.active>a::before {
  content: "";
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--primary-color1);
}

@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-2 .main-menu>ul>li.active>a::before {
    bottom: 22px;
  }
}

@media (max-width: 991px) {
  header.style-2 .main-menu>ul>li.active>a::before {
    display: none;
  }
}

header.style-2 .main-menu>ul>li.active::after {
  color: var(--primary-color1);
}

header.style-2 .main-menu>ul>li.active .dropdown-icon2 {
  color: var(--primary-color1);
}

header.style-2 .main-menu>ul>li.active.position-inherit>a::after {
  color: var(--primary-color1);
}

header.style-2 .main-menu>ul li.menu-item-has-children>i {
  display: block;
}

header.style-3 {
  position: absolute;
  width: 100%;
  z-index: 999;
  transition: all 0.8s ease-out 0s;
  background-color: transparent;
  border-bottom: 1px solid rgba(var(--title-color-opc), 0.1);
  padding: 0 5%;
}

@media (max-width: 1399px) {
  header.style-3 {
    padding: 0 2%;
  }
}

header.style-3.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  background-color: var(--dark-white-color);
  box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
  z-index: 999;
}

@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 991px) {
  header.style-3 .company-logo {
    padding: 15px 0;
  }
}

header.style-3 .company-logo .logo-dark {
  display: block;
}

header.style-3 .company-logo .logo-light {
  display: none;
}

header.style-3 .nav-right {
  gap: 50px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3 .nav-right {
    gap: 35px;
  }
}

@media (max-width: 1199px) {
  header.style-3 .nav-right {
    gap: 25px;
  }
}

@media (max-width: 991px) {
  header.style-3 .nav-right {
    gap: 0;
  }
}

header.style-3 .nav-right .sidebar-and-btn {
  display: flex;
  align-items: center;
  gap: 35px;
}

@media (max-width: 1399px) {
  header.style-3 .nav-right .sidebar-and-btn {
    gap: 25px;
  }
}

header.style-3 .nav-right .sidebar-and-btn .hotline-area {
  display: flex;
  align-items: center;
  gap: 8px;
}

header.style-3 .nav-right .sidebar-and-btn .hotline-area .icon {
  background-color: rgba(var(--title-color-opc), 0.1);
  border-radius: 5px;
  padding: 4px 5px;
  line-height: 1;
}

header.style-3 .nav-right .sidebar-and-btn .hotline-area .icon svg {
  fill: var(--primary-color2);
}

header.style-3 .nav-right .sidebar-and-btn .hotline-area .content {
  position: relative;
  line-height: 1;
}

header.style-3 .nav-right .sidebar-and-btn .hotline-area .content span {
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 11px;
  line-height: 1;
  color: var(--primary-color1);
  margin-bottom: 5px;
  display: inline-block;
}

header.style-3 .nav-right .sidebar-and-btn .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}

header.style-3 .nav-right .sidebar-and-btn .hotline-area .content h6 a {
  font-family: var(--font-hankenGrotesk);
  font-weight: 600;
  font-size: 15px;
  line-height: 1;
  color: var(--title-color);
  transition: 0.5s;
}

header.style-3 .nav-right .sidebar-and-btn .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}

header.style-3 .nav-right .sidebar-and-btn .sidebar-btn {
  background-color: var(--black-color2);
  padding: 12px 14px;
  line-height: 1;
  border-radius: 5px;
  cursor: pointer;
}

header.style-3 .nav-right .sidebar-and-btn .sidebar-btn svg {
  fill: var(--white-color);
}

header.style-3 .nav-right .sidebar-and-btn .primary-btn2 {
  border-radius: 10px;
}

header.style-3 .main-menu {
  display: inline-block;
  position: relative;
}

header.style-3 .main-menu>ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

header.style-3 .main-menu>ul>li {
  display: inline-block;
  position: relative;
  padding: 0 25px;
}

@media (max-width: 1399px) {
  header.style-3 .main-menu>ul>li {
    padding: 0 20px;
  }
}

@media (max-width: 991px) {
  header.style-3 .main-menu>ul>li {
    padding: 0 12px;
  }
}

header.style-3 .main-menu>ul>li:hover i {
  color: var(--black-color2);
  font-size: 20px;
}

@media (min-width: 992px) {
  header.style-3 .main-menu>ul>li:first-child {
    padding-left: 0;
  }

  header.style-3 .main-menu>ul>li:first-child a {
    padding-left: 0;
  }
}

header.style-3 .main-menu>ul>li>a {
  color: var(--text-color);
  display: block;
  letter-spacing: 0.4px;
  padding: 25px 0;
  position: relative;
  font-family: var(--font-inter);
  font-weight: 500;
  font-size: 15px;
  transition: all 0.5s ease-out 0s;
  position: relative;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3 .main-menu>ul>li>a {
    padding: 22px 0;
  }
}

header.style-3 .main-menu>ul>li>a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 30px;
  left: 1px;
  width: 100%;
  border-bottom: 1px solid var(--primary-color1);
  transform-origin: right center;
  transform: scale(0, 1);
  transition: transform 0.5s;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3 .main-menu>ul>li>a::after {
    bottom: 22px;
  }
}

@media (max-width: 991px) {
  header.style-3 .main-menu>ul>li>a::after {
    display: none;
  }
}

header.style-3 .main-menu>ul>li i {
  font-size: 20px;
  text-align: center;
  color: var(--title-color);
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 35px;
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
}

@media (max-width: 991px) {
  header.style-3 .main-menu>ul>li i {
    opacity: 1;
  }
}

header.style-3 .main-menu>ul>li i.active {
  color: var(--title-color);
}

header.style-3 .main-menu>ul>li i.active::before {
  content: "\f2ea";
}

header.style-3 .main-menu>ul>li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  display: none;
  min-width: 220px;
  background: var(--dark-white-color);
  box-shadow: 0 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  transform-origin: top;
}

@media (max-width: 1199px) {
  header.style-3 .main-menu>ul>li ul.sub-menu {
    box-shadow: none;
  }
}

header.style-3 .main-menu>ul>li ul.sub-menu>li {
  padding: 0;
  display: block;
  position: relative;
}

header.style-3 .main-menu>ul>li ul.sub-menu>li i {
  position: absolute;
  top: 14px;
  right: 6px;
  display: block;
  color: var(--title-color);
  font-size: 14px;
}

header.style-3 .main-menu>ul>li ul.sub-menu>li .dropdown-icon {
  color: var(--title-color);
  opacity: 1;
  top: 14px;
  font-size: 14px;
  right: 10px;
}

@media (max-width: 991px) {
  header.style-3 .main-menu>ul>li ul.sub-menu>li .dropdown-icon {
    right: 0;
    top: 8px;
    font-size: 20px;
  }
}

header.style-3 .main-menu>ul>li ul.sub-menu>li>a {
  display: block;
  padding: 15px;
  color: var(--title-color);
  font-weight: 400;
  font-family: var(--font-hankenGrotesk);
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  transition: all 0.4s ease-out 0s;
  position: relative;
  border-bottom: 1px solid var(--border-color);
}

header.style-3 .main-menu>ul>li ul.sub-menu>li>a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  background: linear-gradient(90deg, #fff, var(--primary-color1), #fff);
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: 1;
}

@media (max-width: 991px) {
  header.style-3 .main-menu>ul>li ul.sub-menu>li>a {
    border-color: rgba(233, 228, 228, 0.5);
  }

  header.style-3 .main-menu>ul>li ul.sub-menu>li>a::before {
    background: linear-gradient(90deg, #ddd, #efb93f, #ddd);
  }
}

header.style-3 .main-menu>ul>li ul.sub-menu>li>a:hover {
  color: var(--primary-color1);
}

header.style-3 .main-menu>ul>li ul.sub-menu>li>a:hover::before {
  transform: scale(1);
}

header.style-3 .main-menu>ul>li ul.sub-menu>li>a.active {
  color: var(--primary-color1);
}

header.style-3 .main-menu>ul>li ul.sub-menu>li>a.active::before {
  transform: scale(1);
}

header.style-3 .main-menu>ul>li ul.sub-menu>li:last-child a {
  border-bottom: none;
}

header.style-3 .main-menu>ul>li ul.sub-menu>li:last-child a::before {
  display: none;
}

header.style-3 .main-menu>ul>li ul.sub-menu>li:last-child .sub-menu li a {
  border-bottom: 1px solid var(--border-color);
}

header.style-3 .main-menu>ul>li ul.sub-menu>li:last-child .sub-menu li a::before {
  display: block;
}

header.style-3 .main-menu>ul>li ul.sub-menu>li:last-child .sub-menu li:last-child a {
  border-bottom: none;
}

header.style-3 .main-menu>ul>li ul.sub-menu>li:last-child .sub-menu li:last-child a::before {
  display: none;
}

header.style-3 .main-menu>ul>li ul.sub-menu>li:hover>a {
  color: var(--primary-color1);
}

header.style-3 .main-menu>ul>li ul.sub-menu>li:hover .dropdown-icon {
  color: var(--primary-color1);
}

header.style-3 .main-menu>ul>li ul.sub-menu>li.active>a {
  color: var(--primary-color1);
}

header.style-3 .main-menu>ul>li ul.sub-menu>li.active .dropdown-icon {
  color: var(--primary-color1);
}

header.style-3 .main-menu>ul>li ul.sub-menu>li .sub-menu {
  left: 220px;
  position: absolute;
  max-width: 230px;
  min-width: 215px;
  background: var(--dark-white-color);
  box-shadow: 0 30px 80px rgba(8, 0, 42, 0.08);
  top: 0;
}

@media (max-width: 1199px) {
  header.style-3 .main-menu>ul>li ul.sub-menu>li .sub-menu {
    box-shadow: none;
  }
}

header.style-3 .main-menu>ul>li ul.sub-menu>li .sub-menu::before {
  display: none;
  visibility: hidden;
}

@media only screen and (max-width: 991px) {
  header.style-3 .main-menu>ul>li ul.sub-menu>li .sub-menu {
    position: unset;
    max-width: 230px;
    min-width: 215px;
    background: 0 0;
    top: 0;
  }
}

header.style-3 .main-menu>ul>li ul.sub-menu>li .sub-menu>li i {
  display: block;
}

@media (min-width: 992px) {
  header.style-3 .main-menu>ul>li ul.sub-menu>li:hover>.sub-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}

header.style-3 .main-menu>ul>li ul.sub-menu>li:last-child {
  border-bottom: none;
}

header.style-3 .main-menu>ul>li.menu-item-has-children {
  transition: all 0.55s ease-in-out;
}

header.style-3 .main-menu>ul>li.menu-item-has-children::after {
  content: "\f123";
  font-family: bootstrap-icons;
  font-weight: 500;
  position: absolute;
  top: 25px;
  right: 5px;
  font-size: 14px;
  color: var(--title-color);
  transition: all 0.55s ease-in-out;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3 .main-menu>ul>li.menu-item-has-children::after {
    right: 0;
    top: 23px;
  }
}

@media (max-width: 1199px) {
  header.style-3 .main-menu>ul>li.menu-item-has-children::after {
    right: 0;
  }
}

@media (max-width: 991px) {
  header.style-3 .main-menu>ul>li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}

header.style-3 .main-menu>ul>li.menu-item-has-children:hover::after {
  transform: rotate(45deg);
  color: var(--primary-color1);
}

@media (min-width: 992px) {
  header.style-3 .main-menu>ul>li:hover>ul.sub-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}

header.style-3 .main-menu>ul>li:hover>a {
  color: var(--primary-color1);
}

header.style-3 .main-menu>ul>li:hover>a::after {
  transform-origin: left center;
  transform: scale(1, 1);
}

header.style-3 .main-menu>ul>li:hover::after {
  color: var(--title-color);
}

header.style-3 .main-menu>ul>li:hover .dropdown-icon2 {
  color: var(--primary-color1);
}

header.style-3 .main-menu>ul>li.active>a {
  color: var(--primary-color1);
}

header.style-3 .main-menu>ul>li.active::after {
  color: var(--primary-color1);
}

header.style-3 .main-menu>ul>li.active .dropdown-icon2 {
  color: var(--primary-color1);
}

header.style-3 .main-menu>ul li.menu-item-has-children>i {
  display: block;
}

.sidebar-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 0 70px;
  z-index: 9992;
  overflow-y: auto;
  transition: all 0.3s;
  background-color: var(--dark-white-color);
  height: 100%;
  transform-origin: top;
  transform: translateY(-120%);
  transition: transform 0.6s ease-in;
}




@media (max-width: 1399px) {
  .sidebar-menu {
    padding: 0 0 70px;
  }
}

.sidebar-menu.active {
  transform: translateY(0);
}

.sidebar-menu .sidebar-menu-top-area {
  padding: 25px 0;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 80px;
}

.sidebar-menu .sidebar-menu-top-area .sidebar-menu-logo .logo-light {
  display: none;
}

.sidebar-menu .sidebar-menu-top-area .sidebar-menu-close {
  border: 1px solid var(--primary-color2);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
}

.sidebar-menu .sidebar-menu-top-area .sidebar-menu-close svg {
  fill: var(--primary-color2);
  transition: 0.5s;
}

@media (max-width: 576px) {
  .sidebar-menu .sidebar-menu-top-area .sidebar-menu-close {
    width: 36px;
    height: 36px;
  }

  .sidebar-menu .sidebar-menu-top-area .sidebar-menu-close svg {
    width: 18px;
    height: 18px;
  }
}

.sidebar-menu .sidebar-menu-top-area .sidebar-menu-close:hover {
  background-color: #ff4820;
  border-color: #ff4820;
}

.sidebar-menu .sidebar-menu-top-area .sidebar-menu-close:hover svg {
  fill: var(--white-color);
}

.sidebar-menu .sidebar-contact .getin-touch-area h4 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 35px;
}

@media (max-width: 991px) {
  .sidebar-menu .sidebar-contact .getin-touch-area h4 {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .sidebar-menu .sidebar-contact .getin-touch-area h4 {
    margin-bottom: 25px;
  }
}

.sidebar-menu .sidebar-contact .getin-touch-area h4 svg {
  fill: var(--title-color);
  margin-left: 5px;
}

.sidebar-menu .sidebar-contact .getin-touch-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-menu .sidebar-contact .getin-touch-area ul .single-contact {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 40px;
}

.sidebar-menu .sidebar-contact .getin-touch-area ul .single-contact:last-child {
  margin-bottom: 0;
}

.sidebar-menu .sidebar-contact .getin-touch-area ul .single-contact .icon {
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
  border: 1px solid var(--title-color);
}

.sidebar-menu .sidebar-contact .getin-touch-area ul .single-contact .icon svg {
  transition: 0.35s;
  fill: var(--title-color);
}

.sidebar-menu .sidebar-contact .getin-touch-area ul .single-contact .contact span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 600;
}

.sidebar-menu .sidebar-contact .getin-touch-area ul .single-contact .contact h6 {
  margin-bottom: 0;
}

.sidebar-menu .sidebar-contact .getin-touch-area ul .single-contact .contact h6 a {
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--title-color);
  transition: 0.35s;
}

@media (max-width: 576px) {
  .sidebar-menu .sidebar-contact .getin-touch-area ul .single-contact .contact h6 a {
    font-size: 16px;
  }
}

.sidebar-menu .sidebar-contact .getin-touch-area ul .single-contact .contact h6 a:hover {
  color: var(--primary-color1);
}

.sidebar-menu .sidebar-contact .getin-touch-area ul .single-contact:hover .icon {
  background-color: var(--title-color);
}

.sidebar-menu .sidebar-contact .getin-touch-area ul .single-contact:hover .icon svg {
  fill: var(--dark-white-color);
}

.sidebar-menu .sidebar-contact .social-link-area h6 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 0;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.sidebar-menu .sidebar-contact .social-link-area h6 svg {
  fill: var(--title-color);
  margin-left: 5px;
}

.sidebar-menu .sidebar-contact .social-link-area .social-area {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-menu .sidebar-contact .social-link-area .social-area li a {
  border: 1px solid var(--border-color);
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  padding: 7px 15px;
  transition: 0.35s;
}

.sidebar-menu .sidebar-contact .social-link-area .social-area li a i {
  color: var(--text-color);
  line-height: 1;
  transition: 0.35s;
}

.sidebar-menu .sidebar-contact .social-link-area .social-area li a:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  color: var(--white-color);
}

.sidebar-menu .sidebar-contact .social-link-area .social-area li a:hover i {
  color: var(--white-color);
}

.sidebar-menu .sidebar-menu-wrap {
  border-left: 1px solid var(--border-color);
  margin-right: 50px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .sidebar-menu .sidebar-menu-wrap {
    margin-right: 30px;
  }
}

@media (max-width: 1199px) {
  .sidebar-menu .sidebar-menu-wrap {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .sidebar-menu .sidebar-menu-wrap {
    margin-right: 0;
  }
}

.sidebar-menu .sidebar-menu-wrap .main-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  border-top: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

.sidebar-menu .sidebar-menu-wrap .main-menu::-webkit-scrollbar {
  width: 0;
}

.sidebar-menu .sidebar-menu-wrap .main-menu::-webkit-scrollbar-track {
  background: var(--title-color);
}

.sidebar-menu .sidebar-menu-wrap .main-menu::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li {
  position: relative;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a {
  border-bottom: 1px solid var(--border-color);
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
  display: block;
  line-height: 1;
  padding: 25px;
  transform: translateY(var(--y)) translateZ(0);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

@media (max-width: 767px) {
  .sidebar-menu .sidebar-menu-wrap .main-menu>li>a {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .sidebar-menu .sidebar-menu-wrap .main-menu>li>a {
    padding: 15px 20px;
    font-size: 22px;
  }
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text {
  display: flex;
  overflow: hidden;
  text-shadow: 0 30px 0 var(--title-color);
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span {
  display: block;
  backface-visibility: hidden;
  transition: transform 0.4s ease;
  transform: translateY(var(--m)) translateZ(0);
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(1) {
  transition-delay: 0.05s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(2) {
  transition-delay: 0.1s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(3) {
  transition-delay: 0.15s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(4) {
  transition-delay: 0.2s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(5) {
  transition-delay: 0.25s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(6) {
  transition-delay: 0.3s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(7) {
  transition-delay: 0.35s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(8) {
  transition-delay: 0.4s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(9) {
  transition-delay: 0.45s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(10) {
  transition-delay: 0.5s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(11) {
  transition-delay: 0.55s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(12) {
  transition-delay: 0.6s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(13) {
  transition-delay: 0.65s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(14) {
  transition-delay: 0.7s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(15) {
  transition-delay: 0.75s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(16) {
  transition-delay: 0.8s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(17) {
  transition-delay: 0.85s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(18) {
  transition-delay: 0.9s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(19) {
  transition-delay: 0.95s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(20) {
  transition-delay: 1s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(21) {
  transition-delay: 1.05s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(22) {
  transition-delay: 1.1s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(23) {
  transition-delay: 1.15s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(24) {
  transition-delay: 1.2s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(25) {
  transition-delay: 1.25s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(26) {
  transition-delay: 1.3s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(27) {
  transition-delay: 1.35s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(28) {
  transition-delay: 1.4s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(29) {
  transition-delay: 1.45s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(30) {
  transition-delay: 1.5s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(31) {
  transition-delay: 1.55s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(32) {
  transition-delay: 1.6s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(33) {
  transition-delay: 1.65s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(34) {
  transition-delay: 1.7s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(35) {
  transition-delay: 1.75s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(36) {
  transition-delay: 1.8s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(37) {
  transition-delay: 1.85s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(38) {
  transition-delay: 1.9s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(39) {
  transition-delay: 1.95s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a .menu-text span:nth-child(40) {
  transition-delay: 2s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a:hover {
  --y: -0px;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li>a:hover span {
  --m: calc(30px * -1);
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .dropdown-icon2 {
  position: absolute;
  right: 25px;
  top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: var(--light-black-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .dropdown-icon2 i {
  color: var(--white-color);
  font-size: 28px;
}

@media (max-width: 767px) {
  .sidebar-menu .sidebar-menu-wrap .main-menu>li .dropdown-icon2 {
    right: 21px;
  }
}

@media (max-width: 576px) {
  .sidebar-menu .sidebar-menu-wrap .main-menu>li .dropdown-icon2 {
    top: 10px;
    right: 20px;
    width: 35px;
    height: 35px;
  }
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .dropdown-icon2.active i::before {
  content: "\f2ea";
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .dropdown-icon2.two {
  height: unset;
  width: unset;
  background-color: transparent;
  top: -7px;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .dropdown-icon2.two i {
  color: var(--title-color);
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 40px;
  position: static;
  opacity: 1;
  visibility: visible;
  transition: none;
  display: none;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 70px;
  border-bottom: 1px solid var(--border-color);
}

@media (max-width: 1399px) {
  .sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list {
    padding-left: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list.active {
  display: block;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li {
  position: relative;
  margin-bottom: 15px;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li:last-child {
  margin-bottom: 0;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li::after {
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  position: absolute;
  left: -20px;
  border: 1px solid var(--text-color);
  top: 10px;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li a {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.35s;
}

@media (max-width: 1199px) {
  .sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li a {
    font-size: 25px;
  }
}

@media (max-width: 576px) {
  .sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li a {
    font-size: 20px;
  }
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li a svg {
  fill: var(--text-color);
  margin-left: 5px;
  transition: 0.35s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li a:hover {
  color: var(--title-color);
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li a:hover svg {
  fill: var(--primary-color1);
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li .submenu-list {
  padding-top: 20px;
  padding-bottom: 7px;
  padding-left: 20px;
  border-bottom: none;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li .submenu-list li {
  margin-bottom: 10px;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li .submenu-list li::after {
  display: none;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li .submenu-list li a {
  font-size: 15px;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li .submenu-list li a svg {
  fill: var(--text-color);
  margin-left: 5px;
  transition: 0.35s;
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li .submenu-list li a:hover {
  color: var(--title-color);
}

.sidebar-menu .sidebar-menu-wrap .main-menu>li .submenu-list li .submenu-list li a:hover svg {
  fill: var(--primary-color);
}

.home1-banner-section {
  background: linear-gradient(180deg,
      rgba(48, 103, 255, 0.08) 0%,
      rgba(48, 103, 255, 0) 100%);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 175px 0 0 16.5%;
  overflow: hidden;
}

@media (max-width: 1799px) {
  .home1-banner-section {
    padding: 175px 0 0 12%;
  }
}

@media (max-width: 1699px) {
  .home1-banner-section {
    padding: 175px 0 0 9.5%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home1-banner-section {
    padding: 160px 0 0 4%;
  }
}

@media (max-width: 1399px) {
  .home1-banner-section {
    padding: 150px 0 0 3.5%;
  }
}

@media (max-width: 991px) {
  .home1-banner-section {
    padding: 170px 30px 100px;
  }
}

@media (max-width: 767px) {
  .home1-banner-section {
    padding: 150px 30px 80px;
  }
}

@media (max-width: 576px) {
  .home1-banner-section {
    padding: 140px 15px 70px;
  }
}

.home1-banner-section .banner-content {
  max-width: 750px;
  width: 100%;
}

.home1-banner-section .banner-content>span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 10px 5px;
  background-color: rgba(var(--title-color-opc), 0.05);
  border-radius: 5px;
  margin-bottom: 20px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home1-banner-section .banner-content>span {
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home1-banner-section .banner-content>span {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .home1-banner-section .banner-content>span {
    margin-bottom: 10px;
  }
}

.home1-banner-section .banner-content>span svg {
  fill: rgba(48, 103, 255, 0.3);
}

.home1-banner-section .banner-content h1 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 65px;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 25px;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home1-banner-section .banner-content h1 {
    font-size: 58px;
  }
}

@media (max-width: 1399px) {
  .home1-banner-section .banner-content h1 {
    font-size: 50px;
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home1-banner-section .banner-content h1 {
    font-size: 40px;
  }
}

@media (max-width: 576px) {
  .home1-banner-section .banner-content h1 {
    font-size: 38px;
  }
}

.home1-banner-section .banner-content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 45px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home1-banner-section .banner-content p {
    font-size: 15px;
    margin-bottom: 25px;
  }
}

@media (max-width: 991px) {
  .home1-banner-section .banner-content p {
    margin-bottom: 35px;
  }
}

@media (max-width: 576px) {
  .home1-banner-section .banner-content p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}

.home1-banner-section .banner-content .banner-content-bottom {
  display: flex;
  align-items: center;
  gap: 40px;
}

@media (max-width: 576px) {
  .home1-banner-section .banner-content .banner-content-bottom {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.home1-banner-section .banner-content .banner-content-bottom .rating-area {
  display: flex;
  align-items: center;
  gap: 10px;
}

.home1-banner-section .banner-content .banner-content-bottom .rating-area .review span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 5px;
  display: block;
}

.home1-banner-section .banner-content .banner-content-bottom .rating-area .review .logo-dark {
  display: block;
}

.home1-banner-section .banner-content .banner-content-bottom .rating-area .review .logo-light {
  display: none;
}

.home1-banner-section .banner-content .banner-content-bottom .rating-area .rating .star {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 3px;
  margin-top: 5px;
  line-height: 1;
}

.home1-banner-section .banner-content .banner-content-bottom .rating-area .rating .star li i {
  color: #e62415;
}

.home1-banner-section .banner-content .banner-content-bottom .rating-area .rating span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.home1-banner-section .banner-img-wrap img {
  border-radius: 5px 0 0 0;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home1-banner-section .banner-img-wrap img {
    min-height: 500px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home1-banner-section .banner-img-wrap img {
    min-height: 420px;
  }
}

@media (max-width: 991px) {
  .home1-banner-section .banner-img-wrap {
    display: none;
  }
}

.home1-banner-section .video-area .badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  border-radius: 100%;
  animation: identifier 20s linear infinite;
  position: absolute;
  bottom: 0;
  left: 55%;
  z-index: 1;
  background-color: var(--dark-white-color);
  padding: 5px;
}

@media (max-width: 1699px) {
  .home1-banner-section .video-area .badge {
    left: 52%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home1-banner-section .video-area .badge {
    left: 48%;
  }
}

@media (max-width: 1399px) {
  .home1-banner-section .video-area .badge {
    left: 48%;
  }
}

@media (max-width: 1199px) {
  .home1-banner-section .video-area .badge {
    width: 110px;
    height: 110px;
    left: 49%;
  }
}

@media (max-width: 991px) {
  .home1-banner-section .video-area .badge {
    display: none;
  }
}

.home1-banner-section .video-area .badge .play {
  margin: 0;
  border-radius: 50%;
  animation: identifier 20s linear infinite;
  animation-direction: reverse;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home1-banner-section .video-area .badge .play .play-icon {
  text-align: center;
}

.home1-banner-section .video-area .badge .play .play-icon i {
  font-size: 45px;
  color: rgba(var(--title-color-opc), 0.5);
}

.home1-banner-section .video-area .badge .badge__char {
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - 5px);
  height: 65px;
  width: 10px;
  background-repeat: round;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 500;
  font-size: 13px;
  color: var(--title-color);
  font-family: var(--font-inter);
  transform-origin: bottom center;
  transform: rotate(var(--char-rotate));
}

@media (max-width: 1199px) {
  .home1-banner-section .video-area .badge .badge__char {
    height: 55px;
  }
}

@keyframes identifier {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.home1-banner-bottom {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.home1-banner-bottom.two {
  transform: rotate(-2deg) translateY(-13px);
  margin-top: -30px;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home1-banner-bottom.two {
    transform: rotate(-2deg) translateY(-24px);
  }
}

@media (max-width: 1399px) {
  .home1-banner-bottom.two {
    transform: rotate(-2deg) translateY(-13px);
    margin-top: -19px;
  }
}

@media (max-width: 991px) {
  .home1-banner-bottom.two {
    transform: rotate(0) translateY(0);
    margin-top: -19px;
  }
}

.home1-banner-bottom .marquee_text {
  background: #1e1f1f;
  height: 70px;
  display: flex;
  align-items: center;
}

.home1-banner-bottom .js-marquee {
  display: flex;
  align-items: center;
  gap: 25px;
}

.home1-banner-bottom h6 {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.home1-banner-bottom svg {
  fill: rgba(48, 103, 255, 0.7);
}

.feature-card .icon {
  margin-bottom: 30px;
  transition: transform 0.45s ease;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .feature-card .icon {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .feature-card .icon {
    margin-bottom: 20px;
  }
}

.feature-card .icon svg {
  fill: var(--text-color);
}

.feature-card .content h4 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 27px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
}

@media (max-width: 576px) {
  .feature-card .content h4 {
    font-size: 22px;
    margin-bottom: 10px;
  }
}

.feature-card .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .feature-card .content p {
    font-size: 15px;
  }
}

.feature-card:hover .icon {
  animation: fadeInUp 0.7s linear;
}

.feature-card.two {
  position: relative;
  z-index: 1;
}

.feature-card.two::before {
  content: "\f138";
  font-family: bootstrap-icons !important;
  font-size: 23px;
  position: absolute;
  left: 50%;
  top: 38px;
  z-index: -1;
  color: #3f444b;
}

.feature-card.two::after {
  content: "";
  height: 1px;
  width: 86%;
  background-color: #3f444b;
  opacity: 0.2;
  position: absolute;
  left: 76px;
  top: 52px;
  z-index: -1;
}

.feature-card.two .icon {
  position: relative;
  display: inline-block;
}

.feature-card.two .icon .icon-bg {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.feature-card.two .icon .icon-bg svg {
  fill: rgba(var(--primary-color1-opc), 0.1);
}

.feature-card.two .content ul {
  padding-top: 35px;
}

@media (max-width: 576px) {
  .feature-card.two .content ul {
    padding-top: 25px;
  }
}

.feature-card.two .content ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
}

.feature-card.two .content ul li:last-child {
  margin-bottom: 0;
}

.feature-card.two .content ul li svg {
  fill: var(--text-color);
}

.feature-card.two:hover .icon {
  animation: unset;
}

.feature-card.two:hover .icon>svg {
  animation: zoomIn 0.75s linear;
}

.home1-about-section .about-content {
  max-width: 910px;
  width: 100%;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home1-about-section .about-content {
    max-width: 650px;
  }
}

.home1-about-section .about-content .about-section-title>span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 10px 5px;
  background-color: rgba(var(--title-color-opc), 0.05);
  border-radius: 5px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .home1-about-section .about-content .about-section-title>span {
    margin-bottom: 10px;
  }
}

.home1-about-section .about-content .about-section-title>span svg {
  fill: rgba(48, 103, 255, 0.3);
}

.home1-about-section .about-content .about-section-title h2 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 50px;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .home1-about-section .about-content .about-section-title h2 {
    font-size: 38px;
  }
}

@media (max-width: 767px) {
  .home1-about-section .about-content .about-section-title h2 {
    font-size: 32px;
  }
}

@media (max-width: 576px) {
  .home1-about-section .about-content .about-section-title h2 {
    font-size: 28px;
  }
}

.home1-about-section .about-content .about-section-title p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  padding-top: 20px;
  margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home1-about-section .about-content .about-section-title p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .home1-about-section .about-content .about-section-title p {
    padding-top: 15px;
  }
}

.home1-about-section .about-countdown-area {
  padding-top: 30px;
}

@media (max-width: 991px) {
  .home1-about-section .about-countdown-area ul {
    display: flex;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap;
  }
}

.home1-about-section .about-countdown-area ul .single-countdown {
  display: flex;
  gap: 30px;
  margin-bottom: 50px;
}

@media (max-width: 1399px) {
  .home1-about-section .about-countdown-area ul .single-countdown {
    margin-bottom: 40px;
    gap: 25px;
  }
}

@media (max-width: 991px) {
  .home1-about-section .about-countdown-area ul .single-countdown {
    margin-bottom: 0;
    gap: 20px;
  }
}

.home1-about-section .about-countdown-area ul .single-countdown .icon {
  margin-top: 2px;
}

.home1-about-section .about-countdown-area ul .single-countdown .icon svg {
  fill: var(--text-color);
}

.home1-about-section .about-countdown-area ul .single-countdown .content {
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(var(--title-color-opc), 0.1);
}

@media (max-width: 991px) {
  .home1-about-section .about-countdown-area ul .single-countdown .content {
    padding-bottom: 0;
    border-bottom: unset;
  }
}

.home1-about-section .about-countdown-area ul .single-countdown .content .number {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  line-height: 1;
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-bottom: 10px;
}

.home1-about-section .about-countdown-area ul .single-countdown .content .number h5 {
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 0;
  line-height: 1;
}

@media (max-width: 991px) {
  .home1-about-section .about-countdown-area ul .single-countdown .content .number h5 {
    font-size: 35px;
  }
}

.home1-about-section .about-countdown-area ul .single-countdown .content .number span {
  font-size: 35px;
  font-weight: 700;
}

@media (max-width: 991px) {
  .home1-about-section .about-countdown-area ul .single-countdown .content .number span {
    font-size: 22px;
  }
}

.home1-about-section .about-countdown-area ul .single-countdown .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .home1-about-section .about-countdown-area ul .single-countdown .content p {
    font-size: 14px;
  }
}

.home1-about-section .about-countdown-area ul .single-countdown:last-child {
  margin-bottom: 0;
}

.home1-about-section .about-countdown-area ul .single-countdown:last-child .content {
  border-bottom: unset;
  padding-bottom: 0;
}

.home1-about-section .about-bottom-area {
  padding: 0 12.5%;
}

@media (max-width: 1799px) {
  .home1-about-section .about-bottom-area {
    padding: 0 8%;
  }
}

@media (max-width: 1699px) {
  .home1-about-section .about-bottom-area {
    padding: 0 6%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home1-about-section .about-bottom-area {
    padding: 0 2%;
  }
}

@media (max-width: 1399px) {
  .home1-about-section .about-bottom-area {
    padding: 0 2%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home1-about-section .about-bottom-area .feature-card .content h4 {
    font-size: 25px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home1-about-section .about-bottom-area .feature-card .content h4 {
    font-size: 24px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home1-about-section .about-bottom-area .feature-card .content h4 {
    font-size: 22px;
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home1-about-section .about-bottom-area .feature-card .content p {
    font-size: 15px;
  }
}

.service-card {
  background-color: var(--dark-white-color);
  border-radius: 5px;
  padding: 35px 30px;
  transition: 0.5s;
}

@media (max-width: 1399px) {
  .service-card {
    padding: 35px 25px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-card {
    padding: 25px 20px;
  }
}

@media (max-width: 576px) {
  .service-card {
    padding: 25px 20px;
  }
}

.service-card .content {
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(11, 12, 12, 0.1);
  margin-bottom: 20px;
}

.service-card .content h4 {
  margin-bottom: 15px;
}

.service-card .content h4 a {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  transition: 0.5s;
}

@media (max-width: 1399px) {
  .service-card .content h4 a {
    font-size: 25px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-card .content h4 a {
    font-size: 22px;
  }
}

@media (max-width: 576px) {
  .service-card .content h4 a {
    font-size: 22px;
  }
}

.service-card .content h4 a:hover {
  color: var(--primary-color1);
}

.service-card .content svg {
  fill: var(--title-color);
}

.service-card .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0;
  padding-top: 15px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-card .content p {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .service-card .content p {
    font-size: 15px;
  }
}

.service-card .explore-btn {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  padding-bottom: 2px;
  background: linear-gradient(to bottom, #0b0c0c 0%, #0b0c0c 98%);
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: left 100%;
  transition: background-size 0.75s;
}

.service-card .explore-btn svg {
  fill: var(--title-color);
  transition: 0.75s;
}

.service-card .explore-btn:hover {
  color: var(--primary-color1);
  background-size: 0 1px;
  background-position: 0% 100%;
}

.service-card .explore-btn:hover svg {
  fill: var(--primary-color1);
  transform: rotate(0deg);
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-card.two {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
}

.home1-solution-section {
  background-image: url(../img/home1/solution-bg.png),
    linear-gradient(180deg, #edf1fc 0%, rgba(201, 215, 255, 0.13) 100%);
  background-color: var(--dark-white-color);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
}

@media (max-width: 1199px) {
  .home1-solution-section {
    padding: 90px 0;
  }
}

@media (max-width: 991px) {
  .home1-solution-section {
    padding: 70px 0;
  }
}

.eg-card {
  position: relative;
  padding: 0;
  height: 100%;
  transition: 0.5s;
}

.eg-card .card-content {
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.7) 1.13%,
      rgba(0, 0, 0, 0) 55.54%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: unset;
  padding: 40px;
  display: flex;
  justify-content: end;
  flex-direction: column;
  transition: 0.5s;
}

@media (max-width: 1399px) {
  .eg-card .card-content {
    padding: 35px 20px;
  }
}

@media (max-width: 991px) {
  .eg-card .card-content {
    padding: 35px 20px;
  }
}

@media (max-width: 767px) {
  .eg-card .card-content {
    padding: 30px 20px;
  }
}

.eg-card .card-content .view-btn {
  position: absolute;
  right: 85px;
  top: 85px;
  transition: 0.5s;
  opacity: 0;
}

.eg-card .card-content .catgory-and-title>a {
  border-radius: 15px;
  border: 1px solid var(--white-color);
  background: 0 0;
  display: inline-block;
  color: var(--white-color);
  text-align: center;
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  padding: 5px 18px;
  line-height: 1;
  margin-bottom: 13px;
}

.eg-card .card-content .catgory-and-title h5 {
  margin-bottom: 0;
}

.eg-card .card-content .catgory-and-title h5 a {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 24px;
  font-weight: 500;
  transition: 0.35s;
}

.eg-card .card-content .catgory-and-title h5 a:hover {
  color: #cacaca;
}

.eg-card:hover .card-content .view-btn {
  right: 35px;
  top: 35px;
  opacity: 1;
}

.portfolio-section .portfolio-bottom-area {
  padding: 0 11%;
  margin-top: -220px;
}

@media (max-width: 1799px) {
  .portfolio-section .portfolio-bottom-area {
    padding: 0 6.5%;
  }
}

@media (max-width: 1699px) {
  .portfolio-section .portfolio-bottom-area {
    padding: 0 5%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .portfolio-section .portfolio-bottom-area {
    padding: 0 10px;
  }
}

@media (max-width: 1399px) {
  .portfolio-section .portfolio-bottom-area {
    padding: 0 10px;
  }
}

@media (max-width: 1199px) {
  .portfolio-section .portfolio-bottom-area {
    padding: 0;
    margin-top: -150px;
  }
}

@media (max-width: 576px) {
  .portfolio-section .portfolio-bottom-area {
    display: none;
  }
}

.portfolio-section .portfolio-bottom-area .pagination-area {
  background: #ebf2f6;
  height: 380px;
  display: flex;
  justify-content: center;
  align-items: end;
  padding: 0 80px;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .portfolio-section .portfolio-bottom-area .pagination-area {
    padding: 0 30px;
  }
}

@media (max-width: 1399px) {
  .portfolio-section .portfolio-bottom-area .pagination-area {
    padding: 0 30px;
  }
}

@media (max-width: 1199px) {
  .portfolio-section .portfolio-bottom-area .pagination-area {
    height: 280px;
    padding: 0 15px;
  }
}

.portfolio-section .portfolio-bottom-area .pagination-area .swiper-pagination {
  padding: 40px 0 50px;
  border-top: 1px solid var(--white-color);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 9;
  bottom: unset;
  gap: 12px;
}

@media (max-width: 1199px) {
  .portfolio-section .portfolio-bottom-area .pagination-area .swiper-pagination {
    padding: 30px 0;
  }
}

.portfolio-section .portfolio-bottom-area .pagination-area .swiper-pagination .swiper-pagination-bullet {
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid #596977;
  opacity: 1;
  position: relative;
}

.portfolio-section .portfolio-bottom-area .pagination-area .swiper-pagination .swiper-pagination-bullet::after {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #596977;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.portfolio-section .portfolio-bottom-area .pagination-area .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: var(--primary-color1);
  height: 20px;
  width: 20px;
}

.portfolio-section .portfolio-bottom-area .pagination-area .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  width: 10px;
  height: 10px;
  background-color: var(--primary-color1);
}

.home1-process-section .process-slider {
  padding: 10px;
  margin: -10px;
}

.case-study-card {
  background-color: var(--black-color3);
  border-radius: 10px;
  padding: 30px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .case-study-card {
    padding: 30px 20px;
  }
}

@media (max-width: 991px) {
  .case-study-card {
    padding: 25px 15px;
  }
}

.case-study-card .card-img {
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}

.case-study-card .card-img img {
  border-radius: 10px;
  transition: all 0.5s ease-out;
}

.case-study-card .card-img::after {
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
  z-index: 1;
}

.case-study-card .card-content>a {
  color: var(--primary-color1);
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 10px;
}

.case-study-card .card-content h4 {
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .case-study-card .card-content h4 {
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  .case-study-card .card-content h4 {
    margin-bottom: 10px;
  }
}

.case-study-card .card-content h4 a {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 28px;
  font-weight: 600;
  line-height: 1.4;
  transition: 0.5s;
}

@media (max-width: 1699px) {
  .case-study-card .card-content h4 a {
    font-size: 26px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .case-study-card .card-content h4 a {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .case-study-card .card-content h4 a {
    font-size: 22px;
  }
}

@media (max-width: 576px) {
  .case-study-card .card-content h4 a {
    font-size: 20px;
  }
}

.case-study-card .card-content h4 a:hover {
  color: var(--primary-color1);
}

.case-study-card .card-content p {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 30px;
}

@media (max-width: 576px) {
  .case-study-card .card-content p {
    font-size: 15px;
  }
}

.case-study-card .card-content .learn-more-btn {
  color: var(--white-color);
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  text-transform: uppercase;
  padding-bottom: 2px;
  background: linear-gradient(to bottom, #fff 0%, #fff 98%);
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: left 100%;
  transition: background-size 0.75s;
}

.case-study-card .card-content .learn-more-btn svg {
  fill: var(--white-color);
  transition: 0.75s;
}

.case-study-card .card-content .learn-more-btn:hover {
  color: var(--primary-color1);
  background-size: 0 1px;
  background-position: 0% 100%;
}

.case-study-card .card-content .learn-more-btn:hover svg {
  fill: var(--primary-color1);
}

.case-study-card:hover .card-img img {
  transform: scale(1.1);
}

.case-study-card:hover .card-img::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}

.case-study-card.style-2 {
  background-color: var(--dark-white-color);
  border: 1px solid rgba(var(--title-color-opc), 0.08);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  padding: 30px 20px;
}

@media (max-width: 576px) {
  .case-study-card.style-2 {
    padding: 20px 15px;
  }
}

.case-study-card.style-2 .card-content h4 a {
  color: var(--title-color);
}

.case-study-card.style-2 .card-content h4 a:hover {
  color: var(--primary-color1);
}

.case-study-card.style-2 .card-content p {
  color: rgba(var(--text-color-opc), 0.7);
}

.case-study-card.style-2 .card-content .learn-more-btn {
  color: var(--title-color);
  background: linear-gradient(to bottom, #0b0c0c 0%, #0b0c0c 98%);
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: left 100%;
  transition: background-size 0.75s;
}

.case-study-card.style-2 .card-content .learn-more-btn svg {
  fill: var(--title-color);
  transition: 0.75s;
}

.case-study-card.style-2 .card-content .learn-more-btn:hover {
  color: var(--primary-color1);
  background-size: 0 1px;
  background-position: 0% 100%;
}

.case-study-card.style-2 .card-content .learn-more-btn:hover svg {
  fill: var(--primary-color1);
}

.banner-with-case-study-slider-section {
  overflow: hidden;
}

.banner-with-case-study-slider-section .banner-area {
  position: relative;
  height: 100%;
}

@media (max-width: 991px) {
  .banner-with-case-study-slider-section .banner-area {
    display: none;
  }
}

.banner-with-case-study-slider-section .banner-area img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.banner-with-case-study-slider-section .banner-area .banner-content {
  background: linear-gradient(181deg,
      rgba(11, 12, 12, 0.63) 13.52%,
      rgba(11, 12, 12, 0.5) 25.19%,
      rgba(11, 12, 12, 0.5) 56.23%,
      rgba(11, 12, 12, 0.63) 82.81%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 70px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .banner-with-case-study-slider-section .banner-area .banner-content {
    padding: 70px 30px;
  }
}

@media (max-width: 1399px) {
  .banner-with-case-study-slider-section .banner-area .banner-content {
    padding: 60px 20px;
  }
}

.banner-with-case-study-slider-section .banner-area .banner-content .banner-content-bottom p {
  color: var(--white-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 30px;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .banner-with-case-study-slider-section .banner-area .banner-content .section-title h2 {
    font-size: 45px;
  }
}

@media (max-width: 1399px) {
  .banner-with-case-study-slider-section .banner-area .banner-content .section-title h2 {
    font-size: 38px;
  }
}

.banner-with-case-study-slider-section .case-study-slider-area {
  background-color: var(--light-black-color);
  padding: 90px 115px;
}

@media (max-width: 1699px) {
  .banner-with-case-study-slider-section .case-study-slider-area {
    padding: 90px 80px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .banner-with-case-study-slider-section .case-study-slider-area {
    padding: 80px 50px;
  }
}

@media (max-width: 1399px) {
  .banner-with-case-study-slider-section .case-study-slider-area {
    padding: 50px 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .banner-with-case-study-slider-section .case-study-slider-area {
    padding: 40px 30px;
  }
}

@media (max-width: 576px) {
  .banner-with-case-study-slider-section .case-study-slider-area {
    padding: 40px 20px;
  }
}

.banner-with-case-study-slider-section .case-study-slider-area .slider-btn-grp {
  max-width: 530px;
  width: 100%;
  justify-content: space-between;
}

.banner-with-case-study-slider-section .case-study-slider-area .slider-btn-grp .slider-btn {
  border-color: rgba(255, 255, 255, 0.5);
}

.banner-with-case-study-slider-section .case-study-slider-area .slider-btn-grp .slider-btn i {
  color: rgba(255, 255, 255, 0.5);
}

.banner-with-case-study-slider-section .case-study-slider-area .slider-btn-grp .slider-btn:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.banner-with-case-study-slider-section .case-study-slider-area .slider-btn-grp .slider-btn:hover i {
  color: var(--white-color);
}

.home1-approach-section .approach-area {
  padding: 0 13%;
}

@media (max-width: 1799px) {
  .home1-approach-section .approach-area {
    padding: 0 8%;
  }
}

@media (max-width: 1699px) {
  .home1-approach-section .approach-area {
    padding: 0 6%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home1-approach-section .approach-area {
    padding: 0 20px;
  }
}

@media (max-width: 1399px) {
  .home1-approach-section .approach-area {
    padding: 0 10px;
  }
}

.home1-approach-section .approach-area .divider {
  position: relative;
}

.home1-approach-section .approach-area .divider::before {
  content: "";
  position: absolute;
  top: 38.5%;
  left: 50%;
  transform: translateX(-50%);
  width: 98%;
  height: 2px;
  background-color: var(--border-color);
  border-radius: 10px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home1-approach-section .approach-area .divider::before {
    top: 39.2%;
  }
}

@media (max-width: 1199px) {
  .home1-approach-section .approach-area .divider::before {
    display: none;
  }
}

.home1-approach-section .approach-area .single-approach {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home1-approach-section .approach-area .single-approach .img-area {
  margin-bottom: 15px;
}

.home1-approach-section .approach-area .single-approach .img-area img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
}

.home1-approach-section .approach-area .single-approach .dot {
  margin-bottom: 30px;
  line-height: 1;
}

.home1-approach-section .approach-area .single-approach .dot span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--title-color);
  display: inline-flex;
  position: relative;
  z-index: 1;
}

.home1-approach-section .approach-area .single-approach .approach-content-wrap {
  border-radius: 5px;
  background-image: linear-gradient(180deg,
      rgba(237, 241, 252, 0.38) 0%,
      rgba(201, 215, 255, 0.13) 100%);
  background-color: var(--dark-white-color);
  padding: 30px 25px;
  position: relative;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home1-approach-section .approach-area .single-approach .approach-content-wrap {
    padding: 25px 15px;
  }
}

@media (max-width: 1199px) {
  .home1-approach-section .approach-area .single-approach .approach-content-wrap {
    padding: 25px 20px;
  }
}

.home1-approach-section .approach-area .single-approach .approach-content-wrap::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  border-radius: 0 0 5px 5px;
  background: linear-gradient(180deg,
      rgba(237, 241, 252, 0) 0%,
      rgba(237, 241, 252, 0.76) 26.46%,
      #edf1fc 45.7%);
  z-index: 1;
}

.home1-approach-section .approach-area .single-approach .approach-content-wrap::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 38px;
  background-color: var(--border-color);
  left: 50%;
  transform: translateX(-50%);
  top: -38px;
}

.home1-approach-section .approach-area .single-approach .approach-content-wrap .scroll-content-area {
  overflow: hidden;
}

.home1-approach-section .approach-area .single-approach .approach-content-wrap .scroll-content-area .content {
  max-height: 260px;
  transform: translateY(0);
  transition: 5s;
}

.home1-approach-section .approach-area .single-approach .approach-content-wrap .scroll-content-area .content span {
  color: var(--primary-color1);
  font-family: var(--font-hankenGrotesk);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 10px;
}

.home1-approach-section .approach-area .single-approach .approach-content-wrap .scroll-content-area .content h5 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 23px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 10px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home1-approach-section .approach-area .single-approach .approach-content-wrap .scroll-content-area .content h5 {
    font-size: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home1-approach-section .approach-area .single-approach .approach-content-wrap .scroll-content-area .content h5 {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .home1-approach-section .approach-area .single-approach .approach-content-wrap .scroll-content-area .content h5 {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .home1-approach-section .approach-area .single-approach .approach-content-wrap .scroll-content-area .content h5 {
    font-size: 22px;
  }
}

.home1-approach-section .approach-area .single-approach .approach-content-wrap .scroll-content-area .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 15px;
}

.home1-approach-section .approach-area .single-approach .approach-content-wrap .scroll-content-area .content ul li {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 10px;
  padding-left: 12px;
  position: relative;
}

.home1-approach-section .approach-area .single-approach .approach-content-wrap .scroll-content-area .content ul li:last-child {
  margin-bottom: 0;
}

.home1-approach-section .approach-area .single-approach .approach-content-wrap .scroll-content-area .content ul li strong {
  color: var(--title-color);
  font-weight: 600;
}

.home1-approach-section .approach-area .single-approach .approach-content-wrap .scroll-content-area .content ul li::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--title-color);
}

.home1-approach-section .approach-area .single-approach .approach-content-wrap:hover .scrollTextAni {
  transform: translateY(-200px);
}

.logo-section {
  overflow: hidden;
  padding: 0 4%;
}

.logo-section .logo-wrap .logo-title {
  text-align: center;
  margin-bottom: 45px;
}

@media (max-width: 1399px) {
  .logo-section .logo-wrap .logo-title {
    margin-bottom: 35px;
  }
}

@media (max-width: 991px) {
  .logo-section .logo-wrap .logo-title {
    margin-bottom: 25px;
  }
}

.logo-section .logo-wrap .logo-title h6 {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.logo-section .logo-wrap .logo-area {
  overflow: hidden;
}

.logo-section .logo-wrap .logo-area .js-marquee-wrapper {
  display: flex;
  align-items: center;
  gap: 110px;
}

@media (max-width: 1499px) {
  .logo-section .logo-wrap .logo-area .js-marquee-wrapper {
    gap: 70px;
  }
}

@media (max-width: 576px) {
  .logo-section .logo-wrap .logo-area .js-marquee-wrapper {
    gap: 50px;
  }
}

.logo-section .logo-wrap .logo-area .js-marquee-wrapper .js-marquee {
  display: flex;
  align-items: center;
  gap: 110px;
  margin-right: 0 !important;
}

@media (max-width: 1499px) {
  .logo-section .logo-wrap .logo-area .js-marquee-wrapper .js-marquee {
    gap: 70px;
  }
}

@media (max-width: 576px) {
  .logo-section .logo-wrap .logo-area .js-marquee-wrapper .js-marquee {
    gap: 50px;
  }
}

.testimonial-card-wrapper .testimonial-card {
  background-color: var(--dark-white-color);
  border-radius: 10px;
  padding: 40px 35px;
  position: relative;
  margin-bottom: 45px;
}

@media (max-width: 576px) {
  .testimonial-card-wrapper .testimonial-card {
    padding: 30px 20px;
  }
}

.testimonial-card-wrapper .testimonial-card .logo-and-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.testimonial-card-wrapper .testimonial-card .logo-and-rating .logo .light {
  display: block;
}

.testimonial-card-wrapper .testimonial-card .logo-and-rating .logo .dark {
  display: none;
}

.testimonial-card-wrapper .testimonial-card .logo-and-rating .rating {
  display: flex;
  align-items: center;
  gap: 8px;
}

.testimonial-card-wrapper .testimonial-card .logo-and-rating .rating li i {
  color: #ffc107;
}

.testimonial-card-wrapper .testimonial-card p {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 22px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.03em;
  margin-bottom: 35px;
}

@media (max-width: 991px) {
  .testimonial-card-wrapper .testimonial-card p {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .testimonial-card-wrapper .testimonial-card p {
    font-size: 15px;
  }
}

.testimonial-card-wrapper .testimonial-card .author-and-desig h5 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 23px;
  font-weight: 600;
  line-height: 1;
}

@media (max-width: 576px) {
  .testimonial-card-wrapper .testimonial-card .author-and-desig h5 {
    font-size: 20px;
  }
}

.testimonial-card-wrapper .testimonial-card .author-and-desig p {
  color: rgba(var(--text-color-opc), 0.5);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.32px;
  margin-bottom: 0;
}

.testimonial-card-wrapper .testimonial-card::after {
  content: "";
  width: 40px;
  height: 40px;
  background: var(--dark-white-color);
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  bottom: -20px;
  opacity: 1;
  transition: 0.35s;
}

.testimonial-card-wrapper .author-area {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-card-wrapper .author-area .author-img {
  transition: 0.35s;
  opacity: 1;
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.testimonial-card-wrapper .author-area .author-img img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.home1-testimonial-section {
  background-image: url(../img/home1/testimonial-bg.png),
    linear-gradient(180deg, #d3e1ef 0%, #d3e1ef 100%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  padding: 100px 7%;
}

@media (max-width: 1699px) {
  .home1-testimonial-section {
    padding: 80px 4%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home1-testimonial-section {
    padding: 80px 2%;
  }
}

@media (max-width: 1399px) {
  .home1-testimonial-section {
    padding: 80px 2%;
  }
}

@media (max-width: 991px) {
  .home1-testimonial-section {
    padding: 70px 20px;
  }
}

@media (max-width: 576px) {
  .home1-testimonial-section {
    padding: 70px 10px;
  }
}

.home1-testimonial-section .testimonial-content .rating-area {
  display: flex;
  align-items: center;
  gap: 60px;
  padding-top: 50px;
}

@media (max-width: 1399px) {
  .home1-testimonial-section .testimonial-content .rating-area {
    gap: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home1-testimonial-section .testimonial-content .rating-area {
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 35px;
  }
}

@media (max-width: 991px) {
  .home1-testimonial-section .testimonial-content .rating-area {
    padding-top: 30px;
  }
}

@media (max-width: 576px) {
  .home1-testimonial-section .testimonial-content .rating-area {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.home1-testimonial-section .testimonial-content .rating-area>li {
  position: relative;
}

.home1-testimonial-section .testimonial-content .rating-area>li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  background-color: rgba(var(--primary-color1-opc), 0.2);
  width: 1px;
  height: 35px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home1-testimonial-section .testimonial-content .rating-area>li::before {
    left: -15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home1-testimonial-section .testimonial-content .rating-area>li::before {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .home1-testimonial-section .testimonial-content .rating-area>li::before {
    left: -15px;
  }
}

@media (max-width: 576px) {
  .home1-testimonial-section .testimonial-content .rating-area>li::before {
    display: none;
  }
}

.home1-testimonial-section .testimonial-content .rating-area>li:first-child::before {
  display: none;
}

.home1-testimonial-section .testimonial-content .rating-area>li .single-rating {
  display: flex;
  align-items: center;
  gap: 8px;
}

.home1-testimonial-section .testimonial-content .rating-area>li .single-rating .review {
  line-height: 1;
}

.home1-testimonial-section .testimonial-content .rating-area>li .single-rating .review span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 8px;
  display: block;
}

.home1-testimonial-section .testimonial-content .rating-area>li .single-rating .review .logo-dark {
  display: block;
}

.home1-testimonial-section .testimonial-content .rating-area>li .single-rating .review .logo-light {
  display: none;
}

.home1-testimonial-section .testimonial-content .rating-area>li .single-rating .review img {
  width: 64px;
}

.home1-testimonial-section .testimonial-content .rating-area>li .single-rating .rating {
  line-height: 1;
}

.home1-testimonial-section .testimonial-content .rating-area>li .single-rating .rating .star {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
  line-height: 1;
}

.home1-testimonial-section .testimonial-content .rating-area>li .single-rating .rating .star li i {
  color: #e62415;
  font-size: 12px;
}

.home1-testimonial-section .testimonial-content .rating-area>li .single-rating .rating span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.home1-testimonial-section .testimonial-content .rating-area>li .single-rating.google .rating .star li i {
  color: #ffc107;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home1-testimonial-section .testimonial-content .section-title h2 {
    font-size: 45px;
  }
}

.home1-testimonial-section .tetimonial-slider-wrapper .swiper-slide-active span {
  animation: fadeInDown 1.7s;
}

.home1-testimonial-section .tetimonial-slider-wrapper .swiper-slide-active p {
  animation: fadeInDown 1.7s;
}

.home1-testimonial-section .tetimonial-slider-wrapper .swiper-slide-active .author-and-logo-area {
  animation: fadeInUp 1.7s;
}

.home1-testimonial-section.two {
  padding: 120px 7%;
}

@media (max-width: 1399px) {
  .home1-testimonial-section.two {
    padding: 80px 2%;
  }
}

@media (max-width: 991px) {
  .home1-testimonial-section.two {
    padding: 70px 20px;
  }
}

@media (max-width: 767px) {
  .home1-testimonial-section.two {
    padding: 70px 10px;
  }
}

@media (max-width: 576px) {
  .home1-testimonial-section.two {
    padding: 70px 0;
  }
}

.blog-card .blog-card-img-wrap {
  position: relative;
  overflow: hidden;
}

.blog-card .blog-card-img-wrap .card-img {
  position: relative;
  overflow: hidden;
  display: block;
  transition: all 0.5s ease-out;
}

.blog-card .blog-card-img-wrap .card-img img {
  transition: all 0.5s ease-out;
}

@media (max-width: 991px) {
  .blog-card .blog-card-img-wrap .card-img img {
    width: 100%;
  }
}

.blog-card .blog-card-img-wrap .card-img::after {
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
  z-index: 1;
}

.blog-card .blog-card-img-wrap .date {
  color: var(--white-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 10px 16px;
  background-color: var(--primary-color2);
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.blog-card .blog-card-img-wrap .date svg {
  fill: var(--white-color);
}

.blog-card .card-content {
  padding-top: 25px;
}

.blog-card .card-content .blog-meta {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.blog-card .card-content .blog-meta .category {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.blog-card .card-content .blog-meta .category li {
  position: relative;
  line-height: 1;
}

.blog-card .card-content .blog-meta .category li a {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;
  transition: 0.5s;
}

.blog-card .card-content .blog-meta .category li a:hover {
  color: var(--primary-color1);
}

.blog-card .card-content .blog-meta .blog-comment {
  position: relative;
  padding-left: 20px;
  line-height: 1;
}

.blog-card .card-content .blog-meta .blog-comment::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  background-color: rgba(var(--title-color-opc), 0.2);
  width: 1px;
  height: 12px;
}

.blog-card .card-content .blog-meta .blog-comment span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  position: relative;
}

.blog-card .card-content h4 {
  margin-bottom: 20px;
}

.blog-card .card-content h4 a {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 25px;
  font-weight: 600;
  line-height: 1.4;
  transition: 0.5s;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card .card-content h4 a {
    font-size: 22px;
  }
}

@media (max-width: 576px) {
  .blog-card .card-content h4 a {
    font-size: 22px;
  }
}

.blog-card .card-content h4 a:hover {
  color: var(--primary-color1);
}

.blog-card .card-content .read-more-btn {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  transition: 0.5s;
}

.blog-card .card-content .read-more-btn svg {
  fill: var(--text-color);
  transition: 0.5s;
}

.blog-card .card-content .read-more-btn:hover {
  color: var(--primary-color1);
}

.blog-card .card-content .read-more-btn:hover svg {
  transform: rotate(45deg);
  fill: var(--primary-color1);
}

.blog-card:hover .blog-card-img-wrap .card-img img {
  transform: scale(1.1);
}

.blog-card:hover .blog-card-img-wrap .card-img::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}

.blog-card.style-2 {
  border-radius: 10px;
  border: 1px solid rgba(var(--title-color-opc), 0.1);
}

.blog-card.style-2 .blog-card-img-wrap {
  border-radius: 10px 10px 0 0;
}

.blog-card.style-2 .blog-card-img-wrap .card-img img {
  border-radius: 10px 10px 0 0;
}

.blog-card.style-2 .blog-card-img-wrap .date {
  position: absolute;
  top: 6px;
  left: 6px;
  text-align: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: rgba(11, 12, 12, 0.4);
  -webkit-backdrop-filter: blur(22.5px);
  backdrop-filter: blur(22.5px);
  transition: 0.5s;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 576px) {
  .blog-card.style-2 .blog-card-img-wrap .date {
    width: 65px;
    height: 65px;
  }
}

.blog-card.style-2 .blog-card-img-wrap .date span {
  color: var(--white-color);
  text-align: center;
  font-family: var(--font-inter);
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.22px;
  display: block;
}

.blog-card.style-2 .blog-card-img-wrap .date span strong {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--font-hankenGrotesk);
  margin-bottom: 5px;
  display: block;
}

@media (max-width: 576px) {
  .blog-card.style-2 .blog-card-img-wrap .date span strong {
    font-size: 20px;
  }
}

.blog-card.style-2 .card-content {
  padding: 30px 25px;
}

@media (max-width: 1199px) {
  .blog-card.style-2 .card-content {
    padding: 25px 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .blog-card.style-2 .card-content h4 a {
    font-size: 23px;
  }
}

@media (max-width: 1199px) {
  .blog-card.style-2 .card-content h4 a {
    font-size: 22px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card.style-2 .card-content h4 a {
    font-size: 20px;
  }
}

.blog-card.style-2.two {
  border: none;
}

.blog-card.style-2.two .card-content {
  padding: 30px 0 0;
}

@media (max-width: 1199px) {
  .blog-card.style-2.two .card-content {
    padding: 25px 0 0;
  }
}

.blog-card.style-2:hover .blog-card-img-wrap .date {
  background-color: var(--primary-color1);
}

.contact-section {
  background-color: var(--light-black-color);
  padding: 100px 0;
  margin-top: 230px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .contact-section {
    padding: 90px 0;
  }
}

@media (max-width: 991px) {
  .contact-section {
    padding: 80px 0;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .contact-section {
    padding: 70px 0;
  }
}

.contact-section .contact-content .contact-area {
  margin-bottom: 80px;
  padding-top: 80px;
}

@media (max-width: 1399px) {
  .contact-section .contact-content .contact-area {
    padding-top: 50px;
    margin-bottom: 60px;
  }
}

@media (max-width: 576px) {
  .contact-section .contact-content .contact-area {
    padding-top: 30px;
    margin-bottom: 40px;
  }
}

.contact-section .contact-content .contact-area .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}

.contact-section .contact-content .contact-area .hotline-area .icon {
  position: relative;
}

.contact-section .contact-content .contact-area .hotline-area .icon svg {
  fill: var(--primary-color1);
}

.contact-section .contact-content .contact-area .hotline-area .icon::after {
  content: "";
  height: 35px;
  width: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -10px;
  background-color: rgba(255, 255, 255, 0.15);
}

.contact-section .contact-content .contact-area .hotline-area .content span {
  color: #cacaca;
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  margin-bottom: 7px;
}

.contact-section .contact-content .contact-area .hotline-area .content h6 {
  margin-bottom: 0;
}

.contact-section .contact-content .contact-area .hotline-area .content h6 a {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}

.contact-section .contact-content .social-area h6 {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  text-decoration: underline;
  margin-bottom: 20px;
}

.contact-section .contact-content .social-area .social-list {
  display: flex;
  align-items: center;
  gap: 30px;
}

.contact-section .contact-content .social-area .social-list li {
  display: block;
  text-align: center;
}

.contact-section .contact-content .social-area .social-list li a i {
  color: var(--white-color);
  transition: 0.5s;
}

.contact-section .contact-content .social-area .social-list li a svg {
  fill: var(--white-color);
  transition: 0.5s;
}

.contact-section .contact-content .social-area .social-list li a span {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  display: block;
  padding-top: 2px;
  transition: 0.5s;
}

.contact-section .contact-content .social-area .social-list li:hover a i {
  color: var(--primary-color1);
}

.contact-section .contact-content .social-area .social-list li:hover a svg {
  fill: var(--primary-color1);
}

.contact-section .contact-content .social-area .social-list li:hover a span {
  color: var(--primary-color1);
}

.contact-section .contact-form-wrap {
  padding-left: 70px;
  margin-top: -220px;
}

@media (max-width: 1399px) {
  .contact-section .contact-form-wrap {
    padding-left: 40px;
  }
}

@media (max-width: 1199px) {
  .contact-section .contact-form-wrap {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .contact-section .contact-form-wrap {
    margin-top: 0;
  }
}

.contact-section .contact-form-wrap .contact-form-area {
  background-image: linear-gradient(180deg,
      #edf1fc 0%,
      rgba(201, 215, 255, 0.13) 100%);
  background-color: var(--dark-white-color);
  border-radius: 10px;
  padding: 50px 45px;
}

@media (max-width: 1399px) {
  .contact-section .contact-form-wrap .contact-form-area {
    padding: 45px 30px;
  }
}

@media (max-width: 576px) {
  .contact-section .contact-form-wrap .contact-form-area {
    padding: 30px 20px;
  }
}

.contact-section .contact-form-wrap .contact-form-area h3 {
  font-size: 25px;
  font-family: var(--font-hankenGrotesk);
  font-weight: 700;
  margin-bottom: 35px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--title-color-opc), 0.1);
}

@media (max-width: 576px) {
  .contact-section .contact-form-wrap .contact-form-area h3 {
    font-size: 22px;
  }
}

.contact-section.two {
  margin-top: 120px;
}

@media (max-width: 991px) {
  .contact-section.two {
    margin-top: 0;
  }
}

.footer-section {
  background-image: linear-gradient(180deg,
      rgba(48, 103, 255, 0) 74.5%,
      rgba(48, 103, 255, 0.13) 100%);
  background-color: #fdf9f8;
}

.footer-section .footer-top {
  padding: 40px 0;
}

.footer-section .footer-top .footer-widget .widget-title {
  margin-bottom: 25px;
}

.footer-section .footer-top .footer-widget .widget-title h4 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 30px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.9px;
  margin-bottom: 0;
  position: relative;
  padding-bottom: 10px;
}

.footer-section .footer-top .footer-widget .widget-title h4::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 107px;
  height: 2px;
  border-radius: 10px;
  background: linear-gradient(90deg, #0b0c0c 0.01%, rgba(11, 12, 12, 0) 102.8%);
}

.footer-section .footer-top .footer-widget .widget-title.two {
  margin-bottom: 0;
}

.footer-section .footer-top .footer-widget .widget-title.two>span {
  color: var(--primary-color1);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .footer-section .footer-top .footer-widget .widget-title.two>span {
    margin-bottom: 10px;
  }
}

.footer-section .footer-top .footer-widget .widget-title.two>span svg {
  fill: rgba(var(--text-color-opc), 0.3);
}

.footer-section .footer-top .footer-widget .widget-title.two h3 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 35px;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .footer-section .footer-top .footer-widget .widget-title.two h3 {
    font-size: 30px;
  }
}

.footer-section .footer-top .footer-widget .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  padding-top: 20px;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .footer-section .footer-top .footer-widget .content p {
    font-size: 15px;
    padding-top: 15px;
  }
}

.footer-section .footer-top .footer-widget .menu-container {
  display: flex;
  gap: 30px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .footer-section .footer-top .footer-widget .menu-container {
    gap: 20px;
  }
}

@media (max-width: 1199px) {
  .footer-section .footer-top .footer-widget .menu-container {
    gap: 15px;
  }
}

.footer-section .footer-top .footer-widget .menu-container .widget-list li {
  margin-bottom: 20px;
  line-height: 1;
  transition: 0.5s;
  position: relative;
  transform: translateX(0);
}

@media (max-width: 1199px) {
  .footer-section .footer-top .footer-widget .menu-container .widget-list li {
    margin-bottom: 15px;
  }
}

.footer-section .footer-top .footer-widget .menu-container .widget-list li:last-child {
  margin-bottom: 0;
}

.footer-section .footer-top .footer-widget .menu-container .widget-list li a {
  color: rgba(var(--title-color-opc), 0.7);
  font-family: var(--font-hankenGrotesk);
  font-size: 17px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.34px;
  transition: 0.5s;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .footer-section .footer-top .footer-widget .menu-container .widget-list li a {
    font-size: 15px;
  }
}

@media (max-width: 1199px) {
  .footer-section .footer-top .footer-widget .menu-container .widget-list li a {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .footer-section .footer-top .footer-widget .menu-container .widget-list li a {
    font-size: 14px;
  }
}

.footer-section .footer-top .footer-widget .menu-container .widget-list li a:hover {
  color: var(--primary-color1);
}

.footer-section .footer-top .footer-widget .menu-container .widget-list li::after {
  content: "";
  width: 10px;
  height: 1px;
  background-color: var(--primary-color1);
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.35s;
}

.footer-section .footer-top .footer-widget .menu-container .widget-list li:hover {
  transform: translateX(10px);
}

.footer-section .footer-top .footer-widget .menu-container .widget-list li:hover::after {
  opacity: 1;
}

.footer-section .footer-top .footer-widget .rating-area {
  display: flex;
  align-items: center;
  gap: 60px;
  padding-top: 35px;
}

@media (max-width: 1399px) {
  .footer-section .footer-top .footer-widget .rating-area {
    gap: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .footer-section .footer-top .footer-widget .rating-area {
    flex-wrap: wrap;
    gap: 20px;
  }
}

@media (max-width: 576px) {
  .footer-section .footer-top .footer-widget .rating-area {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.footer-section .footer-top .footer-widget .rating-area>li {
  position: relative;
}

.footer-section .footer-top .footer-widget .rating-area>li .single-rating {
  display: flex;
  align-items: center;
  gap: 8px;
}

.footer-section .footer-top .footer-widget .rating-area>li .single-rating .review {
  line-height: 1;
}

.footer-section .footer-top .footer-widget .rating-area>li .single-rating .review span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 8px;
  display: block;
}

.footer-section .footer-top .footer-widget .rating-area>li .single-rating .review img {
  width: 64px;
}

.footer-section .footer-top .footer-widget .rating-area>li .single-rating .review .logo-dark {
  display: block;
}

.footer-section .footer-top .footer-widget .rating-area>li .single-rating .review .logo-light {
  display: none;
}

.footer-section .footer-top .footer-widget .rating-area>li .single-rating .rating {
  line-height: 1;
}

.footer-section .footer-top .footer-widget .rating-area>li .single-rating .rating .star {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
  line-height: 1;
}

.footer-section .footer-top .footer-widget .rating-area>li .single-rating .rating .star li i {
  color: #e62415;
  font-size: 12px;
}

.footer-section .footer-top .footer-widget .rating-area>li .single-rating .rating span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.footer-section .footer-top .footer-widget .rating-area>li .single-rating.google .rating .star li i {
  color: #ffc107;
}

.footer-section .footer-top .footer-widget .rating-area>li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  background-color: rgba(var(--primary-color1-opc), 0.2);
  width: 1px;
  height: 35px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .footer-section .footer-top .footer-widget .rating-area>li::before {
    left: -15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .footer-section .footer-top .footer-widget .rating-area>li::before {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .footer-section .footer-top .footer-widget .rating-area>li::before {
    left: -15px;
  }
}

@media (max-width: 576px) {
  .footer-section .footer-top .footer-widget .rating-area>li::before {
    display: none;
  }
}

.footer-section .footer-top .footer-widget .rating-area>li:first-child::before {
  display: none;
}

.footer-section .footer-top .footer-widget .footer-logo .logo-dark {
  display: block;
}

.footer-section .footer-top .footer-widget .footer-logo .logo-light {
  display: none;
}

.footer-section .footer-top .footer-widget .subscribe-area h4 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 25px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.9px;
  margin-bottom: 30px;
}

.footer-section .footer-top .footer-widget .subscribe-area .subscribe-form-wrap .subscribe-form {
  border-radius: 5px;
  background: var(--dark-white-color);
  display: flex;
  align-items: center;
  max-width: 335px;
  width: 100%;
  height: 60px;
  padding: 10px 10px 10px 20px;
  margin-bottom: 15px;
}

.footer-section .footer-top .footer-widget .subscribe-area .subscribe-form-wrap .subscribe-form input {
  width: 100%;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  background: 0 0;
  border: none;
  line-height: 1;
}

.footer-section .footer-top .footer-widget .subscribe-area .subscribe-form-wrap .subscribe-form button {
  border: none;
  height: 36px;
  min-width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color1);
}

.footer-section .footer-top .footer-widget .subscribe-area .subscribe-form-wrap .subscribe-form button svg {
  fill: var(--white-color);
}

.footer-section .footer-top .footer-widget .social-area h6 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  text-decoration: underline;
  margin-bottom: 20px;
}

.footer-section .footer-top .footer-widget .social-area .social-list {
  display: flex;
  align-items: center;
  gap: 30px;
}

.footer-section .footer-top .footer-widget .social-area .social-list li {
  display: block;
  text-align: center;
}

.footer-section .footer-top .footer-widget .social-area .social-list li a i {
  color: var(--text-color);
  transition: 0.5s;
}

.footer-section .footer-top .footer-widget .social-area .social-list li a svg {
  fill: var(--text-color);
  transition: 0.5s;
}

.footer-section .footer-top .footer-widget .social-area .social-list li a span {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  display: block;
  padding-top: 2px;
  transition: 0.5s;
}

.footer-section .footer-top .footer-widget .social-area .social-list li:hover a i {
  color: var(--primary-color1);
}

.footer-section .footer-top .footer-widget .social-area .social-list li:hover a svg {
  fill: var(--primary-color1);
}

.footer-section .footer-top .footer-widget .social-area .social-list li:hover a span {
  color: var(--primary-color1);
}

.footer-section .footer-top .footer-logo-area {
  position: relative;
}

.footer-section .footer-top .footer-logo-area .logo-bg .light {
  display: block;
}

.footer-section .footer-top .footer-logo-area .logo-bg .dark {
  display: none;
}

.footer-section .footer-top .footer-logo-area .logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 992px) and (max-width: 1199px) {
  .footer-section .footer-top .footer-logo-area .logo img {
    width: 85px;
  }
}

.footer-section .footer-top .footer-logo-area .logo .light {
  display: block;
}

.footer-section .footer-top .footer-logo-area .logo .dark {
  display: none;
}

.footer-section .contact-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.footer-section .contact-area .hotline-area {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-section .contact-area .hotline-area .icon {
  position: relative;
}

.footer-section .contact-area .hotline-area .icon svg {
  fill: var(--primary-color1);
}

.footer-section .contact-area .hotline-area .content span {
  color: var(--primary-color1);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  margin-bottom: 7px;
}

.footer-section .contact-area .hotline-area .content h6 {
  margin-bottom: 0;
}

.footer-section .contact-area .hotline-area .content h6 a {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.54px;
  transition: 0.5s;
}

.footer-section .contact-area .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}

.footer-section .footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  border-top: 1px solid rgba(var(--title-color-opc), 0.1);
}

@media (max-width: 991px) {
  .footer-section .footer-bottom {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
}

.footer-section .footer-bottom .copyright-area p {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.footer-section .footer-bottom .copyright-area p a {
  color: var(--text-color);
  font-weight: 700;
  transition: 0.5s;
}

.footer-section .footer-bottom .copyright-area p a:hover {
  color: var(--primary-color1);
}

.footer-section .footer-bottom .footer-bottom-right ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 45px;
  flex-wrap: wrap;
  line-height: 1;
}

@media (max-width: 991px) {
  .footer-section .footer-bottom .footer-bottom-right ul {
    gap: 30px;
  }
}

@media (max-width: 576px) {
  .footer-section .footer-bottom .footer-bottom-right ul {
    gap: 20px;
    justify-content: center;
  }
}

.footer-section .footer-bottom .footer-bottom-right ul li a {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.3px;
  transition: 0.5s;
}

.footer-section .footer-bottom .footer-bottom-right ul li a:hover {
  color: var(--primary-color1);
}

.footer-section.style-2 {
  background-image: linear-gradient(180deg,
      rgba(169, 213, 255, 0) 74.5%,
      rgba(169, 213, 255, 0.13) 100%);
  background-color: #d8ecff;
}

.footer-section.style-2 .footer-top {
  padding: 100px 0;
}

@media (max-width: 1199px) {
  .footer-section.style-2 .footer-top {
    padding: 90px 0;
  }
}

@media (max-width: 767px) {
  .footer-section.style-2 .footer-top {
    padding: 70px 0;
  }
}

.footer-section.style-2 .footer-top .footer-widget .widget-title h4 {
  font-size: 25px;
  font-weight: 600;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .footer-section.style-2 .footer-top .footer-widget .widget-title h4 {
    font-size: 20px;
    font-weight: 700;
  }
}

@media (max-width: 767px) {
  .footer-section.style-2 .footer-top .footer-widget .rating-area {
    flex-wrap: wrap;
    gap: 20px;
  }
}

@media (max-width: 767px) {
  .footer-section.style-2 .footer-top .footer-widget .rating-area .single-rating::before {
    display: none;
  }
}

.footer-section.style-3 {
  background-image: url(../img/home3/home3-footer-bg.png),
    linear-gradient(180deg, #0a0a0a 0%, #0a0a0a 100%);
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-section.style-3 .footer-top {
  padding: 100px 0;
}

@media (max-width: 1199px) {
  .footer-section.style-3 .footer-top {
    padding: 90px 0;
  }
}

@media (max-width: 767px) {
  .footer-section.style-3 .footer-top {
    padding: 70px 0;
  }
}

.footer-section.style-3 .footer-top .footer-widget .widget-title h4 {
  color: var(--white-color);
  font-size: 25px;
  font-weight: 600;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .footer-section.style-3 .footer-top .footer-widget .widget-title h4 {
    font-size: 20px;
    font-weight: 700;
  }
}

.footer-section.style-3 .footer-top .footer-widget .widget-title h4::after {
  background: linear-gradient(90deg,
      #0b0c0c 0.01%,
      #fff 0.02%,
      rgba(255, 255, 255, 0) 102.8%);
  opacity: 0.4;
}

.footer-section.style-3 .footer-top .footer-widget .content p {
  color: rgba(255, 255, 255, 0.6);
}

.footer-section.style-3 .footer-top .footer-widget .menu-container .widget-list li a {
  color: rgba(255, 255, 255, 0.6);
}

.footer-section.style-3 .footer-top .footer-widget .menu-container .widget-list li a:hover {
  color: var(--primary-color1);
}

@media (max-width: 767px) {
  .footer-section.style-3 .footer-top .footer-widget .rating-area {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.footer-section.style-3 .footer-top .footer-widget .rating-area .single-rating .review span,
.footer-section.style-3 .footer-top .footer-widget .rating-area .single-rating .rating span {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
}

@media (max-width: 767px) {
  .footer-section.style-3 .footer-top .footer-widget .rating-area .single-rating::before {
    display: none;
  }
}

.footer-section.style-3 .footer-top .footer-widget .subscribe-area h4 {
  color: var(--white-color);
}

.footer-section.style-3 .footer-top .footer-widget .subscribe-area .subscribe-form-wrap .form-check .form-check-label {
  color: rgba(255, 255, 255, 0.6);
}

.footer-section.style-3 .footer-top .footer-widget .social-area h6 {
  color: var(--white-color);
}

.footer-section.style-3 .footer-top .footer-widget .social-area .social-list li a i {
  color: var(--white-color);
}

.footer-section.style-3 .footer-top .footer-widget .social-area .social-list li a svg {
  fill: var(--white-color);
}

.footer-section.style-3 .footer-top .footer-widget .social-area .social-list li a span {
  color: var(--white-color);
}

.footer-section.style-3 .footer-top .footer-widget .social-area .social-list li:hover a i {
  color: var(--primary-color1);
}

.footer-section.style-3 .footer-top .footer-widget .social-area .social-list li:hover a svg {
  fill: var(--primary-color1);
}

.footer-section.style-3 .footer-top .footer-widget .social-area .social-list li:hover a span {
  color: var(--primary-color1);
}

.footer-section.style-3 .contact-area .hotline-area .content h6 a {
  color: rgba(255, 255, 255, 0.6);
}

.footer-section.style-3 .contact-area .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}

.footer-section.style-3 .footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-section.style-3 .footer-bottom .copyright-area p {
  color: rgba(255, 255, 255, 0.6);
}

.footer-section.style-3 .footer-bottom .copyright-area p a {
  color: rgba(255, 255, 255, 0.6);
}

.footer-section.style-3 .footer-bottom .footer-bottom-right ul li a {
  color: rgba(255, 255, 255, 0.6);
}

.footer-section.style-3 .footer-bottom .footer-bottom-right ul li a:hover {
  color: var(--primary-color1);
}

.home2-banner-section {
  background-image: url(../img/home2/home2-banner-bg.png),
    linear-gradient(180deg,
      rgba(212, 185, 244, 0.42) 0%,
      rgba(212, 185, 244, 0) 100%);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 175px 0 0;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
  overflow: hidden;
}

@media (max-width: 1399px) {
  .home2-banner-section {
    padding: 160px 0 0;
  }
}

@media (max-width: 991px) {
  .home2-banner-section {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

@media (max-width: 576px) {
  .home2-banner-section {
    padding: 140px 0 0;
  }
}

.home2-banner-section .banner-content {
  text-align: center;
  max-width: 1130px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 70px;
}

@media (max-width: 1199px) {
  .home2-banner-section .banner-content {
    margin-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .home2-banner-section .banner-content {
    margin-bottom: 40px;
  }
}

@media (max-width: 576px) {
  .home2-banner-section .banner-content {
    margin-bottom: 30px;
  }
}

.home2-banner-section .banner-content>span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 12px 10px;
  background-color: #dbd4ff;
  border-radius: 100px;
  margin-bottom: 20px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home2-banner-section .banner-content>span {
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-banner-section .banner-content>span {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .home2-banner-section .banner-content>span {
    margin-bottom: 10px;
  }
}

.home2-banner-section .banner-content>span svg {
  fill: var(--primary-color1);
}

.home2-banner-section .banner-content h1 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 70px;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 40px;
}

.home2-banner-section .banner-content h1 span {
  text-decoration: underline;
  color: var(--text-color);
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-banner-section .banner-content h1 {
    font-size: 65px;
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .home2-banner-section .banner-content h1 {
    font-size: 50px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .home2-banner-section .banner-content h1 {
    font-size: 45px;
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .home2-banner-section .banner-content h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }
}

.home2-banner-section .banner-content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .home2-banner-section .banner-content p {
    font-size: 15px;
  }
}

.home2-banner-section::before {
  content: url(../img/home2/vector/home2-banner-vector1.svg);
  position: absolute;
  top: 30%;
  left: 70px;
}

@media (max-width: 1699px) {
  .home2-banner-section::before {
    left: 40px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-banner-section::before {
    left: 10px;
  }
}

@media (max-width: 1399px) {
  .home2-banner-section::before {
    top: 40%;
    left: 0;
  }
}

@media (max-width: 1199px) {
  .home2-banner-section::before {
    display: none;
  }
}

.home2-banner-section::after {
  content: url(../img/home2/vector/home2-banner-vector2.svg);
  position: absolute;
  top: 32%;
  right: 0;
}

@media (max-width: 1399px) {
  .home2-banner-section::after {
    top: 45%;
  }
}

@media (max-width: 1199px) {
  .home2-banner-section::after {
    display: none;
  }
}

.home2-banner-section .banner-img .light-img {
  display: block;
}

.home2-banner-section .banner-img .dark-img {
  display: none;
}

.home2-about-section .about-content-wrap .about-title {
  text-align: center;
  margin-bottom: 65px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-about-section .about-content-wrap .about-title {
    margin-bottom: 55px;
  }
}

@media (max-width: 991px) {
  .home2-about-section .about-content-wrap .about-title {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .home2-about-section .about-content-wrap .about-title {
    margin-bottom: 40px;
  }
}

.home2-about-section .about-content-wrap .about-title>span {
  color: var(--primary-color1);
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 8px 10px;
  border: 1px solid var(--border-color);
  background: rgba(233, 229, 255, 0.6);
  border-radius: 5px;
  margin-bottom: 5px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home2-about-section .about-content-wrap .about-title>span {
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-about-section .about-content-wrap .about-title>span {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .home2-about-section .about-content-wrap .about-title>span {
    margin-bottom: 10px;
  }
}

.home2-about-section .about-content-wrap .about-title>span svg {
  fill: rgba(var(--text-color-opc), 0.4);
}

.home2-about-section .about-content-wrap .about-title h2 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  line-height: 1.2;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-about-section .about-content-wrap .about-title h2 {
    font-size: 48px;
  }
}

@media (max-width: 991px) {
  .home2-about-section .about-content-wrap .about-title h2 {
    font-size: 45px;
  }
}

@media (max-width: 767px) {
  .home2-about-section .about-content-wrap .about-title h2 {
    font-size: 38px;
  }
}

.home2-about-section .about-content-wrap .about-title h2 span {
  color: var(--text-color);
  text-decoration: underline;
}

.home2-about-section .about-content-wrap .about-title h2 a {
  color: var(--text-color);
  text-decoration: underline;
  transition: 0.5s;
}

.home2-about-section .about-content-wrap .about-title h2 a:hover {
  color: var(--primary-color1);
}

.home2-about-section .about-content-wrap .about-countdown-wrap {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 65px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-about-section .about-content-wrap .about-countdown-wrap {
    margin-bottom: 55px;
  }
}

@media (max-width: 991px) {
  .home2-about-section .about-content-wrap .about-countdown-wrap {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .home2-about-section .about-content-wrap .about-countdown-wrap {
    margin-bottom: 40px;
  }
}

.home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown {
  text-align: center;
}

.home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .countdown-area {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}

.home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .countdown-area svg {
  fill: none;
  stroke: var(--primary-color1);
}

.home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .countdown-area svg path:last-child {
  stroke: rgba(var(--primary-color1-opc), 0.15);
}

.home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .countdown-area .number-and-arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .countdown-area .number-and-arrow .number {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  line-height: 1;
  display: flex;
  align-items: baseline;
  gap: 2px;
  margin-bottom: 10px;
}

.home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .countdown-area .number-and-arrow .number h5 {
  font-size: 45px;
  font-weight: 800;
  margin-bottom: 0;
  line-height: 1;
}

@media (max-width: 1199px) {
  .home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .countdown-area .number-and-arrow .number h5 {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .countdown-area .number-and-arrow .number h5 {
    font-size: 35px;
  }
}

.home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .countdown-area .number-and-arrow .number span {
  font-size: 45px;
  font-weight: 800;
}

@media (max-width: 1199px) {
  .home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .countdown-area .number-and-arrow .number span {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .countdown-area .number-and-arrow .number span {
    font-size: 35px;
  }
}

.home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .countdown-area .number-and-arrow .arrow {
  line-height: 1;
}

.home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .countdown-area .number-and-arrow .arrow svg {
  fill: var(--primary-color1);
}

.home2-about-section .about-content-wrap .about-countdown-wrap .single-countdown .content p {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 0;
}

.home2-about-section .about-content-wrap p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  text-align: center;
  margin-bottom: 0;
}

.home2-about-section .about-feature-card {
  text-align: center;
  border-radius: 10px;
  border: 1px solid rgba(var(--title-color-opc), 0.05);
  background: #edf8eb;
  padding: 30px;
  transition: 0.5s;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-about-section .about-feature-card {
    padding: 30px 20px;
  }
}

@media (max-width: 576px) {
  .home2-about-section .about-feature-card {
    padding: 30px 20px;
  }
}

.home2-about-section .about-feature-card .icon {
  margin-bottom: 20px;
}

.home2-about-section .about-feature-card .icon svg {
  fill: var(--text-color);
}

.home2-about-section .about-feature-card .content h3 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-about-section .about-feature-card .content h3 {
    font-size: 28px;
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  .home2-about-section .about-feature-card .content h3 {
    font-size: 28px;
    margin-bottom: 15px;
  }
}

.home2-about-section .about-feature-card .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 0;
}

.home2-about-section .about-feature-card.two {
  background: #f5f3ef;
}

.home2-about-section .about-feature-card.three {
  background: #f2f2f8;
}

.home2-about-section .about-feature-card:hover {
  transform: translateY(-10px);
}

.home2-about-section .about-feature-card:hover .icon {
  animation: zoomIn 0.7s linear;
}

.home2-service-section {
  background: #f4f5ed;
  padding: 100px 0;
  overflow: hidden;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-service-section {
    padding: 90px 0;
  }
}

@media (max-width: 991px) {
  .home2-service-section {
    padding: 70px 0;
  }
}

.home2-service-section .sevices-wrap .single-services {
  transition: 0.5s;
  border: 1px solid rgba(var(--title-color-opc), 0.08);
  border-bottom: none;
  position: relative;
  display: grid;
  grid-template-columns: 10% 25% 45% 20%;
  z-index: 1;
}

@media (max-width: 1399px) {
  .home2-service-section .sevices-wrap .single-services {
    grid-template-columns: 13% 30% 37% 20%;
  }
}

@media (max-width: 767px) {
  .home2-service-section .sevices-wrap .single-services {
    padding: 20px;
    display: block;
  }
}

.home2-service-section .sevices-wrap .single-services:last-child {
  border-bottom: 1px solid rgba(var(--title-color-opc), 0.08);
}

.home2-service-section .sevices-wrap .single-services .icon {
  padding: 30px;
  border-right: 1px solid rgba(var(--title-color-opc), 0.08);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .home2-service-section .sevices-wrap .single-services .icon {
    padding: 0;
    margin-bottom: 15px;
    justify-content: start;
    border-right: unset;
  }
}

.home2-service-section .sevices-wrap .single-services .icon svg {
  fill: var(--text-color);
  transition: 0.5s;
  min-width: 50px;
  max-width: 50px;
}

.home2-service-section .sevices-wrap .single-services h5 {
  padding: 30px 15px 30px 30px;
  max-width: 320px;
  width: 100%;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .home2-service-section .sevices-wrap .single-services h5 {
    padding: 30px 15px;
  }
}

@media (max-width: 767px) {
  .home2-service-section .sevices-wrap .single-services h5 {
    margin-bottom: 10px;
    padding: 0;
  }
}

.home2-service-section .sevices-wrap .single-services h5 a {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .home2-service-section .sevices-wrap .single-services h5 a {
    font-size: 22px;
  }
}

.home2-service-section .sevices-wrap .single-services h5 a span {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  transition: 0.5s;
  display: block;
}

@media (max-width: 991px) {
  .home2-service-section .sevices-wrap .single-services h5 a span {
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .home2-service-section .sevices-wrap .single-services h5 a {
    font-size: 20px;
  }

  .home2-service-section .sevices-wrap .single-services h5 a span {
    font-size: 16px;
  }
}

.home2-service-section .sevices-wrap .single-services .services-img {
  width: 260px;
  height: 320px;
  position: absolute;
  top: -150px;
  left: 0;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 5px;
  overflow: hidden;
  pointer-events: none;
}

@media (max-width: 767px) {
  .home2-service-section .sevices-wrap .single-services .services-img {
    display: none;
    visibility: hidden;
  }
}

.home2-service-section .sevices-wrap .single-services .content {
  padding: 30px 0;
  max-width: 560px;
  width: 100%;
}

@media (max-width: 767px) {
  .home2-service-section .sevices-wrap .single-services .content {
    margin-bottom: 10px;
    line-height: 26px;
    padding: 0;
  }
}

.home2-service-section .sevices-wrap .single-services .content ul {
  -moz-columns: 2;
  columns: 2;
  -moz-column-gap: 0;
  column-gap: 0;
  padding-top: 20px;
}

@media (max-width: 1199px) {
  .home2-service-section .sevices-wrap .single-services .content ul {
    -moz-columns: unset;
    columns: unset;
  }
}

.home2-service-section .sevices-wrap .single-services .content ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.3px;
  margin-bottom: 15px;
}

.home2-service-section .sevices-wrap .single-services .content ul li svg {
  fill: var(--primary-color1);
}

.home2-service-section .sevices-wrap .single-services .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}

.home2-service-section .sevices-wrap .single-services .button-area {
  padding: 30px;
}

@media (max-width: 991px) {
  .home2-service-section .sevices-wrap .single-services .button-area {
    padding: 30px 15px;
  }
}

@media (max-width: 767px) {
  .home2-service-section .sevices-wrap .single-services .button-area {
    padding: 0;
  }
}

.home2-service-section .sevices-wrap .single-services .button-area .explore-btn {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: linear-gradient(to bottom,
      var(--primary-color1) 0%,
      var(--primary-color1) 98%);
  background-size: 0 1px;
  background-repeat: no-repeat;
  background-position: left 100%;
  transition: background-size 0.75s;
}

.home2-service-section .sevices-wrap .single-services .button-area .explore-btn svg {
  fill: var(--title-color);
  transition: 0.5s;
}

.home2-service-section .sevices-wrap .single-services:hover .icon svg {
  fill: var(--primary-color1);
  animation: bounceIn 1s linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.home2-service-section .sevices-wrap .single-services:hover h5 a {
  color: var(--primary-color1);
}

.home2-service-section .sevices-wrap .single-services:hover h5 a span {
  color: var(--primary-color1);
}

.home2-service-section .sevices-wrap .single-services:hover .services-img {
  opacity: 1;
}

.home2-service-section .sevices-wrap .single-services:hover .explore-btn {
  color: var(--primary-color1);
  background-size: 100% 1px;
  background-position: 0% 100%;
}

.home2-service-section .sevices-wrap .single-services:hover .explore-btn svg {
  fill: var(--primary-color1);
  transform: rotate(45deg);
}

.home2-service-section.two {
  background: unset;
  padding: 0;
  padding-top: 120px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-service-section.two {
    padding-top: 100px;
  }
}

@media (max-width: 991px) {
  .home2-service-section.two {
    padding-top: 90px;
  }
}

@media (max-width: 767px) {
  .home2-service-section.two {
    padding-top: 70px;
  }
}

.feature-card3 .feature-content h3 {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .feature-card3 .feature-content h3 {
    font-size: 28px;
    margin-bottom: 10px;
  }
}

.feature-card3 .feature-content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 25px;
}

.feature-card3 .feature-content ul li {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
}

.feature-card3 .feature-content ul li:last-child {
  margin-bottom: 0;
}

.feature-card3 .feature-content ul li .check-icon {
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgba(var(--primary-color1-opc), 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-card3 .feature-content ul li .check-icon svg {
  fill: var(--primary-color1);
}

.feature-card3 .feature-content ul li .content h5 {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.4px;
  margin-bottom: 10px;
}

.feature-card3 .feature-content ul li .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .feature-card3 .feature-img {
    display: none;
  }
}

.home2-feature-section .section-title-wrap {
  position: sticky;
  top: 100px;
}

@media (max-width: 1199px) {
  .home2-feature-section .section-title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }
}

.home2-feature-section .section-title-wrap .section-title2 {
  max-width: 425px;
  width: 100%;
  margin-bottom: 35px;
}

@media (max-width: 1199px) {
  .home2-feature-section .section-title-wrap .section-title2 {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-feature-section .section-title-wrap .section-title2 {
    max-width: 550px;
  }
}

.home2-feature-section .feature-list>li {
  border-bottom: 1px solid rgba(var(--title-color-opc), 0.1);
  padding-bottom: 40px;
  margin-bottom: 45px;
}

.home2-feature-section .feature-list>li:last-child {
  border-bottom: unset;
  padding-bottom: 0;
  margin-bottom: 0;
}

.horizontal-scrolling-section {
  overflow: hidden;
}

.horizontal-scrolling-section .horizontal-scrolling-wrapper {
  width: 200%;
  display: flex;
  flex-wrap: no-wrap;
}

@media (max-width: 1199px) {
  .horizontal-scrolling-section .horizontal-scrolling-wrapper {
    display: block;
    width: 100% !important;
  }
}

.horizontal-scrolling-section .horizontal-scrolling-wrapper .single-scroll {
  width: 200%;
  height: 100%;
  will-change: transform;
  opacity: 1 !important;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .horizontal-scrolling-section .horizontal-scrolling-wrapper .single-scroll {
    height: 92%;
  }
}

@media (max-width: 1199px) {
  .horizontal-scrolling-section .horizontal-scrolling-wrapper .single-scroll {
    width: 100% !important;
    height: auto;
  }
}

.home2-process-section {
  background-image: url(../img/home2/home2-process-bg.png),
    linear-gradient(180deg, #e9e5ff 0%, #e9e5ff 100%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  padding: 120px 0 0;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-process-section {
    padding: 90px 0 0;
  }
}

@media (max-width: 1399px) {
  .home2-process-section {
    padding: 60px 0 0;
  }
}

@media (max-width: 767px) {
  .home2-process-section {
    padding: 70px 0 0;
  }
}

.home2-process-section .section-title2 {
  margin-bottom: 70px;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-process-section .section-title2 {
    margin-bottom: 50px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home2-process-section .section-title2 {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .home2-process-section .section-title2 {
    margin-bottom: 40px;
  }
}

.home2-process-section .section-title2 span {
  background: #ddd7ff;
  border: 1px solid rgba(var(--text-color-opc), 0.05);
}

.home2-process-section .process-slider-area {
  padding: 0 10% 185px;
  position: relative;
}

@media (max-width: 1699px) {
  .home2-process-section .process-slider-area {
    padding: 0 8% 185px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-process-section .process-slider-area {
    padding: 0 5% 150px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home2-process-section .process-slider-area {
    padding: 0 3% 120px;
  }
}

@media (max-width: 1199px) {
  .home2-process-section .process-slider-area {
    padding: 0 3% 150px;
  }
}

@media (max-width: 767px) {
  .home2-process-section .process-slider-area {
    padding: 0 3% 130px;
  }
}

.home2-process-section .process-slider-area .home2-process-slider {
  padding-top: 50px;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-process-section .process-slider-area .home2-process-slider {
    padding-top: 30px;
  }
}

@media (max-width: 1399px) {
  .home2-process-section .process-slider-area .home2-process-slider {
    padding-top: 30px;
  }
}

.home2-process-section .process-slider-area .single-process {
  text-align: center;
}

.home2-process-section .process-slider-area .single-process .step {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-process-section .process-slider-area .single-process .step {
    margin-bottom: 20px;
  }
}

@media (max-width: 1399px) {
  .home2-process-section .process-slider-area .single-process .step {
    margin-bottom: 20px;
  }
}

.home2-process-section .process-slider-area .single-process .step .number {
  background-color: var(--dark-white-color);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.home2-process-section .process-slider-area .single-process .step .number::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid var(--white-color);
  z-index: 1;
}

.home2-process-section .process-slider-area .single-process .step .number::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 48px;
  background: rgba(255, 255, 255, 0.4);
  left: 50%;
  transform: translateX(-50%);
  top: -50px;
  z-index: -9;
}

.home2-process-section .process-slider-area .single-process .step .number h6 {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 17px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 5px;
}

.home2-process-section .process-slider-area .single-process .step .number span {
  color: var(--primary-color1);
  font-family: var(--font-hankenGrotesk);
  font-size: 20px;
  font-weight: 800;
  line-height: 1;
}

.home2-process-section .process-slider-area .single-process .content h4 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-process-section .process-slider-area .single-process .content h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1399px) {
  .home2-process-section .process-slider-area .single-process .content h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.home2-process-section .process-slider-area .single-process .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 0;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-process-section .process-slider-area .single-process .content p {
    font-size: 15px;
  }
}

@media (max-width: 1399px) {
  .home2-process-section .process-slider-area .single-process .content p {
    font-size: 15px;
  }
}

.home2-process-section .process-slider-area .slider-btn-area {
  background-color: var(--dark-white-color);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px 50px 0 0;
  line-height: 1;
  padding: 0 30px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  min-width: 380px;
}

@media (max-width: 576px) {
  .home2-process-section .process-slider-area .slider-btn-area {
    min-width: unset;
    width: 100%;
  }
}

.home2-process-section .process-slider-area .slider-btn-area .slider-btn {
  cursor: pointer;
}

.home2-process-section .process-slider-area .slider-btn-area .slider-btn i {
  color: var(--text-color);
  font-size: 20px;
  transition: 0.5s;
}

.home2-process-section .process-slider-area .slider-btn-area .slider-btn i:hover {
  color: var(--primary-color1);
}

.home2-process-section .process-slider-area .slider-btn-area .slider-btn.swiper-button-disabled {
  opacity: 0.2;
}

.home2-process-section .process-slider-area .slider-btn-area .content {
  padding: 18px 20px;
  border-left: 1px solid #e9e5ff;
  border-right: 1px solid #e9e5ff;
  margin: 0 20px;
}

@media (max-width: 767px) {
  .home2-process-section .process-slider-area .slider-btn-area .content {
    padding: 18px 15px;
  }
}

@media (max-width: 576px) {
  .home2-process-section .process-slider-area .slider-btn-area .content {
    padding: 18px 10px;
  }
}

.home2-process-section .process-slider-area .slider-btn-area .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 17px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .home2-process-section .process-slider-area .slider-btn-area .content p {
    font-size: 15px;
  }
}

.home2-process-section .process-slider-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: rgba(255, 255, 255, 0.4);
}

.home2-process-section.two {
  background-image: unset;
  background-color: #dfebf6;
}

.home2-marketing-agency-section {
  background-image: url(../img/home2/marketing-agency-bg.png),
    linear-gradient(180deg, #fffaf0 0%, #fffaf0 100%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  padding: 120px 0;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-marketing-agency-section {
    padding: 90px 0;
  }
}

@media (max-width: 1399px) {
  .home2-marketing-agency-section {
    padding: 60px 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-marketing-agency-section {
    padding: 100px 0;
  }
}

@media (max-width: 991px) {
  .home2-marketing-agency-section {
    padding: 90px 0;
  }
}

@media (max-width: 767px) {
  .home2-marketing-agency-section {
    padding: 70px 0;
  }
}

@media (max-width: 1199px) {
  .home2-marketing-agency-section .graf-img {
    display: none;
  }
}

.home2-marketing-agency-section .graf-img .light {
  display: block;
}

.home2-marketing-agency-section .graf-img .dark {
  display: none;
}

.home2-marketing-agency-section .graf-img img {
  min-width: 250px;
}

.home2-marketing-agency-section .marketing-agency-content {
  max-width: 696px;
  width: 100%;
  margin: 0 auto;
}

.home2-marketing-agency-section .marketing-agency-content .section-title2 h2 span {
  font-weight: 400;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home2-marketing-agency-section .marketing-agency-content .section-title2 h2 {
    font-size: 45px;
  }
}

@media (max-width: 576px) {
  .home2-marketing-agency-section .marketing-agency-content .section-title2 h2 {
    font-size: 35px;
  }
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .nav-area {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .nav-area nav {
  display: flex;
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .nav-area nav .nav-tabs {
  border-bottom: none;
  background: rgba(var(--primary-color1-opc), 0.1);
  border-radius: 15px;
  padding: 4px;
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .nav-area nav .nav-tabs .nav-link {
  min-width: 22px;
  max-width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  padding: 0;
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .nav-area nav .nav-tabs .nav-link:hover {
  border: none;
  box-shadow: none;
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .nav-area nav .nav-tabs .nav-link:focus {
  border: none;
  box-shadow: none;
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .nav-area nav .nav-tabs .nav-link.active {
  color: var(--white-color);
  background: var(--primary-color1);
  border: none;
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .nav-area span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .counter-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 767px) {
  .home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .counter-wrap {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .counter-wrap .single-countdown {
  max-width: 205px;
  width: 100%;
  text-align: center;
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .counter-wrap .single-countdown .number {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .counter-wrap .single-countdown .number h5 {
  color: var(--title-color);
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1;
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .counter-wrap .single-countdown .number span {
  font-size: 30px;
  font-weight: 600;
}

.home2-marketing-agency-section .marketing-agency-content .nav-and-tab-area .counter-wrap .single-countdown p {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0;
}

.case-study-card2 {
  border: 1px solid rgba(var(--title-color-opc), 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.case-study-card2 .card-img {
  position: relative;
  overflow: hidden;
}

.case-study-card2 .card-img img {
  border-radius: 10px 10px 0 0;
  transition: all 0.5s ease-out;
}

.case-study-card2 .card-img::after {
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
  z-index: 1;
}

.case-study-card2 .card-content {
  padding: 30px 25px;
}

@media (max-width: 1199px) {
  .case-study-card2 .card-content {
    padding: 25px 20px;
  }
}

.case-study-card2 .card-content h4 {
  margin-bottom: 25px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .case-study-card2 .card-content h4 {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .case-study-card2 .card-content h4 {
    margin-bottom: 20px;
  }
}

.case-study-card2 .card-content h4 a {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
  transition: 0.5s;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .case-study-card2 .card-content h4 a {
    font-size: 25px;
  }
}

.case-study-card2 .card-content h4 a:hover {
  color: var(--primary-color1);
}

.case-study-card2 .card-content .number {
  color: var(--primary-color1);
  font-family: var(--font-inter);
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 2px;
  margin-bottom: 30px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .case-study-card2 .card-content .number {
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .case-study-card2 .card-content .number {
    margin-bottom: 25px;
  }
}

.case-study-card2 .card-content .number h5 {
  color: var(--primary-color1);
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1;
}

.case-study-card2 .card-content .number span {
  font-size: 40px;
  font-weight: 700;
}

.case-study-card2 .card-content .tag-list {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .case-study-card2 .card-content .tag-list {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .case-study-card2 .card-content .tag-list {
    margin-bottom: 35px;
  }
}

.case-study-card2 .card-content .tag-list li a {
  border-radius: 100px;
  border: 1px solid rgba(var(--text-color-opc), 0.5);
  padding: 5px 15px;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  transition: 0.5s;
}

.case-study-card2 .card-content .tag-list li a:hover {
  color: var(--white-color);
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.case-study-card2 .card-content .read-more-btn {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  transition: 0.5s;
}

.case-study-card2 .card-content .read-more-btn svg {
  fill: var(--text-color);
  transition: 0.5s;
}

.case-study-card2 .card-content .read-more-btn:hover {
  color: var(--primary-color1);
}

.case-study-card2 .card-content .read-more-btn:hover svg {
  transform: rotate(45deg);
  fill: var(--primary-color1);
}

.case-study-card2:hover .card-img img {
  transform: scale(1.1);
}

.case-study-card2:hover .card-img::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}

.home2-case-study-section .pagination-area {
  display: flex;
  justify-content: center;
  align-items: end;
}

.home2-case-study-section .pagination-area .swiper-pagination {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 9;
  bottom: unset;
  gap: 12px;
}

.home2-case-study-section .pagination-area .swiper-pagination .swiper-pagination-bullet {
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid #596977;
  opacity: 1;
  position: relative;
}

.home2-case-study-section .pagination-area .swiper-pagination .swiper-pagination-bullet::after {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #596977;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.home2-case-study-section .pagination-area .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: var(--primary-color1);
  height: 20px;
  width: 20px;
}

.home2-case-study-section .pagination-area .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  width: 10px;
  height: 10px;
  background-color: var(--primary-color1);
}

.home2-testimonial-section {
  overflow: hidden;
}

.home2-testimonial-section .testimonial-content {
  background: rgba(233, 229, 255, 0.8);
  padding: 110px 150px;
  height: 100%;
}

@media (max-width: 1799px) {
  .home2-testimonial-section .testimonial-content {
    padding: 110px 120px;
  }
}

@media (max-width: 1699px) {
  .home2-testimonial-section .testimonial-content {
    padding: 110px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-testimonial-section .testimonial-content {
    padding: 110px 70px;
  }
}

@media (max-width: 1399px) {
  .home2-testimonial-section .testimonial-content {
    padding: 100px 35px;
  }
}

@media (max-width: 1199px) {
  .home2-testimonial-section .testimonial-content {
    padding: 90px 25px;
  }
}

@media (max-width: 991px) {
  .home2-testimonial-section .testimonial-content {
    padding: 70px 25px;
  }
}

.home2-testimonial-section .testimonial-content .section-title2 span {
  background: #ddd7ff;
}

.home2-testimonial-section .testimonial-content .bottom-area {
  margin-top: 145px;
  border-radius: 200px;
  background: var(--dark-white-color);
  display: inline-flex;
  align-items: center;
  gap: 20px;
  padding: 5px;
  max-width: 335px;
  width: 100%;
}

@media (max-width: 1199px) {
  .home2-testimonial-section .testimonial-content .bottom-area {
    margin-top: 120px;
  }
}

@media (max-width: 991px) {
  .home2-testimonial-section .testimonial-content .bottom-area {
    margin-top: 50px;
  }
}

.home2-testimonial-section .testimonial-content .bottom-area .icon {
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(var(--primary-color1-opc), 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.home2-testimonial-section .testimonial-content .bottom-area .icon svg {
  fill: var(--primary-color1);
}

.home2-testimonial-section .testimonial-content .bottom-area .text p {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0;
}

.home2-testimonial-section .testimonial-wrap {
  background-color: #f7f7fa;
  padding: 105px 120px;
  height: 100%;
}

@media (max-width: 1499px) {
  .home2-testimonial-section .testimonial-wrap {
    padding: 100px 80px;
  }
}

@media (max-width: 1399px) {
  .home2-testimonial-section .testimonial-wrap {
    padding: 80px 50px;
  }
}

@media (max-width: 576px) {
  .home2-testimonial-section .testimonial-wrap {
    padding: 60px 20px;
  }
}

.home2-testimonial-section .testimonial-wrap .swiper-slide-active .author-area {
  animation: fadeInUp 1.7s;
}

.home2-testimonial-section .testimonial-wrap .swiper-slide-active p {
  animation: fadeInDown 1.7s;
}

.home2-testimonial-section .testimonial-wrap .swiper-slide-active span {
  animation: fadeInDown 1.7s;
}

.home2-testimonial-section .testimonial-wrap .testimonial-card2>span {
  color: var(--primary-color1);
  font-family: var(--font-hankenGrotesk);
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
}

.home2-testimonial-section .testimonial-wrap .testimonial-card2 p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 22px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 35px;
}

@media (max-width: 576px) {
  .home2-testimonial-section .testimonial-wrap .testimonial-card2 p {
    font-size: 18px;
  }
}

.home2-testimonial-section .testimonial-wrap .testimonial-card2 .author-area {
  display: flex;
  align-items: center;
  gap: 15px;
}

.home2-testimonial-section .testimonial-wrap .testimonial-card2 .author-area .author-img img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.home2-testimonial-section .testimonial-wrap .testimonial-card2 .author-area .content {
  line-height: 1;
}

.home2-testimonial-section .testimonial-wrap .testimonial-card2 .author-area .content h6 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 7px;
}

.home2-testimonial-section .testimonial-wrap .testimonial-card2 .author-area .content span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
}

.home2-testimonial-section .testimonial-wrap .slider-btn-area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  padding-top: 50px;
  z-index: 9;
}

@media (max-width: 991px) {
  .home2-testimonial-section .testimonial-wrap .slider-btn-area {
    padding-top: 40px;
  }
}

.home2-testimonial-section .testimonial-wrap .slider-btn-area .dash {
  width: 100%;
  height: 1px;
  background-color: rgba(var(--title-color-opc), 0.08);
}

.home2-testimonial-section .testimonial-wrap .slider-btn-area .slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home2-testimonial-section .testimonial-wrap .slider-btn-area .slider-btn-group.w-100 {
  max-width: 100px;
  min-width: 100px;
  width: 100%;
}

.home2-testimonial-section .testimonial-wrap .rating-area {
  display: flex;
  align-items: center;
  gap: 60px;
  padding-top: 45px;
}

@media (max-width: 1399px) {
  .home2-testimonial-section .testimonial-wrap .rating-area {
    gap: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-testimonial-section .testimonial-wrap .rating-area {
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 35px;
  }
}

@media (max-width: 991px) {
  .home2-testimonial-section .testimonial-wrap .rating-area {
    padding-top: 35px;
  }
}

@media (max-width: 576px) {
  .home2-testimonial-section .testimonial-wrap .rating-area {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.home2-testimonial-section .testimonial-wrap .rating-area>li {
  position: relative;
}

.home2-testimonial-section .testimonial-wrap .rating-area>li .single-rating {
  display: flex;
  align-items: center;
  gap: 8px;
}

.home2-testimonial-section .testimonial-wrap .rating-area>li .single-rating .review {
  line-height: 1;
}

.home2-testimonial-section .testimonial-wrap .rating-area>li .single-rating .review span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 8px;
  display: block;
}

.home2-testimonial-section .testimonial-wrap .rating-area>li .single-rating .review .logo-dark {
  display: block;
}

.home2-testimonial-section .testimonial-wrap .rating-area>li .single-rating .review .logo-light {
  display: none;
}

.home2-testimonial-section .testimonial-wrap .rating-area>li .single-rating .review img {
  width: 64px;
}

.home2-testimonial-section .testimonial-wrap .rating-area>li .single-rating .rating {
  line-height: 1;
}

.home2-testimonial-section .testimonial-wrap .rating-area>li .single-rating .rating .star {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
  line-height: 1;
}

.home2-testimonial-section .testimonial-wrap .rating-area>li .single-rating .rating .star li i {
  color: #e62415;
  font-size: 12px;
}

.home2-testimonial-section .testimonial-wrap .rating-area>li .single-rating .rating span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.home2-testimonial-section .testimonial-wrap .rating-area>li .single-rating.google .rating .star li i {
  color: #ffc107;
}

.home2-testimonial-section .testimonial-wrap .rating-area>li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  background-color: rgba(var(--primary-color1-opc), 0.2);
  width: 1px;
  height: 35px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home2-testimonial-section .testimonial-wrap .rating-area>li::before {
    left: -15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-testimonial-section .testimonial-wrap .rating-area>li::before {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .home2-testimonial-section .testimonial-wrap .rating-area>li::before {
    left: -15px;
  }
}

@media (max-width: 576px) {
  .home2-testimonial-section .testimonial-wrap .rating-area>li::before {
    display: none;
  }
}

.home2-testimonial-section .testimonial-wrap .rating-area>li:first-child::before {
  display: none;
}

.pricing-card {
  background-color: var(--dark-white-color);
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  border-radius: 10px;
}

.pricing-card .pricing-top {
  padding: 40px 30px;
  border-bottom: 1px solid rgba(var(--primary-color1-opc), 0.1);
}

@media (max-width: 1399px) {
  .pricing-card .pricing-top {
    padding: 40px 25px;
  }
}

.pricing-card .pricing-top span {
  font-family: var(--font-hankenGrotesk);
  font-weight: 400;
  font-size: 20px;
  color: var(--text-color);
  display: inline-block;
  line-height: 1;
  margin-bottom: 30px;
}

.pricing-card .pricing-top h2 {
  margin-bottom: 0;
  font-family: var(--font-hankenGrotesk);
  font-weight: 800;
  font-size: 50px;
  line-height: 44px;
  color: var(--title-color);
}

@media (max-width: 576px) {
  .pricing-card .pricing-top h2 {
    font-size: 45px;
  }
}

.pricing-card .pricing-top h2 sub {
  font-weight: 400;
  font-size: 17px;
  color: var(--text-color);
  bottom: 0;
}

.pricing-card .pricing-content {
  padding: 50px 25px;
}

.pricing-card .pricing-content ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: var(--font-inter);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.03em;
  color: var(--text-color);
  line-height: 1.5;
  padding-bottom: 20px;
  margin-bottom: 15px;
  position: relative;
}

.pricing-card .pricing-content ul li:last-child {
  padding-bottom: 0;
}

.pricing-card .pricing-content ul li:last-child::before {
  display: none;
}

.pricing-card .pricing-content ul li svg {
  fill: var(--primary-color1);
  min-width: 16px;
}

.pricing-card .pricing-content ul li svg path:last-child {
  fill: var(--white-color);
}

.pricing-card .pricing-content ul li::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg,
      rgba(5, 45, 57, 0.17) 0%,
      rgba(5, 45, 57, 0) 81.82%);
}

.pricing-card .pricing-content ul li.red svg {
  fill: #f73131;
}

.pricing-card .pricing-content .pay-btn-area {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.pricing-card .pricing-content .pay-btn-area .pay-btn {
  border-radius: 5px;
  border: 1px solid var(--primary-color2);
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 15px;
  color: var(--title-color);
  letter-spacing: 0.48px;
  line-height: 1;
  padding: 14px 46px;
  position: relative;
  align-items: center;
  display: inline-flex;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}

.pricing-card .pricing-content .pay-btn-area .pay-btn>span {
  transition: opacity 0.2s, transform 0.2s;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.pricing-card .pricing-content .pay-btn-area .pay-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 102% 102%;
  border-radius: inherit;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  opacity: 0;
  z-index: -1;
}

.pricing-card .pricing-content .pay-btn-area .pay-btn::after {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  opacity: 0;
  transform: translate(0, 100%);
  transition: opacity 0.2s, transform 0.2s;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  white-space: nowrap;
}

.pricing-card .pricing-content .pay-btn-area .pay-btn:hover {
  color: var(--dark-white-color);
  background-color: var(--primary-color2);
}

.pricing-card .pricing-content .pay-btn-area .pay-btn:hover::before {
  opacity: 1;
}

.pricing-card .pricing-content .pay-btn-area .pay-btn:hover>span {
  transform: translateY(-150%);
  opacity: 0;
}

.pricing-card .pricing-content .pay-btn-area .pay-btn:hover::after {
  transform: translate(0%, -50%);
  opacity: 1;
}

.pricing-card .batch {
  width: 63px;
  height: 59px;
  border-radius: 0 0 100px 100px;
  background: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 30px;
}

.pricing-card .batch span {
  color: var(--white-color);
  text-align: center;
  font-family: var(--font-hankenGrotesk);
  font-size: 15px;
  font-style: italic;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.3px;
}

.pricing-card .batch span strong {
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  display: block;
  margin-bottom: 5px;
}

.pricing-card.two {
  background: rgba(var(--primary-color1-opc), 0.1);
}

.pricing-card.two .pricing-content .pay-btn-area .pay-btn {
  border: unset;
  background-color: var(--primary-color1);
  color: var(--white-color);
}

.pricing-card.two .pricing-content .pay-btn-area .pay-btn:hover {
  color: var(--dark-white-color);
  background-color: var(--primary-color2);
}

.pricing-card.two .pricing-content .pay-btn-area .pay-btn:hover::before {
  opacity: 1;
}

.pricing-card.two .pricing-content .pay-btn-area .pay-btn:hover>span {
  transform: translateY(-150%);
  opacity: 0;
}

.pricing-card.two .pricing-content .pay-btn-area .pay-btn:hover::after {
  transform: translate(0%, -50%);
  opacity: 1;
}

.pricing-card.three {
  background: rgba(16, 57, 59, 0.1);
}

.pricing-card.three .pricing-content .pay-btn-area .pay-btn {
  border: unset;
  background-color: var(--primary-color1);
  color: var(--white-color);
}

.pricing-card.three .pricing-content .pay-btn-area .pay-btn:hover {
  color: var(--dark-white-color);
  background-color: var(--primary-color2);
}

.pricing-card.three .pricing-content .pay-btn-area .pay-btn:hover::before {
  opacity: 1;
}

.pricing-card.three .pricing-content .pay-btn-area .pay-btn:hover>span {
  transform: translateY(-150%);
  opacity: 0;
}

.pricing-card.three .pricing-content .pay-btn-area .pay-btn:hover::after {
  transform: translate(0%, -50%);
  opacity: 1;
}

.pricing-card.three .batch {
  background-color: #f98138;
}

.home2-pricing-plan-section .pricing-plan-tab-area nav {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.home2-pricing-plan-section .pricing-plan-tab-area nav .nav-tabs {
  border-bottom: none;
  background: var(--dark-white-color);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(63, 68, 75, 0.1);
  border-radius: 50px;
  padding: 10px;
}

@media (max-width: 576px) {
  .home2-pricing-plan-section .pricing-plan-tab-area nav .nav-tabs {
    border-radius: 10px;
  }
}

.home2-pricing-plan-section .pricing-plan-tab-area nav .nav-tabs .nav-link {
  font-family: var(--font-hankenGrotesk);
  font-weight: 500;
  font-size: 17px;
  color: var(--title-color);
  border: none;
  padding: 17px 28px;
  line-height: 1;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 8px;
}

@media (max-width: 576px) {
  .home2-pricing-plan-section .pricing-plan-tab-area nav .nav-tabs .nav-link {
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
  }
}

.home2-pricing-plan-section .pricing-plan-tab-area nav .nav-tabs .nav-link span {
  font-family: var(--font-inter);
  font-weight: 700;
  font-size: 8px;
  text-transform: uppercase;
  color: var(--dark-white-color);
  background: var(--title-color);
  border-radius: 100%;
  padding: 5px 7px;
}

.home2-pricing-plan-section .pricing-plan-tab-area nav .nav-tabs .nav-link:hover {
  border: none;
}

.home2-pricing-plan-section .pricing-plan-tab-area nav .nav-tabs .nav-link:focus {
  border: none;
}

.home2-pricing-plan-section .pricing-plan-tab-area nav .nav-tabs .nav-link.yearly {
  position: relative;
}

.home2-pricing-plan-section .pricing-plan-tab-area nav .nav-tabs .nav-link.yearly span {
  position: absolute;
  right: 0;
  top: -18px;
}

@media (max-width: 576px) {
  .home2-pricing-plan-section .pricing-plan-tab-area nav .nav-tabs .nav-link.yearly span {
    position: relative;
    top: unset;
  }
}

.home2-pricing-plan-section .pricing-plan-tab-area nav .nav-tabs .nav-link.active {
  color: var(--white-color);
  background: var(--primary-color1);
  border: none;
}

.home2-contact-section {
  background-image: url(../img/home2/home2-contact-bg.png),
    linear-gradient(180deg, #e9e5ff 0%, #e9e5ff 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 95px 0;
  margin-left: 60px;
  margin-right: 60px;
  border-radius: 50px;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-contact-section {
    padding: 95px 30px;
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media (max-width: 1399px) {
  .home2-contact-section {
    padding: 75px 30px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 40px;
  }
}

@media (max-width: 1199px) {
  .home2-contact-section {
    padding: 70px 20px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 30px;
  }
}

@media (max-width: 767px) {
  .home2-contact-section {
    padding: 70px 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 576px) {
  .home2-contact-section {
    padding: 70px 0;
    border-radius: 20px;
  }
}

.home2-contact-section .contact-content {
  max-width: 536px;
  width: 100%;
}

.home2-contact-section .contact-content .section-title2 span {
  background-color: #ddd7ff;
  border: 1px solid rgba(var(--text-color-opc), 0.05);
}

.home2-contact-section .contact-content>ul {
  padding-top: 40px;
  margin-bottom: 60px;
}

@media (max-width: 1199px) {
  .home2-contact-section .contact-content>ul {
    padding-top: 30px;
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .home2-contact-section .contact-content>ul {
    margin-bottom: 40px;
  }
}

.home2-contact-section .contact-content>ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.3px;
  margin-bottom: 25px;
}

.home2-contact-section .contact-content>ul li:last-child {
  margin-bottom: 0;
}

.home2-contact-section .contact-content>ul li svg {
  fill: var(--primary-color1);
}

.home2-contact-section .contact-content>ul li svg path:last-child {
  fill: #fffcee;
}

.home2-contact-section .contact-content .social-area h6 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  text-decoration: underline;
  margin-bottom: 20px;
}

.home2-contact-section .contact-content .social-area .social-list {
  display: flex;
  align-items: center;
  gap: 30px;
}

.home2-contact-section .contact-content .social-area .social-list li {
  display: block;
  text-align: center;
}

.home2-contact-section .contact-content .social-area .social-list li a i {
  color: var(--text-color);
  transition: 0.5s;
}

.home2-contact-section .contact-content .social-area .social-list li a svg {
  fill: var(--text-color);
  transition: 0.5s;
}

.home2-contact-section .contact-content .social-area .social-list li a span {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  display: block;
  padding-top: 2px;
  transition: 0.5s;
}

.home2-contact-section .contact-content .social-area .social-list li:hover a i {
  color: var(--primary-color1);
}

.home2-contact-section .contact-content .social-area .social-list li:hover a svg {
  fill: var(--primary-color1);
}

.home2-contact-section .contact-content .social-area .social-list li:hover a span {
  color: var(--primary-color1);
}

.home2-contact-section .contact-form-wrap {
  background-color: var(--dark-white-color);
  border-radius: 30px;
  padding: 50px 45px;
}

@media (max-width: 1399px) {
  .home2-contact-section .contact-form-wrap {
    padding: 45px 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home2-contact-section .contact-form-wrap {
    border-radius: 20px;
  }
}

@media (max-width: 576px) {
  .home2-contact-section .contact-form-wrap {
    padding: 30px 20px;
    border-radius: 10px;
  }
}

.home2-contact-section .contact-form-wrap h3 {
  font-size: 25px;
  font-family: var(--font-hankenGrotesk);
  font-weight: 700;
  margin-bottom: 35px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--title-color-opc), 0.1);
}

@media (max-width: 576px) {
  .home2-contact-section .contact-form-wrap h3 {
    font-size: 22px;
  }
}

.home2-blog-section .blog-section-left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 50px;
}

@media (max-width: 991px) {
  .home2-blog-section .blog-section-left {
    padding-bottom: 0;
  }
}

@media (max-width: 991px) {
  .home2-blog-section .blog-section-left .explore-btn {
    padding-top: 20px;
  }
}

.home3-banner-section {
  background-image: url(../img/home3/home3-banner-bg.png),
    linear-gradient(180deg, #0a0a0a 0%, #0a0a0a 100%);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 11% 0 13%;
  margin-bottom: 310px;
}

@media (max-width: 1699px) {
  .home3-banner-section {
    margin-bottom: 300px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home3-banner-section {
    padding: 11% 0 17%;
  }
}

@media (max-width: 1399px) {
  .home3-banner-section {
    padding: 13% 0 17%;
    margin-bottom: 280px;
  }
}

@media (max-width: 1199px) {
  .home3-banner-section {
    padding: 170px 0 100px;
    margin-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .home3-banner-section {
    margin-bottom: 80px;
  }
}

@media (max-width: 576px) {
  .home3-banner-section {
    padding: 140px 0 70px;
  }
}

.home3-banner-section .banner-content {
  text-align: center;
}

.home3-banner-section .banner-content>span {
  color: var(--white-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 12px 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  margin-bottom: 20px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home3-banner-section .banner-content>span {
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home3-banner-section .banner-content>span {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .home3-banner-section .banner-content>span {
    margin-bottom: 10px;
  }
}

.home3-banner-section .banner-content>span svg {
  fill: var(--primary-color1);
}

.home3-banner-section .banner-content h1 {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 70px;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 40px;
}

@media (max-width: 1399px) {
  .home3-banner-section .banner-content h1 {
    margin-bottom: 40px;
    font-size: 65px;
  }
}

@media (max-width: 1199px) {
  .home3-banner-section .banner-content h1 {
    font-size: 63px;
  }
}

.home3-banner-section .banner-content h1 span {
  text-decoration: underline;
  color: var(--primary-color1);
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home3-banner-section .banner-content h1 {
    font-size: 65px;
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .home3-banner-section .banner-content h1 {
    font-size: 50px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .home3-banner-section .banner-content h1 {
    font-size: 45px;
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .home3-banner-section .banner-content h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }
}

.home3-banner-section .banner-content p {
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--font-inter);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 0;
}

.home3-banner-section .banner-content ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 60px;
  flex-wrap: wrap;
  padding-top: 40px;
}

@media (max-width: 1399px) {
  .home3-banner-section .banner-content ul {
    margin-bottom: 45px;
  }
}

@media (max-width: 767px) {
  .home3-banner-section .banner-content ul {
    gap: 20px;
    margin-bottom: 35px;
  }
}

.home3-banner-section .banner-content ul li {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 17px;
  font-weight: 600;
  line-height: 1.2;
}

.home3-banner-section .banner-content ul li svg {
  fill: #f98138;
}

.home3-banner-section .banner-content ul li svg path:last-child {
  fill: var(--dark-title-color);
}

.home3-banner-section .banner-content .banner-content-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  gap: 40px;
}

@media (max-width: 576px) {
  .home3-banner-section .banner-content .banner-content-bottom {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.home3-banner-section .banner-content .banner-content-bottom .video-area {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.home3-banner-section .banner-content .banner-content-bottom .video-area .icon {
  position: relative;
}

.home3-banner-section .banner-content .banner-content-bottom .video-area .icon .video-circle {
  stroke: var(--white-color);
  fill: none;
  transition: 0.5s;
}

.home3-banner-section .banner-content .banner-content-bottom .video-area .icon .video-circle .top-half {
  stroke-dasharray: 320;
  stroke-dashoffset: 320;
  transition: stroke-dashoffset 0.8s cubic-bezier(0.33, 1, 0.68, 1);
}

.home3-banner-section .banner-content .banner-content-bottom .video-area .icon i {
  color: var(--white-color);
  font-size: 30px;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home3-banner-section .banner-content .banner-content-bottom .video-area h6 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.48px;
  margin-bottom: 0;
}

.home3-banner-section .banner-content .banner-content-bottom .video-area:hover .icon .video-circle .top-half {
  stroke-dashoffset: 0;
}

.home3-banner-section .bottom-img1,
.home3-banner-section .bottom-img2,
.home3-banner-section .bottom-img3 {
  border-radius: 10px;
  box-shadow: 0 6px 25px 0 rgba(63, 68, 75, 0.1);
}

@media (max-width: 1199px) {

  .home3-banner-section .bottom-img1,
  .home3-banner-section .bottom-img2,
  .home3-banner-section .bottom-img3 {
    display: none;
  }
}

.home3-banner-section .bottom-img1 {
  position: absolute;
  left: 45px;
  bottom: -13%;
}

@media (max-width: 1699px) {
  .home3-banner-section .bottom-img1 {
    left: 25px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home3-banner-section .bottom-img1 {
    max-width: 325px;
    bottom: -10%;
  }
}

@media (max-width: 1399px) {
  .home3-banner-section .bottom-img1 {
    max-width: 325px;
    left: 10px;
    bottom: -10%;
  }
}

.home3-banner-section .bottom-img2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -22%;
}

@media (max-width: 1399px) {
  .home3-banner-section .bottom-img2 {
    max-width: 450px;
  }
}

.home3-banner-section .bottom-img3 {
  position: absolute;
  right: 45px;
  bottom: -52px;
}

@media (max-width: 1699px) {
  .home3-banner-section .bottom-img3 {
    right: 25px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home3-banner-section .bottom-img3 {
    bottom: -14%;
  }
}

@media (max-width: 1399px) {
  .home3-banner-section .bottom-img3 {
    bottom: -14%;
    right: 10px;
  }
}

.home3-process-section .process-card {
  border-radius: 7px;
  background: #ffebeb;
  padding: 30px 25px;
  transition: all 0.5s ease-out;
}

@media (max-width: 1399px) {
  .home3-process-section .process-card {
    padding: 30px 20px;
  }
}

.home3-process-section .process-card .process-card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.home3-process-section .process-card .process-card-top .icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--dark-white-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.home3-process-section .process-card .process-card-top .icon svg {
  fill: var(--text-color);
  transition: all 0.5s ease-out;
}

.home3-process-section .process-card .process-card-top .number span {
  color: rgba(var(--title-color-opc), 0.05);
  font-family: var(--font-hankenGrotesk);
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
}

.home3-process-section .process-card .content h4 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home3-process-section .process-card .content h4 {
    font-size: 26px;
  }
}

.home3-process-section .process-card .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.9;
  margin-bottom: 0;
}

.home3-process-section .process-card:hover {
  transform: translateY(-5px);
}

.home3-process-section .process-card:hover .process-card-top .icon svg {
  transform: rotate(360deg);
}

.home3-process-section .process-card.two {
  background: #e4f4f1;
}

.home3-process-section .process-card.three {
  background: #f0efe9;
}

.home3-process-section .process-card.four {
  background: #eff4f8;
}

.home3-countdown-section {
  background-image: url(../img/home3/home3-countdown-bg.png),
    linear-gradient(180deg, #0a0a0a 0%, #0a0a0a 100%);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 150px 0 120px;
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  border-radius: 10px 10px 0 0;
}

@media (max-width: 1199px) {
  .home3-countdown-section {
    padding: 130px 0 100px;
    -webkit-clip-path: polygon(0 7%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 7%, 100% 0, 100% 100%, 0% 100%);
  }
}

@media (max-width: 991px) {
  .home3-countdown-section {
    padding: 120px 0 90px;
    -webkit-clip-path: polygon(0 6%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 6%, 100% 0, 100% 100%, 0% 100%);
  }
}

@media (max-width: 767px) {
  .home3-countdown-section {
    padding: 90px 0 70px;
  }
}

@media (max-width: 576px) {
  .home3-countdown-section {
    -webkit-clip-path: polygon(0 4%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 4%, 100% 0, 100% 100%, 0% 100%);
  }
}

.home3-countdown-section .section-title {
  max-width: 560px;
  width: 100%;
  margin-bottom: 235px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home3-countdown-section .section-title {
    margin-bottom: 200px;
  }
}

@media (max-width: 1199px) {
  .home3-countdown-section .section-title {
    margin-bottom: 180px;
  }
}

@media (max-width: 991px) {
  .home3-countdown-section .section-title {
    margin-bottom: 140px;
  }
}

@media (max-width: 767px) {
  .home3-countdown-section .section-title {
    margin-bottom: 60px;
  }
}

.home3-countdown-section .single-countdown {
  display: flex;
  gap: 15px;
}

.home3-countdown-section .single-countdown .icon svg {
  fill: var(--primary-color1);
}

.home3-countdown-section .single-countdown .content .number {
  color: var(--white-color);
  font-family: var(--font-inter);
  line-height: 1;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.home3-countdown-section .single-countdown .content .number h5 {
  color: var(--white-color);
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1;
}

@media (max-width: 991px) {
  .home3-countdown-section .single-countdown .content .number h5 {
    font-size: 35px;
  }
}

.home3-countdown-section .single-countdown .content .number span {
  font-size: 35px;
  font-weight: 700;
}

.home3-countdown-section .single-countdown .content p {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.9;
  margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home3-countdown-section .single-countdown .content p {
    font-size: 13px;
  }
}

.home3-project-management-section .project-management-tab-wrapper .project-management-nav .nav-tabs {
  border-bottom: 1px solid rgba(var(--text-color-opc), 0.2);
  padding-left: 80px;
  padding-right: 80px;
  gap: 50px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-nav .nav-tabs {
    padding-left: 60px;
    padding-right: 60px;
    gap: 24px;
  }
}

@media (max-width: 1199px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-nav .nav-tabs {
    padding-left: 0;
    padding-right: 0;
    gap: 5px;
  }
}

@media (max-width: 991px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-nav .nav-tabs {
    gap: 0;
  }
}

@media (max-width: 576px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-nav .nav-tabs {
    flex-direction: column;
  }
}

.home3-project-management-section .project-management-tab-wrapper .project-management-nav .nav-tabs .nav-link {
  color: rgba(var(--title-color-opc), 0.5);
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.36px;
  border: none;
  background: linear-gradient(to bottom, #0b0c0c 0%, #0b0c0c 98%);
  background-size: 0 3px;
  background-repeat: no-repeat;
  background-position: right 100%;
  transition: background-size 0.75s;
}

.home3-project-management-section .project-management-tab-wrapper .project-management-nav .nav-tabs .nav-link.active {
  color: var(--title-color);
  background-size: 100% 3px;
  background-position: 0% 100%;
}

.home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content {
  background-image: url(../img/home3/home3-faq-section-bg.png),
    linear-gradient(180deg, #efefff 0%, #efefff 100%);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 0 0 10px;
  padding: 85px 70px;
  height: 100%;
}

@media (max-width: 1399px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content {
    padding: 75px 45px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content {
    padding: 60px 25px;
  }
}

@media (max-width: 991px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content {
    padding: 60px 40px;
    border-radius: 10px;
  }
}

@media (max-width: 767px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content {
    padding: 60px 25px;
  }
}

@media (max-width: 576px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content {
    padding: 60px 20px;
  }
}

.home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content h3 {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 35px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 15px;
}

@media (max-width: 576px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content h3 {
    font-size: 32px;
  }
}

.home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.32px;
  margin-bottom: 35px;
}

@media (max-width: 576px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content p {
    font-size: 14px;
  }
}

.home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content ul {
  -moz-columns: 2;
  columns: 2;
  margin-bottom: 50px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content ul {
    -moz-column-gap: 0;
    column-gap: 0;
  }
}

@media (max-width: 576px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content ul {
    -moz-columns: unset;
    columns: unset;
    margin-bottom: 40px;
  }
}

.home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.3px;
  margin-bottom: 25px;
}

.home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content ul li:last-child {
  margin-bottom: 0;
}

.home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content ul li svg {
  fill: var(--primary-color1);
}

.home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-img-wrap {
  background-image: url(../img/home3/project-management-tab-img-bg.png),
    linear-gradient(180deg, #0a0a0a 0%, #0a0a0a 100%);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0 10px 10px 0;
  display: flex;
  align-items: end;
  height: 100%;
  padding: 55px 0 0 55px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-img-wrap {
    padding: 0 15px 0;
    align-items: center;
  }
}

@media (max-width: 991px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-img-wrap {
    display: none;
  }
}

.home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-img-wrap img {
  border-radius: 10px 0 0 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-img-wrap img {
    border-radius: 10px;
  }
}

.testimonial-card3 {
  background-color: var(--dark-white-color);
  padding: 40px 35px;
  border-radius: 10px;
  position: relative;
}

@media (max-width: 1399px) {
  .testimonial-card3 {
    padding: 35px 25px;
  }
}

@media (max-width: 576px) {
  .testimonial-card3 {
    padding: 30px 20px;
  }
}

.testimonial-card3 .author-area {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

@media (max-width: 576px) {
  .testimonial-card3 .author-area {
    margin-bottom: 25px;
  }
}

.testimonial-card3 .author-area .author-img img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.testimonial-card3 .author-area .content {
  line-height: 1;
}

.testimonial-card3 .author-area .content h6 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 23px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 7px;
}

.testimonial-card3 .author-area .content span {
  color: var(--primary-color1);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
}

.testimonial-card3 p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.54px;
  margin-bottom: 25px;
}

@media (max-width: 576px) {
  .testimonial-card3 p {
    font-size: 15px;
  }
}

.testimonial-card3 .logo-and-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.testimonial-card3 .logo-and-rating .rating {
  display: flex;
  align-items: center;
  gap: 9px;
}

.testimonial-card3 .logo-and-rating .rating li i {
  color: #ffc107;
}

.testimonial-card3::before {
  content: url(../img/home3/vector/testimonial-card3-quote.svg);
  position: absolute;
  top: 0;
  right: 18%;
}

@media (max-width: 1399px) {
  .testimonial-card3::before {
    right: 10%;
  }
}

@media (max-width: 991px) {
  .testimonial-card3::before {
    right: 0;
  }
}

.testimonial-card3.style-2 {
  padding: 60px 35px;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .testimonial-card3.style-2 {
    padding: 50px 35px;
  }
}

@media (max-width: 1399px) {
  .testimonial-card3.style-2 {
    padding: 50px 35px;
  }
}

@media (max-width: 767px) {
  .testimonial-card3.style-2 {
    padding: 45px 25px;
  }
}

@media (max-width: 576px) {
  .testimonial-card3.style-2 {
    padding: 35px 20px;
  }
}

.testimonial-card3.style-2>span {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .testimonial-card3.style-2>span {
    margin-bottom: 10px;
  }
}

.testimonial-card3.style-2 p {
  font-size: 22px;
  letter-spacing: 0.03em;
  margin-bottom: 35px;
}

@media (max-width: 767px) {
  .testimonial-card3.style-2 p {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .testimonial-card3.style-2 p {
    font-size: 17px;
    margin-bottom: 30px;
  }
}

.testimonial-card3.style-2 .author-area {
  margin-bottom: 0;
}

.testimonial-card3.style-2 .author-and-logo-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  gap: 20px;
}

.testimonial-card3.style-2 .author-and-logo-area::before {
  content: url(../img/home3/vector/testimonial-card3-quote.svg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 992px) and (max-width: 1199px) {
  .testimonial-card3.style-2 .author-and-logo-area::before {
    display: none;
  }
}

@media (max-width: 767px) {
  .testimonial-card3.style-2 .author-and-logo-area::before {
    display: none;
  }
}

.testimonial-card3.style-2::before {
  display: none;
}

.home3-testimonial-section {
  background-image: url(../img/home3/home3-testimonial-bg.png),
    linear-gradient(96deg, #fbd8ff 1.56%, #dedbfd 50.41%, #c5ccff 98.25%);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 90px 6.5% 30px;
}

@media (max-width: 1699px) {
  .home3-testimonial-section {
    padding: 90px 5% 30px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home3-testimonial-section {
    padding: 90px 3% 30px;
  }
}

@media (max-width: 1399px) {
  .home3-testimonial-section {
    padding: 90px 20px 30px;
  }
}

@media (max-width: 767px) {
  .home3-testimonial-section {
    padding: 70px 20px 30px;
  }
}

@media (max-width: 576px) {
  .home3-testimonial-section {
    padding: 70px 0 30px;
  }
}

.home3-testimonial-section .slider-btn-and-pagination-wrap {
  padding-top: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 540px;
  width: 100%;
}

@media (max-width: 1199px) {
  .home3-testimonial-section .slider-btn-and-pagination-wrap {
    padding-top: 60px;
  }
}

@media (max-width: 767px) {
  .home3-testimonial-section .slider-btn-and-pagination-wrap {
    padding-top: 40px;
  }
}

.home3-testimonial-section .slider-btn-and-pagination-wrap .franctional-pagi2 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 24px;
  font-weight: 400;
}

@media (max-width: 576px) {
  .home3-testimonial-section .slider-btn-and-pagination-wrap .franctional-pagi2 {
    top: unset;
    position: relative;
    right: unset;
    display: flex;
  }
}

.home3-testimonial-section .slider-btn-and-pagination-wrap .franctional-pagi2 .swiper-pagination-current {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 24px;
  font-weight: 400;
}

.home3-testimonial-section .slider-btn-and-pagination-wrap .franctional-pagi2 .swiper-pagination-total {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 16px;
  font-weight: 400;
}

.home3-feature-section .feature-content-wrap .progressBarContainer div span.progressBar {
  width: 100%;
  height: 1px;
  background-color: rgba(var(--text-color-opc), 0.1);
  display: block;
}

.home3-feature-section .feature-content-wrap .progressBarContainer div span.progressBar .inProgress {
  background-color: var(--primary-color1);
  width: 0%;
  height: 1px;
}

.home3-feature-section .feature-content-wrap .feature-list-wrap {
  max-width: 536px;
  width: 100%;
}

.home3-feature-section .feature-content-wrap .feature-list-wrap .pt-25 {
  padding-top: 25px;
}

.home3-feature-section .feature-content-wrap .single-feature {
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home3-feature-section .feature-content-wrap .single-feature {
    gap: 15px;
  }
}

@media (max-width: 576px) {
  .home3-feature-section .feature-content-wrap .single-feature {
    flex-direction: column;
  }
}

.home3-feature-section .feature-content-wrap .single-feature .icon {
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #d3eafd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home3-feature-section .feature-content-wrap .single-feature .icon svg {
  fill: #359ff5;
}

.home3-feature-section .feature-content-wrap .single-feature .content h6 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 10px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home3-feature-section .feature-content-wrap .single-feature .content h6 {
    margin-bottom: 5px;
  }
}

.home3-feature-section .feature-content-wrap .single-feature .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home3-feature-section .feature-content-wrap .single-feature .content p {
    font-size: 14px;
  }
}

.home3-feature-section .feature-content-wrap .single-feature.two .icon {
  background-color: #dbefdc;
}

.home3-feature-section .feature-content-wrap .single-feature.two .icon svg {
  fill: #54b259;
}

.home3-feature-section .feature-content-wrap .single-feature.three .icon {
  background-color: #fbd9e4;
}

.home3-feature-section .feature-content-wrap .single-feature.three .icon svg {
  fill: #eb4278;
}

.home3-pricing-plan-section .pricing-plan-tab-area .nav-area {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.home3-pricing-plan-section .pricing-plan-tab-area .nav-area nav {
  display: flex;
}

.home3-pricing-plan-section .pricing-plan-tab-area .nav-area nav .nav-tabs {
  border-bottom: none;
  background: rgba(var(--primary-color1-opc), 0.1);
  border: 1px solid rgba(var(--text-color-opc), 0.1);
  border-radius: 15px;
  padding: 4px;
}

.home3-pricing-plan-section .pricing-plan-tab-area .nav-area nav .nav-tabs .nav-link {
  min-width: 32px;
  max-width: 32px;
  height: 26px;
  border-radius: 25px;
  border: none;
  padding: 0;
}

.home3-pricing-plan-section .pricing-plan-tab-area .nav-area nav .nav-tabs .nav-link:hover {
  border: none;
  box-shadow: none;
}

.home3-pricing-plan-section .pricing-plan-tab-area .nav-area nav .nav-tabs .nav-link:focus {
  border: none;
  box-shadow: none;
}

.home3-pricing-plan-section .pricing-plan-tab-area .nav-area nav .nav-tabs .nav-link.active {
  color: var(--white-color);
  background: var(--primary-color1);
  border: none;
}

.home3-pricing-plan-section .pricing-plan-tab-area .nav-area span {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.32px;
}

.home3-newsletter-section .newsletter-wrapper {
  background-image: url(../img/home3/home3-newsletter-bg.png),
    linear-gradient(180deg, #0a0a0a 0%, #0a0a0a 100%);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  padding: 90px 110px;
}

@media (max-width: 1399px) {
  .home3-newsletter-section .newsletter-wrapper {
    padding: 90px 60px;
  }
}

@media (max-width: 1199px) {
  .home3-newsletter-section .newsletter-wrapper {
    padding: 90px 30px;
  }
}

@media (max-width: 991px) {
  .home3-newsletter-section .newsletter-wrapper {
    padding: 80px 20px;
  }
}

@media (max-width: 767px) {
  .home3-newsletter-section .newsletter-wrapper {
    padding: 70px 20px;
    border-radius: 10px;
  }
}

.home3-newsletter-section .newsletter-wrapper .section-title {
  max-width: 546px;
  width: 100%;
}

.home3-newsletter-section .newsletter-wrapper .newsletter-form-area h6 {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 15px;
}

.home3-newsletter-section .newsletter-wrapper .newsletter-form-area .form-inner {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
}

.home3-newsletter-section .newsletter-wrapper .newsletter-form-area .form-inner input {
  background-color: transparent;
  height: unset;
  border: none;
  color: var(--white-color);
}

.home3-newsletter-section .newsletter-wrapper .newsletter-form-area .form-inner button {
  background-color: var(--primary-color1);
  border-radius: 0 5px 5px 0;
  padding: 16px 26px;
  transition: 0.5s;
}

.home3-newsletter-section .newsletter-wrapper .newsletter-form-area .form-inner button i {
  color: var(--white-color);
  font-size: 20px;
}

.home3-newsletter-section .newsletter-wrapper .newsletter-form-area .form-inner button:hover {
  background-color: var(--light-black-color);
}

.faq-content .accordion .accordion-item {
  border: none;
  background-color: transparent;
}

.faq-content .accordion .accordion-item:first-child .accordion-header .accordion-button {
  padding-top: 0;
}

.faq-content .accordion .accordion-item .accordion-header .accordion-button {
  border: none;
  border-radius: unset;
  box-shadow: none;
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  background-color: transparent;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  padding: 0;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid rgba(var(--title-color-opc), 0.08);
}

.faq-content .accordion .accordion-item .accordion-header .accordion-button::after {
  width: unset;
  height: unset;
  content: "\f4fe";
  font-family: bootstrap-icons;
  background-image: none;
  font-weight: 800;
  font-size: 22px;
}

.faq-content .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background-color: unset;
}

.faq-content .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  content: "\f2ea";
}

@media (max-width: 767px) {
  .faq-content .accordion .accordion-item .accordion-header .accordion-button {
    font-size: 18px;
  }
}

.faq-content .accordion .accordion-item .accordion-body {
  padding: 20px;
  padding-bottom: 15px;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.48px;
}

@media (max-width: 576px) {
  .faq-content .accordion .accordion-item .accordion-body {
    font-size: 14px;
    padding: 15px 10px;
  }
}

.faq-content.style-2 .accordion .accordion-item .accordion-header .accordion-button::after {
  content: url(../img/innerpage/vector/faq-arrow-down.svg);
}

.faq-content.style-2 .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  content: url(../img/innerpage/vector/faq-arrow-down.svg);
}

.home3-faq-section {
  background-image: url(../img/home3/home3-faq-section-bg.png),
    linear-gradient(180deg, #efefff 0%, #efefff 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 0;
}

@media (max-width: 991px) {
  .home3-faq-section {
    padding: 80px 0;
  }
}

@media (max-width: 767px) {
  .home3-faq-section {
    padding: 70px 0;
  }
}

.home3-tools-section .tools-card {
  border-radius: 5px;
  border: 1px solid rgba(var(--title-color-opc), 0.05);
  background: var(--dark-white-color);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  padding: 20px 25px;
  display: flex;
  align-items: center;
  gap: 20px;
}

@media (max-width: 1399px) {
  .home3-tools-section .tools-card {
    gap: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home3-tools-section .tools-card {
    padding: 20px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .home3-tools-section .tools-card {
    flex-direction: column;
    align-items: start;
  }
}

.home3-tools-section .tools-card .icon {
  border: 1px solid rgba(var(--title-color-opc), 0.05);
  background: var(--white-color);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.05);
  min-width: 62px;
  max-width: 62px;
  height: 62px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home3-tools-section .tools-card .content h6 {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 5px;
}

.home3-tools-section .tools-card .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 0;
}

.home4-banner-section {
  background-image: url(../img/home4/home4-banner-bg.png),
    linear-gradient(180deg, #fefbf5 0%, #fefbf5 100%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  padding: 175px 0 150px;
}

@media (max-width: 1699px) {
  .home4-banner-section {
    padding: 165px 0 150px;
  }
}

@media (max-width: 991px) {
  .home4-banner-section {
    padding: 150px 0 80px;
  }
}

@media (max-width: 576px) {
  .home4-banner-section {
    padding: 130px 0 80px;
  }
}

@keyframes up-down {
  0% {
    transform: translateY(130px);
  }

  50% {
    transform: translateY(110px);
  }

  100% {
    transform: translateY(130px);
  }
}

.home4-banner-section .banner-wrapper {
  position: relative;
  z-index: 1;
}

.home4-banner-section .banner-wrapper h1 {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 85px;
  font-weight: 700;
  line-height: 1.2;
  position: relative;
  margin-bottom: 0;
}

.home4-banner-section .banner-wrapper h1 span {
  font-style: italic;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px var(--title-color);
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-banner-section .banner-wrapper h1 {
    font-size: 75px;
  }
}

@media (max-width: 1399px) {
  .home4-banner-section .banner-wrapper h1 {
    font-size: 65px;
  }
}

@media (max-width: 767px) {
  .home4-banner-section .banner-wrapper h1 {
    font-size: 48px;
  }
}

@media (max-width: 576px) {
  .home4-banner-section .banner-wrapper h1 {
    font-size: 42px;
  }
}

.home4-banner-section .banner-wrapper h1::before {
  content: url(../img/home4/vector/banner-text-top-vector.png);
  position: absolute;
  top: -35px;
  right: 18%;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-banner-section .banner-wrapper h1::before {
    right: 26%;
  }
}

@media (max-width: 1399px) {
  .home4-banner-section .banner-wrapper h1::before {
    right: 26%;
  }
}

@media (max-width: 991px) {
  .home4-banner-section .banner-wrapper h1::before {
    display: none;
  }
}

.home4-banner-section .banner-wrapper .banner-content-and-btn {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: -35px;
}

@media (max-width: 1199px) {
  .home4-banner-section .banner-wrapper .banner-content-and-btn {
    margin-top: -45px;
  }
}

@media (max-width: 991px) {
  .home4-banner-section .banner-wrapper .banner-content-and-btn {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 25px;
    gap: 40px;
  }
}

@media (max-width: 767px) {
  .home4-banner-section .banner-wrapper .banner-content-and-btn {
    margin-top: 20px;
  }
}

.home4-banner-section .banner-wrapper .banner-content-and-btn p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7;
  max-width: 650px;
  width: 100%;
  margin-bottom: 0;
  margin-left: 100px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-banner-section .banner-wrapper .banner-content-and-btn p {
    max-width: 580px;
    font-size: 18px;
  }
}

@media (max-width: 1199px) {
  .home4-banner-section .banner-wrapper .banner-content-and-btn p {
    margin-left: 0;
  }
}

@media (max-width: 576px) {
  .home4-banner-section .banner-wrapper .banner-content-and-btn p {
    font-size: 17px;
  }
}

.home4-banner-section .banner-wrapper .banner-content-and-btn a {
  position: relative;
  line-height: 1;
  transition: 0.5s;
  height: 178px;
  width: 178px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.home4-banner-section .banner-wrapper .banner-content-and-btn a .bg {
  line-height: 1;
  transition: 0.5s;
}

.home4-banner-section .banner-wrapper .banner-content-and-btn a .bg svg {
  fill: transparent;
  stroke: rgba(var(--title-color-opc), 0.1);
  transition: 0.5s;
}

.home4-banner-section .banner-wrapper .banner-content-and-btn a .details-button {
  transition: 0.5s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.home4-banner-section .banner-wrapper .banner-content-and-btn a:hover .bg svg {
  fill: var(--primary-color1);
  stroke: var(--primary-color1);
}

.home4-banner-section .banner-wrapper .banner-content-and-btn a:hover .details-button {
  background-image: linear-gradient(0deg, #fff 0%, #fff 100%);
  color: var(--white-color);
}

.home4-banner-section .banner-wrapper .banner-content-and-btn a:hover .details-button::after {
  color: var(--white-color);
}

.home4-banner-section .banner-wrapper .banner-content-and-btn a:hover .details-button svg {
  stroke: var(--white-color);
}

.home4-banner-section .banner-wrapper .scroll-down-btn {
  display: flex;
  justify-content: center;
  animation: up-down 2s linear infinite alternate;
}

@media (max-width: 991px) {
  .home4-banner-section .banner-wrapper .scroll-down-btn {
    display: none;
  }
}

.home4-banner-section .banner-wrapper .scroll-down-btn a {
  width: 41px;
  height: 80px;
  border-radius: 110px;
  border: 1px solid rgba(var(--title-color-opc), 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.home4-banner-section .banner-wrapper .scroll-down-btn a svg {
  fill: none;
  stroke: rgba(var(--title-color-opc), 0.5);
  transition: 0.5;
}

.home4-banner-section .banner-wrapper .scroll-down-btn a:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.home4-banner-section .banner-wrapper .scroll-down-btn a:hover svg {
  stroke: var(--white-color);
}

.home4-banner-section .banner-img {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (max-width: 1699px) {
  .home4-banner-section .banner-img {
    width: 450px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-banner-section .banner-img {
    width: 400px;
  }
}

@media (max-width: 1399px) {
  .home4-banner-section .banner-img {
    width: 380px;
  }
}

@media (max-width: 1199px) {
  .home4-banner-section .banner-img {
    display: none;
  }
}

.home4-banner-section .banner-img.dark {
  display: none;
}

.home4-banner-section .social-list {
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 65px;
  z-index: 1;
}

@media (max-width: 1199px) {
  .home4-banner-section .social-list {
    left: 10px;
  }
}

@media (max-width: 1199px) {
  .home4-banner-section .social-list {
    display: none;
  }
}

.home4-banner-section .social-list li {
  position: relative;
  line-height: 1;
  transition: 0.5s;
}

.home4-banner-section .social-list li::before {
  content: "";
  position: absolute;
  top: -33px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--text-color);
  width: 3px;
  height: 3px;
  border-radius: 50%;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-banner-section .social-list li::before {
    top: -15px;
  }
}

.home4-banner-section .social-list li:first-child::before {
  display: none;
}

.home4-banner-section .social-list li a i {
  color: var(--text-color);
  font-size: 22px;
  transition: 0.5s;
}

.home4-banner-section .social-list li a svg {
  fill: var(--text-color);
}

.home4-banner-section .social-list li:hover a i {
  color: var(--primary-color1);
}

.home4-banner-section .social-list li:hover a svg {
  fill: var(--primary-color1);
}

.home4-banner-section .scroll-text {
  writing-mode: vertical-rl;
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 1199px) {
  .home4-banner-section .scroll-text {
    display: none;
  }
}

.home4-banner-section .scroll-text h2 {
  white-space: nowrap;
  font-family: var(--font-hankenGrotesk);
  font-size: 75px;
  font-weight: 700;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin-bottom: 0;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgba(var(--title-color-opc), 0.1);
  animation: marqueeTop 50s linear infinite;
}

@keyframes marqueeTop {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -100%, 0);
  }
}

.home4-about-section {
  scroll-margin-top: 150px;
}

.home4-about-section .about-content-wrap .about-section-title span {
  border-radius: 50px;
  border: 1px solid rgba(var(--text-color-opc), 0.2);
  padding: 13px 20px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.39px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

@media (max-width: 991px) {
  .home4-about-section .about-content-wrap .about-section-title span {
    margin-bottom: 15px;
  }
}

.home4-about-section .about-content-wrap .about-section-title span svg {
  fill: var(--primary-color1);
}

.home4-about-section .about-content-wrap .about-section-title h2 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 58px;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 25px;
}

@media (max-width: 1399px) {
  .home4-about-section .about-content-wrap .about-section-title h2 {
    font-size: 50px;
  }
}

@media (max-width: 1199px) {
  .home4-about-section .about-content-wrap .about-section-title h2 {
    font-size: 38px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .home4-about-section .about-content-wrap .about-section-title h2 {
    font-size: 32px;
  }
}

@media (max-width: 576px) {
  .home4-about-section .about-content-wrap .about-section-title h2 {
    font-size: 28px;
  }
}

.home4-about-section .about-content-wrap .about-section-title p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .home4-about-section .about-content-wrap .about-section-title p {
    font-size: 17px;
  }
}

.home4-about-section .about-content-wrap .explore-btn {
  font-size: 16px;
}

.home4-about-section .text-rotate-area {
  position: relative;
}

@media (max-width: 1199px) {
  .home4-about-section .text-rotate-area {
    display: none;
  }
}

.home4-about-section .text-rotate-area .badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  border-radius: 100%;
  animation: identifier 20s linear infinite;
  z-index: 1;
  background-color: transparent;
  padding: 5px;
}

.home4-about-section .text-rotate-area .badge .inner-circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: rgba(var(--text-color-opc), 0.1);
}

.home4-about-section .text-rotate-area .badge .badge__char {
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - 5px);
  height: 125px;
  width: 10px;
  background-repeat: round;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 400;
  font-size: 16px;
  color: var(--text-color);
  font-family: var(--font-inter);
  transform-origin: bottom center;
  transform: rotate(var(--char-rotate));
}

@keyframes identifier {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.home4-about-section .text-rotate-area .half-circle-img-area {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.home4-about-section .text-rotate-area .half-circle-img-area img {
  width: 200px;
  height: 395px;
  border-radius: 0 300px 300px 0;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-about-section .text-rotate-area .half-circle-img-area img {
    width: 180px;
  }
}

.home4-about-section .about-coundown-area .single-countdown {
  text-align: center;
}

.home4-about-section .about-coundown-area .single-countdown .number {
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home4-about-section .about-coundown-area .single-countdown .number h2 {
  font-family: var(--font-hankenGrotesk);
  font-size: 150px;
  font-weight: 900;
  letter-spacing: 4.5px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px var(--title-color);
  margin-bottom: 0;
  line-height: 0.8;
}

@media (max-width: 1199px) {
  .home4-about-section .about-coundown-area .single-countdown .number h2 {
    font-size: 130px;
  }
}

@media (max-width: 576px) {
  .home4-about-section .about-coundown-area .single-countdown .number h2 {
    font-size: 100px;
  }
}

.home4-about-section .about-coundown-area .single-countdown .number span {
  font-family: var(--font-hankenGrotesk);
  font-size: 150px;
  font-weight: 900;
  letter-spacing: 4.5px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px var(--title-color);
  line-height: 0.8;
}

@media (max-width: 1199px) {
  .home4-about-section .about-coundown-area .single-countdown .number span {
    font-size: 130px;
  }
}

@media (max-width: 576px) {
  .home4-about-section .about-coundown-area .single-countdown .number span {
    font-size: 100px;
  }
}

.home4-about-section .about-coundown-area .single-countdown .content {
  background-color: #f7f6f1;
  padding-top: 20px;
  margin-top: -15px;
  position: relative;
  z-index: 1;
}

.home4-about-section .about-coundown-area .single-countdown .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .home4-about-section .about-coundown-area .single-countdown .content p {
    font-size: 14px;
  }
}

.home4-service-section {
  overflow: hidden;
}

.home4-service-section .sevices-wrap .single-services {
  transition: 0.5s;
  border-radius: 10px;
  border: 1px solid rgba(63, 68, 75, 0.1);
  background: var(--dark-white-color);
  position: relative;
  display: grid;
  grid-template-columns: 7% 26% 52% 15%;
}

@media (max-width: 1399px) {
  .home4-service-section .sevices-wrap .single-services {
    grid-template-columns: 7% 26% 51% 16%;
  }
}

@media (max-width: 1199px) {
  .home4-service-section .sevices-wrap .single-services {
    grid-template-columns: 7% 26% 47% 20%;
  }
}

@media (max-width: 991px) {
  .home4-service-section .sevices-wrap .single-services {
    grid-template-columns: 7% 24% 45% 24%;
  }
}

@media (max-width: 767px) {
  .home4-service-section .sevices-wrap .single-services {
    padding: 20px;
    display: block;
  }
}

.home4-service-section .sevices-wrap .single-services .icon {
  padding: 25px;
  border-right: 1px solid rgba(var(--title-color-opc), 0.08);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--primary-color1-opc), 0.05);
  position: relative;
}

@media (max-width: 767px) {
  .home4-service-section .sevices-wrap .single-services .icon {
    background-color: unset;
    padding: 0;
    margin-bottom: 15px;
    align-items: start;
    justify-content: space-between;
    border-right: unset;
  }
}

.home4-service-section .sevices-wrap .single-services .icon svg {
  fill: var(--text-color);
  transition: 0.5s;
  min-width: 50px;
  max-width: 50px;
}

.home4-service-section .sevices-wrap .single-services .icon .number {
  position: absolute;
  top: 14px;
  left: 14px;
}

@media (max-width: 767px) {
  .home4-service-section .sevices-wrap .single-services .icon .number {
    position: relative;
    top: unset;
    left: unset;
  }
}

.home4-service-section .sevices-wrap .single-services .icon .number span {
  font-family: var(--font-inter);
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 2px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgba(var(--text-color-opc), 0.3);
}

.home4-service-section .sevices-wrap .single-services h5 {
  padding: 20px 10px 20px 30px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 26px;
  font-weight: 700;
  transition: 0.5s;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .home4-service-section .sevices-wrap .single-services h5 {
    padding: 20px 15px;
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .home4-service-section .sevices-wrap .single-services h5 {
    margin-bottom: 10px;
    padding: 0;
    font-size: 20px;
  }
}

.home4-service-section .sevices-wrap .single-services .services-img {
  width: 260px;
  height: 320px;
  position: absolute;
  top: -150px;
  left: 0;
  opacity: 0;
  z-index: 9;
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 5px;
  overflow: hidden;
  pointer-events: none;
}

@media (max-width: 767px) {
  .home4-service-section .sevices-wrap .single-services .services-img {
    display: none;
    visibility: hidden;
  }
}

.home4-service-section .sevices-wrap .single-services .content {
  padding: 30px 80px;
  position: relative;
}

@media (max-width: 1399px) {
  .home4-service-section .sevices-wrap .single-services .content {
    padding: 30px 40px;
  }
}

@media (max-width: 1199px) {
  .home4-service-section .sevices-wrap .single-services .content {
    padding: 25px 20px;
  }
}

@media (max-width: 767px) {
  .home4-service-section .sevices-wrap .single-services .content {
    margin-bottom: 20px;
    line-height: 26px;
    padding: 0;
  }
}

.home4-service-section .sevices-wrap .single-services .content .tag-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  padding-top: 25px;
}

@media (max-width: 1199px) {
  .home4-service-section .sevices-wrap .single-services .content .tag-list {
    padding-top: 15px;
  }
}

.home4-service-section .sevices-wrap .single-services .content .tag-list li {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  border: 1px solid rgba(var(--primary-color1-opc), 0.25);
  border-radius: 50px;
  padding: 8px 13px;
}

.home4-service-section .sevices-wrap .single-services .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}

.home4-service-section .sevices-wrap .single-services .content::before,
.home4-service-section .sevices-wrap .single-services .content::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 1px;
  height: 100px;
  background: rgba(var(--text-color-opc), 0.1);
  border-radius: 10px;
}

@media (max-width: 767px) {

  .home4-service-section .sevices-wrap .single-services .content::before,
  .home4-service-section .sevices-wrap .single-services .content::after {
    display: none;
  }
}

.home4-service-section .sevices-wrap .single-services .content::after {
  left: unset;
  right: 0;
}

.home4-service-section .sevices-wrap .single-services .button-area {
  padding: 30px;
  display: flex;
  align-items: center;
}

@media (max-width: 1399px) {
  .home4-service-section .sevices-wrap .single-services .button-area {
    padding: 20px;
  }
}

@media (max-width: 991px) {
  .home4-service-section .sevices-wrap .single-services .button-area {
    padding: 30px 15px;
  }
}

@media (max-width: 767px) {
  .home4-service-section .sevices-wrap .single-services .button-area {
    padding: 0;
  }
}

.home4-service-section .sevices-wrap .single-services .button-area .explore-btn {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: rgba(var(--primary-color1-opc), 0.1);
  border-radius: 23px;
  border: 1px solid rgba(var(--title-color-opc), 0.1);
  padding: 14px 19px;
  transition: 0.5s;
}

.home4-service-section .sevices-wrap .single-services .button-area .explore-btn svg {
  fill: var(--title-color);
  transition: 0.5s;
}

.home4-service-section .sevices-wrap .single-services:hover .icon svg {
  fill: var(--primary-color1);
  animation: bounceIn 1.2s linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.home4-service-section .sevices-wrap .single-services:hover h5 a {
  color: var(--primary-color1);
}

.home4-service-section .sevices-wrap .single-services:hover h5 a span {
  color: var(--primary-color1);
}

.home4-service-section .sevices-wrap .single-services:hover .services-img {
  opacity: 1;
}

.home4-service-section .sevices-wrap .single-services:hover .explore-btn {
  color: var(--white-color);
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.home4-service-section .sevices-wrap .single-services:hover .explore-btn svg {
  fill: var(--white-color);
  transform: rotate(45deg);
}

.home4-experience-section {
  background-image: url(../img/home4/home4-experience-bg.png),
    linear-gradient(180deg, #121212 0%, #121212 100%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  padding: 120px 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home4-experience-section {
    padding: 100px 0;
  }
}

@media (max-width: 991px) {
  .home4-experience-section {
    padding: 90px 0;
  }
}

@media (max-width: 767px) {
  .home4-experience-section {
    padding: 70px 0;
  }
}

.home4-experience-section .experience-card-wrap .single-experience {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #121212;
  padding: 40px 35px;
  position: relative;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home4-experience-section .experience-card-wrap .single-experience {
    padding: 35px 20px;
  }
}

@media (max-width: 576px) {
  .home4-experience-section .experience-card-wrap .single-experience {
    padding: 30px 20px;
  }
}

.home4-experience-section .experience-card-wrap .single-experience .background-img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1;
  transition: 0.5s;
  opacity: 0;
}

.home4-experience-section .experience-card-wrap .single-experience .background-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
}

.home4-experience-section .experience-card-wrap .single-experience .background-img::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.6);
  border-radius: 10px;
  transition: 0.5s;
}

.home4-experience-section .experience-card-wrap .single-experience .content {
  position: relative;
  z-index: 2;
}

.home4-experience-section .experience-card-wrap .single-experience .content h4 {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 10px;
}

.home4-experience-section .experience-card-wrap .single-experience .content p {
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0;
}

.home4-experience-section .experience-card-wrap .single-experience .number {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
}

@media (max-width: 576px) {
  .home4-experience-section .experience-card-wrap .single-experience .number {
    top: 15px;
    right: 15px;
  }
}

.home4-experience-section .experience-card-wrap .single-experience .number span {
  font-family: var(--font-inter);
  font-size: 50px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 2px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.15);
}

@media (max-width: 576px) {
  .home4-experience-section .experience-card-wrap .single-experience .number span {
    font-size: 35px;
  }
}

.home4-experience-section .experience-card-wrap .single-experience:hover .background-img {
  opacity: 1;
}

.home4-experience-section .experience-percentage-wrap {
  max-width: 640px;
  width: 100%;
}

.home4-experience-section .experience-percentage-wrap .section-title3 span {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--white-color);
}

.home4-experience-section .experience-percentage-wrap .section-title3 h2 {
  color: var(--white-color);
}

.home4-experience-section .experience-percentage-wrap .eg-progress-wrap .eg-progress-bar-single .eg-progress-bar-title h6 {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 18px;
}

.home4-experience-section .experience-percentage-wrap .eg-progress-wrap .eg-progress-bar-single .eg-progress-bar {
  height: 3px;
  background-color: rgba(var(--primary-color1-opc), 0.5);
  border-radius: 25px;
}

.home4-experience-section .experience-percentage-wrap .eg-progress-wrap .eg-progress-bar-single .eg-progress-bar .experience-bar-per {
  height: 3px;
  background-color: var(--white-color);
  border-radius: 25px;
  width: 0;
  transition: 1s linear;
  position: relative;
}

.home4-experience-section .experience-percentage-wrap .eg-progress-wrap .eg-progress-bar-single .eg-progress-bar .experience-bar-per:before {
  content: attr(data-per);
  position: absolute;
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-hankenGrotesk);
  line-height: 1;
  top: -30px;
  right: 0;
  transform: translateX(50%);
  z-index: 1;
}

@media (max-width: 576px) {
  .home4-experience-section .experience-percentage-wrap .eg-progress-wrap .eg-progress-bar-single .eg-progress-bar .experience-bar-per:before {
    right: 10px;
  }
}

.experience-logo-area .logo-area {
  overflow: hidden;
}

.experience-logo-area .logo-area .marquee_text2 {
  background-color: #222;
  padding: 25px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.experience-logo-area .logo-area .js-marquee-wrapper {
  display: flex;
  align-items: center;
  gap: 110px;
}

@media (max-width: 1499px) {
  .experience-logo-area .logo-area .js-marquee-wrapper {
    gap: 70px;
  }
}

@media (max-width: 576px) {
  .experience-logo-area .logo-area .js-marquee-wrapper {
    gap: 50px;
  }
}

.experience-logo-area .logo-area .js-marquee-wrapper .js-marquee {
  display: flex;
  align-items: center;
  gap: 110px;
  margin-right: 0 !important;
}

@media (max-width: 1499px) {
  .experience-logo-area .logo-area .js-marquee-wrapper .js-marquee {
    gap: 70px;
  }
}

@media (max-width: 576px) {
  .experience-logo-area .logo-area .js-marquee-wrapper .js-marquee {
    gap: 50px;
  }
}

.div-tooltip-tit {
  display: none;
  position: absolute;
  z-index: 9;
  color: var(--white-color);
  font-family: var(--font-inter);
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  background-color: var(--dark-primary-color2);
  padding: 8px 20px;
}

.div-tooltip-sub {
  display: none;
  color: rgba(255, 255, 255, 0.6);
  position: absolute;
  z-index: 9;
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.26px;
  text-transform: uppercase;
  background-color: var(--dark-primary-color2);
  padding: 10px 20px;
}

.portfolio-info-flow-section {
  overflow-x: hidden !important;
}

.eg-card2 {
  position: relative;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.5s;
}

.eg-card2 .card-img {
  position: relative;
  overflow: hidden;
  display: block;
  transition: all 0.5s ease-out;
}

.eg-card2 .card-img img {
  border-radius: 10px;
  transition: all 0.5s ease-out;
}

.eg-card2 .card-img::after {
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
  z-index: 1;
}

.eg-card2 .card-content {
  background: linear-gradient(180deg,
      rgba(11, 12, 12, 0) 24.31%,
      rgba(11, 12, 12, 0.9) 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: unset;
  padding: 20px 25px;
  border-radius: 10px;
  display: flex;
  justify-content: end;
  flex-direction: column;
  z-index: 9;
  transition: 0.5s;
}

@media (max-width: 1399px) {
  .eg-card2 .card-content {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .eg-card2 .card-content {
    padding: 20px 15px;
  }
}

.eg-card2 .card-content span {
  color: rgba(255, 255, 255, 0.8);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.26px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.eg-card2 .card-content h5 {
  margin-bottom: 15px;
}

@media (max-width: 576px) {
  .eg-card2 .card-content h5 {
    margin-bottom: 10px;
  }
}

.eg-card2 .card-content h5 a {
  color: var(--white-color);
  font-family: var(--font-inter);
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
  transition: 0.5s;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .eg-card2 .card-content h5 a {
    font-size: 22px;
  }
}

@media (max-width: 576px) {
  .eg-card2 .card-content h5 a {
    font-size: 23px;
  }
}

.eg-card2 .card-content h5 a:hover {
  color: var(--primary-color1);
}

.eg-card2 .card-content .tag-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.eg-card2 .card-content .tag-list li>a {
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: 0 0;
  display: inline-block;
  color: var(--white-color);
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  padding: 5px 15px;
  line-height: 1;
  transition: 0.5s;
}

.eg-card2 .card-content .tag-list li>a:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.eg-card2.two .card-img img {
  min-height: 431px;
  -o-object-fit: cover;
  object-fit: cover;
}

.eg-card2:hover .card-img img {
  transform: scale(1.1);
}

.eg-card2:hover .card-img::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}

.eg-card2.style-2 .card-img {
  border-radius: 5px;
  height: 100%;
}

.eg-card2.style-2 .card-img img {
  border-radius: 5px;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.eg-card2.style-2 .card-content {
  position: relative;
  background: unset;
  justify-content: center;
  padding-left: 60px;
}

@media (max-width: 1399px) {
  .eg-card2.style-2 .card-content {
    padding: 20px 20px 20px 40px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .eg-card2.style-2 .card-content {
    padding: 20px 20px 20px 30px;
  }
}

@media (max-width: 991px) {
  .eg-card2.style-2 .card-content {
    padding: 25px 0 0;
  }
}

.eg-card2.style-2 .card-content span {
  color: rgba(var(--title-color-opc), 0.6);
}

.eg-card2.style-2 .card-content h5 {
  margin-bottom: 20px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .eg-card2.style-2 .card-content h5 {
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  .eg-card2.style-2 .card-content h5 {
    margin-bottom: 15px;
  }
}

.eg-card2.style-2 .card-content h5 a {
  color: var(--title-color);
  font-size: 40px;
  font-weight: 800;
}

@media (max-width: 1199px) {
  .eg-card2.style-2 .card-content h5 a {
    font-size: 32px;
  }
}

@media (max-width: 576px) {
  .eg-card2.style-2 .card-content h5 a {
    font-size: 25px;
  }
}

.eg-card2.style-2 .card-content h5 a:hover {
  color: var(--primary-color1);
}

.eg-card2.style-2 .card-content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 45px;
}

@media (max-width: 1199px) {
  .eg-card2.style-2 .card-content p {
    margin-bottom: 30px;
    font-size: 15px;
  }
}

.eg-card2.style-2 .card-content .tag-list li>a {
  border: 1px solid rgba(var(--primary-color2-opc), 0.4);
  color: var(--title-color);
}

.eg-card2.style-2 .card-content .tag-list li>a:hover {
  color: var(--white-color);
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.eg-card2.style-3 {
  overflow: hidden;
}

.eg-card2.style-3 .card-content-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.home4-portfolio-section .pt--40 {
  padding-top: 40px;
}

@media (max-width: 991px) {
  .home4-portfolio-section .pt--40 {
    padding-top: 0;
  }
}

.feature-card4 {
  background-color: var(--dark-white-color);
  border-radius: 10px;
  padding: 25px 20px;
}

.feature-card4 .card-top-area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .feature-card4 .card-top-area {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .feature-card4 .card-top-area {
    margin-bottom: 20px;
  }
}

.feature-card4 .card-top-area .number span {
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 1.8px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgba(var(--primary-color1-opc), 0.3);
}

.feature-card4 .content h4 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .feature-card4 .content h4 {
    font-size: 23px;
  }
}

@media (max-width: 576px) {
  .feature-card4 .content h4 {
    font-size: 22px;
  }
}

.feature-card4 .content ul {
  padding-top: 20px;
}

.feature-card4 .content ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.3px;
  border-bottom: 1px solid rgba(var(--text-color-opc), 0.1);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.feature-card4 .content ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: unset;
}

.feature-card4 .content ul li svg {
  fill: var(--text-color);
  min-width: 15px;
  max-width: 15px;
}

.feature-card4:hover .icon {
  animation: zoomIn 0.7s linear;
}

.feature-card4.arrow1 {
  position: relative;
}

.feature-card4.arrow1::before {
  content: url(../img/home4/vector/process-arrow1.svg);
  position: absolute;
  top: 20%;
  right: -12%;
  z-index: 9;
}

@media (max-width: 1199px) {
  .feature-card4.arrow1::before {
    display: none;
  }
}

.feature-card4.arrow2 {
  position: relative;
}

.feature-card4.arrow2::before {
  content: url(../img/home4/vector/process-arrow2.svg);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -12%;
  z-index: 9;
}

@media (max-width: 1199px) {
  .feature-card4.arrow2::before {
    display: none;
  }
}

.feature-card4.arrow3 {
  position: relative;
}

.feature-card4.arrow3::before {
  content: url(../img/home4/vector/process-arrow3.svg);
  position: absolute;
  bottom: 35%;
  right: -12%;
  z-index: 9;
}

@media (max-width: 1199px) {
  .feature-card4.arrow3::before {
    display: none;
  }
}

.home4-process-section .process-bottom-area .process-bottom-title {
  margin-bottom: 50px;
}

@media (max-width: 1199px) {
  .home4-process-section .process-bottom-area .process-bottom-title {
    margin-bottom: 30px;
  }
}

.home4-process-section .process-bottom-area .process-bottom-title h2 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 58px;
  font-weight: 800;
  line-height: 1.3;
  margin-bottom: 0;
}

@media (max-width: 1399px) {
  .home4-process-section .process-bottom-area .process-bottom-title h2 {
    font-size: 50px;
  }
}

@media (max-width: 1199px) {
  .home4-process-section .process-bottom-area .process-bottom-title h2 {
    font-size: 38px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .home4-process-section .process-bottom-area .process-bottom-title h2 {
    font-size: 32px;
  }
}

@media (max-width: 576px) {
  .home4-process-section .process-bottom-area .process-bottom-title h2 {
    font-size: 28px;
  }
}

.home4-process-section .process-bottom-area .process-bottom-title h2 span {
  color: var(--text-color);
  font-weight: 500;
}

.home4-process-section .process-bottom-area .process-bottom-content-and-img p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 35px;
}

.home4-process-section .process-bottom-area .process-bottom-content-and-img img {
  border-radius: 5px;
}

.home4-contact-section {
  background-image: url(../img/home4/home4-section-bg.png),
    linear-gradient(180deg, #121212 0%, #121212 100%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  padding: 100px 0;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
}

@media (max-width: 1199px) {
  .home4-contact-section {
    padding: 90px 0;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .home4-contact-section {
    padding: 70px 0;
  }
}

@media (max-width: 576px) {
  .home4-contact-section {
    margin-left: 0;
    margin-right: 0;
    border-radius: unset;
  }
}

.home4-contact-section .section-title3 {
  max-width: 850px;
  width: 100%;
}

@media (max-width: 1199px) {
  .home4-contact-section .section-title3 {
    max-width: 650px;
  }
}

.home4-contact-section .section-title3>span {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--white-color);
}

.home4-contact-section .section-title3 h2 {
  color: var(--white-color);
  font-size: 60px;
}

@media (max-width: 1399px) {
  .home4-contact-section .section-title3 h2 {
    font-size: 50px;
  }
}

@media (max-width: 1199px) {
  .home4-contact-section .section-title3 h2 {
    font-size: 45px;
  }
}

@media (max-width: 767px) {
  .home4-contact-section .section-title3 h2 {
    font-size: 38px;
  }
}

.home4-contact-section .section-title3 h2 span {
  color: rgba(247, 246, 241, 0.6);
  font-weight: 500;
}

.home4-contact-section .button-area {
  width: 248px;
  height: 248px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(30px);
  transition: 0.8s;
}

@media (max-width: 991px) {
  .home4-contact-section .button-area {
    transform: translateY(0px);
  }
}

@media (max-width: 767px) {
  .home4-contact-section .button-area {
    width: 220px;
    height: 220px;
  }
}

.home4-contact-section .button-area .details-button {
  background-image: linear-gradient(0deg, #fff 0%, #fff 100%);
  color: var(--white-color);
}

.home4-contact-section .button-area .details-button::after {
  color: var(--white-color);
}

.home4-contact-section .button-area .details-button svg {
  stroke: var(--white-color);
}

.home4-contact-section .button-area:hover {
  box-shadow: inset 0 0 0 10em var(--primary-color1);
}

.home4-contact-section .button-area:hover .details-button {
  --background-size: 0%;
  --stroke-dashoffset: 26;
  --stroke-duration: 0.3s;
  --stroke-easing: cubic-bezier(0.3, 1.5, 0.5, 1);
  --stroke-delay: 0.195s;
}

.home4-contact-section .button-area:hover .details-button::after {
  opacity: 0;
  transition-delay: 0s;
}

.home4-contact-section .marquee-area {
  padding-top: 60px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .home4-contact-section .marquee-area {
    padding-top: 0;
  }
}

.home4-contact-section .marquee-area .marquee_text {
  display: flex;
  align-items: center;
}

.home4-contact-section .marquee-area .js-marquee {
  display: flex;
  align-items: center;
  gap: 25px;
}

.home4-contact-section .marquee-area h6 {
  color: rgba(255, 255, 255, 0.5);
  font-family: var(--font-hankenGrotesk);
  font-size: 35px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.home4-contact-section .marquee-area svg {
  fill: var(--primary-color1);
}

.home4-award-section {
  position: relative;
  padding: 0 15%;
}

@media (max-width: 1799px) {
  .home4-award-section {
    padding: 0 11%;
  }
}

@media (max-width: 1699px) {
  .home4-award-section {
    padding: 0 9%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-award-section {
    padding: 0 3%;
  }
}

@media (max-width: 1399px) {
  .home4-award-section {
    padding: 0 2.5%;
  }
}

@media (max-width: 1199px) {
  .home4-award-section {
    padding: 0 20px;
  }
}

@media (max-width: 576px) {
  .home4-award-section {
    padding: 0;
  }
}

.home4-award-section .section-title-wrap {
  position: sticky;
  top: 100px;
}

.home4-award-section .section-title-wrap .vector {
  position: absolute;
  top: 0;
  right: -37px;
}

@media (max-width: 991px) {
  .home4-award-section .section-title-wrap .vector {
    display: none;
  }
}

.home4-award-section .section-title-wrap .vector svg {
  fill: var(--text-color);
}

.home4-award-section .section-title-wrap .section-title3 h2 {
  font-size: 58px;
}

@media (max-width: 1399px) {
  .home4-award-section .section-title-wrap .section-title3 h2 {
    font-size: 50px;
  }
}

@media (max-width: 1199px) {
  .home4-award-section .section-title-wrap .section-title3 h2 {
    font-size: 42px;
  }
}

@media (max-width: 767px) {
  .home4-award-section .section-title-wrap .section-title3 h2 {
    font-size: 38px;
  }
}

.home4-award-section .section-title-wrap .section-title3 h2 span {
  color: var(--text-color);
  font-weight: 500;
}

.home4-award-section .sevices-wrap2 {
  position: relative;
  /* overflow: hidden; */
}

.home4-award-section .sevices-wrap2::-webkit-scrollbar {
  width: 0;
}

.home4-award-section .sevices-wrap2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: rgba(var(--title-color-opc), 0.05);
}

@media (max-width: 991px) {
  .home4-award-section .sevices-wrap2::before {
    display: none;
  }
}

.home4-award-section .sevices-wrap2 .single-services {
  transition: 0.5s;
  border-bottom: none;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  padding: 45px 0 45px 30px;
  border-bottom: 1px solid rgba(var(--title-color-opc), 0.05);
}

@media (max-width: 991px) {
  .home4-award-section .sevices-wrap2 .single-services {
    border: 1px solid rgba(var(--title-color-opc), 0.05);
    border-bottom: none;
    padding: 35px 25px;
  }
}

@media (max-width: 767px) {
  .home4-award-section .sevices-wrap2 .single-services {
    flex-direction: column;
    align-items: start;
  }
}

@media (max-width: 576px) {
  .home4-award-section .sevices-wrap2 .single-services {
    padding: 30px 20px;
  }
}

.home4-award-section .sevices-wrap2 .single-services:last-child {
  border-bottom: unset;
}

@media (max-width: 991px) {
  .home4-award-section .sevices-wrap2 .single-services:last-child {
    border-bottom: 1px solid rgba(var(--title-color-opc), 0.05);
  }
}

.home4-award-section .sevices-wrap2 .single-services .services-img {
  width: 245px;
  height: 303px;
  border-radius: 200px 200px 5px 5px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
  pointer-events: none;
}

@media (max-width: 767px) {
  .home4-award-section .sevices-wrap2 .single-services .services-img {
    display: none;
    visibility: hidden;
  }
}

.home4-award-section .sevices-wrap2 .single-services .content span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  margin-bottom: 10px;
}

.home4-award-section .sevices-wrap2 .single-services .content h5 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 35px;
  font-weight: 700;
  line-height: 1.2;
  transition: 0.5s;
  margin-bottom: 10px;
}

@media (max-width: 576px) {
  .home4-award-section .sevices-wrap2 .single-services .content h5 {
    font-size: 30px;
  }
}

.home4-award-section .sevices-wrap2 .single-services .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0;
}

.home4-award-section .sevices-wrap2 .single-services .explore-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba(var(--title-color-opc), 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.home4-award-section .sevices-wrap2 .single-services .explore-btn svg {
  fill: rgba(var(--title-color-opc), 0.3);
  transition: 0.5s;
}

.home4-award-section .sevices-wrap2 .single-services:hover .services-img {
  opacity: 1;
}

.home4-award-section .sevices-wrap2 .single-services:hover .explore-btn {
  background-color: var(--title-color);
}

.home4-award-section .sevices-wrap2 .single-services:hover .explore-btn svg {
  fill: var(--dark-white-color);
  transform: rotate(45deg);
}

.teams-card {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgba(18, 18, 18, 0.1);
  background: var(--dark-white-color);
}

.teams-card .card-img-wrap {
  -webkit-mask-image: url(../img/home4/home4-teams-card-bg.png);
  mask-image: url(../img/home4/home4-teams-card-bg.png);
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: cover;
  display: block;
  position: relative;
}

.teams-card .card-img-wrap .card-img {
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-out;
}

.teams-card .card-img-wrap .card-img::after {
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
}

.teams-card .card-img-wrap .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg,
      rgba(11, 12, 12, 0),
      rgba(11, 12, 12, 0.85));
  opacity: 0;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: 0.5s;
}

.teams-card .card-img-wrap .overlay .social-area {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}

.teams-card .card-img-wrap .overlay .social-area .social-list {
  position: relative;
  width: 100%;
  height: 60px;
  margin: 0 auto;
}

.teams-card .card-img-wrap .overlay .social-area .social-list span,
.teams-card .card-img-wrap .overlay .social-area .social-list a {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 135px;
  font-size: 25px;
  line-height: 214px;
  text-align: center;
  transform-origin: top center;
}

.teams-card .card-img-wrap .overlay .social-area .social-list span:nth-child(1),
.teams-card .card-img-wrap .overlay .social-area .social-list a:nth-child(1) {
  transform: rotate(50deg);
}

.teams-card .card-img-wrap .overlay .social-area .social-list span:nth-child(2),
.teams-card .card-img-wrap .overlay .social-area .social-list a:nth-child(2) {
  transform: rotate(38deg);
}

.teams-card .card-img-wrap .overlay .social-area .social-list span:nth-child(3),
.teams-card .card-img-wrap .overlay .social-area .social-list a:nth-child(3) {
  transform: rotate(20deg);
}

.teams-card .card-img-wrap .overlay .social-area .social-list span:nth-child(4),
.teams-card .card-img-wrap .overlay .social-area .social-list a:nth-child(4) {
  transform: rotate(3deg);
}

.teams-card .card-img-wrap .overlay .social-area .social-list span:nth-child(5),
.teams-card .card-img-wrap .overlay .social-area .social-list a:nth-child(5) {
  transform: rotate(-15deg);
}

.teams-card .card-img-wrap .overlay .social-area .social-list span:nth-child(6),
.teams-card .card-img-wrap .overlay .social-area .social-list a:nth-child(6) {
  transform: rotate(-26deg);
}

.teams-card .card-img-wrap .overlay .social-area .social-list span:nth-child(7),
.teams-card .card-img-wrap .overlay .social-area .social-list a:nth-child(7) {
  transform: rotate(-45deg);
}

.teams-card .card-img-wrap .overlay .social-area .social-list a i {
  color: var(--white-color);
  font-size: 18px;
  transition: 0.5s;
}

.teams-card .card-img-wrap .overlay .social-area .social-list a:hover i {
  color: var(--primary-color1);
}

.teams-card .card-img-wrap .overlay .social-area .social-list span i {
  color: rgba(255, 255, 255, 0.5);
}

.teams-card .card-content {
  padding-top: 20px;
  text-align: center;
  line-height: 1;
}

.teams-card .card-content h4 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
  transition: 0.5s;
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .teams-card .card-content h4 {
    font-size: 22px;
  }
}

.teams-card .card-content span {
  color: rgba(var(--title-color-opc), 0.5);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.teams-card:hover .card-img-wrap .card-img::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}

.teams-card:hover .card-img-wrap .overlay {
  opacity: 1;
  transform: scaleY(1);
}

.home4-testimonial-section {
  background-image: url(../img/home4/home4-section-bg.png),
    linear-gradient(180deg, #121212 0%, #121212 100%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  padding: 120px 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home4-testimonial-section {
    padding: 100px 0;
  }
}

@media (max-width: 991px) {
  .home4-testimonial-section {
    padding: 90px 0;
  }
}

@media (max-width: 767px) {
  .home4-testimonial-section {
    padding: 70px 0;
  }
}

.home4-testimonial-section .section-title-area .section-title3 {
  max-width: 850px;
  width: 100%;
}

@media (max-width: 1199px) {
  .home4-testimonial-section .section-title-area .section-title3 {
    max-width: 650px;
  }
}

.home4-testimonial-section .section-title-area .section-title3>span {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--white-color);
}

.home4-testimonial-section .section-title-area .section-title3 h2 {
  color: var(--white-color);
  font-size: 60px;
}

@media (max-width: 1399px) {
  .home4-testimonial-section .section-title-area .section-title3 h2 {
    font-size: 50px;
  }
}

@media (max-width: 1199px) {
  .home4-testimonial-section .section-title-area .section-title3 h2 {
    font-size: 45px;
  }
}

@media (max-width: 767px) {
  .home4-testimonial-section .section-title-area .section-title3 h2 {
    font-size: 38px;
  }
}

.home4-testimonial-section .section-title-area .section-title3 h2 span {
  color: rgba(247, 246, 241, 0.6);
  font-weight: 500;
}

.home4-testimonial-section .section-title-area .button-area {
  position: relative;
  bottom: unset;
  left: unset;
}

@media (max-width: 767px) {
  .home4-testimonial-section .section-title-area .button-area {
    width: unset;
    height: unset;
    border: unset;
  }

  .home4-testimonial-section .section-title-area .button-area:hover {
    box-shadow: none;
  }
}

.home4-testimonial-section .testimonial-wrap {
  position: relative;
}

.home4-testimonial-section .testimonial-wrap .swiper-slide-active p {
  animation: fadeInDown 1.7s;
}

.home4-testimonial-section .testimonial-wrap .swiper-slide-active span {
  animation: fadeInDown 1.7s;
}

.home4-testimonial-section .testimonial-wrap .swiper-slide-active .author-name-desig {
  animation: fadeInDown 1.7s;
}

.home4-testimonial-section .testimonial-wrap .testimonial-card2 {
  display: flex;
  align-items: center;
  gap: 50px;
}

@media (max-width: 1399px) {
  .home4-testimonial-section .testimonial-wrap .testimonial-card2 {
    gap: 30px;
  }
}

@media (max-width: 991px) {
  .home4-testimonial-section .testimonial-wrap .testimonial-card2 {
    gap: 20px;
  }
}

@media (max-width: 767px) {
  .home4-testimonial-section .testimonial-wrap .testimonial-card2 {
    flex-direction: column;
    align-items: flex-start;
  }
}

.home4-testimonial-section .testimonial-wrap .testimonial-card2 .author-img {
  position: relative;
  padding-left: 80px;
}

@media (max-width: 991px) {
  .home4-testimonial-section .testimonial-wrap .testimonial-card2 .author-img {
    padding-left: 0;
  }
}

.home4-testimonial-section .testimonial-wrap .testimonial-card2 .author-img img {
  height: 150px;
  min-width: 150px;
  max-width: 150px;
  border-radius: 50%;
}

.home4-testimonial-section .testimonial-wrap .testimonial-card2 .author-img::before {
  content: url(../img/home4/vector/testi-quote.png);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

@media (max-width: 991px) {
  .home4-testimonial-section .testimonial-wrap .testimonial-card2 .author-img::before {
    display: none;
  }
}

.home4-testimonial-section .testimonial-wrap .testimonial-card2 .content>span {
  color: var(--primary-color1);
  font-family: var(--font-hankenGrotesk);
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
}

.home4-testimonial-section .testimonial-wrap .testimonial-card2 .content p {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--font-inter);
  font-size: 22px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 35px;
}

@media (max-width: 767px) {
  .home4-testimonial-section .testimonial-wrap .testimonial-card2 .content p {
    font-size: 20px;
    margin-bottom: 25px;
  }
}

@media (max-width: 576px) {
  .home4-testimonial-section .testimonial-wrap .testimonial-card2 .content p {
    font-size: 18px;
  }
}

.home4-testimonial-section .testimonial-wrap .testimonial-card2 .content .author-name-desig {
  line-height: 1;
}

.home4-testimonial-section .testimonial-wrap .testimonial-card2 .content .author-name-desig h6 {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 7px;
}

.home4-testimonial-section .testimonial-wrap .testimonial-card2 .content .author-name-desig span {
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
}

.home4-testimonial-section .testimonial-wrap .slider-btn-area {
  position: absolute;
  bottom: 10px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  max-width: 650px;
  width: 100%;
  z-index: 9;
}

@media (max-width: 1399px) {
  .home4-testimonial-section .testimonial-wrap .slider-btn-area {
    max-width: 370px;
    gap: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home4-testimonial-section .testimonial-wrap .slider-btn-area {
    max-width: 450px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .home4-testimonial-section .testimonial-wrap .slider-btn-area {
    bottom: 50px;
  }
}

@media (max-width: 576px) {
  .home4-testimonial-section .testimonial-wrap .slider-btn-area {
    justify-content: end;
  }
}

.home4-testimonial-section .testimonial-wrap .slider-btn-area .dash {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 576px) {
  .home4-testimonial-section .testimonial-wrap .slider-btn-area .dash {
    display: none;
  }
}

.home4-testimonial-section .testimonial-wrap .slider-btn-area .slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home4-testimonial-section .testimonial-wrap .slider-btn-area .slider-btn-group.w-100 {
  max-width: 100px;
  min-width: 100px;
  width: 100%;
}

.home4-testimonial-section .button-area {
  position: absolute;
  bottom: 60px;
  left: 30px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.8s;
}

@media (max-width: 1699px) {
  .home4-testimonial-section .button-area {
    bottom: 30px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-testimonial-section .button-area {
    width: 180px;
    height: 180px;
    left: 10px;
    bottom: 10px;
  }
}

@media (max-width: 1399px) {
  .home4-testimonial-section .button-area {
    left: 15px;
    bottom: 20px;
  }
}

@media (max-width: 1199px) {
  .home4-testimonial-section .button-area {
    display: none;
  }
}

.home4-testimonial-section .button-area .details-button {
  background-image: linear-gradient(0deg, #fff 0%, #fff 100%);
  color: var(--white-color);
  font-size: 15px;
}

.home4-testimonial-section .button-area .details-button::after {
  color: var(--white-color);
}

.home4-testimonial-section .button-area .details-button svg {
  stroke: var(--white-color);
}

.home4-testimonial-section .button-area:hover {
  box-shadow: inset 0 0 0 10em var(--primary-color1);
}

.home4-testimonial-section .button-area:hover .details-button {
  --background-size: 0%;
  --stroke-dashoffset: 26;
  --stroke-duration: 0.3s;
  --stroke-easing: cubic-bezier(0.3, 1.5, 0.5, 1);
  --stroke-delay: 0.195s;
}

.home4-testimonial-section .button-area:hover .details-button::after {
  opacity: 0;
  transition-delay: 0s;
}

.home4-testimonial-section::before {
  content: url(../img/home4/vector/home4-testi-section-arrow.svg);
  position: absolute;
  top: 100px;
  right: 100px;
}

@media (max-width: 1399px) {
  .home4-testimonial-section::before {
    top: 80px;
    right: 60px;
  }
}

@media (max-width: 1199px) {
  .home4-testimonial-section::before {
    display: none;
  }
}

.blog-card2 .blog-card-img-wrap {
  position: relative;
  overflow: hidden;
}

.blog-card2 .blog-card-img-wrap .card-img {
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 10px;
  transition: all 0.5s ease-out;
}

.blog-card2 .blog-card-img-wrap .card-img img {
  min-height: 230px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.5s ease-out;
}

.blog-card2 .blog-card-img-wrap .card-img::after {
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
  z-index: 1;
}

.blog-card2 .blog-card-img-wrap .date {
  position: absolute;
  top: 6px;
  left: 6px;
  text-align: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: rgba(11, 12, 12, 0.4);
  -webkit-backdrop-filter: blur(22.5px);
  backdrop-filter: blur(22.5px);
  transition: 0.5s;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 576px) {
  .blog-card2 .blog-card-img-wrap .date {
    width: 65px;
    height: 65px;
  }
}

.blog-card2 .blog-card-img-wrap .date span {
  color: var(--white-color);
  text-align: center;
  font-family: var(--font-inter);
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.22px;
  display: block;
}

.blog-card2 .blog-card-img-wrap .date span strong {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--font-hankenGrotesk);
  margin-bottom: 5px;
  display: block;
}

@media (max-width: 576px) {
  .blog-card2 .blog-card-img-wrap .date span strong {
    font-size: 20px;
  }
}

.blog-card2 .blog-card-content-wrap {
  padding-left: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .blog-card2 .blog-card-content-wrap {
    padding-left: 0;
    padding-top: 25px;
  }
}

@media (max-width: 767px) {
  .blog-card2 .blog-card-content-wrap {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

.blog-card2 .blog-card-content-wrap .blog-card-content {
  max-width: 380px;
  width: 100%;
}

@media (max-width: 767px) {
  .blog-card2 .blog-card-content-wrap .blog-card-content {
    max-width: unset;
  }
}

.blog-card2 .blog-card-content-wrap .blog-card-content .blog-meta {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.blog-card2 .blog-card-content-wrap .blog-card-content .blog-meta .category {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.blog-card2 .blog-card-content-wrap .blog-card-content .blog-meta .category li {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  position: relative;
}

.blog-card2 .blog-card-content-wrap .blog-card-content .blog-meta .category li a {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;
  transition: 0.5s;
}

.blog-card2 .blog-card-content-wrap .blog-card-content .blog-meta .category li a:hover {
  color: var(--primary-color1);
}

.blog-card2 .blog-card-content-wrap .blog-card-content .blog-meta .blog-comment {
  position: relative;
  padding-left: 20px;
  line-height: 1;
}

.blog-card2 .blog-card-content-wrap .blog-card-content .blog-meta .blog-comment::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  background-color: rgba(var(--title-color-opc), 0.2);
  width: 1px;
  height: 12px;
}

.blog-card2 .blog-card-content-wrap .blog-card-content .blog-meta .blog-comment span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  position: relative;
}

.blog-card2 .blog-card-content-wrap .blog-card-content h4 {
  margin-bottom: 15px;
}

@media (max-width: 576px) {
  .blog-card2 .blog-card-content-wrap .blog-card-content h4 {
    margin-bottom: 10px;
  }
}

.blog-card2 .blog-card-content-wrap .blog-card-content h4 a {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 27px;
  font-weight: 600;
  line-height: 1.4;
  transition: 0.5s;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .blog-card2 .blog-card-content-wrap .blog-card-content h4 a {
    font-size: 24px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card2 .blog-card-content-wrap .blog-card-content h4 a {
    font-size: 22px;
  }
}

@media (max-width: 576px) {
  .blog-card2 .blog-card-content-wrap .blog-card-content h4 a {
    font-size: 22px;
  }
}

.blog-card2 .blog-card-content-wrap .blog-card-content h4 a:hover {
  color: var(--primary-color1);
}

.blog-card2 .blog-card-content-wrap .blog-card-content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0;
}

.blog-card2 .blog-card-content-wrap .button-area .read-more-btn {
  min-width: 120px;
  max-width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(var(--title-color-opc), 0.1);
  transition: 0.8s;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  transition: 0.8s;
}

.blog-card2 .blog-card-content-wrap .button-area .read-more-btn svg {
  fill: var(--text-color);
  transition: 0.5s;
}

.blog-card2 .blog-card-content-wrap .button-area .read-more-btn:hover {
  box-shadow: inset 0 0 0 10em var(--primary-color1);
  color: var(--white-color);
}

.blog-card2 .blog-card-content-wrap .button-area .read-more-btn:hover svg {
  transform: rotate(45deg);
  fill: var(--white-color);
}

.blog-card2:hover .blog-card-img-wrap .card-img img {
  transform: scale(1.1);
}

.blog-card2:hover .blog-card-img-wrap .card-img::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}

.blog-card2:hover .blog-card-img-wrap .date {
  background-color: var(--primary-color1);
}

.blog-card2.border--top {
  padding: 35px 0;
  border-top: 1px solid rgba(var(--title-color-opc), 0.1);
}

@media (max-width: 767px) {
  .blog-card2.border--top {
    padding: 25px 0;
  }
}

.blog-card2.last-item {
  padding: 35px 0;
  border-top: 1px solid rgba(var(--title-color-opc), 0.1);
  border-bottom: 1px solid rgba(var(--title-color-opc), 0.1);
}

@media (max-width: 767px) {
  .blog-card2.last-item {
    padding: 25px 0;
  }
}

.blog-card2.style-2 .blog-card-img-wrap .card-img {
  border-radius: 5px;
}

.blog-card2.style-2 .blog-card-img-wrap .card-img img {
  min-height: 270px;
  border-radius: 5px;
}

.blog-card2.style-2 .blog-card-content-wrap {
  display: block;
  padding-left: 60px;
}

@media (max-width: 1399px) {
  .blog-card2.style-2 .blog-card-content-wrap {
    padding-left: 45px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card2.style-2 .blog-card-content-wrap {
    padding-left: 30px;
  }
}

@media (max-width: 991px) {
  .blog-card2.style-2 .blog-card-content-wrap {
    padding-left: 0;
    padding-top: 25px;
  }
}

.blog-card2.style-2 .blog-card-content-wrap .blog-card-content {
  max-width: 550px;
  margin-bottom: 45px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card2.style-2 .blog-card-content-wrap .blog-card-content {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .blog-card2.style-2 .blog-card-content-wrap .blog-card-content {
    max-width: unset;
    margin-bottom: 25px;
  }
}

@media (max-width: 576px) {
  .blog-card2.style-2 .blog-card-content-wrap .blog-card-content {
    margin-bottom: 20px;
  }
}

.blog-card2.style-2 .blog-card-content-wrap .button-area .read-more-btn {
  min-width: unset;
  max-width: unset;
  height: unset;
  border-radius: unset;
  border: unset;
}

.blog-card2.style-2 .blog-card-content-wrap .button-area .read-more-btn svg {
  fill: var(--text-color);
  transition: 0.5s;
}

.blog-card2.style-2 .blog-card-content-wrap .button-area .read-more-btn:hover {
  box-shadow: none;
  color: var(--primary-color1);
}

.blog-card2.style-2 .blog-card-content-wrap .button-area .read-more-btn:hover svg {
  transform: rotate(45deg);
  fill: var(--primary-color1);
}

.blog-card2.style-3 .blog-card-img-wrap .card-img {
  border-radius: 5px;
}

.blog-card2.style-3 .blog-card-img-wrap .card-img img {
  border-radius: 5px;
}

.blog-card2.style-3 .blog-card-content-wrap {
  display: block;
  padding-left: 40px;
  padding-top: 35px;
}

@media (max-width: 1399px) {
  .blog-card2.style-3 .blog-card-content-wrap {
    padding-left: 35px;
  }
}

@media (max-width: 1199px) {
  .blog-card2.style-3 .blog-card-content-wrap {
    padding-left: 25px;
  }
}

@media (max-width: 991px) {
  .blog-card2.style-3 .blog-card-content-wrap {
    padding-left: 0;
    padding-top: 30px;
  }
}

.blog-card2.style-3 .blog-card-content-wrap .blog-card-content {
  max-width: unset;
  margin-bottom: 30px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card2.style-3 .blog-card-content-wrap .blog-card-content {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .blog-card2.style-3 .blog-card-content-wrap .blog-card-content {
    max-width: unset;
    margin-bottom: 25px;
  }
}

@media (max-width: 576px) {
  .blog-card2.style-3 .blog-card-content-wrap .blog-card-content {
    margin-bottom: 20px;
  }
}

.blog-card2.style-3 .blog-card-content-wrap .blog-card-content h4 a {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
}

@media (max-width: 1399px) {
  .blog-card2.style-3 .blog-card-content-wrap .blog-card-content h4 a {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .blog-card2.style-3 .blog-card-content-wrap .blog-card-content h4 a {
    font-size: 30px;
  }
}

@media (max-width: 576px) {
  .blog-card2.style-3 .blog-card-content-wrap .blog-card-content h4 a {
    font-size: 28px;
  }
}

.blog-card2.style-3 .blog-card-content-wrap .star-btn a {
  width: 136px;
  height: 90px;
}

.blog-card2.style-3 .blog-card-content-wrap .star-btn a .details-button {
  font-size: 15px;
}

.home4-blog-section .section-title3 {
  max-width: 760px;
  width: 100%;
}

.home4-blog-section .section-title3 h2 {
  font-size: 60px;
}

@media (max-width: 1399px) {
  .home4-blog-section .section-title3 h2 {
    font-size: 50px;
  }
}

@media (max-width: 1199px) {
  .home4-blog-section .section-title3 h2 {
    font-size: 45px;
  }
}

@media (max-width: 767px) {
  .home4-blog-section .section-title3 h2 {
    font-size: 38px;
  }
}

.home4-blog-section .section-title3 h2 span {
  color: var(--text-color);
  font-weight: 500;
}

.home4-footer-top-section {
  overflow: hidden;
}

@media (max-width: 576px) {
  .home4-footer-top-section {
    display: none;
    visibility: hidden;
  }
}

.home4-footer-top-section .content {
  background: linear-gradient(180deg,
      rgba(48, 103, 255, 0.08) 0%,
      rgba(48, 103, 255, 0.1) 100%);
  padding: 55px 0 55px 100px;
  display: flex;
  gap: 35px;
  position: relative;
}

@media (max-width: 1499px) {
  .home4-footer-top-section .content {
    padding: 45px 0 45px 30px;
  }
}

.home4-footer-top-section .content h2 {
  margin-bottom: 0;
}

.home4-footer-top-section .content h2 a {
  color: rgba(var(--text-color-opc), 0.5);
  font-family: var(--font-hankenGrotesk);
  font-size: 110px;
  font-weight: 400;
  line-height: 1;
  text-decoration: underline;
  transition: 0.5s;
}

.home4-footer-top-section .content .sm-arrow {
  fill: rgba(var(--text-color-opc), 0.5);
  transition: 0.5s;
}

@media (max-width: 1399px) {
  .home4-footer-top-section .content .sm-arrow {
    display: none;
  }
}

.home4-footer-top-section .content .arrow {
  position: absolute;
  top: 20px;
  right: 20px;
}

.home4-footer-top-section .content .arrow svg {
  fill: rgba(var(--title-color-opc), 0.05);
  width: 202px;
  height: 204px;
}

.home4-footer-top-section .content:hover h2 a {
  color: rgba(var(--title-color-opc), 0.7);
}

.home4-footer-top-section .content:hover .sm-arrow {
  fill: rgba(var(--title-color-opc), 0.7);
}

.home4-footer-section {
  overflow: hidden;
}

.home4-footer-section .home4-footer-wrap {
  background-color: #121212;
}

.home4-footer-section .home4-footer-wrap .footer-top {
  padding: 110px 100px;
}

@media (max-width: 1699px) {
  .home4-footer-section .home4-footer-wrap .footer-top {
    padding: 110px 70px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-footer-section .home4-footer-wrap .footer-top {
    padding: 110px 30px;
  }
}

@media (max-width: 1399px) {
  .home4-footer-section .home4-footer-wrap .footer-top {
    padding: 90px 20px;
  }
}

@media (max-width: 767px) {
  .home4-footer-section .home4-footer-wrap .footer-top {
    padding: 70px 20px;
  }
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .widget-title {
  margin-bottom: 25px;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .widget-title h4 {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 20px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  margin-bottom: 0;
  position: relative;
  padding-bottom: 10px;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .widget-title h4::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 107px;
  height: 2px;
  border-radius: 10px;
  background: linear-gradient(90deg,
      #0b0c0c 0.01%,
      #fff 0.02%,
      rgba(255, 255, 255, 0) 102.8%);
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget h2 {
  margin-bottom: 0;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget h2 a {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 50px;
  font-weight: 600;
  line-height: 1.2;
  text-decoration-line: underline;
  transition: 0.5s;
}

@media (max-width: 1699px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget h2 a {
    font-size: 45px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget h2 a {
    font-size: 40px;
  }
}

@media (max-width: 1399px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget h2 a {
    font-size: 35px;
  }
}

@media (max-width: 1199px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget h2 a {
    font-size: 28px;
  }
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget h2 a:hover {
  color: var(--primary-color1);
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .content p {
  color: var(--white-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  padding-top: 20px;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget .content p {
    font-size: 15px;
    padding-top: 15px;
  }
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container {
  display: flex;
  gap: 30px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container {
    gap: 20px;
  }
}

@media (max-width: 1199px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container {
    gap: 15px;
  }
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li {
  margin-bottom: 20px;
  line-height: 1;
  transition: 0.5s;
  position: relative;
  transform: translateX(0);
}

@media (max-width: 1199px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li {
    margin-bottom: 15px;
  }
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li:last-child {
  margin-bottom: 0;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li a {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--font-hankenGrotesk);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.34px;
  text-transform: uppercase;
  transition: 0.5s;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li a {
    font-size: 15px;
  }
}

@media (max-width: 1199px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li a {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li a {
    font-size: 14px;
  }
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li a:hover {
  color: var(--primary-color1);
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li::after {
  content: "";
  width: 10px;
  height: 1px;
  background-color: var(--primary-color1);
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.35s;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li:hover {
  transform: translateX(10px);
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li:hover::after {
  opacity: 1;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-logo {
  margin-bottom: 35px;
}

@media (max-width: 576px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-logo {
    margin-bottom: 30px;
  }
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-logo .logo-dark {
  display: block;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-logo .logo-light {
  display: none;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .social-area {
  padding-top: 50px;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .social-area h6 {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  text-decoration: underline;
  margin-bottom: 20px;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .social-area .social-list {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .social-area .social-list li {
  display: block;
  text-align: center;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .social-area .social-list li a i {
  color: rgba(255, 255, 255, 0.7);
  transition: 0.5s;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .social-area .social-list li a span {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--font-hankenGrotesk);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  display: block;
  padding-top: 2px;
  transition: 0.5s;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .social-area .social-list li:hover a i {
  color: var(--primary-color1);
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .social-area .social-list li:hover a svg {
  fill: var(--primary-color1);
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .social-area .social-list li:hover a span {
  color: var(--primary-color1);
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-author-area {
  display: flex;
  gap: 15px;
}

@media (max-width: 576px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-author-area {
    flex-wrap: wrap;
  }
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-img img {
  min-width: 55px;
  max-width: 55px;
  height: 55px;
  border-radius: 50%;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content .author-name-desig {
  line-height: 1;
  margin-bottom: 18px;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content .author-name-desig h6 {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content .author-name-desig span {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--font-hankenGrotesk);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content p {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 30px;
  font-style: italic;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 35px;
}

@media (max-width: 1199px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content p {
    font-size: 22px;
    line-height: 1.4;
  }
}

@media (max-width: 576px) {
  .home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content p {
    margin-bottom: 20px;
  }
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content .details-button {
  background-image: linear-gradient(0deg, #fff 0%, #fff 100%);
  color: var(--white-color);
  font-size: 15px;
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content .details-button::after {
  color: var(--white-color);
}

.home4-footer-section .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content .details-button svg {
  stroke: var(--white-color);
}

.home4-footer-section .home4-footer-wrap .footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 100px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 1699px) {
  .home4-footer-section .home4-footer-wrap .footer-bottom {
    padding: 30px 70px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-footer-section .home4-footer-wrap .footer-bottom {
    padding: 20px 30px;
  }
}

@media (max-width: 1399px) {
  .home4-footer-section .home4-footer-wrap .footer-bottom {
    padding: 20px;
  }
}

@media (max-width: 991px) {
  .home4-footer-section .home4-footer-wrap .footer-bottom {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
}

.home4-footer-section .home4-footer-wrap .footer-bottom .copyright-area p {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--font-hankenGrotesk);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.home4-footer-section .home4-footer-wrap .footer-bottom .copyright-area p a {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
  transition: 0.5s;
}

.home4-footer-section .home4-footer-wrap .footer-bottom .copyright-area p a:hover {
  color: var(--primary-color1);
}

.home4-footer-section .home4-footer-wrap .footer-bottom .footer-bottom-right ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 45px;
  flex-wrap: wrap;
  line-height: 1;
}

@media (max-width: 991px) {
  .home4-footer-section .home4-footer-wrap .footer-bottom .footer-bottom-right ul {
    gap: 30px;
  }
}

@media (max-width: 576px) {
  .home4-footer-section .home4-footer-wrap .footer-bottom .footer-bottom-right ul {
    gap: 20px;
    justify-content: center;
  }
}

.home4-footer-section .home4-footer-wrap .footer-bottom .footer-bottom-right ul li a {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--font-hankenGrotesk);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.3px;
  text-decoration: underline;
  transition: 0.5s;
}

.home4-footer-section .home4-footer-wrap .footer-bottom .footer-bottom-right ul li a:hover {
  color: var(--primary-color1);
}

.home4-footer-section .contact-area {
  background: linear-gradient(180deg,
      rgba(48, 103, 255, 0.08) 0%,
      rgba(48, 103, 255, 0.1) 100%);
  padding: 65px 35px;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.home4-footer-section .contact-area h6 {
  color: var(--primary-color1);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;
  margin-bottom: 10px;
}

.home4-footer-section .contact-area a {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6;
  transition: 0.5s;
}

.home4-footer-section .contact-area a:hover {
  color: var(--title-color);
}

.home4-footer-section.style-2 {
  background: #fbfff1;
}

.home4-footer-section.style-2 .home4-footer-wrap {
  background-color: var(--dark-white-color);
  border-radius: 0 20px 0 0;
}

@media (max-width: 1199px) {
  .home4-footer-section.style-2 .home4-footer-wrap {
    border-radius: unset;
  }
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .widget-title h4 {
  color: var(--title-color);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .widget-title h4::after {
  background: linear-gradient(90deg, #0b0c0c 0.01%, rgba(11, 12, 12, 0) 102.8%);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li a {
  color: rgba(var(--title-color-opc), 0.7);
  display: inline-block;
  position: relative;
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid var(--primary-color1);
  transform-origin: right center;
  transform: scale(0, 1);
  transition: transform 0.5s;
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li a:hover {
  color: var(--primary-color1);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li a:hover::after {
  transform-origin: left center;
  transform: scale(1, 1);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li::after {
  display: none;
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .menu-container .widget-list li:hover {
  transform: translateX(0);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .footer-logo .logo-dark {
  display: block;
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .footer-logo .logo-light {
  display: none;
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .footer-contact-area h6 {
  color: var(--primary-color1);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;
  margin-bottom: 10px;
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .footer-contact-area a {
  color: rgba(var(--text-color-opc), 0.7);
  font-family: var(--font-inter);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6;
  transition: 0.5s;
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .footer-contact-area a:hover {
  color: var(--title-color);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .social-area {
  padding-top: 30px;
}

@media (max-width: 576px) {
  .home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .social-area {
    padding-top: 20px;
  }
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .social-area h6 {
  color: var(--title-color);
}

@media (max-width: 576px) {
  .home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .social-area h6 {
    margin-bottom: 15px;
  }
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .social-area .social-list li {
  display: block;
  text-align: center;
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .social-area .social-list li a i {
  color: rgba(var(--title-color-opc), 0.5);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .social-area .social-list li a span {
  color: rgba(var(--title-color-opc), 0.7);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .social-area .social-list li:hover a i {
  color: var(--primary-color1);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .social-area .social-list li:hover a svg {
  fill: var(--primary-color1);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .social-area .social-list li:hover a span {
  color: var(--primary-color1);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content .author-name-desig h6 {
  color: var(--title-color);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content .author-name-desig span {
  color: rgba(var(--title-color-opc), 0.7);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content p {
  color: var(--title-color);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content .details-button {
  background-image: linear-gradient(0deg, #0b0c0c 0%, #0b0c0c 100%);
  color: var(--title-color);
  font-size: 15px;
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content .details-button::after {
  color: var(--title-color);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .footer-author-area .author-content .details-button svg {
  stroke: var(--title-color);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-bottom {
  border-top: 1px solid rgba(var(--title-color-opc), 0.1);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-bottom .copyright-area p {
  color: rgba(var(--title-color-opc), 0.7);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-bottom .copyright-area p a {
  color: rgba(var(--title-color-opc), 0.7);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-bottom .copyright-area p a:hover {
  color: var(--primary-color1);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-bottom .footer-bottom-right ul li a {
  color: rgba(var(--title-color-opc), 0.7);
}

.home4-footer-section.style-2 .home4-footer-wrap .footer-bottom .footer-bottom-right ul li a:hover {
  color: var(--primary-color1);
}

.home4-footer-section.style-2 .contact-area {
  background: #fbfff1;
  height: unset;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px 20px;
}

@media (max-width: 1399px) {
  .home4-footer-section.style-2 .contact-area {
    padding: 40px 15px;
  }
}

.home4-footer-section.style-2 .contact-area h2 {
  margin-bottom: 0;
}

.home4-footer-section.style-2 .contact-area h2 a {
  color: rgba(var(--text-color-opc), 0.2);
  font-family: var(--font-hankenGrotesk);
  font-size: 55px;
  font-weight: 700;
  line-height: 1;
  text-decoration-line: underline;
  writing-mode: vertical-lr;
  transition: 0.5s;
}

@media (max-width: 1399px) {
  .home4-footer-section.style-2 .contact-area h2 a {
    font-size: 50px;
  }
}

.home4-footer-section.style-2 .contact-area h2 a:hover {
  color: rgba(var(--text-color-opc), 0.5);
}

.sub-title5 {
  display: inline-flex;
  align-items: end;
  justify-content: center;
  gap: 8px;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  background-color: transparent;
  text-transform: uppercase;
  border-radius: 100px;
  padding: 8px 12px;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}

.sub-title5 svg {
  fill: #e62415;
}

.sub-title5::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  border-radius: 100px;
  border: 1px solid rgba(11, 12, 12, 0.1);
  background: #e1e3f3;
  z-index: -1;
}

.sub-title5::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%) rotate(2deg);
  border-radius: 100px;
  background: rgba(11, 12, 12, 0.1);
  z-index: -1;
}

.sub-title5.two::after {
  border: 1px solid rgba(11, 12, 12, 0.1);
  background: var(--dark-white-color);
}

.sub-title5.two::before {
  background: rgba(11, 12, 12, 0.1);
}

.home5-banner-section {
  background-image: url(../img/home5/banner-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 220px 0 60px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1399px) {
  .home5-banner-section {
    padding: 170px 0 60px;
  }
}

.home5-banner-section .banner-content {
  position: relative;
}

.home5-banner-section .banner-content h1 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 70px;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 20px;
}

.home5-banner-section .banner-content h1 span {
  text-decoration: underline;
  color: var(--primary-color1);
  font-style: italic;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home5-banner-section .banner-content h1 {
    font-size: 65px;
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .home5-banner-section .banner-content h1 {
    font-size: 50px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .home5-banner-section .banner-content h1 {
    font-size: 45px;
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .home5-banner-section .banner-content h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }
}

.home5-banner-section .banner-content p {
  max-width: 954px;
  width: 100%;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .home5-banner-section .banner-content p {
    font-size: 15px;
  }
}

.home5-banner-section .banner-content .banner-content-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

@media (max-width: 576px) {
  .home5-banner-section .banner-content .banner-content-bottom {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.home5-banner-section .banner-content .banner-content-bottom .rating-area {
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1;
}

.home5-banner-section .banner-content .banner-content-bottom .rating-area .review span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 5px;
  display: block;
}

.home5-banner-section .banner-content .banner-content-bottom .rating-area .review .logo-dark {
  display: block;
}

.home5-banner-section .banner-content .banner-content-bottom .rating-area .review .logo-light {
  display: none;
}

.home5-banner-section .banner-content .banner-content-bottom .rating-area .rating .star {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 3px;
  margin-top: 5px;
  line-height: 1;
}

.home5-banner-section .banner-content .banner-content-bottom .rating-area .rating .star li i {
  color: #e62415;
}

.home5-banner-section .banner-content .banner-content-bottom .rating-area .rating span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.home5-banner-section .banner-bottom-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4% 0 10%;
  margin-top: -100px;
}

@media (max-width: 1399px) {
  .home5-banner-section .banner-bottom-section {
    padding: 0 2% 0 5%;
  }
}

.home5-banner-section .banner-bottom-section .wrapper {
  --clip-path: circle(65px at left);
  --clip-path-hover: circle(70px at left);
  --clip-path-clicked: circle(100vw at left);
  --duration: 0.4s;
  --timing-function: ease;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 999;
}

.home5-banner-section .banner-bottom-section .wrapper .video-info {
  width: 140px;
  position: relative;
  height: 140px;
}

.home5-banner-section .banner-bottom-section .wrapper .video-info h4 {
  font-family: var(--font-inter);
  font-size: 30px;
  color: var(--white-color);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  display: none;
}

.home5-banner-section .banner-bottom-section .wrapper .video-info .video {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-clip-path: var(--clip-path);
  clip-path: var(--clip-path);
  transition: -webkit-clip-path var(--duration) var(--timing-function);
  transition: clip-path var(--duration) var(--timing-function);
  transition: clip-path var(--duration) var(--timing-function),
    -webkit-clip-path var(--duration) var(--timing-function);
  position: relative;
  top: 55px;
  left: 100px;
}

.home5-banner-section .banner-bottom-section .wrapper .video-info .video::before {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 40px;
  height: 40px;
  background: url(../img/home5/icon/play-btn.svg);
  content: "";
  z-index: 999999999999;
  background-size: 100%;
}

.home5-banner-section .banner-bottom-section .wrapper .video-info .video video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: auto;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-banner-section .banner-bottom-section .banner-img {
    max-width: 370px;
  }
}

@media (max-width: 1399px) {
  .home5-banner-section .banner-bottom-section .banner-img {
    max-width: 330px;
  }
}

.home5-banner-section .banner-bottom-section .banner-img img {
  border-radius: 20px;
}

.home5-banner-section #video_check1 {
  width: 200px;
  height: 200px;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border-radius: 40px !important;
  outline: none;
  z-index: 2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  z-index: 9999999;
}

.home5-banner-section #video_check1:focus {
  outline: 0;
}

.home5-banner-section #video_check1:hover~.video {
  -webkit-clip-path: var(--clip-path-hover);
  clip-path: var(--clip-path-hover);
}

.home5-banner-section #video_check1:checked {
  width: 100%;
  height: 100%;
  border-radius: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.home5-banner-section #video_check1:checked~.video {
  -webkit-clip-path: var(--clip-path-clicked);
  clip-path: var(--clip-path-clicked);
}

.home5-banner-section #video_check1:checked~h4 {
  display: block;
}

.home5-banner-section #video_check1:checked~.video::before {
  background-image: url(../img/home5/icon/video-close.svg);
}

.home5-banner-section .scroll-down-btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 160px;
  animation: up-down 2s linear infinite alternate;
  z-index: 9;
}

@media (max-width: 991px) {
  .home5-banner-section .scroll-down-btn {
    display: none;
  }
}

.home5-banner-section .scroll-down-btn a {
  width: 41px;
  height: 80px;
  border-radius: 110px;
  border: 1px solid rgba(var(--title-color-opc), 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.home5-banner-section .scroll-down-btn a svg {
  fill: none;
  stroke: rgba(var(--title-color-opc), 0.5);
  transition: 0.5;
}

.home5-banner-section .scroll-down-btn a:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.home5-banner-section .scroll-down-btn a:hover svg {
  stroke: var(--white-color);
}

.partnership-area {
  scroll-margin-top: 100px;
}

.partnership-area .single-pertner {
  border-radius: 10px;
  background: #f8f8f8;
  padding: 20px 30px;
  position: relative;
  transition: 0.35s;
}

.partnership-area .single-pertner svg {
  position: absolute;
  right: 0;
  top: 0;
  stroke: #eee;
  transition: 0.35s;
}

.partnership-area .single-pertner span {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 10px;
  transition: 0.35s;
}

.partnership-area .single-pertner .partner-logos {
  margin-bottom: 35px;
  transition: 0.35s;
}

.partnership-area .single-pertner .partner-logos .white {
  display: none;
  visibility: hidden;
  transition: 0.35s;
}

.partnership-area .single-pertner p {
  margin-bottom: 0;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  transition: 0.35s;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .partnership-area .single-pertner p {
    font-size: 15px;
  }
}

.partnership-area .single-pertner:hover {
  background-color: var(--title-color);
}

.partnership-area .single-pertner:hover svg {
  stroke: var(--primary-color1);
}

.partnership-area .single-pertner:hover span {
  color: var(--dark-white-color);
}

.partnership-area .single-pertner:hover p {
  color: var(--dark-white-color);
}

.partnership-area .single-pertner:hover .partner-logos .black {
  display: none;
  visibility: hidden;
}

.partnership-area .single-pertner:hover .partner-logos .white {
  display: block;
  visibility: visible;
}

.partnership-area .total-partner a {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #eee;
  line-height: 1;
  padding: 11px 20px;
  transition: 0.5s;
}

.partnership-area .total-partner a svg {
  fill: var(--title-color);
  transition: 0.5s;
}

.partnership-area .total-partner a:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  color: var(--white-color);
}

.partnership-area .total-partner a:hover svg {
  fill: var(--white-color);
  transform: rotate(45deg);
}

.home5-about-section .about-content>p {
  color: var(--text-color);
  font-family: Inter;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .home5-about-section .about-content>p {
    font-size: 15px;
  }
}

.home5-about-section .about-content .eg-progress-wrap .eg-progress-bar-single .eg-progress-bar-title h6 {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 18px;
}

.home5-about-section .about-content .eg-progress-wrap .eg-progress-bar-single .eg-progress-bar {
  height: 3px;
  background-color: rgba(var(--primary-color1-opc), 0.5);
  border-radius: 25px;
}

.home5-about-section .about-content .eg-progress-wrap .eg-progress-bar-single .eg-progress-bar .experience-bar-per {
  height: 3px;
  background-color: var(--text-color);
  border-radius: 25px;
  width: 0;
  transition: 1s linear;
  position: relative;
}

.home5-about-section .about-content .eg-progress-wrap .eg-progress-bar-single .eg-progress-bar .experience-bar-per:before {
  content: attr(data-per);
  position: absolute;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-hankenGrotesk);
  line-height: 1;
  top: -30px;
  right: 0;
  transform: translateX(50%);
  z-index: 1;
}

@media (max-width: 576px) {
  .home5-about-section .about-content .eg-progress-wrap .eg-progress-bar-single .eg-progress-bar .experience-bar-per:before {
    right: 10px;
  }
}

.home5-about-section .about-content .about-countdown-area {
  padding-top: 80px;
}

@media (max-width: 991px) {
  .home5-about-section .about-content .about-countdown-area {
    padding-top: 50px;
  }
}

.home5-about-section .about-content .about-countdown-area ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  flex-wrap: wrap;
}

.home5-about-section .about-content .about-countdown-area ul .single-countdown {
  position: relative;
  display: flex;
  gap: 30px;
}

.home5-about-section .about-content .about-countdown-area ul .single-countdown::after {
  content: "";
  height: 120px;
  width: 1px;
  background-color: rgba(var(--title-color-opc), 0.1);
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1399px) {
  .home5-about-section .about-content .about-countdown-area ul .single-countdown::after {
    left: -15px;
  }
}

@media (max-width: 1199px) {
  .home5-about-section .about-content .about-countdown-area ul .single-countdown::after {
    display: none;
  }
}

.home5-about-section .about-content .about-countdown-area ul .single-countdown:first-child::after {
  display: none;
}

.home5-about-section .about-content .about-countdown-area ul .single-countdown .icon svg {
  fill: var(--text-color);
}

@media (max-width: 991px) {
  .home5-about-section .about-content .about-countdown-area ul .single-countdown .content {
    padding-bottom: 0;
    border-bottom: unset;
  }
}

.home5-about-section .about-content .about-countdown-area ul .single-countdown .content .number {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  line-height: 1;
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-bottom: 10px;
}

.home5-about-section .about-content .about-countdown-area ul .single-countdown .content .number h5 {
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 0;
  line-height: 1;
}

@media (max-width: 991px) {
  .home5-about-section .about-content .about-countdown-area ul .single-countdown .content .number h5 {
    font-size: 35px;
  }
}

.home5-about-section .about-content .about-countdown-area ul .single-countdown .content .number span {
  font-size: 27px;
  font-weight: 500;
}

@media (max-width: 991px) {
  .home5-about-section .about-content .about-countdown-area ul .single-countdown .content .number span {
    font-size: 22px;
  }
}

.home5-about-section .about-content .about-countdown-area ul .single-countdown .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .home5-about-section .about-content .about-countdown-area ul .single-countdown .content p {
    font-size: 14px;
  }
}

.home5-about-section .about-right {
  position: relative;
  padding-bottom: 100px;
}

@media (max-width: 991px) {
  .home5-about-section .about-right {
    padding-bottom: 0;
  }
}

.home5-about-section .about-right .banner-btn {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 991px) {
  .home5-about-section .about-right .banner-btn {
    position: relative;
    right: unset;
    bottom: unset;
  }
}

.home5-about-section .about-right .about-img {
  max-width: 470px;
  width: 100%;
  border-radius: 500px 500px 5px 0;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

@media (max-width: 991px) {
  .home5-about-section .about-right .about-img {
    display: none;
    visibility: hidden;
  }
}

.home5-about-section .about-right .about-img::after {
  content: "";
  height: 99%;
  width: 100%;
  border-radius: 500px 500px 5px 0;
  background: var(--title-color);
  position: absolute;
  left: 15px;
  bottom: -10px;
  z-index: -1;
}

.home5-about-section .about-right .about-img img {
  border-radius: 500px 500px 5px 0;
}

.home5-about-section .about-right a {
  position: relative;
  line-height: 1;
  transition: 0.5s;
  height: 201px;
  width: 201px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.home5-about-section .about-right a .bg {
  line-height: 1;
  transition: 0.5s;
}

.home5-about-section .about-right a .bg svg {
  fill: var(--dark-white-color);
  stroke: rgba(var(--title-color-opc), 0.1);
  transition: 0.5s;
}

.home5-about-section .about-right a .details-button {
  transition: 0.5s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.home5-about-section .about-right a:hover .bg svg {
  fill: var(--primary-color1);
  stroke: var(--primary-color1);
}

.home5-about-section .about-right a:hover .details-button {
  --background-size: 0%;
  --stroke-dashoffset: 26;
  --stroke-duration: 0.3s;
  --stroke-easing: cubic-bezier(0.3, 1.5, 0.5, 1);
  --stroke-delay: 0.195s;
  background-image: linear-gradient(0deg, #fff 0%, #fff 100%);
  color: var(--white-color);
}

.home5-about-section .about-right a:hover .details-button svg {
  stroke: var(--white-color);
}

.home5-about-section .about-right a:hover .details-button::after {
  opacity: 0;
  transition-delay: 0s;
  color: var(--white-color);
}

.home5-services-section {
  background: #f3ecec;
  padding: 110px 7%;
}

@media (max-width: 1699px) {
  .home5-services-section {
    padding: 110px 5%;
  }
}

@media (max-width: 1399px) {
  .home5-services-section {
    padding: 110px 1%;
  }
}

@media (max-width: 991px) {
  .home5-services-section {
    padding: 90px 0%;
  }
}

.home5-services-section .banner-btn {
  padding-top: 50px;
}

@media (max-width: 1199px) {
  .home5-services-section .banner-btn {
    padding-top: 30px;
  }
}

.home5-services-section .banner-btn a {
  position: relative;
  line-height: 1;
  transition: 0.5s;
  height: 201px;
  width: 201px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.home5-services-section .banner-btn a .bg {
  line-height: 1;
  transition: 0.5s;
}

.home5-services-section .banner-btn a .bg svg {
  fill: transparent;
  stroke: rgba(var(--title-color-opc), 0.1);
  transition: 0.5s;
}

.home5-services-section .banner-btn a .details-button {
  transition: 0.5s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.home5-services-section .banner-btn a:hover .bg svg {
  fill: var(--primary-color1);
  stroke: var(--primary-color1);
}

.home5-services-section .banner-btn a:hover .details-button {
  --background-size: 0%;
  --stroke-dashoffset: 26;
  --stroke-duration: 0.3s;
  --stroke-easing: cubic-bezier(0.3, 1.5, 0.5, 1);
  --stroke-delay: 0.195s;
  background-image: linear-gradient(0deg, #fff 0%, #fff 100%);
  color: var(--white-color);
}

.home5-services-section .banner-btn a:hover .details-button svg {
  stroke: var(--white-color);
}

.home5-services-section .banner-btn a:hover .details-button::after {
  opacity: 0;
  transition-delay: 0s;
  color: var(--white-color);
}

.home5-services-section .services-card {
  height: 100%;
  display: flex;
  gap: 20px;
  padding: 30px 25px;
  border-left: 1px solid rgba(var(--title-color-opc), 0.1);
  border-bottom: 1px solid rgba(var(--title-color-opc), 0.1);
}

@media (max-width: 1399px) {
  .home5-services-section .services-card {
    padding: 30px 20px;
  }
}

@media (max-width: 576px) {
  .home5-services-section .services-card {
    flex-wrap: wrap;
  }
}

.home5-services-section .services-card .icon svg {
  fill: var(--text-color);
  transition: all 0.5s ease-out;
}

.home5-services-section .services-card .services-content .title-category {
  margin-bottom: 15px;
}

.home5-services-section .services-card .services-content .title-category h3 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 2px;
  line-height: 1;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-services-section .services-card .services-content .title-category h3 {
    font-size: 25px;
  }
}

@media (max-width: 1399px) {
  .home5-services-section .services-card .services-content .title-category h3 {
    font-size: 25px;
  }
}

@media (max-width: 576px) {
  .home5-services-section .services-card .services-content .title-category h3 {
    font-size: 22px;
  }
}

.home5-services-section .services-card .services-content .title-category span {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 500;
  padding-left: 37px;
  line-height: 1;
  position: relative;
}

.home5-services-section .services-card .services-content .title-category span::after {
  content: "";
  height: 2px;
  width: 30px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(var(--title-color-opc), 0.6);
}

.home5-services-section .services-card .services-content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .home5-services-section .services-card .services-content p {
    font-size: 15px;
  }
}

.home5-services-section .services-card .services-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 10px;
  margin-bottom: 30px;
}

@media (max-width: 576px) {
  .home5-services-section .services-card .services-content ul {
    margin-bottom: 20px;
  }
}

.home5-services-section .services-card .services-content ul li {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  padding-top: 5px;
  margin-right: 20px;
}

.home5-services-section .services-card .services-content ul li i {
  color: var(--primary-color1);
}

.home5-services-section .services-card .services-content ul li i::before {
  font-weight: 800 !important;
}

.home5-services-section .services-card.boder-bottom-none {
  border-bottom: none;
}

@media (max-width: 576px) {
  .home5-services-section .services-card.boder-bottom-none {
    border-bottom: 1px solid rgba(var(--title-color-opc), 0.1);
  }
}

.home5-services-section .services-card:hover .icon svg {
  transform: rotate(360deg);
}

.home5-why-choose-section .why-choose-img {
  position: relative;
  z-index: 1;
}

.home5-why-choose-section .why-choose-img img {
  -webkit-clip-path: polygon(0 0, 98% 2%, 98% 98%, 0% 100%);
  clip-path: polygon(0 0, 98% 2%, 98% 98%, 0% 100%);
}

.home5-why-choose-section .why-choose-img:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: rgba(var(--primary-color1-opc), 0.2);
}

.home5-why-choose-section .why-choose-content>p {
  color: var(--text-color);
  font-family: Inter;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .home5-why-choose-section .why-choose-content>p {
    font-size: 15px;
  }
}

.home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown {
  text-align: center;
  padding: 35px;
  border-right: 1px solid rgba(var(--title-color-opc), 0.1);
  border-bottom: 1px solid rgba(var(--title-color-opc), 0.1);
  width: 100%;
}

@media (max-width: 767px) {
  .home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown {
    border: 1px solid rgba(var(--title-color-opc), 0.1);
    border-bottom: none;
  }
}

.home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown.two {
  border-right: none;
}

@media (max-width: 767px) {
  .home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown.two {
    border-right: 1px solid rgba(var(--title-color-opc), 0.1);
  }
}

.home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown.three {
  border-bottom: none;
}

.home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown.four {
  border-bottom: none;
  border-right: none;
}

@media (max-width: 767px) {
  .home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown.four {
    border-right: 1px solid rgba(var(--title-color-opc), 0.1);
    border-bottom: 1px solid rgba(var(--title-color-opc), 0.1);
  }
}

.home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown .number {
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown .number h2 {
  font-family: var(--font-hankenGrotesk);
  font-size: 150px;
  font-weight: 900;
  letter-spacing: 4.5px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px var(--title-color);
  margin-bottom: 0;
  line-height: 0.8;
}

@media (max-width: 1199px) {
  .home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown .number h2 {
    font-size: 130px;
  }
}

@media (max-width: 576px) {
  .home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown .number h2 {
    font-size: 100px;
  }
}

.home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown .number span {
  font-family: var(--font-hankenGrotesk);
  font-size: 150px;
  font-weight: 900;
  letter-spacing: 4.5px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px var(--title-color);
  line-height: 0.8;
}

@media (max-width: 1199px) {
  .home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown .number span {
    font-size: 130px;
  }
}

@media (max-width: 576px) {
  .home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown .number span {
    font-size: 100px;
  }
}

.home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown .content {
  background-color: var(--dark-white-color);
  padding-top: 20px;
  margin-top: -15px;
  position: relative;
  z-index: 1;
}

.home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown .content p {
    font-size: 14px;
  }
}

.home5-industry-section .section-title5 p {
  max-width: 574px;
  width: 100%;
  margin-left: auto;
}

.industry-card .industry-img {
  overflow: hidden;
  position: relative;
  border-radius: 10px 10px 0 0;
}

.industry-card .industry-img img {
  border-radius: 10px 10px 0 0;
  transition: all 0.5s ease-out;
}

@media (max-width: 991px) {
  .industry-card .industry-img img {
    width: 100%;
  }
}

.industry-card .industry-img::after {
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
  z-index: 1;
}

.industry-card .industry-content {
  border: 1px solid rgba(var(--title-color-opc), 0.2);
  border-top: none;
  border-radius: 0 0 10px 10px;
  padding: 20px;
}

.industry-card .industry-content h5 {
  color: var(--text-color);
  text-align: center;
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.36px;
  margin-bottom: 0;
  transition: 0.35s;
}

.industry-card .industry-content h5:hover {
  color: var(--primary-color1);
}

.industry-card:hover .industry-img img {
  transform: scale(1.1);
}

.industry-card:hover .industry-img::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}

.star-btn a {
  position: relative;
  line-height: 1;
  transition: 0.5s;
  height: 201px;
  width: 201px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.star-btn a .bg {
  line-height: 1;
  transition: 0.5s;
}

.star-btn a .bg svg {
  fill: transparent;
  stroke: rgba(var(--title-color-opc), 0.15);
  transition: 0.5s;
}

.star-btn a .details-button {
  transition: 0.5s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.star-btn a:hover .bg svg {
  fill: var(--primary-color1);
  stroke: var(--primary-color1);
}

.star-btn a:hover .details-button {
  --background-size: 0%;
  --stroke-dashoffset: 26;
  --stroke-duration: 0.3s;
  --stroke-easing: cubic-bezier(0.3, 1.5, 0.5, 1);
  --stroke-delay: 0.195s;
  background-image: linear-gradient(0deg, #fff 0%, #fff 100%);
  color: var(--white-color);
}

.star-btn a:hover .details-button svg {
  stroke: var(--white-color);
}

.star-btn a:hover .details-button::after {
  opacity: 0;
  transition-delay: 0s;
  color: var(--white-color);
}

.team-card2 {
  border-radius: 500px 500px 10px 10px;
  border: 1px solid rgba(18, 18, 18, 0.1);
  background: var(--dark-white-color);
  padding: 10px;
  transition: all 0.45s;
}

.team-card2 .team-img {
  position: relative;
  border-radius: 500px 500px 0 0;
  transition: all 0.45s;
}

.team-card2 .team-img img {
  transition: all 0.45s;
  border-radius: 500px 500px 0 0;
}

@media (max-width: 1199px) {
  .team-card2 .team-img img {
    width: 100%;
  }
}

.team-card2 .team-img .social-area {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: end;
  justify-content: center;
  padding-bottom: 20px;
  gap: 22px;
  border-radius: 500px 500px 0 0;
  background: linear-gradient(180deg, rgba(11, 12, 12, 0) 17.78%, #0b0c0c 100%);
  position: absolute;
  left: 0;
  bottom: 0;
  height: 10%;
  width: 100%;
  transition: all 0.45s;
  opacity: 0;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .team-card2 .team-img .social-area {
    gap: 17px;
  }
}

@media (max-width: 1399px) {
  .team-card2 .team-img .social-area {
    gap: 17px;
  }
}

.team-card2 .team-img .social-area li {
  line-height: 1;
  position: relative;
}

.team-card2 .team-img .social-area li a {
  color: var(--white-color);
  font-size: 20px;
  line-height: 1;
  transition: 0.35s;
}

.team-card2 .team-img .social-area li a:hover {
  color: var(--primary-color1);
}

.team-card2 .team-img .social-area li::after {
  content: "";
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: var(--white-color);
  opacity: 0.2;
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .team-card2 .team-img .social-area li::after {
    left: -12px;
  }
}

@media (max-width: 1399px) {
  .team-card2 .team-img .social-area li::after {
    left: -11px;
  }
}

.team-card2 .team-img .social-area li:first-child::after {
  display: none;
  visibility: hidden;
}

.team-card2 .team-content {
  line-height: 1;
  padding: 15px 0;
}

.team-card2 .team-content h4 {
  margin-bottom: 3px;
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 25px;
  font-weight: 700;
}

.team-card2 .team-content span {
  line-height: 1;
  color: rgba(var(--title-color-opc), 0.5);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
}

.team-card2:hover {
  border-radius: 10px;
}

.team-card2:hover .team-img {
  border-radius: 10px;
}

.team-card2:hover .team-img img {
  border-radius: 10px 10px 0 0;
}

.team-card2:hover .team-img .social-area {
  border-radius: 10px 10px 0 0;
  opacity: 1;
  height: 100%;
}

.home5-team-section {
  background-color: #fffaf0;
  padding: 110px 10%;
}

@media (max-width: 1799px) {
  .home5-team-section {
    padding: 110px 8%;
  }
}

@media (max-width: 1699px) {
  .home5-team-section {
    padding: 110px 5%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-team-section {
    padding: 110px 3%;
  }
}

@media (max-width: 1399px) {
  .home5-team-section {
    padding: 110px 1%;
  }
}

@media (max-width: 991px) {
  .home5-team-section {
    padding: 90px 0%;
  }
}

.home5-testimonial-section .testimonial-left {
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  z-index: 1;
}

@media (max-width: 576px) {
  .home5-testimonial-section .testimonial-left {
    justify-content: start;
  }
}

.home5-testimonial-section .testimonial-left svg {
  stroke: #0b0c0c;
  opacity: 0.2;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 576px) {
  .home5-testimonial-section .testimonial-left svg {
    top: unset;
    bottom: 0;
    transform: translate(0);
  }
}

.home5-testimonial-section .testimonial-left .testimonial-img {
  padding: 5px;
  border: 1px solid #eee;
  border-radius: 143px 143px 0 143px;
  transform: translateX(-40px);
}

@media (max-width: 991px) {
  .home5-testimonial-section .testimonial-left .testimonial-img {
    transform: translateX(0);
  }
}

.home5-testimonial-section .testimonial-left .testimonial-img img {
  border-radius: 143px 143px 0 143px;
}

.home5-testimonial-section .testimonial-wrap {
  position: relative;
}

.home5-testimonial-section .testimonial-wrap .swiper-slide-active p {
  animation: fadeInDown 1.7s;
}

.home5-testimonial-section .testimonial-wrap .swiper-slide-active span {
  animation: fadeInDown 1.7s;
}

.home5-testimonial-section .testimonial-wrap .swiper-slide-active .author-name-desig {
  animation: fadeInDown 1.7s;
}

.home5-testimonial-section .testimonial-wrap .testimonial-card2 {
  display: flex;
  align-items: center;
  gap: 50px;
}

@media (max-width: 1399px) {
  .home5-testimonial-section .testimonial-wrap .testimonial-card2 {
    gap: 30px;
  }
}

@media (max-width: 991px) {
  .home5-testimonial-section .testimonial-wrap .testimonial-card2 {
    gap: 20px;
  }
}

@media (max-width: 767px) {
  .home5-testimonial-section .testimonial-wrap .testimonial-card2 {
    flex-direction: column;
    align-items: flex-start;
  }
}

.home5-testimonial-section .testimonial-wrap .testimonial-card2 .content>span {
  color: var(--primary-color1);
  font-family: var(--font-hankenGrotesk);
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
}

.home5-testimonial-section .testimonial-wrap .testimonial-card2 .content p {
  color: rgba(var(--title-color-opc), 0.7);
  font-family: var(--font-inter);
  font-size: 22px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 35px;
}

@media (max-width: 767px) {
  .home5-testimonial-section .testimonial-wrap .testimonial-card2 .content p {
    font-size: 20px;
    margin-bottom: 25px;
  }
}

@media (max-width: 576px) {
  .home5-testimonial-section .testimonial-wrap .testimonial-card2 .content p {
    font-size: 18px;
  }
}

.home5-testimonial-section .testimonial-wrap .testimonial-card2 .content .author-name-desig {
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 10px;
}

.home5-testimonial-section .testimonial-wrap .testimonial-card2 .content .author-name-desig .author-img img {
  width: 59px;
  height: 59px;
  border-radius: 50%;
}

.home5-testimonial-section .testimonial-wrap .testimonial-card2 .content .author-name-desig .content h6 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 7px;
}

.home5-testimonial-section .testimonial-wrap .testimonial-card2 .content .author-name-desig .content span {
  color: rgba(var(--title-color-opc), 0.6);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
}

.home5-testimonial-section .testimonial-wrap .slider-btn-area {
  position: absolute;
  bottom: 10px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  max-width: 550px;
  width: 100%;
  z-index: 9;
}

@media (max-width: 1399px) {
  .home5-testimonial-section .testimonial-wrap .slider-btn-area {
    max-width: 370px;
    gap: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home5-testimonial-section .testimonial-wrap .slider-btn-area {
    max-width: 450px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .home5-testimonial-section .testimonial-wrap .slider-btn-area {
    bottom: 50px;
  }
}

@media (max-width: 576px) {
  .home5-testimonial-section .testimonial-wrap .slider-btn-area {
    justify-content: end;
  }
}

.home5-testimonial-section .testimonial-wrap .slider-btn-area .dash {
  width: 100%;
  height: 1px;
  background-color: rgba(var(--title-color-opc), 0.1);
}

@media (max-width: 576px) {
  .home5-testimonial-section .testimonial-wrap .slider-btn-area .dash {
    display: none;
  }
}

.home5-testimonial-section .testimonial-wrap .slider-btn-area .slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home5-testimonial-section .testimonial-wrap .slider-btn-area .slider-btn-group.w-100 {
  max-width: 100px;
  min-width: 100px;
  width: 100%;
}

.home5-blog-section .section-title5 p {
  max-width: 575px;
  width: 100%;
  margin-left: auto;
}

.home5-blog-section .w-85 {
  width: 85%;
}

@media (max-width: 991px) {
  .home5-blog-section .w-85 {
    width: 100%;
  }
}

.portfolio-card {
  border: 1px solid #eee;
  border-radius: 10px;
  transition: 0.35s;
}

.portfolio-card .image-and-tag {
  position: relative;
  background-color: #eee;
  padding: 30px 40px 0;
  border-radius: 10px 10px 0 0;
  transition: 0.35s;
}

@media (max-width: 1199px) {
  .portfolio-card .image-and-tag {
    padding: 20px 20px 0;
  }
}

.portfolio-card .image-and-tag .tag {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  transition: 0.35s;
}

.portfolio-card .image-and-tag .tag li {
  transition: 0.35s;
}

.portfolio-card .image-and-tag .tag li a {
  line-height: 1;
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  background-color: var(--white-color);
  border-radius: 50px;
  padding: 5px 22px;
  transition: 0.35s;
}

.portfolio-card .image-and-tag .tag li a:hover {
  color: var(--white-color);
  background-color: var(--primary-color1);
}

.portfolio-card .image-and-tag .portfolio-img {
  position: relative;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  transition: 0.35s;
}

.portfolio-card .image-and-tag .portfolio-img img {
  border-radius: 5px 5px 0 0;
  transition: all 0.5s ease-out;
}

@media (max-width: 991px) {
  .portfolio-card .image-and-tag .portfolio-img img {
    -o-object-fit: cover;
    object-fit: cover;
    min-height: 200px;
  }
}

.portfolio-card .portfolio-content {
  padding: 28px 40px;
  position: relative;
}

@media (max-width: 1199px) {
  .portfolio-card .portfolio-content {
    padding: 25px 20px;
  }
}

.portfolio-card .portfolio-content h4 {
  margin-bottom: 0;
}

.portfolio-card .portfolio-content h4 a {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 25px;
  font-weight: 600;
  line-height: 1.4;
  transition: 0.5s;
}

@media (max-width: 1199px) {
  .portfolio-card .portfolio-content h4 a {
    font-size: 22px;
  }
}

.portfolio-card .portfolio-content h4 a:hover {
  color: var(--primary-color1);
}

.portfolio-card .portfolio-content .details-btn {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #282828;
  position: absolute;
  top: -25px;
  right: 70px;
  transition: 0.35s;
}

@media (max-width: 1199px) {
  .portfolio-card .portfolio-content .details-btn {
    right: 50px;
  }
}

@media (max-width: 991px) {
  .portfolio-card .portfolio-content .details-btn {
    right: 30px;
    width: 45px;
    height: 45px;
  }
}

.portfolio-card .portfolio-content .details-btn svg {
  fill: var(--white-color);
  transition: 0.35s;
}

.portfolio-card .portfolio-content .details-btn:hover {
  background-color: var(--primary-color1);
}

.portfolio-card .portfolio-content .details-btn:hover svg {
  transform: rotate(45deg);
}

.portfolio-card:hover .image-and-tag .portfolio-img img {
  transform: scale(1.1);
}

.portfolio-card:hover .portfolio-content .details-btn {
  background-color: var(--primary-color1);
}

.home5-portfolio-section .section-title5 p {
  max-width: 575px;
  width: 100%;
  margin-left: auto;
}

.home5-contact-section {
  background: #fbfff1;
  overflow: hidden;
  position: relative;
  padding: 100px 0;
}

@media (max-width: 1199px) {
  .home5-contact-section {
    padding: 90px 0;
  }
}

@media (max-width: 767px) {
  .home5-contact-section {
    padding: 70px 0;
  }
}

.home5-contact-section .star-btn {
  transform: translateY(30px);
}

@media (max-width: 991px) {
  .home5-contact-section .star-btn {
    transform: translateY(0px);
  }
}

.home5-contact-section .star-btn a {
  width: 238px;
  height: 238px;
}

@media (max-width: 576px) {
  .home5-contact-section .star-btn a {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 576px) {
  .home5-contact-section .star-btn a .bg svg {
    width: 200px;
  }
}

@media (max-width: 576px) {
  .home5-contact-section .star-btn a .details-button {
    font-size: 16px;
  }
}

.home5-contact-section .marquee-area {
  padding-top: 60px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home5-contact-section .marquee-area {
    padding-top: 45px;
  }
}

@media (max-width: 991px) {
  .home5-contact-section .marquee-area {
    padding-top: 0;
  }
}

.home5-contact-section .marquee-area .marquee_text {
  display: flex;
  align-items: center;
}

.home5-contact-section .marquee-area .js-marquee {
  display: flex;
  align-items: center;
  gap: 25px;
}

.home5-contact-section .marquee-area h6 {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 35px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .home5-contact-section .marquee-area h6 {
    font-size: 30px;
  }
}

.home5-contact-section .marquee-area svg {
  fill: var(--primary-color1);
}

.home5-contact-section .arrow {
  position: absolute;
  top: 0;
  right: 0;
}

.home5-contact-section .arrow svg {
  fill: rgba(var(--text-color-opc), 0.05);
}

@media (max-width: 1399px) {
  .home5-contact-section .arrow svg {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 1199px) {
  .home5-contact-section .arrow svg {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 991px) {
  .home5-contact-section .arrow svg {
    display: none;
  }
}

.breadcrumb-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 200px 0 90px;
}

@media (max-width: 991px) {
  .breadcrumb-section {
    padding: 160px 0 80px;
  }
}

.breadcrumb-section .banner-wrapper .banner-content h1 {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 56px;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .breadcrumb-section .banner-wrapper .banner-content h1 {
    font-size: 50px;
  }
}

@media (max-width: 576px) {
  .breadcrumb-section .banner-wrapper .banner-content h1 {
    font-size: 40px;
  }
}

.breadcrumb-section .banner-wrapper .banner-content .breadcrumb-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  padding: 11px 22px;
  line-height: 1;
  margin-bottom: 12px;
}

.breadcrumb-section .banner-wrapper .banner-content .breadcrumb-list li {
  color: var(--white-color);
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.39px;
  text-transform: uppercase;
  position: relative;
  padding-left: 16px;
}

.breadcrumb-section .banner-wrapper .banner-content .breadcrumb-list li a {
  color: var(--primary-color1);
}

.breadcrumb-section .banner-wrapper .banner-content .breadcrumb-list li::before {
  content: "";
  width: 8px;
  height: 2px;
  border-radius: 50%;
  background-color: var(--white-color);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.breadcrumb-section .banner-wrapper .banner-content .breadcrumb-list li:first-child {
  padding-left: 0;
}

.breadcrumb-section .banner-wrapper .banner-content .breadcrumb-list li:first-child::before {
  display: none;
}

.breadcrumb-section .banner-wrapper .scroll-down-btn {
  animation: up-down2 2s linear infinite alternate;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

@keyframes up-down2 {
  0% {
    transform: translateY(40px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(40px);
  }
}

@media (max-width: 767px) {
  .breadcrumb-section .banner-wrapper .scroll-down-btn {
    display: none;
  }
}

.breadcrumb-section .banner-wrapper .scroll-down-btn a {
  width: 41px;
  height: 80px;
  border-radius: 110px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.breadcrumb-section .banner-wrapper .scroll-down-btn a svg {
  fill: none;
  stroke: rgba(255, 255, 255, 0.5);
  transition: 0.5s;
}

.breadcrumb-section .banner-wrapper .scroll-down-btn a:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.breadcrumb-section .banner-wrapper .scroll-down-btn a:hover svg {
  stroke: var(--white-color);
}

.scroll-margin {
  scroll-margin-top: 50px;
}

.case-study-details-page {
  scroll-margin-top: 50px;
}

.case-study-details-page .case-thumb {
  margin-bottom: 25px;
}

.case-study-details-page .case-thumb img {
  border-radius: 10px;
}

.case-study-details-page .case-details-content h3 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 33px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;
  padding-top: 32px;
}

@media (max-width: 767px) {
  .case-study-details-page .case-details-content h3 {
    font-size: 28px;
  }
}

.case-study-details-page .case-details-content p {
  margin-bottom: 12px;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
}

@media (max-width: 767px) {
  .case-study-details-page .case-details-content p {
    font-size: 17px;
    line-height: 30px;
  }
}

.case-study-details-page .case-details-content .features {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 15px;
}

.case-study-details-page .case-details-content .features li {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 13px;
}

.case-study-details-page .case-details-content .features li:last-child {
  margin-bottom: 0;
}

.case-study-details-page .case-details-content .features li svg {
  fill: var(--primary-color1);
  margin-right: 5px;
}

.case-study-details-page .case-details-content .features.two {
  -moz-columns: 2;
  columns: 2;
}

@media (max-width: 767px) {
  .case-study-details-page .case-details-content .features.two {
    -moz-columns: 1;
    columns: 1;
  }
}

.case-study-details-page .case-details-content .case-img-group {
  padding-top: 50px;
  margin-bottom: 25px;
}

.case-study-details-page .case-details-content .case-img-group .case-img img {
  border-radius: 10px;
}

.case-study-details-page .case-sidebar .case-info-wrap {
  border-radius: 10px;
  border: 1px solid var(--border-color);
  background: rgba(48, 103, 255, 0.03);
  padding: 25px 35px;
}

@media (max-width: 1199px) {
  .case-study-details-page .case-sidebar .case-info-wrap {
    padding: 25px;
  }
}

.case-study-details-page .case-sidebar .case-info-wrap h4 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.case-study-details-page .case-sidebar .case-info-wrap h4 svg {
  fill: #e62415;
}

.case-study-details-page .case-sidebar .case-info-wrap .case-info {
  margin: 0;
  padding: 0;
  list-style: none;
}

.case-study-details-page .case-sidebar .case-info-wrap .case-info li {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid var(--border-color);
  padding: 20px 0 15px;
}

.case-study-details-page .case-sidebar .case-info-wrap .case-info li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.case-study-details-page .case-sidebar .case-info-wrap .case-info li .icon svg {
  stroke: rgba(var(--title-color-opc), 0.2);
}

.case-study-details-page .case-sidebar .case-info-wrap .case-info li .content {
  line-height: 1;
}

.case-study-details-page .case-sidebar .case-info-wrap .case-info li .content span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  margin-bottom: 3px;
}

.case-study-details-page .case-sidebar .case-info-wrap .case-info li .content h5 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .case-study-details-page .case-sidebar .case-info-wrap .case-info li .content h5 {
    font-size: 18px;
  }
}

.case-study-details-page .case-sidebar .social-share-area {
  border-radius: 10px;
  border: 1px solid var(--border-color);
  background: rgba(48, 103, 255, 0.03);
  padding: 25px 35px;
}

@media (max-width: 1199px) {
  .case-study-details-page .case-sidebar .social-share-area {
    padding: 25px;
  }
}

.case-study-details-page .case-sidebar .social-share-area h4 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 22px;
  font-weight: 600;
  text-decoration-line: underline;
  margin-bottom: 20px;
}

.case-study-details-page .case-sidebar .social-share-area ul {
  display: flex;
  align-items: center;
  gap: 40px;
}

@media (max-width: 1399px) {
  .case-study-details-page .case-sidebar .social-share-area ul {
    gap: 25px;
    flex-wrap: wrap;
  }
}

.case-study-details-page .case-sidebar .social-share-area ul li {
  line-height: 1;
}

.case-study-details-page .case-sidebar .social-share-area ul li a {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  transition: 0.35s;
}

.case-study-details-page .case-sidebar .social-share-area ul li a i {
  font-size: 16px;
}

.case-study-details-page .case-sidebar .social-share-area ul li a span {
  display: block;
}

.case-study-details-page .case-sidebar .social-share-area ul li a:hover {
  color: var(--primary-color1);
}

.details-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0;
  border-top: 1px solid rgba(var(--title-color-opc), 0.1);
  border-bottom: 1px solid rgba(var(--title-color-opc), 0.1);
}

@media (max-width: 767px) {
  .details-navigation {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.details-navigation .single-navigation {
  display: flex;
  align-items: center;
  gap: 20px;
  transition: 0.35s;
}

@media (max-width: 991px) {
  .details-navigation .single-navigation {
    gap: 15px;
  }
}

.details-navigation .single-navigation .star-btn a {
  width: 163px;
  height: 80px;
}

.details-navigation .single-navigation .star-btn .nav-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 5px;
}

.details-navigation .single-navigation .star-btn .nav-btn svg {
  fill: var(--primary-color1);
  transition: 0.5s;
}

.details-navigation .single-navigation .star-btn .nav-btn span {
  color: var(--primary-color1);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  text-decoration-line: underline;
  white-space: nowrap;
  transition: 0.5s;
}

.details-navigation .single-navigation .star-btn:hover .nav-btn svg {
  fill: var(--white-color);
  transform: rotate(-45deg);
}

.details-navigation .single-navigation .star-btn:hover .nav-btn span {
  color: var(--white-color);
}

.details-navigation .single-navigation .content {
  max-width: 301px;
  width: 100%;
  line-height: 1;
  transition: 0.35s;
}

.details-navigation .single-navigation .content p {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0;
}

.details-navigation .single-navigation.two .star-btn:hover .nav-btn svg {
  transform: rotate(45deg);
}

.relatate-case-study-section .section-title5 h2 span {
  display: inline-block;
}

.team-card-section {
  scroll-margin-top: 50px;
}

.team-card-section .star-btn a {
  width: 235px;
  height: 235px;
}

.pagination-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}

.pagination-area .paginations {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
}

.pagination-area .paginations .page-item a {
  color: rgba(var(--text-color-opc), 0.5);
  font-family: var(--font-hankenGrotesk);
  font-size: 17px;
  font-weight: 700;
  line-height: 1;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 1px solid rgba(var(--text-color-opc), 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.pagination-area .paginations .page-item a:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  color: var(--white-color);
}

.pagination-area .paginations .page-item.active a {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  color: var(--white-color);
}

.pagination-area .paginations .page-item.paginations-button a {
  font-size: 15px;
  font-weight: 600;
  gap: 5px;
  width: unset;
  height: unset;
  padding: 11px 15px;
}

.pagination-area .paginations .page-item.paginations-button a svg {
  fill: rgba(var(--text-color-opc), 0.5);
  transition: 0.5s;
}

.pagination-area .paginations .page-item.paginations-button a:hover svg {
  fill: var(--white-color);
}

.sidebar-area .faq-content .accordion .accordion-item .accordion-header .accordion-button {
  font-size: 15px;
  text-transform: initial;
}

.sidebar-area .widget-title {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 22px;
  font-weight: 700;
  line-height: 0.9;
  letter-spacing: 0.4px;
  border-bottom: 1px solid var(--title-color);
  display: inline-block;
  margin-bottom: 30px;
}

.sidebar-area .single-widget {
  padding: 25px 35px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar-area .single-widget {
    padding: 25px 20px;
  }
}

@media (max-width: 576px) {
  .sidebar-area .single-widget {
    padding: 25px 20px;
  }
}

.sidebar-area .single-widget .category-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-area .single-widget .category-list li {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  transition: 0.5s;
}

.sidebar-area .single-widget .category-list li:last-child {
  margin-bottom: 0;
}

.sidebar-area .single-widget .category-list li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.3px;
  transition: 0.5s;
}

.sidebar-area .single-widget .category-list li a span {
  display: flex;
  align-items: center;
  gap: 12px;
}

.sidebar-area .single-widget .category-list li a span svg {
  fill: var(--primary-color1);
  transition: 0.5s;
}

.sidebar-area .single-widget .category-list li:hover a {
  color: var(--primary-color1);
}

.sidebar-area .single-widget .category-list li:hover a span svg {
  transform: rotate(45deg);
}

.sidebar-area .single-widget .category-list li:hover::before {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.sidebar-area .single-widget .category-list.two li a span {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 2px 6px;
  border: 1px solid var(--text-color);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.sidebar-area .single-widget .category-list.two li:hover a span {
  border: 1px solid var(--primary-color1);
  color: var(--primary-color1);
}

.sidebar-area .single-widget .recent-post-widget {
  display: flex;
  align-items: center;
  gap: 15px;
}

.sidebar-area .single-widget .recent-post-widget .recent-post-img {
  border-radius: 5px;
}

.sidebar-area .single-widget .recent-post-widget .recent-post-img img {
  min-width: 92px;
  max-width: 92px;
  height: 71px;
  border-radius: 5px;
}

.sidebar-area .single-widget .recent-post-widget .recent-post-content {
  line-height: 1;
}

.sidebar-area .single-widget .recent-post-widget .recent-post-content>a {
  color: rgba(var(--text-color), 0.5);
  font-family: var(--font-inter);
  font-size: 12px;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
  transition: 0.5s;
}

@media (max-width: 576px) {
  .sidebar-area .single-widget .recent-post-widget .recent-post-content>a {
    margin-bottom: 5px;
  }
}

.sidebar-area .single-widget .recent-post-widget .recent-post-content>a:hover {
  color: var(--primary-color1);
}

.sidebar-area .single-widget .recent-post-widget .recent-post-content h6 {
  margin-bottom: 0;
}

.sidebar-area .single-widget .recent-post-widget .recent-post-content h6 a {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.32px;
  transition: 0.5s;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar-area .single-widget .recent-post-widget .recent-post-content h6 a {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .sidebar-area .single-widget .recent-post-widget .recent-post-content h6 a {
    font-size: 16px;
  }
}

.sidebar-area .single-widget .recent-post-widget .recent-post-content h6 a:hover {
  color: var(--primary-color1);
}

.sidebar-area .single-widget .tag-list {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar-area .single-widget .tag-list {
    gap: 14px;
  }
}

.sidebar-area .single-widget .tag-list li a {
  color: rgba(var(--title-color-opc), 0.5);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 500;
  display: block;
  line-height: 1;
  padding: 9px 17px;
  border-radius: 100px;
  background: #f3f3f3;
  transition: 0.5s;
}

.sidebar-area .single-widget .tag-list li:hover a {
  color: var(--white-color);
  background-color: var(--primary-color1);
}

.sidebar-area .single-widget .search-box {
  display: flex;
  align-items: center;
}

.sidebar-area .single-widget .search-box input {
  width: 100%;
  padding: 8px 20px;
  font-family: var(--font-inter);
  font-size: 12px;
  height: 45px;
  border: 1px solid rgba(var(--primary-color1-opc), 0.3);
}

.sidebar-area .single-widget .search-box input:focus {
  border: 1px solid #ddd;
}

.sidebar-area .single-widget .search-box button {
  background-color: var(--primary-color1);
  min-width: 90px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.sidebar-area .single-widget .search-box button::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: var(--title-color);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}

.sidebar-area .single-widget .search-box button i {
  color: var(--white-color);
  font-size: 22px;
}

@media (max-width: 576px) {
  .sidebar-area .single-widget .search-box button {
    min-width: 70px;
  }
}

.sidebar-area .single-widget .search-box button:hover {
  color: var(--white-color);
}

.sidebar-area .single-widget .search-box button:hover::after {
  transform: skewX(45deg) scale(1, 1);
}

.sidebar-area .single-widget .social-list {
  display: flex;
  align-items: center;
  gap: 30px;
}

.sidebar-area .single-widget .social-list li {
  display: block;
  text-align: center;
}

.sidebar-area .single-widget .social-list li a i {
  color: var(--text-color);
  transition: 0.5s;
}

.sidebar-area .single-widget .social-list li a span {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  display: block;
  padding-top: 2px;
  transition: 0.5s;
}

.sidebar-area .single-widget .social-list li:hover a i {
  color: var(--primary-color1);
}

.sidebar-area .single-widget .social-list li:hover a svg {
  fill: var(--primary-color1);
}

.sidebar-area .single-widget .social-list li:hover a span {
  color: var(--primary-color1);
}

.pricing-plan-process-section .feature-card4 {
  border: 1px solid rgba(11, 12, 12, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}

.faq-section .verticle-text h2 {
  color: rgba(var(--title-color-opc), 0.1);
  font-family: var(--font-hankenGrotesk);
  font-size: 100px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 5px;
  text-transform: uppercase;
  writing-mode: vertical-lr;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .faq-section .verticle-text h2 {
    writing-mode: unset;
    font-size: 80px;
  }
}

@media (max-width: 767px) {
  .faq-section .verticle-text h2 {
    font-size: 60px;
  }
}

.contact-page .contact-area {
  border-radius: 10px;
  border: 1px solid rgba(11, 12, 12, 0.08);
  background: var(--dark-white-color);
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.03);
  padding: 70px 35px;
}

@media (max-width: 1199px) {
  .contact-page .contact-area {
    padding: 50px 25px;
  }
}

@media (max-width: 576px) {
  .contact-page .contact-area {
    padding: 40px 25px;
  }
}

.contact-page .contact-area .title {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 25px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 4px;
}

.contact-page .contact-area .address {
  margin-bottom: 30px;
}

.contact-page .contact-area .address span {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
  margin-bottom: 30px;
}

.contact-page .contact-area .address p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
}

.contact-page .contact-area .working-hour {
  display: flex;
  align-items: center;
  gap: 20px;
}

.contact-page .contact-area .working-hour .icon {
  position: relative;
}

.contact-page .contact-area .working-hour .icon svg {
  fill: var(--primary-color1);
}

.contact-page .contact-area .working-hour .icon::after {
  content: "";
  height: 50px;
  width: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -10px;
  background-color: rgba(var(--title-color-opc), 0.15);
}

.contact-page .contact-area .working-hour .content>span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  margin-bottom: 7px;
}

.contact-page .contact-area .working-hour .content h6 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
}

.contact-page .contact-area .working-hour .content h6 span {
  color: var(--primary-color1);
}

.contact-page .company-map {
  width: 100%;
  height: 100%;
}

.contact-page .company-map iframe {
  width: 100%;
  height: 100%;
  min-height: 350px;
  filter: grayscale(199%) invert(72%) contrast(122%);
  border-radius: 10px;
}

.product-card {
  border: 1px solid #ececec;
  padding: 5px;
  transition: 0.5s;
  position: relative;
}

.product-card .product-card-img {
  position: relative;
  overflow: hidden;
}

.product-card .product-card-img>a img {
  transition: all 0.5s ease-out;
}

.product-card .product-card-img>a .batch {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.product-card .product-card-img>a .batch>span {
  width: 52px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  font-family: var(--font-rubik);
  letter-spacing: 0.3px;
  text-transform: uppercase;
  background-color: var(--primary-color1);
  z-index: 1;
}

.product-card .product-card-img .cart-area {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: 0.5s ease-in-out;
}

.product-card .product-card-img .cart-area .add-cart-btn {
  color: var(--white-color);
  text-align: center;
  font-size: 15px;
  font-family: var(--font-inter);
  font-weight: 500;
  background: var(--primary-color1);
  padding: 12px 18px;
  line-height: 1;
  display: flex;
  align-items: baseline;
  gap: 7px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
  transition: 0.5s;
}

.product-card .product-card-img .cart-area .add-cart-btn::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: var(--primary-color2);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}

.product-card .product-card-img .cart-area .add-cart-btn:hover {
  color: var(--dark-white-color);
}

.product-card .product-card-img .cart-area .add-cart-btn:hover::after {
  transform: skewX(45deg) scale(1, 1);
}

.product-card .product-card-content {
  padding: 30px 20px;
  text-align: center;
}

.product-card .product-card-content h6 {
  margin-bottom: 10px;
}

.product-card .product-card-content h6 a {
  color: var(--title-color);
  font-size: 25px;
  font-family: var(--font-hankenGrotesk);
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.3;
  transition: 0.5s;
}

@media (max-width: 1199px) {
  .product-card .product-card-content h6 a {
    font-size: 23px;
  }
}

.product-card .product-card-content h6 a:hover {
  color: var(--primary-color1);
}

.product-card .product-card-content span {
  color: var(--title-color);
  font-size: 18px;
  font-family: var(--font-hankenGrotesk);
  font-weight: 600;
  margin-bottom: 8px;
}

.product-card .product-card-content span del {
  color: #ababab;
  font-size: 15px;
  font-weight: 400;
}

.product-card .product-card-content .rating {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 8px;
}

.product-card .product-card-content .rating ul {
  display: flex;
  align-items: center;
  gap: 4px;
}

.product-card .product-card-content .rating ul li {
  line-height: 1;
}

.product-card .product-card-content .rating ul li i {
  color: #ffc200;
  font-size: 12px;
}

.product-card .product-card-content .rating span {
  color: var(--text-color);
  font-size: 13px;
  font-family: var(--font-inter);
  font-weight: 400;
  line-height: 1;
}

.product-card .for-border {
  width: 0;
}

.product-card .for-border::before {
  content: "";
  position: absolute;
  right: -0.5px;
  top: 0;
  height: 0%;
  width: 0.5px;
  background: rgba(var(--title-color-opc), 0.6);
  transition: 0.3s;
  transition-delay: 0.5s;
}

.product-card .for-border::after {
  content: "";
  position: absolute;
  left: -0.5px;
  bottom: 0;
  height: 0%;
  width: 0.5px;
  background: rgba(var(--title-color-opc), 0.6);
  transition: 0.3s;
  transition-delay: 0.6s;
}

.product-card::before {
  content: "";
  position: absolute;
  left: 0;
  top: -0.5px;
  width: 0%;
  height: 0.5px;
  background: rgba(var(--title-color-opc), 0.6);
  transition: 0.3s;
  transition-delay: 0.9s;
}

.product-card::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -0.5px;
  width: 0%;
  height: 0.5px;
  background: rgba(var(--title-color-opc), 0.6);
  transition: 0.3s;
  transition-delay: 0.9s;
}

.product-card:hover .product-card-img a img {
  transform: scale(1.1);
}

.product-card:hover .product-card-img .cart-area {
  opacity: 1;
  transform: scaleY(1);
}

.product-card:hover::before {
  width: 100%;
  transition-delay: 0s;
}

.product-card:hover::after {
  width: 100%;
  transition-delay: 0s;
}

.product-card:hover .for-border::before {
  height: 100%;
  transition-delay: 0.3s;
}

.product-card:hover .for-border::after {
  height: 100%;
  transition-delay: 0.3s;
}

.product-details-top-section .product-details-img {
  position: relative;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .product-details-top-section .product-details-img {
    flex-direction: column;
    gap: 40px;
  }
}

@media (max-width: 576px) {
  .product-details-top-section .product-details-img {
    flex-direction: column;
    gap: 40px;
  }
}

.product-details-top-section .product-details-img .product-details-tab-img {
  position: relative;
  overflow: hidden;
}

.product-details-top-section .product-details-img .product-details-tab-img img {
  border-radius: 10px;
}

@media (max-width: 991px) {
  .product-details-top-section .product-details-img .product-details-tab-img img {
    width: 100%;
  }
}

.product-details-top-section .product-details-img .nav-pills {
  padding-top: 30px;
  -moz-columns: 4;
  columns: 4;
  display: block;
}

@media (max-width: 576px) {
  .product-details-top-section .product-details-img .nav-pills {
    -moz-columns: unset;
    columns: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}

.product-details-top-section .product-details-img .nav-pills .nav-link {
  background-color: unset;
  border-radius: unset;
  padding: 0;
  position: relative;
}

.product-details-top-section .product-details-img .nav-pills .nav-link img {
  border-radius: 10px;
}

@media (max-width: 576px) {
  .product-details-top-section .product-details-img .nav-pills .nav-link img {
    max-width: 100px;
    min-width: 100px;
  }
}

.product-details-top-section .product-details-img .nav-pills .nav-link::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--title-color);
  opacity: 0.5;
  border-radius: 10px;
}

.product-details-top-section .product-details-img .nav-pills .nav-link.active::after {
  display: none;
}

.product-details-top-section .product-details-content h1 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 35px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.7px;
  margin-bottom: 10px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .product-details-top-section .product-details-content h1 {
    font-size: 33px;
  }
}

@media (max-width: 576px) {
  .product-details-top-section .product-details-content h1 {
    font-size: 30px;
    margin-bottom: 15px;
  }
}

.product-details-top-section .product-details-content .rating-review {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  .product-details-top-section .product-details-content .rating-review {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

.product-details-top-section .product-details-content .rating-review .rating {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
}

.product-details-top-section .product-details-content .rating-review .rating .star {
  display: flex;
  align-items: center;
  gap: 6px;
}

.product-details-top-section .product-details-content .rating-review .rating .star i {
  font-size: 14px;
  color: #ffc107;
}

.product-details-top-section .product-details-content .rating-review .rating a {
  color: rgba(var(--title-color-opc), 0.5);
  font-family: var(--font-hankenGrotesk);
  font-size: 15px;
  font-weight: 400;
  transition: 0.5s;
}

.product-details-top-section .product-details-content .rating-review .rating a:hover {
  color: var(--primary-color1);
}

.product-details-top-section .product-details-content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.48px;
  margin-bottom: 20px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .product-details-top-section .product-details-content p {
    font-size: 15px;
  }
}

.product-details-top-section .product-details-content .price-area {
  margin-bottom: 35px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .product-details-top-section .product-details-content .price-area {
    margin-bottom: 30px;
  }
}

.product-details-top-section .product-details-content .price-area span {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 25px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}

.product-details-top-section .product-details-content .price-area span del {
  color: #ababab;
  font-size: 18px;
  font-weight: 400;
}

.product-details-top-section .product-details-content .quantity-area {
  margin-bottom: 35px;
}

.product-details-top-section .product-details-content .quantity-area h6 {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.48px;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  .product-details-top-section .product-details-content .quantity-area h6 {
    margin-bottom: 15px;
  }
}

.product-details-top-section .product-details-content .quantity-area .quantity-counter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.product-details-top-section .product-details-content .quantity-area .quantity-counter a {
  width: 40px;
  height: 40px;
  background: rgba(var(--title-color-opc), 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--title-color);
  border-radius: 5px;
  transition: 0.5s;
}

.product-details-top-section .product-details-content .quantity-area .quantity-counter a:hover {
  background-color: var(--primary-color2);
  color: var(--dark-white-color);
}

.product-details-top-section .product-details-content .quantity-area .quantity-counter .quantity__input {
  max-width: 58px;
  width: 100%;
  height: 40px;
  border: 1px solid var(--primary-color2);
  background-color: var(--dark-white-color);
  border-radius: 5px;
  text-align: center;
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.48px;
}

.product-details-top-section .product-details-content .quantity-area .quantity-counter .quantity__input:focus {
  border: 1px solid #ddd;
}

.product-details-top-section .product-details-content .product-details-btn {
  display: flex;
  align-items: center;
  gap: 25px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 30px;
  flex-wrap: wrap;
}

@media (max-width: 576px) {
  .product-details-top-section .product-details-content .product-details-btn {
    gap: 20px;
  }
}

.product-details-top-section .product-details-content .product-details-btn .primary-btn1 {
  padding: 16px 30px;
}

.product-details-top-section .product-details-content .product-details-btn .primary-btn2 {
  padding: 16px 33px;
}

.product-details-top-section .product-details-content .payment-method {
  margin-top: 25px;
  padding: 17px 30px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  max-width: 460px;
  width: 100%;
  margin-bottom: 20px;
}

.product-details-top-section .product-details-content .payment-method h6 {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.48px;
  margin-bottom: 15px;
}

.product-details-top-section .product-details-content .payment-method .payment-card-list {
  display: flex;
  align-items: center;
  gap: 20px;
}

.product-details-top-section .product-details-content .product-shipping-delivers li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.product-details-top-section .product-details-content .product-shipping-delivers li:last-child {
  margin-bottom: 0;
}

.product-details-top-section .product-details-content .product-shipping-delivers li svg {
  fill: var(--primary-color1);
}

.product-details-top-section .product-details-content .product-shipping-delivers li p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  margin-bottom: 0;
}

.product-details-top-section .product-details-content .product-shipping-delivers li p a {
  color: var(--text-color);
  background: linear-gradient(to bottom, #100c08 0%, #100c08 98%);
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position: left 100%;
  transition: background-size 0.75s;
}

.product-details-top-section .product-details-content .product-shipping-delivers li p a:hover {
  background-size: 0 2px;
  background-position: 0% 100%;
  color: var(--primary-color1);
}

.product-details-top-section .product-details-content .wishlist-area {
  padding-top: 20px;
}

.product-details-top-section .product-details-content .wishlist-area a {
  color: var(--text-colorr);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  transition: 0.5s;
}

.product-details-top-section .product-details-content .wishlist-area a span {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 1px solid var(--title-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.product-details-top-section .product-details-content .wishlist-area a span svg {
  fill: var(--title-color);
  transition: 0.5s;
}

.product-details-top-section .product-details-content .wishlist-area a:hover {
  color: var(--primary-color1);
}

.product-details-top-section .product-details-content .wishlist-area a:hover span {
  border-color: var(--primary-color1);
  background-color: var(--primary-color1);
}

.product-details-top-section .product-details-content .wishlist-area a:hover span svg {
  fill: var(--white-color);
}

.product-details-description {
  scroll-margin-top: 150px;
}

.product-details-description .product-details-description-nav .nav-tabs {
  border-bottom: 1px solid var(--border-color);
  padding-left: 80px;
  gap: 65px;
}

@media (max-width: 991px) {
  .product-details-description .product-details-description-nav .nav-tabs {
    gap: 50px;
  }
}

@media (max-width: 767px) {
  .product-details-description .product-details-description-nav .nav-tabs {
    gap: 20px;
    padding-left: 0;
  }
}

@media (max-width: 576px) {
  .product-details-description .product-details-description-nav .nav-tabs {
    gap: 10px;
  }
}

.product-details-description .product-details-description-nav .nav-tabs .nav-link {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
  border: none;
  background: linear-gradient(to bottom,
      rgba(var(--primary-color2-opc), 1) 0%,
      rgba(var(--primary-color2-opc), 1) 98%);
  background-size: 0 3px;
  background-repeat: no-repeat;
  background-position: right 100%;
  transition: background-size 0.75s;
}

@media (max-width: 576px) {
  .product-details-description .product-details-description-nav .nav-tabs .nav-link {
    padding-bottom: 5px;
    margin-right: 15px;
    padding: 0;
  }
}

.product-details-description .product-details-description-nav .nav-tabs .nav-link.active {
  color: var(--title-color);
  font-weight: 500;
  background-size: 100% 3px;
  background-position: 0% 100%;
}

.product-details-description .product-details-description-tab .description-content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.45px;
  margin-bottom: 25px;
}

.product-details-description .product-details-description-tab .description-content p:last-child {
  margin-bottom: 0;
}

.product-details-description .product-details-description-tab .addithonal-information .total-table2 {
  margin-bottom: 0;
}

.product-details-description .product-details-description-tab .addithonal-information .total-table2 tbody tr {
  border-width: 1px;
  border-color: var(--border-color);
}

.product-details-description .product-details-description-tab .addithonal-information .total-table2 tbody tr td {
  border-width: 1px;
  border-color: var(--border-color);
  background: var(--dark-white-color);
  padding: 15px;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  line-height: 30px;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .product-details-description .product-details-description-tab .addithonal-information .total-table2 tbody tr td {
    padding: 10px;
  }
}

.product-details-description .product-details-description-tab .addithonal-information .total-table2 tbody tr td span {
  font-weight: 600;
  font-size: 16px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  text-align: start;
  line-height: 26px;
}

@media (min-width: 992px) {
  .product-details-description .product-details-description-tab .addithonal-information .total-table2 tbody tr td span {
    width: 25%;
  }
}

.product-details-description .product-details-description-tab .reviews-area .number-of-review {
  line-height: 1;
  margin-bottom: 40px;
}

.product-details-description .product-details-description-tab .reviews-area .number-of-review h4 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 24px;
  font-weight: 600;
}

@media (max-width: 576px) {
  .product-details-description .product-details-description-tab .reviews-area .number-of-review h4 {
    font-size: 22px;
  }
}

.product-details-description .product-details-description-tab .reviews-area .comment {
  margin: 0;
  padding: 0;
  list-style: none;
}

.product-details-description .product-details-description-tab .reviews-area .comment>li {
  margin-bottom: 40px;
}

.product-details-description .product-details-description-tab .reviews-area .comment>li>.single-comment-area {
  margin-bottom: 24px;
}

.product-details-description .product-details-description-tab .reviews-area .comment>li:last-child {
  margin-bottom: 0;
}

.product-details-description .product-details-description-tab .reviews-area .comment>li:last-child>.single-comment-area {
  margin-bottom: 0;
}

.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .author-img img {
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
}

.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .author-and-review .author-name-deg {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .author-and-review .author-name-deg h6 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 15px;
  font-weight: 600;
}

.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .author-and-review .author-name-deg span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .author-and-review .review {
  gap: 5px;
  line-height: 1;
}

.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .author-and-review .review li {
  line-height: 1;
}

.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .author-and-review .review li i {
  color: #dda701;
  font-size: 12px;
}

.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 12px;
}

.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .replay-btn {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  cursor: pointer;
  transition: 0.35s;
}

.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .replay-btn svg {
  transition: 0.35s;
  fill: var(--text-color);
}

.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .replay-btn:hover {
  color: var(--primary-color1);
}

.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .replay-btn:hover svg {
  fill: var(--primary-color1);
}

.product-details-description .product-details-description-tab .reviews-area .comment .comment-replay {
  margin-left: 70px;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  padding: 24px 0;
}

.product-details-description .product-details-description-tab .reviews-area .comment .comment-replay>li {
  margin-bottom: 30px;
}

.product-details-description .product-details-description-tab .reviews-area .comment .comment-replay>li:last-child {
  margin-bottom: 0;
}

.product-details-description .product-details-description-tab .review-form {
  background-color: var(--dark-white-color);
  border: 1px solid var(--border-color);
  box-shadow: 4px 3px 40px 0 rgba(16, 33, 34, 0.06);
  padding: 50px 40px;
  border-radius: 10px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .product-details-description .product-details-description-tab .review-form {
    padding: 40px 30px;
  }
}

@media (max-width: 576px) {
  .product-details-description .product-details-description-tab .review-form {
    padding: 40px 25px;
  }
}

.product-details-description .product-details-description-tab .review-form .number-of-review {
  line-height: 1;
  margin-bottom: 30px;
}

.product-details-description .product-details-description-tab .review-form .number-of-review h4 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 24px;
  font-weight: 600;
}

.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area {
  margin-bottom: 15px;
}

.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area p {
  margin-bottom: 5px;
  color: var(--title-color);
}

.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate {
  float: left;
}

.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate:not(:checked)>input {
  position: absolute;
  top: -9999px;
}

.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate:not(:checked)>label {
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  color: #ccc;
  padding-right: 8px;
  display: inline-block;
  margin-right: 8px;
}

.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate:not(:checked)>label::before {
  content: "\f586";
  font-family: bootstrap-icons !important;
}

.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate input:checked~label {
  color: #dda701;
}

.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate:not(:checked)>label:hover,
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate:not(:checked)>label:hover~label {
  color: #dda701;
}

.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area input:checked+label:hover,
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area input:checked+label:hover~label,
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area input:checked~label:hover,
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area input:checked~label:hover~label,
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area label:hover~input:checked~label {
  color: #dda701;
}

.product-details-description .product-details-description-tab .review-form .primary-btn1 {
  padding: 16px 35px;
}

.cart-page .cart-widget-title {
  margin-bottom: 35px;
}

@media (max-width: 991px) {
  .cart-page .cart-widget-title {
    margin-bottom: 25px;
  }
}

.cart-page .cart-widget-title h4 {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 30px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 0;
}

.cart-page .cart-shopping-wrapper .cart-table {
  width: 100%;
}

@media (max-width: 767px) {
  .cart-page .cart-shopping-wrapper .cart-table {
    margin-bottom: 30px;
  }
}

.cart-page .cart-shopping-wrapper .cart-table thead tr {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.cart-page .cart-shopping-wrapper .cart-table thead tr th {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  padding: 20px 15px;
}

@media (max-width: 767px) {
  .cart-page .cart-shopping-wrapper .cart-table thead tr th {
    display: none;
  }
}

.cart-page .cart-shopping-wrapper .cart-table thead tr th:first-child {
  padding-left: 0;
}

@media (max-width: 767px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr {
    border-bottom: 1px solid var(--border-color);
  }
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td {
  padding: 30px 15px;
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td span {
  color: var(--text-color);
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td:first-child {
  padding-left: 0;
}

@media (max-width: 767px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr td {
    text-align: right;
  }
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media (max-width: 767px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper {
    align-items: flex-end;
    flex-direction: column;
  }
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-img img {
  border-radius: 5px;
  max-width: 120px;
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content h6 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 10px;
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 35px;
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content p span {
  color: var(--title-color);
  font-weight: 600;
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media (max-width: 767px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul {
    justify-content: flex-end;
  }
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li:last-child::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -10px;
  width: 1px;
  height: 11px;
  background-color: rgba(var(--title-color-opc), 0.2);
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area {
  position: absolute;
  top: -30px;
  right: -60px;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: bottom;
}

@media (max-width: 991px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area {
    right: unset;
    left: -60px;
  }
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area .quantity {
  display: flex;
  gap: 5px;
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: rgba(var(--title-color-opc), 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area .quantity a i {
  color: var(--title-color);
  transition: 0.35s;
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area .quantity a:hover {
  background: var(--primary-color2);
  color: var(--dark-white-color);
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area .quantity a:hover i {
  color: var(--dark-white-color);
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  border: 1px solid var(--primary-color2);
  background-color: var(--dark-white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area.active {
  opacity: 1;
  transform: scaleY(1);
  animation: fade-down 0.3s linear;
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li:hover {
  color: #ff4820;
}

.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li:hover:last-child {
  color: var(--primary-color1);
}

@media (max-width: 767px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr td {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
    padding: 15px;
  }

  .cart-page .cart-shopping-wrapper .cart-table tbody tr td::before {
    content: attr(data-label);
    position: absolute;
    left: 15px;
    color: var(--title-color);
    font-family: var(--font-inter);
    font-size: 18px;
    font-weight: 500;
  }
}

@media (max-width: 767px) and (max-width: 576px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr td::before {
    font-size: 13px;
    font-weight: 700;
  }
}

.cart-page .cart-shopping-wrapper .details-button {
  background-image: linear-gradient(0deg, #3067ff 0%, #3067ff 100%);
  color: var(--primary-color1);
}

.cart-page .cart-shopping-wrapper .details-button::after {
  color: var(--primary-color1);
}

.cart-page .cart-shopping-wrapper .details-button svg {
  stroke: var(--primary-color1);
}

@media (min-width: 992px) {
  .cart-page .cart-order-sum-area {
    padding-left: 15px;
  }
}

.cart-page .cart-order-sum-area .order-summary-wrap {
  padding: 40px 30px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .cart-page .cart-order-sum-area .order-summary-wrap {
    padding: 40px 25px;
  }
}

@media (max-width: 576px) {
  .cart-page .cart-order-sum-area .order-summary-wrap {
    padding: 35px 25px;
  }
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list {
  width: 100%;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
  line-height: 1;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li:first-child {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li span {
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .order-info {
  text-align: right;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .order-info p {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 5px;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .order-info strong {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area {
  width: 100%;
  margin-bottom: 10px;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area span {
  margin-bottom: 15px;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner {
  position: relative;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner input {
  width: 100%;
  height: 45px;
  padding: 10px 100px 10px 20px;
  border-radius: 5px;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner input::-moz-placeholder {
  color: rgba(var(--title-color-opc), 0.5);
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner input::placeholder {
  color: rgba(var(--title-color-opc), 0.5);
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner input:focus {
  border-color: #eee;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner .apply-btn {
  padding: 13px 23px;
  border-radius: 0 5px 5px 0;
  background-color: var(--primary-color2);
  border: 1px solid var(--dark-title-color);
  color: var(--dark-white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.48px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  transition: 0.5s;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
  position: absolute;
  top: 0;
  right: 0;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner .apply-btn::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: var(--dark-white-color);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner .apply-btn:hover {
  color: var(--title-color);
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner .apply-btn:hover::after {
  transform: skewX(45deg) scale(1, 1);
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li:last-child {
  margin-bottom: 40px;
}

.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li:last-child span {
  font-size: 16px;
  font-weight: 600;
}

.cart-page .cart-order-sum-area .order-summary-wrap>.primary-btn1 {
  width: 100%;
  justify-content: center;
  padding: 18px 25px;
}

.checkout-page .checkout-form-wrapper .checkout-form-title {
  margin-bottom: 30px;
}

.checkout-page .checkout-form-wrapper .checkout-form-title h4 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 30px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .checkout-page .checkout-form-wrapper .checkout-form-title h4 {
    font-size: 26px;
  }
}

.checkout-page .checkout-form-wrapper .checkout-form-title p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}

.checkout-page .checkout-form-wrapper .checkout-form {
  border-radius: 10px;
  border: 1px solid var(--border-color);
  padding: 45px 35px 50px;
}

@media (max-width: 767px) {
  .checkout-page .checkout-form-wrapper .checkout-form {
    padding: 45px 20px 50px;
  }
}

.checkout-page .checkout-form-wrapper .checkout-form .form-check {
  min-height: unset;
  margin-bottom: 0;
}

.checkout-page .checkout-form-wrapper .checkout-form .form-check .form-check-input {
  cursor: pointer;
  height: 14px;
  width: 14px;
  margin-top: 8px;
}

.checkout-page .checkout-form-wrapper .checkout-form .form-check .form-check-input:focus {
  box-shadow: none;
}

.checkout-page .checkout-form-wrapper .checkout-form .form-check .form-check-input:checked {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.checkout-page .checkout-form-wrapper .checkout-form .form-check .form-check-input[type="checkbox"] {
  border-radius: 3px;
}

.checkout-page .checkout-form-wrapper .checkout-form .form-check .form-check-label {
  cursor: pointer;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}

.checkout-page .cart-menu {
  min-height: 10rem;
  z-index: 99;
  transform-origin: top;
}

.checkout-page .cart-body ul {
  margin-bottom: 50px;
}

.checkout-page .cart-body ul .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.checkout-page .cart-body ul .single-item:last-child {
  margin-bottom: 0;
}

.checkout-page .cart-body ul .single-item .item-area {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  width: 100%;
}

.checkout-page .cart-body ul .single-item .item-area .close-btn {
  background: 0 0;
  transition: 0.35s;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 72, 32, 0.14);
}

.checkout-page .cart-body ul .single-item .item-area .close-btn i {
  color: #ff4820;
  line-height: 1;
  font-size: 20px;
  transition: 0.35s;
}

.checkout-page .cart-body ul .single-item .item-area .close-btn:hover {
  background-color: #ff4820;
}

.checkout-page .cart-body ul .single-item .item-area .close-btn:hover i {
  color: var(--white-color);
}

.checkout-page .cart-body ul .single-item .item-area .main-item {
  display: flex;
  gap: 15px;
  width: 100%;
}

@media (max-width: 767px) {
  .checkout-page .cart-body ul .single-item .item-area .main-item {
    gap: 12px;
  }
}

.checkout-page .cart-body ul .single-item .item-area .main-item .item-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.checkout-page .cart-body ul .single-item .item-area .main-item .item-img img {
  height: 70px;
  max-width: 90px;
  min-width: 90px;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .checkout-page .cart-body ul .single-item .item-area .main-item .item-img img {
    width: 45px;
    height: unset;
  }
}

.checkout-page .cart-body ul .single-item .item-area .main-item .content-and-quantity {
  width: 100%;
}

.checkout-page .cart-body ul .single-item .item-area .main-item .content {
  line-height: 1;
  margin-bottom: 10px;
}

.checkout-page .cart-body ul .single-item .item-area .main-item .content span {
  font-family: var(--font-hankenGrotesk);
  font-weight: 600;
  font-size: 14px;
  color: var(--text-color);
  display: inline-block;
}

.checkout-page .cart-body ul .single-item .item-area .main-item .content h6 {
  margin-bottom: 0;
  line-height: 1;
}

.checkout-page .cart-body ul .single-item .item-area .main-item .content h6 a {
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: var(--title-color);
  transition: 0.5s;
}

@media (max-width: 767px) {
  .checkout-page .cart-body ul .single-item .item-area .main-item .content h6 a {
    font-size: 14px;
  }
}

.checkout-page .cart-body ul .single-item .item-area .main-item .content h6 a:hover {
  color: var(--primary-color1);
}

.checkout-page .cart-body ul .single-item .quantity-area .quantity {
  display: flex;
  gap: 5px;
}

.checkout-page .cart-body ul .single-item .quantity-area .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: rgba(var(--title-color-opc), 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}

.checkout-page .cart-body ul .single-item .quantity-area .quantity a i {
  color: var(--title-color);
  transition: 0.35s;
}

.checkout-page .cart-body ul .single-item .quantity-area .quantity a:hover {
  background: var(--primary-color2);
  color: var(--dark-white-color);
}

.checkout-page .cart-body ul .single-item .quantity-area .quantity a:hover i {
  color: var(--dark-white-color);
}

.checkout-page .cart-body ul .single-item .quantity-area .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--dark-white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}

.checkout-page .cart-footer {
  justify-content: flex-start;
  padding: 0;
  border-top: 1px solid var(--border-color);
}

.checkout-page .cart-footer .pricing-area {
  width: 100%;
}

.checkout-page .cart-footer .pricing-area ul {
  border-bottom: 1px solid var(--border-color);
  width: 100%;
  padding: 10px 0;
}

.checkout-page .cart-footer .pricing-area ul:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 30px;
}

.checkout-page .cart-footer .pricing-area ul li {
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 16px;
  color: var(--title-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.checkout-page .cart-footer .pricing-area ul.total li {
  font-size: 18px;
  font-weight: 700;
}

.checkout-page .cart-footer .primary-btn1 {
  width: 100%;
  justify-content: center;
}

.checkout-page .choose-payment-method {
  margin-bottom: 40px;
  line-height: 1;
}

.checkout-page .choose-payment-method h6 {
  margin-bottom: 10px;
  color: var(--title-color);
  font-family: var(--font-inter);
  font-size: 17px;
  font-weight: 600;
}

.checkout-page .choose-payment-method .payment-option {
  line-height: 1;
}

.checkout-page .choose-payment-method .payment-option ul {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.checkout-page .choose-payment-method .payment-option ul li {
  width: 110px;
  height: 68px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.checkout-page .choose-payment-method .payment-option ul li .checked {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  background: #d2e7ef;
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  border-top-left-radius: 5px;
}

.checkout-page .choose-payment-method .payment-option ul li .checked i {
  opacity: 0;
  color: var(--white-color);
  margin-left: 2px;
}

.checkout-page .choose-payment-method .payment-option ul li.active .checked {
  background: var(--primary-color1);
}

.checkout-page .choose-payment-method .payment-option ul li.active .checked i {
  opacity: 1;
}

.error-page {
  padding: 170px 0 120px;
}

@media (max-width: 1399px) {
  .error-page {
    padding: 130px 0 120px;
  }
}

@media (max-width: 991px) {
  .error-page {
    padding: 120px 0 90px;
  }
}

.error-page .error-content {
  text-align: center;
}

.error-page .error-content h2 {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 95px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .error-page .error-content h2 {
    font-size: 85px;
  }
}

@media (max-width: 576px) {
  .error-page .error-content h2 {
    font-size: 65px;
  }
}

.error-page .error-content h1 {
  color: var(--primary-color1);
  font-family: var(--font-inter);
  font-size: 300px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 0.4;
  margin-bottom: 85px;
}

@media (max-width: 991px) {
  .error-page .error-content h1 {
    font-size: 250px;
    margin-bottom: 75px;
  }
}

@media (max-width: 576px) {
  .error-page .error-content h1 {
    font-size: 150px;
    margin-bottom: 50px;
  }
}

.error-page .error-content h3 {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 80px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .error-page .error-content h3 {
    margin-bottom: 30px;
  }
}

@media (max-width: 576px) {
  .error-page .error-content h3 {
    font-size: 50px;
    margin-bottom: 20px;
  }
}

.error-page .error-content p {
  max-width: 790px;
  width: 100%;
  margin: 0 auto;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 30px;
}

@media (max-width: 576px) {
  .error-page .error-content p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}

.error-page .error-content .star-btn a {
  width: 154px;
  height: 90px;
}

.error-page .error-content .star-btn a .details-button {
  font-size: 15px;
}

.portfolio-details-page {
  scroll-margin-top: 50px;
}

.portfolio-details-page .portfolio-details-thumb img {
  border-radius: 20px;
}

@media (max-width: 767px) {
  .portfolio-details-page .portfolio-details-thumb img {
    border-radius: 10px;
  }
}

.portfolio-details-page .portfolio-details-content img {
  border-radius: 20px;
}

@media (max-width: 767px) {
  .portfolio-details-page .portfolio-details-content img {
    border-radius: 10px;
  }
}

.portfolio-details-page .portfolio-details-content h3 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 33px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .portfolio-details-page .portfolio-details-content h3 {
    font-size: 28px;
  }
}

.portfolio-details-page .portfolio-details-content p {
  margin-bottom: 12px;
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 35px;
}

.portfolio-details-page .portfolio-details-content p:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .portfolio-details-page .portfolio-details-content p {
    font-size: 17px;
    line-height: 30px;
  }
}

.portfolio-details-page .portfolio-details-content .features {
  margin: 0;
  padding: 0;
  list-style: none;
  -moz-columns: 2;
  columns: 2;
}

@media (max-width: 767px) {
  .portfolio-details-page .portfolio-details-content .features {
    -moz-columns: 1;
    columns: 1;
  }
}

.portfolio-details-page .portfolio-details-content .features li {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 13px;
}

.portfolio-details-page .portfolio-details-content .features li:last-child {
  margin-bottom: 0;
}

.portfolio-details-page .portfolio-details-content .features li svg {
  fill: var(--primary-color1);
  margin-right: 5px;
}

.portfolio-details-page .portfolio-details-content .portfolio-img-group {
  padding-top: 50px;
  margin-bottom: 25px;
}

.portfolio-details-page .portfolio-details-content .portfolio-img-group .portfolio-img img {
  border-radius: 10px;
}

.portfolio-details-page .portfolio-sidebar .portfolio-info-wrap {
  border-radius: 10px;
  border: 1px solid var(--border-color);
  background: rgba(48, 103, 255, 0.03);
  padding: 35px 30px;
}

@media (max-width: 1199px) {
  .portfolio-details-page .portfolio-sidebar .portfolio-info-wrap {
    padding: 25px;
  }
}

.portfolio-details-page .portfolio-sidebar .portfolio-info-wrap .portfolio-info {
  margin: 0;
  padding: 0;
  list-style: none;
}

.portfolio-details-page .portfolio-sidebar .portfolio-info-wrap .portfolio-info li {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 20px;
  margin-bottom: 15px;
}

.portfolio-details-page .portfolio-sidebar .portfolio-info-wrap .portfolio-info li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.portfolio-details-page .portfolio-sidebar .portfolio-info-wrap .portfolio-info li .icon svg {
  stroke: rgba(var(--title-color-opc), 0.2);
}

.portfolio-details-page .portfolio-sidebar .portfolio-info-wrap .portfolio-info li .content {
  line-height: 1;
}

.portfolio-details-page .portfolio-sidebar .portfolio-info-wrap .portfolio-info li .content span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  margin-bottom: 3px;
}

.portfolio-details-page .portfolio-sidebar .portfolio-info-wrap .portfolio-info li .content h5 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .portfolio-details-page .portfolio-sidebar .portfolio-info-wrap .portfolio-info li .content h5 {
    font-size: 18px;
  }
}

.portfolio-details-page .portfolio-details-banner {
  position: relative;
}

.portfolio-details-page .portfolio-details-banner img {
  border-radius: 10px;
}

.portfolio-details-page .portfolio-details-banner .portfolio-details-banner-content {
  background: linear-gradient(190deg,
      rgba(23, 23, 23, 0.9) 20.03%,
      rgba(23, 23, 23, 0.1) 92.25%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 70px 35px;
}

@media (max-width: 576px) {
  .portfolio-details-page .portfolio-details-banner .portfolio-details-banner-content {
    padding: 45px 15px;
  }
}

.portfolio-details-page .portfolio-details-banner .portfolio-details-banner-content h2 {
  color: var(--white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 45px;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: 0.45px;
  margin-bottom: 35px;
}

@media (max-width: 576px) {
  .portfolio-details-page .portfolio-details-banner .portfolio-details-banner-content h2 {
    font-size: 42px;
    margin-bottom: 30px;
  }
}

.portfolio-details-page .portfolio-details-banner .portfolio-details-banner-content h2 span {
  color: var(--primary-color1);
  font-style: italic;
  font-weight: 500;
  display: block;
}

.service-details-page .service-details-top-area .service-details-top-content h2 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 60px;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 30px;
}

@media (max-width: 1199px) {
  .service-details-page .service-details-top-area .service-details-top-content h2 {
    font-size: 50px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .service-details-page .service-details-top-area .service-details-top-content h2 {
    font-size: 45px;
  }
}

.service-details-page .service-details-top-area .service-details-top-content h2 span {
  color: var(--text-color);
  font-weight: 400;
  display: block;
}

.service-details-page .service-details-top-area .service-details-top-content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.9;
  margin-bottom: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-details-page .service-details-top-area .service-details-top-content p {
    font-size: 15px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .service-details-page .service-details-top-area .service-details-top-content p {
    margin-bottom: 25px;
  }
}

.service-details-page .service-details-top-area .service-details-top-content .key-features {
  padding: 0;
  margin: 0;
  list-style: none;
  -moz-columns: 2;
  columns: 2;
}

@media (max-width: 576px) {
  .service-details-page .service-details-top-area .service-details-top-content .key-features {
    -moz-columns: 1;
    columns: 1;
  }
}

.service-details-page .service-details-top-area .service-details-top-content .key-features li {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 17px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.34px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-details-page .service-details-top-area .service-details-top-content .key-features li {
    font-size: 16px;
  }
}

.service-details-page .service-details-top-area .service-details-top-content .key-features li:last-child {
  margin-bottom: 0;
}

.service-details-page .service-details-top-area .service-details-top-content .key-features li svg {
  fill: var(--primary-color1);
}

.service-details-page .service-details-top-area .service-details-img img {
  border-radius: 5px;
}

.service-details-feature-section .section-title5 {
  position: sticky;
  top: 100px;
}

.service-details-feature-section .service-feature-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.service-details-feature-section .service-feature-list .single-feature {
  display: flex;
  gap: 20px;
  padding-bottom: 25px;
  margin-bottom: 35px;
  border-bottom: 1px solid var(--border-color);
}

@media (max-width: 576px) {
  .service-details-feature-section .service-feature-list .single-feature {
    gap: 15px;
  }
}

.service-details-feature-section .service-feature-list .single-feature:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: unset;
}

.service-details-feature-section .service-feature-list .single-feature .icon {
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(var(--primary-color1-opc), 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-details-feature-section .service-feature-list .single-feature .icon svg {
  fill: var(--primary-color1);
}

.service-details-feature-section .service-feature-list .single-feature .content h5 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 10px;
}

@media (max-width: 576px) {
  .service-details-feature-section .service-feature-list .single-feature .content h5 {
    font-size: 20px;
  }
}

.service-details-feature-section .service-feature-list .single-feature .content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .service-details-feature-section .service-feature-list .single-feature .content p {
    font-size: 15px;
  }
}

.service-details-tools-section .tools-card {
  border: 1px solid var(--border-color);
  background-color: var(--white-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  line-height: 1;
  padding: 32px 20px;
}

.service-details-tools-section .tools-card .tools-name span {
  color: var(--dark-title-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
}

.blog-details .blog-details-thumb {
  position: relative;
}

.blog-details .blog-details-thumb .batch {
  position: absolute;
  top: 20px;
  left: 15px;
}

.blog-details .blog-details-thumb .batch span {
  color: var(--dark-white-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: 1;
  border-radius: 50px;
  border: 1px solid var(--white-color);
  background: var(--title-color);
  padding: 6px 26px;
}

.blog-details .blog-details-thumb img {
  border-radius: 5px;
}

.blog-details .blog-details-author-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 60px;
  gap: 15px;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .blog-details .blog-details-author-meta {
    margin-bottom: 50px;
    padding: 20px 0;
  }
}

.blog-details .blog-details-author-meta .author-area {
  display: flex;
  align-items: center;
  gap: 10px;
}

.blog-details .blog-details-author-meta .author-area .author-img img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.blog-details .blog-details-author-meta .author-area .author-content h6 {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
}

.blog-details .blog-details-author-meta .author-area .author-content h6 a {
  color: var(--primary-color1);
}

.blog-details .blog-details-author-meta ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.blog-details .blog-details-author-meta ul li {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  letter-spacing: 0.3px;
  margin-right: 50px;
  position: relative;
  transition: 0.25s ease;
}

@media (min-width: 576px) and (max-width: 768px) {
  .blog-details .blog-details-author-meta ul li {
    margin-right: 20px;
  }
}

@media (max-width: 576px) {
  .blog-details .blog-details-author-meta ul li {
    margin-right: 16px;
    font-size: 12px;
    line-height: 1.2;
  }
}

.blog-details .blog-details-author-meta ul li:hover a {
  color: var(--primary-color1);
}

.blog-details .blog-details-author-meta ul li:hover a svg {
  fill: var(--primary-color1);
}

.blog-details .blog-details-author-meta ul li::before {
  content: "";
  position: absolute;
  right: -15px;
  top: 3px;
  width: 2px;
  height: 10px;
  line-height: 5px;
  background-color: var(--primary-color1);
  border-radius: 4px;
}

@media (min-width: 576px) and (max-width: 768px) {
  .blog-details .blog-details-author-meta ul li::before {
    right: -10px;
  }
}

@media (max-width: 576px) {
  .blog-details .blog-details-author-meta ul li::before {
    right: -10px;
  }
}

.blog-details .blog-details-author-meta ul li:last-child {
  margin-right: 0;
}

.blog-details .blog-details-author-meta ul li:last-child::before {
  display: none;
  visibility: hidden;
}

.blog-details .blog-details-author-meta ul li svg {
  width: 14px;
  margin-right: 10px;
  fill: var(--text-color);
  transition: 0.25s ease;
}

@media (min-width: 576px) and (max-width: 768px) {
  .blog-details .blog-details-author-meta ul li svg {
    margin-right: 5px;
  }
}

@media (max-width: 576px) {
  .blog-details .blog-details-author-meta ul li svg {
    margin-right: 5px;
  }
}

.blog-details .blog-details-author-meta ul li a {
  color: var(--text-color);
}

.blog-details .blog-details-content {
  margin-bottom: 90px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-details .blog-details-content {
    margin-bottom: 80px;
  }
}

@media (max-width: 991px) {
  .blog-details .blog-details-content {
    margin-bottom: 70px;
  }
}

.blog-details .blog-details-content h3 {
  font-family: var(--font-hankenGrotesk);
  font-weight: 800;
  font-size: 35px;
  line-height: 1.2;
  color: var(--title-color);
  margin-bottom: 15px;
}

@media (max-width: 1199px) {
  .blog-details .blog-details-content h3 {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .blog-details .blog-details-content h3 {
    font-size: 30px;
  }
}

.blog-details .blog-details-content p {
  font-family: var(--font-inter);
  font-weight: 400;
  font-size: 18px;
  line-height: 1.8;
  color: var(--text-color);
  margin-bottom: 35px;
}

@media (max-width: 991px) {
  .blog-details .blog-details-content p {
    font-size: 17px;
    margin-bottom: 25px;
  }
}

@media (max-width: 576px) {
  .blog-details .blog-details-content p {
    font-size: 15px;
  }
}

.blog-details .blog-details-content img {
  border-radius: 5px;
}

.blog-details .blog-details-content ul {
  padding: 0;
  margin: 0;
  list-style: none;
  -moz-columns: 2;
  columns: 2;
}

@media (max-width: 576px) {
  .blog-details .blog-details-content ul {
    -moz-columns: unset;
    columns: unset;
  }
}

.blog-details .blog-details-content ul li {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 17px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.34px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .blog-details .blog-details-content ul li {
    font-size: 16px;
  }
}

.blog-details .blog-details-content ul li:last-child {
  margin-bottom: 0;
}

.blog-details .blog-details-content ul li svg {
  fill: var(--primary-color1);
}

.blog-details .blog-details-content .first-para {
  margin-bottom: 10px;
}

@media (max-width: 576px) {
  .blog-details .blog-details-content .first-para {
    margin-bottom: 15px;
  }
}

.blog-details .blog-details-content .first-para::first-letter {
  font-size: 75px;
  float: left;
  line-height: 1;
  color: var(--title-color);
  font-weight: 500;
}

@media (max-width: 576px) {
  .blog-details .blog-details-content .first-para::first-letter {
    font-size: 60px;
  }
}

.blog-details .blog-details-content .second-para {
  margin-bottom: 65px;
}

@media (max-width: 991px) {
  .blog-details .blog-details-content .second-para {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .blog-details .blog-details-content .second-para {
    margin-bottom: 35px;
  }
}

.blog-details .blog-details-content blockquote {
  position: relative;
}

.blog-details .blog-details-content blockquote .quotes {
  fill: none;
  stroke: rgba(var(--primary-color1-opc), 0.15);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.blog-details .blog-details-content .blockquote-text {
  margin-bottom: 35px;
  color: rgba(var(--text-color-opc), 0.6);
  font-size: 22px;
}

@media (max-width: 767px) {
  .blog-details .blog-details-content .blockquote-text {
    margin-bottom: 25px;
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .blog-details .blog-details-content .blockquote-text {
    margin-bottom: 20px;
  }
}

.blog-details .blog-details-content .third-para {
  margin-bottom: 45px;
}

.blog-details .blog-details-content .blockquote-author {
  font-family: var(--font-hankenGrotesk);
  font-weight: 600;
  font-size: 30px;
  line-height: 1;
  color: var(--title-color);
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .blog-details .blog-details-content .blockquote-author {
    margin-bottom: 0;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .blog-details .blog-details-content .post-details-img img {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .blog-details .blog-details-content .post-details-img img {
    width: 100%;
  }
}

.blog-details .blog-details-content .post-tags-social {
  margin-top: 70px;
  margin-bottom: 34px;
}

@media (max-width: 767px) {
  .blog-details .blog-details-content .post-tags-social {
    margin-top: 50px;
  }
}

.blog-details .blog-details-content .post-tags-social .tags ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .blog-details .blog-details-content .post-tags-social .tags ul {
    gap: 8px;
  }
}

@media (max-width: 767px) {
  .blog-details .blog-details-content .post-tags-social .tags ul {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .blog-details .blog-details-content .post-tags-social .tags ul {
    gap: 10px;
  }
}

@media (max-width: 350px) {
  .blog-details .blog-details-content .post-tags-social .tags ul {
    gap: 6px;
  }
}

.blog-details .blog-details-content .post-tags-social .tags ul li a {
  font-family: var(--font-prompt);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  color: #acacac;
  transition: 0.5s;
}

@media (max-width: 350px) {
  .blog-details .blog-details-content .post-tags-social .tags ul li a {
    font-size: 14px;
  }
}

.blog-details .blog-details-content .post-tags-social .tags ul li a span {
  color: rgba(255, 76, 96, 0.5);
}

.blog-details .blog-details-content .post-tags-social .tags ul li a:hover {
  color: var(--primary-one);
}

.blog-details .blog-details-content .post-tags-social .social {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
}

@media (max-width: 767px) {
  .blog-details .blog-details-content .post-tags-social .social {
    justify-content: flex-start;
  }
}

.blog-details .blog-details-content .post-tags-social .social p {
  font-family: var(--font-prompt);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  color: #2c2c2c;
  margin: 0;
}

.blog-details .blog-details-content .post-tags-social .social ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 30px;
}

.blog-details .blog-details-content .post-tags-social .social ul li {
  transition: 0.5s;
}

.blog-details .blog-details-content .post-tags-social .social ul li a {
  font-size: 18px;
  color: var(--border-color2);
  transition: 0.5s;
}

.blog-details .blog-details-content .post-tags-social .social ul li a:hover {
  color: var(--primary-one);
}

.blog-details .blog-tag-and-social-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.blog-details .blog-tag-and-social-area .blog-tag {
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 10px;
}

.blog-details .blog-tag-and-social-area .blog-tag h6 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}

.blog-details .blog-tag-and-social-area .blog-tag ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.blog-details .blog-tag-and-social-area .blog-tag ul li a {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  transition: 0.5s;
}

.blog-details .blog-tag-and-social-area .blog-tag ul li a:hover {
  color: var(--primary-color1);
}

.blog-details .blog-tag-and-social-area .social-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}

.blog-details .blog-tag-and-social-area .social-list li {
  display: block;
  text-align: center;
}

.blog-details .blog-tag-and-social-area .social-list li a i {
  color: var(--text-color);
  transition: 0.5s;
}

.blog-details .blog-tag-and-social-area .social-list li a span {
  color: var(--text-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  display: block;
  padding-top: 2px;
  transition: 0.5s;
}

.blog-details .blog-tag-and-social-area .social-list li:hover a i {
  color: var(--primary-color1);
}

.blog-details .blog-tag-and-social-area .social-list li:hover a svg {
  fill: var(--primary-color1);
}

.blog-details .blog-tag-and-social-area .social-list li:hover a span {
  color: var(--primary-color1);
}

.blog-details .comment-area {
  scroll-margin-top: 150px;
}

.blog-details .comment-title {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
}

.blog-details .comment-title h4 {
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1;
  white-space: nowrap;
}

.blog-details .comment-title .dash {
  position: relative;
  width: 100%;
}

.blog-details .comment-title .dash::after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: var(--border-color);
}

.blog-details .comment {
  margin: 0;
  padding: 0;
  list-style: none;
}

.blog-details .comment>li {
  margin-bottom: 40px;
}

.blog-details .comment>li>.single-comment-area {
  margin-bottom: 24px;
}

.blog-details .comment>li:last-child {
  margin-bottom: 0;
}

.blog-details .comment>li:last-child>.single-comment-area {
  margin-bottom: 0;
}

.blog-details .comment .single-comment-area {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.blog-details .comment .single-comment-area .author-img img {
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
}

.blog-details .comment .single-comment-area .comment-content .author-name-deg {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.blog-details .comment .single-comment-area .comment-content .author-name-deg h6 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 18px;
  font-weight: 600;
}

.blog-details .comment .single-comment-area .comment-content .author-name-deg span {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.blog-details .comment .single-comment-area .comment-content p {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 12px;
}

.blog-details .comment .single-comment-area .comment-content .replay-btn {
  color: var(--text-color);
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  cursor: pointer;
  transition: 0.35s;
}

.blog-details .comment .single-comment-area .comment-content .replay-btn svg {
  transition: 0.35s;
  fill: var(--text-color);
}

.blog-details .comment .single-comment-area .comment-content .replay-btn:hover {
  color: var(--primary-color1);
}

.blog-details .comment .single-comment-area .comment-content .replay-btn:hover svg {
  fill: var(--primary-color1);
}

.blog-details .comment .comment-replay {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 70px;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  padding: 24px 0;
}

.blog-details .comment .comment-replay>li {
  margin-bottom: 30px;
}

.blog-details .comment .comment-replay>li:last-child {
  margin-bottom: 0;
}

.blog-details .inquiry-form {
  background: rgba(var(--primary-color1-opc), 0.1);
  border-radius: 20px;
  padding: 45px 40px 50px;
}

@media (max-width: 767px) {
  .blog-details .inquiry-form {
    padding: 45px 20px 50px;
  }
}

.blog-details .inquiry-form .title {
  margin-bottom: 30px;
}

.blog-details .inquiry-form .title h4 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-hankenGrotesk);
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0;
}

body.dark .primary-btn3 {
  border: 1px solid #3067ff;
  border-bottom: 3px solid #3067ff;
}

body.dark .details-button {
  background-image: linear-gradient(0deg, #fff 0%, #fff 100%);
}

body.dark .load-btn::after {
  background-color: var(--primary-color1);
}

body.dark .load-btn span {
  background-image: linear-gradient(0deg, #0b0c0c 0%, #0b0c0c 100%);
}

body.dark .load-btn span::after {
  color: var(--dark-primary-color2);
}

body.dark .load-btn span svg {
  stroke: var(--dark-primary-color2);
}

body.dark .load-btn:hover span {
  color: var(--white-color);
}

body.dark .load-btn:hover span svg {
  stroke: var(--white-color);
}

body.dark .form-inner input {
  background-color: var(--light-black-color);
  border: unset;
}

body.dark .form-inner textarea {
  background-color: var(--light-black-color);
  border: unset;
}

body.dark header.style-1 .header-logo .logo-dark {
  display: none;
}

body.dark header.style-1 .header-logo .logo-light {
  display: block;
}

body.dark header.style-1 .company-logo .logo-dark {
  display: none;
}

body.dark header.style-1 .company-logo .logo-light {
  display: block;
}

@media only screen and (max-width: 991px) {
  body.dark header.style-1 .mobile-logo-area .mobile-logo-wrap .logo-dark {
    display: none;
  }

  body.dark header.style-1 .mobile-logo-area .mobile-logo-wrap .logo-light {
    display: block;
  }
}

body.dark header.style-1 .main-menu>ul>li ul.sub-menu>li>a::before {
  background: linear-gradient(90deg, #5f5f5f, var(--primary-color1), #5f5f5f);
}

body.dark header.style-2 .main-menu>ul>li ul.sub-menu>li>a::before {
  background: linear-gradient(90deg, #5f5f5f, var(--primary-color1), #5f5f5f);
}

body.dark header.style-3 .main-menu>ul>li ul.sub-menu>li>a::before {
  background: linear-gradient(90deg, #5f5f5f, var(--primary-color1), #5f5f5f);
}

body.dark header.style-3 .header-logo .logo-dark {
  display: none;
}

body.dark header.style-3 .header-logo .logo-light {
  display: block;
}

body.dark header.style-3 .company-logo .logo-dark {
  display: none;
}

body.dark header.style-3 .company-logo .logo-light {
  display: block;
}

body.dark .sidebar-menu .sidebar-menu-top-area .sidebar-menu-logo .logo-light {
  display: block;
}

body.dark .sidebar-menu .sidebar-menu-top-area .sidebar-menu-logo .logo-dark {
  display: none;
}

body.dark .home1-banner-section .banner-content .banner-content-bottom .rating-area .review .logo-dark {
  display: none;
}

body.dark .home1-banner-section .banner-content .banner-content-bottom .rating-area .review .logo-light {
  display: block;
}

body.dark .home5-banner-section .banner-content .banner-content-bottom .rating-area .review .logo-dark {
  display: none;
}

body.dark .home5-banner-section .banner-content .banner-content-bottom .rating-area .review .logo-light {
  display: block;
}

body.dark .home1-solution-section {
  background-color: var(--light-black-color);
  background-image: unset;
}

body.dark .service-card .explore-btn {
  background: linear-gradient(to bottom, #fff 0%, #fff 98%);
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: left 100%;
}

body.dark .service-card .explore-btn:hover {
  background-size: 0 1px;
  background-position: 0% 100%;
}

body.dark .portfolio-section .portfolio-bottom-area .pagination-area {
  background-color: var(--light-black-color);
}

body.dark .portfolio-section .portfolio-bottom-area .pagination-area .swiper-pagination {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

body.dark .home1-approach-section .single-approach .approach-content-wrap {
  background-image: unset;
  background-color: var(--light-black-color);
}

body.dark .home1-approach-section .single-approach .approach-content-wrap::before {
  background: linear-gradient(180deg,
      rgba(36, 38, 39, 0) 0%,
      rgba(36, 38, 39, 0.76) 26.46%,
      #242627 45.7%);
}

body.dark .testimonial-card-wrapper .testimonial-card .logo-and-rating .logo .light {
  display: none;
}

body.dark .testimonial-card-wrapper .testimonial-card .logo-and-rating .logo .dark {
  display: block;
}

body.dark .home1-testimonial-section {
  background-image: url(../img/home1/testimonial-bg.png),
    linear-gradient(180deg, #0b0c0c 0%, #0b0c0c 100%);
}

body.dark .home1-testimonial-section .testimonial-content .rating-area li .single-rating .review .logo-dark {
  display: none;
}

body.dark .home1-testimonial-section .testimonial-content .rating-area li .single-rating .review .logo-light {
  display: block;
}

body.dark .home1-testimonial-section .testimonial-content .rating-area li::before {
  background-color: rgba(255, 255, 255, 0.2);
}

body.dark .contact-section .contact-form-wrap .contact-form-area {
  background-image: unset;
}

body.dark .footer-section {
  background-color: #000;
  background-image: unset;
}

body.dark .footer-section .footer-top .rating-area li .single-rating .review .logo-dark {
  display: none;
}

body.dark .footer-section .footer-top .rating-area li .single-rating .review .logo-light {
  display: block;
}

body.dark .footer-section .footer-top .rating-area li::before {
  background-color: rgba(255, 255, 255, 0.2);
}

body.dark .footer-section .footer-top .footer-widget .widget-title h4::after {
  background: linear-gradient(90deg,
      #0b0c0c 0.01%,
      #fff 0.02%,
      rgba(255, 255, 255, 0) 102.8%);
  opacity: 0.4;
}

body.dark .footer-section .footer-top .footer-logo-area .logo-bg .light {
  display: none;
}

body.dark .footer-section .footer-top .footer-logo-area .logo-bg .dark {
  display: block;
}

body.dark .footer-section .footer-top .footer-logo-area .logo .light {
  display: none;
}

body.dark .footer-section .footer-top .footer-logo-area .logo .dark {
  display: block;
}

body.dark .footer-section .footer-top .footer-logo .logo-dark {
  display: none;
}

body.dark .footer-section .footer-top .footer-logo .logo-light {
  display: block;
}

body.dark .footer-section.style-2 {
  background-image: unset;
  background-color: var(--light-black-color);
}

body.dark .footer-section.style-3 {
  background-image: unset;
  background-color: var(--light-black-color);
}

body.dark .footer-section.style-4 {
  background-image: unset;
  background-color: var(--light-black-color);
}

body.dark .section-title2>span {
  background-color: var(--light-black-color);
  color: var(--white-color);
  border: 1px solid rgba(var(--title-color-opc), 0.08);
}

body.dark .home2-banner-section {
  background-image: url(../img/home2/home2-banner-bg-dark.png);
}

body.dark .home2-banner-section .banner-content>span {
  background-color: var(--light-black-color);
  color: var(--white-color);
  border: 1px solid rgba(var(--title-color-opc), 0.08);
}

body.dark .home2-banner-section .banner-img .light-img {
  display: none;
}

body.dark .home2-banner-section .banner-img .dark-img {
  display: block;
}

body.dark .home2-service-section {
  background-color: var(--light-black-color);
}

body.dark .home2-service-section .section-title2 span {
  background-color: var(--black-color);
}

body.dark .home2-about-section .about-title>span {
  background-color: var(--light-black-color);
  color: var(--white-color);
  border: 1px solid rgba(var(--title-color-opc), 0.08);
}

body.dark .home2-about-section .about-feature-card {
  background-color: var(--light-black-color);
}

body.dark .home2-process-section {
  background: var(--light-black-color);
}

body.dark .home2-process-section .section-title2 span {
  background-color: var(--black-color);
}

body.dark .home2-process-section .process-slider-area .slider-btn-area {
  border: 1px solid rgba(var(--title-color-opc), 0.1);
}

body.dark .home2-process-section .process-slider-area .slider-btn-area .content {
  border-left: 1px solid rgba(var(--title-color-opc), 0.1);
  border-right: 1px solid rgba(var(--title-color-opc), 0.1);
}

body.dark .home2-process-section .process-slider-area .single-process .number span {
  color: var(--white-color);
}

body.dark .home2-process-section .process-slider-area .single-process .number::before {
  border: 1px solid rgba(var(--title-color-opc), 0.1);
}

body.dark .home2-process-section .process-slider-area .single-process .number::after {
  background: rgba(255, 255, 255, 0.08);
}

body.dark .home2-process-section .process-slider-area::before {
  background: rgba(255, 255, 255, 0.08);
}

body.dark .home2-process-section.two .section-title5 .sub-title5.two::after {
  border: 1px solid rgba(18, 31, 31, 0.1);
  background: #323333;
}

body.dark .home2-process-section.two .section-title5 .sub-title5.two::before {
  background: #434343;
}

body.dark .home2-process-section.two .process-slider-area .single-process .number span {
  color: var(--white-color);
}

body.dark .home2-process-section.two .process-slider-area .single-process .number::before {
  border: 1px solid rgba(var(--title-color-opc), 0.1);
}

body.dark .home2-process-section.two .process-slider-area .single-process .number::after {
  background: rgba(255, 255, 255, 0.08);
}

body.dark .home2-marketing-agency-section {
  background-image: unset;
  background-color: #1c1d26;
}

body.dark .home2-marketing-agency-section .section-title2>span {
  background-color: var(--black-color);
}

body.dark .home2-marketing-agency-section .graf-img .light {
  display: none;
}

body.dark .home2-marketing-agency-section .graf-img .dark {
  display: block;
}

body.dark .home2-testimonial-section .testimonial-content {
  background-color: var(--light-black-color);
}

body.dark .home2-testimonial-section .testimonial-content .section-title2 span {
  background: var(--black-color);
  border: none;
}

body.dark .home2-testimonial-section .testimonial-wrap {
  background-color: transparent;
}

body.dark .home2-testimonial-section .testimonial-wrap .rating-area li .single-rating .review .logo-dark {
  display: none;
}

body.dark .home2-testimonial-section .testimonial-wrap .rating-area li .single-rating .review .logo-light {
  display: block;
}

body.dark .home2-testimonial-section .testimonial-wrap .rating-area li::before {
  background-color: rgba(255, 255, 255, 0.2);
}

body.dark .home2-contact-section {
  background-image: unset;
  background-color: var(--light-black-color);
}

body.dark .home2-contact-section .section-title2>span {
  background-color: var(--black-color);
}

body.dark .home3-process-section .process-card {
  background-color: var(--light-black-color);
}

body.dark .pricing-card.three {
  background: rgba(var(--primary-color1-opc), 0.1);
}

body.dark .pricing-card .pricing-content ul li::before {
  background: linear-gradient(90deg,
      rgba(5, 45, 57, 0.75) 0%,
      rgba(5, 45, 57, 0) 81.82%);
}

body.dark .home3-project-management-section .project-management-tab-wrapper .project-management-nav .nav-tabs .nav-link {
  background: linear-gradient(to bottom, white 0%, white 98%);
  background-size: 0 3px;
  background-repeat: no-repeat;
  background-position: right 100%;
  transition: background-size 0.75s;
}

body.dark .home3-project-management-section .project-management-tab-wrapper .project-management-nav .nav-tabs .nav-link.active {
  background-size: 100% 3px;
  background-position: 0% 100%;
}

body.dark .home3-project-management-section .project-management-tab-wrapper .project-management-tab .project-management-tab-content {
  background-image: unset;
  background-color: var(--light-black-color);
}

body.dark .home3-testimonial-section {
  background-image: unset;
  background-color: var(--light-black-color);
}

body.dark .home3-feature-section .feature-content-wrap .single-feature .icon {
  background-color: var(--light-black-color);
}

body.dark .home3-feature-section .feature-content-wrap .single-feature .icon svg {
  fill: var(--white-color);
}

body.dark .home3-faq-section {
  background-image: unset;
  background-color: var(--light-black-color);
}

body.dark .home4-banner-section {
  background-image: url(../img/home4/home4-banner-bg-dark.png),
    linear-gradient(180deg, #121212 0%, #121212 100%);
}

body.dark .home4-banner-section .banner-img.light {
  display: none;
}

body.dark .home4-banner-section .banner-img.dark {
  display: block;
}

body.dark .home4-about-section .about-coundown-area .single-countdown .content {
  background-color: #000;
}

body.dark .home4-about-section .about-coundown-area .single-countdown .content p {
  color: rgba(255, 255, 255, 0.6);
}

body.dark .feature-card.two::after {
  opacity: 1;
}

body.dark .feature-card.two .icon .icon-bg svg {
  fill: rgba(var(--primary-color1-opc), 0.2);
}

body.dark .home2-pricing-plan-section .pricing-plan-tab-area nav .nav-tabs {
  border: 1px solid rgba(63, 68, 75, 0.4);
}

body.dark .partnership-area .total-partner a {
  border-color: #4d4d4d;
}

body.dark .partnership-area .total-partner a:hover {
  border-color: var(--primary-color1);
}

body.dark .partnership-area .single-pertner {
  background: #242627;
}

body.dark .partnership-area .single-pertner svg {
  stroke: #474747;
}

body.dark .partnership-area .single-pertner span {
  color: var(--title-color);
}

body.dark .partnership-area .single-pertner .partner-logos .black {
  display: none;
  visibility: hidden;
}

body.dark .partnership-area .single-pertner .partner-logos .white {
  display: block;
  visibility: visible;
}

body.dark .partnership-area .single-pertner p {
  color: var(--text-color);
}

body.dark .partnership-area .single-pertner:hover {
  background-color: var(--title-color);
}

body.dark .partnership-area .single-pertner:hover svg {
  stroke: var(--primary-color1);
}

body.dark .partnership-area .single-pertner:hover span {
  color: var(--dark-white-color);
}

body.dark .partnership-area .single-pertner:hover p {
  color: var(--dark-white-color);
}

body.dark .partnership-area .single-pertner:hover .partner-logos .white {
  display: none;
  visibility: hidden;
}

body.dark .partnership-area .single-pertner:hover .partner-logos .black {
  display: block;
  visibility: visible;
}

body.dark .home5-banner-section {
  background-image: url(../img/home2/home2-banner-bg-dark.png);
}

body.dark .home5-services-section {
  background-color: #242627;
}

body.dark .home5-why-choose-section .why-choose-content .about-coundown-area .single-countdown .content {
  background-color: #000;
}

body.dark .sub-title5 {
  color: var(--text-color);
}

body.dark .sub-title5::after {
  border: 1px solid rgba(11, 12, 12, 0.1);
  background: #3b3b3b;
}

body.dark .sub-title5::before {
  background: rgba(241, 244, 255, 0.2);
}

body.dark .sub-title5.two::after {
  border: 1px solid rgba(18, 31, 31, 0.1);
  background: #323333;
}

body.dark .sub-title5.two::before {
  background: #434343;
}

body.dark .home5-team-section {
  background-color: var(--light-black-color);
}

body.dark .portfolio-card {
  border: 1px solid #242627;
}

body.dark .portfolio-card .image-and-tag {
  background-color: var(--light-black-color);
}

body.dark .portfolio-card .image-and-tag .tag li a {
  background-color: #000;
}

body.dark .portfolio-card .image-and-tag .tag li a:hover {
  color: var(--white-color);
  background-color: var(--primary-color1);
}

body.dark .portfolio-card .portfolio-content h4 a {
  color: var(--title-color);
}

body.dark .portfolio-card .portfolio-content h4 a:hover {
  color: var(--primary-color1);
}

body.dark .home4-footer-section.style-2 {
  background: var(--light-black-color);
}

body.dark .home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .widget-title {
  margin-bottom: 25px;
}

body.dark .home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .widget-title h4::after {
  background: linear-gradient(90deg,
      #0b0c0c 0.01%,
      #fff 0.02%,
      rgba(255, 255, 255, 0) 102.8%);
}

body.dark .home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .footer-logo .logo-dark {
  display: none;
}

body.dark .home4-footer-section.style-2 .home4-footer-wrap .footer-top .footer-widget .footer-logo .logo-light {
  display: block;
}

body.dark .home4-footer-section.style-2 .contact-area {
  background: var(--light-black-color);
}

body.dark .home5-contact-section {
  background: var(--light-black-color);
}

body.dark .case-study-card.style-2 .card-content .learn-more-btn {
  background: linear-gradient(to bottom, #fff 0%, #fff 98%);
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: left 100%;
  transition: background-size 0.75s;
}

body.dark .case-study-card.style-2 .card-content .learn-more-btn:hover {
  background-size: 0 1px;
  background-position: 0% 100%;
}

body.dark .product-card {
  border: 1px solid rgba(255, 255, 255, 0.15);
}

body.dark .checkout-page {
  background-color: var(--light-black-color);
}

body.dark .checkout-page .checkout-form-wrapper .checkout-form {
  background-color: var(--dark-white-color);
}

body.dark .blog-details .inquiry-form {
  background: var(--dark-white-color);
}

body.dark .sidebar-area .single-widget .tag-list li a {
  background: 0 0;
  border: 1px solid var(--border-color);
}

body.dark .sidebar-area .single-widget .tag-list li a:hover {
  background: var(--primary-color1);
}

.tt-style-switch {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 30%;
  right: 0;
  width: 40px;
  height: 35px;
  background-color: #444;
  font-size: 16px;
  color: #fff !important;
  cursor: pointer;
  z-index: 9999;
  border-radius: 50px 0 0 50px;
  transition: all 0.2s;
}

.tt-style-switch .bi {
  color: #fff;
  z-index: 9999;
}

body:not(.is-mobile) .tt-style-switch:hover {
  width: 45px;
  background-color: red;
  color: #fff;
}

.tt-stsw-dark,
.tt-stsw-light {
  line-height: 0;
}

.tt-style-switch:not(.is-dark) .tt-stsw-dark {
  display: none;
}

.tt-style-switch.is-dark .tt-stsw-light {
  display: none;
}

@media (min-width: 769px) {
  body.tt-sliding-sidebar-right-on .tt-style-switch {
    right: auto;
    left: 0;
    border-radius: 0 50px 50px 0;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }

  50% {
    opacity: 1;
  }
}

@keyframes bounceInDown {

  0%,
  60%,
  75%,
  90%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  100% {
    transform: none;
  }
}