/*===== userdetails  =====*/
.step-app.h-wizard-demo3>.step-steps {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem;
    border-radius: 0;
    border-bottom: 1px dashed var(--border-color);
}

.step-app.h-wizard-demo3>.step-steps>li {
    background-color: transparent;
    display: flex;
    align-items: center;
    flex: inherit;
    outline: none;
    font-size: 18px;
    margin-right: 25px;
    flex-grow: 0;
}

.step-app.h-wizard-demo3>.step-steps>li span {
    background-color: #00b4b7;
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;
    border-radius: 5rem;
    margin: 0 10px 0 0;
    height: 36px;
    width: 36px;
}

.step-app.h-wizard-demo3>.step-steps>li span:hover {
    background-color: orange;
}

.step-app.h-wizard-demo3>.step-content {
    padding: 1.5rem;
    border: 0;
}

.step-app.h-wizard-demo3>.step-steps>li span.active {
    background-color: orange;
}

.step-app.h-wizard-demo3>.step-footer {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    color: var(--bs-body-color);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border-radius: var(--bs-card-border-radius);
}


/*===== EmployementDetails  =====*/
.nextbtn {
    background-color: #00b4b7;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
}

/*===== Add Role  =====*/

.form-check-input:checked {
    background-color: orange;
    border-color: orange;
}